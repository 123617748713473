import { axiosDefault, axiosHeaders } from '../_helpers/auth-header';
import { config } from '../_helpers/config';

//  Driver actions :
//  postDriver, getDrivers, putDriver, deleteDriver

export const postDriver = (workspaceId, target) => {
    const url = config.ROApi + 'workspaces/' + workspaceId + '/drivers';

    let postData = {
        Id: target.Id,
        WorkspaceId: target.WorkspaceId,
        DriverName: target.DriverName,
        CostOtStart: target.CostOtStart,
        CostPerDist: isNaN(target.CostPerDist) ? parseFloat(target.CostPerDist.replace('$', '').replace(/,/g, '')) : parseFloat(target.CostPerDist),
        CostPerHour: isNaN(target.CostPerHour) ? parseFloat(target.CostPerHour.replace('$', '').replace(/,/g, '')) : parseFloat(target.CostPerHour),
        CostPerOtHour: isNaN(target.CostPerOtHour)
            ? parseFloat(target.CostPerOtHour.replace('$', '').replace(/,/g, ''))
            : parseFloat(target.CostPerOtHour),
        CostPerPiece: isNaN(target.CostPerPiece)
            ? parseFloat(target.CostPerPiece.replace('$', '').replace(/,/g, ''))
            : parseFloat(target.CostPerPiece),
        CostPerStop: isNaN(target.CostPerStop) ? parseFloat(target.CostPerStop.replace('$', '').replace(/,/g, '')) : parseFloat(target.CostPerStop),
        SkillSetProperties: target.SkillSetProperties,
    };

    let headers = {
        Authorization: 'Bearer ' + JSON.parse(window.sessionStorage['userInfo']).accessToken,
    };

    return axiosDefault.post(url, postData, { headers });
};

export const getDrivers = async (workspaceId) => {
    const url = config.ROApi + 'workspaces/' + workspaceId + '/drivers';

    let headers = {
        Authorization: 'Bearer ' + JSON.parse(window.sessionStorage['userInfo']).accessToken,
    };

    return axiosDefault.get(url, { headers });
};

export const putDriver = async (workspaceId, target) => {
    const url = config.ROApi + 'workspaces/' + workspaceId + '/drivers/' + target.Id;

    let putData = {
        Id: target.Id,
        WorkspaceId: target.WorkspaceId,
        DriverName: target.DriverName,
        CostOtStart: target.CostOtStart,
        CostPerDist: isNaN(target.CostPerDist) ? parseFloat(target.CostPerDist.replace('$', '').replace(/,/g, '')) : parseFloat(target.CostPerDist),
        CostPerHour: isNaN(target.CostPerHour) ? parseFloat(target.CostPerHour.replace('$', '').replace(/,/g, '')) : parseFloat(target.CostPerHour),
        CostPerOtHour: isNaN(target.CostPerOtHour)
            ? parseFloat(target.CostPerOtHour.replace('$', '').replace(/,/g, ''))
            : parseFloat(target.CostPerOtHour),
        CostPerPiece: isNaN(target.CostPerPiece)
            ? parseFloat(target.CostPerPiece.replace('$', '').replace(/,/g, ''))
            : parseFloat(target.CostPerPiece),
        CostPerStop: isNaN(target.CostPerStop) ? parseFloat(target.CostPerStop.replace('$', '').replace(/,/g, '')) : parseFloat(target.CostPerStop),
        SkillSetProperties: target.SkillSetProperties,
    };

    let headers = {
        Authorization: 'Bearer ' + JSON.parse(window.sessionStorage['userInfo']).accessToken,
    };

    return axiosDefault.put(url, putData, { headers });
};

export const deleteDriver = async (workspaceId, target) => {
    const url = config.ROApi + 'workspaces/' + workspaceId + '/drivers/' + target.Id;

    let headers = {
        Authorization: 'Bearer ' + JSON.parse(window.sessionStorage['userInfo']).accessToken,
    };

    return axiosDefault.delete(url, { headers });
};

//  Facility
export const getFacilities = async (workspaceId, siteMode, project) => {
    let url = config.ROApi + `workspaces/${workspaceId}/facilities`;
    if (siteMode === 'PLANNER') {
        url = config.ROApi + `workspaces/${workspaceId}/sandbox/${project}/facilities`;
    }
    let headers = {
        Authorization: 'Bearer ' + JSON.parse(window.sessionStorage['userInfo']).accessToken,
    };
    return axiosDefault.get(url, { headers });
};

//  Route Plan actions :                                                            //
//  postRoutePlan, getRoutePlans, putRoutePlan, deleteRoutePlan                     //

export const postRoutePlan = async (workspaceId, target) => {
    const url = config.ROApi + 'workspaces/' + workspaceId + '/routeplans/' + target.Id;

    let postData = {
        Id: target.Id,
        WorkspaceId: target.WorkspaceId,
        PlanName: target.PlanName,
        GenerateMethod: target.GenerateMethod,
        ServDaySunday: target.ServDaySunday,
        ServDayMonday: target.ServDayMonday,
        ServDayTuesday: target.ServDayTuesday,
        ServDayWednesday: target.ServDayWednesday,
        ServDayThursday: target.ServDayThursday,
        ServDayFriday: target.ServDayFriday,
        ServDaySaturday: target.ServDaySaturday,
        NumRoutes: 1,
        Zones: target.Zones,
        SandboxId: target.SandboxId,
    };

    let headers = {
        Authorization: 'Bearer ' + JSON.parse(window.sessionStorage['userInfo']).accessToken,
    };

    return axiosDefault.post(url, postData, { headers });
};

export const getRoutePlans = (workspaceId, siteMode, project) => {
    let url = config.ROApi + 'workspaces/' + workspaceId + '/routeplans';
    //if (siteMode === 'PLANNER') {
    if (project) {
        url = config.ROApi + 'workspaces/' + workspaceId + '/sandboxes/' + project + '/routeplans';
    }
    let headers = {
        Authorization: 'Bearer ' + JSON.parse(window.sessionStorage['userInfo']).accessToken,
    };

    return axiosDefault.get(url, { headers });
};

export const putRoutePlan = (workspaceId, target) => {
    const url = config.ROApi + 'workspaces/' + workspaceId + '/routeplans/' + target.Id;
    let putData = {
        Id: target.Id,
        WorkspaceId: target.WorkspaceId,
        PlanName: target.PlanName,
        GenerateMethod: target.GenerateMethod,
        ServDaySunday: target.ServDaySunday,
        ServDayMonday: target.ServDayMonday,
        ServDayTuesday: target.ServDayTuesday,
        ServDayWednesday: target.ServDayWednesday,
        ServDayThursday: target.ServDayThursday,
        ServDayFriday: target.ServDayFriday,
        ServDaySaturday: target.ServDaySaturday,
        NumRoutes: target.NumRoutes,
        Zones: target.Zones,
        SandboxId: target.SandboxId,
    };

    let headers = {
        Authorization: 'Bearer ' + JSON.parse(window.sessionStorage['userInfo']).accessToken,
    };

    return axiosDefault.put(url, putData, { headers });
};

export const deleteRoutePlan = (workspaceId, target) => {
    const url = config.ROApi + 'workspaces/' + workspaceId + '/routeplans/' + target.Id;

    let headers = {
        Authorization: 'Bearer ' + JSON.parse(window.sessionStorage['userInfo']).accessToken,
    };

    return axiosDefault.delete(url, { headers });
};

export const copyRoutePlan = (workspaceId, project, target) => {
    const url = `${config.ROApi}workspaces/${workspaceId}/sandboxes/${project}/routeplans/${target.Id}`;

    let postData = {
        Id: target.Id,
        WorkspaceId: target.WorkspaceId,
        PlanName: target.PlanName,
        GenerateMethod: target.GenerateMethod,
        ServDaySunday: target.ServDaySunday,
        ServDayMonday: target.ServDayMonday,
        ServDayTuesday: target.ServDayTuesday,
        ServDayWednesday: target.ServDayWednesday,
        ServDayThursday: target.ServDayThursday,
        ServDayFriday: target.ServDayFriday,
        ServDaySaturday: target.ServDaySaturday,
        NumRoutes: 1,
        Zones: target.Zones,
        SandboxId: target.SandboxId,
    };

    let headers = {
        Authorization: 'Bearer ' + JSON.parse(window.sessionStorage['userInfo']).accessToken,
    };

    return axiosDefault.post(url, postData, { headers });
};

//  Route Set actions :                                                              //
//  postRouteSet, getRouteSet, putRouteSet, deleteRouteSet                           //

export const postRouteSet = (workspaceId, target) => {
    const url = config.ROApi + 'workspaces/' + workspaceId + '/routesets';

    let PreStartTime = target.PreStartTime,
        PostEndTime = target.PostEndTime;
    if (PreStartTime && PreStartTime.includes(':')) {
        let pst = target.PreStartTime.split(':');
        PreStartTime = pst[0] * 60 * 60 + pst[1] * 60;
    }
    if (PostEndTime && PostEndTime.includes(':')) {
        let pet = target.PostEndTime.split(':');
        PostEndTime = pet[0] * 60 * 60 + pet[1] * 60;
    }

    let postData = {
        Id: target.Id,
        RoutePlanId: target.RoutePlanId,
        Route: target.Route,
        DriverId: target.DriverId,
        TruckId: target.TruckId,
        Breaks: target.Breaks,
        Trips: target.Trips,
        TargetTime: target.TargetTime,
        PostEndTime: PostEndTime,
        PreStartTime: PreStartTime,
        StartLocation: target.StartLocation,
        StartTime: target.StartTime,
        BreakInfoList: target.BreakInfoList,
        TripInfoList: target.TripInfoList,
        ServeTimeMult: target.ServeTimeMult,
    };

    let headers = {
        Authorization: 'Bearer ' + JSON.parse(window.sessionStorage['userInfo']).accessToken,
    };

    return axiosDefault.post(url, postData, { headers });
};

export const getRouteSets = async (workspaceId, routeplanId, siteMode, project) => {
    let url = `${config.ROApi}workspaces/${workspaceId}/routeplans/${routeplanId}/routesets`;
    if (siteMode === 'PLANNER') {
        url = `${config.ROApi}workspaces/${workspaceId}/sandboxes/${project}/routeplans/${routeplanId}/routesets`;
    }
    let headers = {
        Authorization: 'Bearer ' + JSON.parse(window.sessionStorage['userInfo']).accessToken,
    };

    return axiosDefault.get(url, { headers });
};

export const putRouteSet = async (workspaceId, target) => {
    const url = config.ROApi + 'workspaces/' + workspaceId + '/routesets/' + target.Id;

    let PreStartTime = target.PreStartTime,
        PostEndTime = target.PostEndTime;
    if (PreStartTime && PreStartTime.includes(':')) {
        let pst = target.PreStartTime.split(':');
        PreStartTime = pst[0] * 60 * 60 + pst[1] * 60;
    }
    if (PostEndTime && PostEndTime.includes(':')) {
        let pet = target.PostEndTime.split(':');
        PostEndTime = pet[0] * 60 * 60 + pet[1] * 60;
    }

    let putData = {
        Id: target.Id,
        RoutePlanId: target.RoutePlanId,
        Route: target.Route,
        DriverId: target.DriverId,
        TruckId: target.TruckId,
        Breaks: target.Breaks,
        Trips: target.Trips,
        TargetTime: target.TargetTime,
        PostEndTime: PostEndTime,
        PreStartTime: PreStartTime,
        StartLocation: target.StartLocation,
        StartTime: target.StartTime,
        BreakInfoList: target.BreakInfoList,
        TripInfoList: target.TripInfoList,
        ServeTimeMult: target.ServeTimeMult,
    };

    let headers = {
        Authorization: 'Bearer ' + JSON.parse(window.sessionStorage['userInfo']).accessToken,
    };

    return axiosDefault.put(url, putData, { headers });
};

export const deleteRouteSet = async (workspaceId, target) => {
    const url = config.ROApi + 'workspaces/' + workspaceId + '/routesets/' + target.Id;

    let headers = {
        Authorization: 'Bearer ' + JSON.parse(window.sessionStorage['userInfo']).accessToken,
    };

    return axiosDefault.delete(url, { headers });
};

//  Truck actions :
//  postTruck, getTrucks, putTruck, deleteTruck
export const postTruck = async (workspaceId, target) => {
    const url = config.ROApi + 'workspaces/' + workspaceId + '/trucks';
    let postData = {
        Id: target.Id,
        WorkspaceId: target.WorkspaceId,
        TruckName: target.TruckName,
        VehicleCapacity: [isNaN(target.VehicleCapacity) ? parseFloat(target.VehicleCapacity.replace(/,/g, '')) : parseFloat(target.VehicleCapacity)],
        CostFixed: isNaN(target.CostFixed) ? parseFloat(target.CostFixed.replace('$', '').replace(/,/g, '')) : parseFloat(target.CostFixed),
        CostVariable: isNaN(target.CostVariable)
            ? parseFloat(target.CostVariable.replace('$', '').replace(/,/g, ''))
            : parseFloat(target.CostVariable),
        ServiceSide: target.ServiceSide,
        SkillSetProperties: target.SkillSetProperties,
    };
    let headers = {
        Authorization: 'Bearer ' + JSON.parse(window.sessionStorage['userInfo']).accessToken,
    };

    return axiosDefault.post(url, postData, { headers });
};

export const getTrucks = async (workspaceId) => {
    const url = config.ROApi + 'workspaces/' + workspaceId + '/trucks';

    let headers = {
        Authorization: 'Bearer ' + JSON.parse(window.sessionStorage['userInfo']).accessToken,
    };

    return axiosDefault.get(url, { headers });
};

export const putTruck = async (workspaceId, target) => {
    const url = config.ROApi + 'workspaces/' + workspaceId + '/trucks/' + target.Id;
    let putData = {
        Id: target.Id,
        WorkspaceId: target.WorkspaceId,
        TruckName: target.TruckName,
        VehicleCapacity: [isNaN(target.VehicleCapacity) ? parseFloat(target.VehicleCapacity.replace(/,/g, '')) : parseFloat(target.VehicleCapacity)],
        CostFixed: isNaN(target.CostFixed) ? parseFloat(target.CostFixed.replace('$', '').replace(/,/g, '')) : parseFloat(target.CostFixed),
        CostVariable: isNaN(target.CostVariable)
            ? parseFloat(target.CostVariable.replace('$', '').replace(/,/g, '')).toFixed(2)
            : parseFloat(target.CostVariable).toFixed(2),
        ServiceSide: target.ServiceSide,
        SkillSetProperties: target.SkillSetProperties,
    };

    let headers = {
        Authorization: 'Bearer ' + JSON.parse(window.sessionStorage['userInfo']).accessToken,
    };

    return axiosDefault.put(url, putData, { headers });
};

export const deleteTruck = async (workspaceId, target) => {
    const url = config.ROApi + 'workspaces/' + workspaceId + '/trucks/' + target.Id;

    let headers = {
        Authorization: 'Bearer ' + JSON.parse(window.sessionStorage['userInfo']).accessToken,
    };

    return axiosDefault.delete(url, { headers });
};

export const updateAllServiceSide = async (workspaceId, ServiceSide) => {
    const url = config.ROApi + 'workspaces/' + workspaceId + '/trucks/' + ServiceSide;
    let headers = {
        Authorization: 'Bearer ' + JSON.parse(window.sessionStorage['userInfo']).accessToken,
    };

    return axiosDefault.put(url, null, { headers });
};

export const getSpecialties = (workspace) => {
    const url = `${config.ROApi}workspaces/${workspace}/specialties`;
    return axiosDefault.get(url, axiosHeaders());
};

export const createSpecialty = (workspace, data) => {
    const url = `${config.ROApi}workspaces/${workspace}/specialties`;
    return axiosDefault.post(url, data, axiosHeaders());
};

export const deleteSpecialty = (workspace, id) => {
    const url = `${config.ROApi}workspaces/${workspace}/specialties/${id}`;
    return axiosDefault.delete(url, axiosHeaders());
};
