import React, { useState, useEffect, useRef, useContext } from 'react';
//RPW Components
import { config } from '../../_helpers/config';
import { SidePanel } from './SidePanel';
import { ReportPanel, DispatchReportPanel } from './ReportPanel';
import { ExportPanel, DispatchExportPanel } from './ExportPanel';
import {
    ServiceLocationTable,
    ScenarioServiceLocationTable,
    PinServiceLocationDialog,
    UnpinServiceLocationDialog,
    EditServiceLocationDialog,
    DeleteServiceLocationDialog,
} from './ServiceLocationTable';
import { RefreshServiceLocationDialog } from './RefreshServiceLocationsDialog';
import { SequenceDialog } from './EditSequenceDialog';
import { SelectionDetailPanel, SwapConfirmDialog } from './SelectionDetailPanel';
import { SandboxSolverDialog, SandboxUpdateRouteStatsDialog } from './SandboxSolverDialog';
import { StreetMode, DeleteTurnExceptionDialog, AlleyAssignmentTable, DeleteAlleyAssignmentsDialog } from './StreetSettings';
import {
    getFormattedTime,
    getFormattedTimeSeconds,
    debounce,
    renderServiceWindow,
    renderServiceProhibited,
    serviceDayList,
    isMobile,
    colors,
    slOutFields,
    hexToRGBA,
    defaultServiceDayList,
    hhmmssToFloat,
    regularSymbol,
    editingSymbol,
    assignedSymbol,
    firstSymbol,
    lastSymbol,
    otherRouteSymbol,
    compareArray,
    ROUTE_VIEW_TAB_CATEGORIES,
    STREET_RENDERER,
    INVISIBLE_STREET_RENDERER,
} from '../../_helpers/common';
import {
    HEREStreetBaseMap_V2,
    HERESatelliteBaseMap_V2,
    HEREHybridBaseMap_V2,
    HEREStreetBaseMap_V3,
    HERESatelliteBaseMap_V3,
    HEREHybridBaseMap_V3,
    HERETrafficLayer_V2,
    HERETrafficLayer_V3,
} from '../../_helpers/arcgis/BasemapsAndWebTiles';
import { customSearchSource_V2, customSearchSource_V3 } from '../../_helpers/arcgis/SearchSource';
import FacilityTable from './FacilityTable';
import { RouteSummaryTable, ScenarioRouteSummaryTable, TripsInfoPanel, SmartSuiteDialog } from './RouteSummaryTable';
import {
    NeighborhoodClusterTable,
    NeighborhoodClusterDialog,
    ReshapeNeighborhoodClusterDialog,
    DeleteNeighborhoodClusterDialog,
    WarningDialog,
} from '../ManageNeighborhoods/ManageNeighborhoodsController';
import { ProjectTable, ScenarioTable } from './PlannerTables';
import { PrintDialog } from './PrintDialog';
import {
    getWorkspaceExtent,
    getRouteList,
    getDispatchHistory,
    getRouteSummary,
    pinServiceLocations,
    unpinServiceLocations,
    flipServiceLocations,
    getJobRouteExtent,
    getJobRouteSequence,
    getJobRouteTravelPath,
    updateServiceWindow,
    getRouteSummaries,
    getWorkspaceServiceDayHealthScore,
    getFacilities,
    getServiceLocations,
    getSelectedRouteExtent,
    getReportTemplates,
    getTrips,
    sendSmartSuite,
    getRouteDayExtent,
    updateStreetClosure,
    updateServiceSide,
    putTurnException,
    putUTurnException,
    deleteUserTurnException,
    swapPatterns,
    getReports,
    getSideOfStreet,
    getExportFormats,
    exportServiceLocations,
    getTravelPathData,
    getSeqGap,
    getSequenceLayerData,
    createNeighborhoodClusters,
    deleteNeighborhoodClusters,
    deleteSelectedZones,
    getServicePatterns,
    createServicePattern,
    updateServicePattern,
    deleteServicePattern,
    getNeighborhoodClusters,
    getSequenceClusters,
    getZoneInfo,
    exportServicePattern,
    exportRouteDayServiceLocations,
    updateNeighborhoodClusters,
    updateNeighborhoodClustersAssignment,
    exportRouteDayServicePatterns,
    getRoutesFromProject,
    swapStops,
    sendToNavusoft,
    getRouteDay,
    getServiceAreaBoundary,
    createServiceAreaBoundary,
    updateServiceAreaBoundary,
    deleteServiceAreaBoundary,
    submitJob,
    getAlleyAssignments,
    createAlleyAssignments,
    deleteAlleyAssignments,
    checkAlleyAssignments,
    getScenarioServiceLocations,
    deleteServiceLocations,
} from '../../_actions/MapPage.actions';
import { getTrucks, getSpecialties } from '../../_actions/Manage.actions';
import { ManagementSpecialtyDialog } from '../Manage/SpecialtyDialogs';
//Mui
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import FormControl from '@mui/material/FormControl';
import DialogTitle from '@mui/material/DialogTitle';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import MuiSelect from '@mui/material/Select';
//Esri
import Map from '@arcgis/core/Map';
import MapView from '@arcgis/core/views/MapView';
import Search from '@arcgis/core/widgets/Search';
import Home from '@arcgis/core/widgets/Home';
import Zoom from '@arcgis/core/widgets/Zoom';
import BasemapGallery from '@arcgis/core/widgets/BasemapGallery';
import Expand from '@arcgis/core/widgets/Expand';
import CoordinateConversion from '@arcgis/core/widgets/CoordinateConversion';
import Legend from '@arcgis/core/widgets/Legend';
import Measurement from '@arcgis/core/widgets/Measurement';
import FeatureLayer from '@arcgis/core/layers/FeatureLayer';
import MapImageLayer from '@arcgis/core/layers/MapImageLayer';
import Popup from '@arcgis/core/widgets/Popup';
import * as reactiveUtils from '@arcgis/core/core/reactiveUtils';
import * as urlUtils from '@arcgis/core/core/urlUtils';
import esriConfig from '@arcgis/core/config';
import Viewpoint from '@arcgis/core/Viewpoint';
import Graphic from '@arcgis/core/Graphic';
import { Extent, Point } from '@arcgis/core/geometry';
import Draw from '@arcgis/core/views/draw/Draw';
import * as webMercatorUtils from '@arcgis/core/geometry/support/webMercatorUtils';
import '@arcgis/core/assets/esri/themes/light/main.css';
import Color from '@arcgis/core/Color';
import UniqueValueRenderer from '@arcgis/core/renderers/UniqueValueRenderer';
import GraphicsLayer from '@arcgis/core/layers/GraphicsLayer';
import SketchViewModel from '@arcgis/core/widgets/Sketch/SketchViewModel';
import Polyline from '@arcgis/core/geometry/Polyline';
import * as print from '@arcgis/core/rest/print';
import PrintTemplate from '@arcgis/core/rest/support/PrintTemplate';
import PrintParameters from '@arcgis/core/rest/support/PrintParameters';
import Sketch from '@arcgis/core/widgets/Sketch';
import { updateUserActivity, checkFunction, checkDisableSandbox } from '../../_actions/Global.actions';
import { getRoutePlans, getRouteSets } from '../../_actions/Manage.actions';
import 'devextreme/dist/css/dx.light.compact.css';
import { formatFileTimeStamp } from '../../_helpers/date';
import { ManageServicePattern, UpdateServicePatternDialog } from './ServicePattern';
import { ServiceAreaBoundaryWidget, ReshapeDialogServiceAreaBoundary, DeleteDialogServiceAreaBoundary } from './ServiceAreaBoundaryWidget';
import { signalRService } from '../../_helpers/signalr-connection';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import { GlobalROContext, RODialog } from '../common';
import * as intl from '@arcgis/core/intl.js';

let serviceLocationLayer,
    projectServiceLocationLayer,
    servicePatternLayer,
    projectServicePatternLayer,
    servicePatternQuery,
    projectServicePatternQuery;
let RAASServiceLocationLayer;
let facilityLayer, RAASFacilityLayer, projectFacilityLayer, streetsLayer;
let turnExceptionsLayer, serviceSidesLayer, closedStreetsLayer;
let serviceLocationQuery, projectServiceLocationQuery, RaaSServiceLocationQuery;
let serviceAreaGraphicLayer, editServiceAreaGraphicLayer;
let defineServiceAreaSketch, editServiceAreaSketch;
let alleyAssignmentsLayer, blockFaceLayer, alleyAssignmentsGraphicsLayer;
let routeBlockfaceLayer, sandboxRouteBlockfaceLayer;
let isResizing = false;

const serviceDayMap = {
    0: 'No Service Day',
    1: 'Monday',
    2: 'Tuesday',
    3: 'Wednesday',
    4: 'Thursday',
    5: 'Friday',
    6: 'Saturday',
    7: 'Sunday',
};

const initialRouteViewTableData = {
    'R/S': [],
    'S/R': [],
    R: [],
    S: [],
};

export const MapPage = () => {
    const {
        hereVersion,
        setHereVersion,
        workspace,
        setWorkspace,
        serviceDay,
        setServiceDay,
        week,
        setWeek,
        frequency,
        setFrequency,
        routePlanId,
        setRoutePlanId,
        servicePattern,
        setServicePattern,
        project,
        setProject,
        projectObject,
        siteMode,
        setSiteMode,
        servicePatternView,
        setServicePatternView,
        runningJob,
        setRunningJob,
        globalLoading,
        setGlobalLoading,
        routingProjects,
        setRoutingProjects,
        routingProjectScenarios,
        setRoutingProjectScenarios,
        refreshProject,
        setRefreshProject,
        refreshScenario,
        setRefreshScenario,
        showWorkspaceList,
        setShowWorkspaceList,
        showServiceDayList,
        setShowServiceDayList,
        showRoutePlanList,
        setShowRoutePlanList,
        showWeekList,
        setShowWeekList,
        showFrequencyList,
        setShowFrequencyList,
        showServicePatternList,
        setShowServicePatternList,
        showDownloadWorkspace,
        setShowDownloadWorkspace,
        openAlert,
        setOpenAlert,
        alertTitle,
        setAlertTitle,
        alertMessage,
        setAlertMessage,
    } = useContext(GlobalROContext);

    const workspaceId = useRef(workspace);
    const selectedServiceDay = useRef(serviceDay);

    let ArcgisAPI = config.ArcgisAPI;
    let PrintURL = config.PrintURL;
    let RaaSArcgisAPI = config.RaaSArcgisAPI;
    let RaaSPrintURL = config.RaaSPrintURL;

    const isDev = process.env.NODE_ENV === 'development';
    if (!isDev) {
        let userInfo = {};
        if (window.sessionStorage['userInfo']) {
            userInfo = JSON.parse(window.sessionStorage['userInfo']);
        }
        ArcgisAPI = ArcgisAPI.replace('[RoeClientToken]', userInfo.accessToken);
        PrintURL = PrintURL.replace('[RoeClientToken]', userInfo.accessToken);
        RaaSArcgisAPI = RaaSArcgisAPI.replace('[RoeClientToken]', userInfo.accessToken);
        RaaSPrintURL = RaaSPrintURL.replace('[RoeClientToken]', userInfo.accessToken);
    }

    //Map state
    const [map, setMap] = useState(null);
    const [view, setView] = useState(null);
    const [initExtent, setInitExtent] = useState(null);
    const [windowDimensions, setWindowDimensions] = useState({
        height: window.innerHeight,
        width: window.innerWidth,
    });

    const [mapMode, _setMapMode] = useState(null);
    const mapModeRef = React.useRef(mapMode);
    const setMapMode = (x) => {
        mapModeRef.current = x;
        _setMapMode(x);
    };

    const [printServiceDayLayer, setPrintServiceDayLayer] = useState(null);
    const [printProjectServiceDayLayer, setPrintProjectServiceDayLayer] = useState(null);
    const [streetHighlightGraphicsLayer, setStreetHighlightGraphicsLayer] = useState(null);
    const [pickXYGraphicsLayer, setPickXYGraphicsLayer] = useState(null);
    const [pickXYSketch, setPickXYSketch] = useState(null);

    const [trafficLayer, setTrafficLayer] = useState(null);
    const [userTurnExceptionsGraphicsLayer, setUserTurnExceptionsGraphicsLayer] = useState(null);
    const [bottomPaneVisibility, setBottomPaneVisibility] = useState(false);
    const [measurementToolsVisibility, setMeasurementToolsVisibility] = useState(false);
    const [activeMeasurementTool, setActiveMeasurementTool] = useState('');

    const [selectionToolsVisibility, setSelectionToolsVisibility] = useState(false);

    const [facilityLayerVisibility, setFacilityLayerVisibility] = useState(true);
    const [RAASFacilityLayerVisibility, setRAASFacilityLayerVisibility] = useState(true);
    const [streetLayerVisibility, setStreetLayerVisibility] = useState(false);
    const [trafficLayerVisibility, setTrafficLayerVisibility] = useState(false);
    const [blockFaceLayerVisbility, setBlockFaceLayerVisbility] = useState(false);

    const [selectedFacilityIds, setSelectedFacilityIds] = useState([]);
    const [selectedServiceLocationIds, _setSelectedServiceLocationIds] = useState([]);
    const selectedServiceLocationIdsRef = React.useRef(selectedServiceLocationIds);
    const setSelectedServiceLocationIds = (x) => {
        selectedServiceLocationIdsRef.current = x;
        _setSelectedServiceLocationIds(x);
    };
    const [selectedRaaSServiceLocationIds, _setSelectedRaaSServiceLocationIds] = useState([]);
    const selectedRaaSServiceLocationIdsRef = React.useRef(selectedRaaSServiceLocationIds);
    const setSelectedRaaSServiceLocationIds = (x) => {
        selectedRaaSServiceLocationIdsRef.current = x;
        _setSelectedRaaSServiceLocationIds(x);
    };
    const [bottomPaneGrid, setBottomPaneGrid] = useState(null);
    const [facilityHighlight, setFacilityHighlight] = useState(null);
    const [editFacilityDialog, setEditFacilityDialog] = useState(false);
    const [facilityTarget, setFacilityTarget] = useState({});
    const [isFacilityFormValid, setIsFacilityFormValid] = useState(false);
    const [pinFacilityDialog, setPinFacilityDialog] = useState(false);
    const [facilityDialogMode, setFacilityDialogMode] = useState('New');
    const [slHighlightGraphicsLayer, setSlHighlightGraphicsLayer] = useState(null);

    const [pinServiceLocationDialogOpen, setPinServiceLocationDialogOpen] = useState(false);
    const [unpinServiceLocationDialogOpen, setUnpinServiceLocationDialogOpen] = useState(false);
    const [editServiceLocationOpen, setEditServiceLocationOpen] = useState(false);
    const [refreshServiceLocationOpen, setRefreshServiceLocationOpen] = useState(false);

    const [mapInfoMessage, setMapInfoMessage] = useState('');
    const [pickedXY, _setPickedXY] = useState({});
    const pickedXYRef = React.useRef(pickedXY);
    const setPickedXY = (x) => {
        pickedXYRef.current = x;
        _setPickedXY(x);
    };

    const [pickedLinkId, _setPickedLinkId] = useState(null);
    const pickedLinkIdRef = React.useRef(pickedLinkId);
    const setPickedLinkId = (x) => {
        pickedLinkIdRef.current = x;
        _setPickedLinkId(x);
    };

    const [serviceLocationTarget, setServiceLocationTarget] = useState(null);

    const [mapSelectionMode, _setMapSelectionMode] = useState(null);
    const mapSelectionModeRef = React.useRef(mapSelectionMode);
    const setMapSelectionMode = (x) => {
        mapSelectionModeRef.current = x;
        _setMapSelectionMode(x);
    };

    const [selStreetMaintenanceMode, _setSelStreetMaintenanceMode] = useState(null);
    const selStreetMaintenanceModeRef = React.useRef(selStreetMaintenanceMode);
    const setSelStreetMaintenanceMode = (x) => {
        selStreetMaintenanceModeRef.current = x;
        _setSelStreetMaintenanceMode(x);
    };

    const [slSelectByStreetSideHighlightLayer, setSlSelectByStreetSideHighlightLayer] = useState(null);

    //Dispatch
    const [selDispatchTool, setSelDispatchTool] = useState('SplitRoute');
    const [scenarioList, setScenarioList] = useState([]);
    const [selectedScenarioId, setSelectedScenarioId] = useState(null);
    const [scenarioRouteList, setScenarioRouteList] = useState([]);
    const [scenarioRouteSummary, setScenarioRouteSummary] = useState([]);
    const [selectedScenarioRoutes, setSelectedScenarioRoutes] = useState([]);
    const [showScenarioExport, setShowScenarioExport] = useState(false);
    const [showScenarioReport, setShowScenarioReport] = useState(false);

    const dispatchStartList = [{ Id: -1, Label: 'First Stop' }];
    const [dispatchFacilityList, setDispatchFacilityList] = useState([]);
    //Dispatch - Split
    const [splitSeqList, setSplitSeqList] = useState([]);
    const defSplitFormData = {
        ServiceDay: serviceDayList.filter((item) => item.index === new Date().getDay())[0].value,
        Routes: null,
        SequenceNum: null,
        NumberOfRoutes: 2,
        OfficeID: -1,
        EndOfficeID: -1,
    };
    const defCreateRoutesFormData = {
        ServiceDay: serviceDayList.filter((item) => item.index === new Date().getDay())[0].value,
        RoutePlan: '',
        NumRoutes: 1,
        GenerateMethod: 'Time',
        Zones: false,
    };

    const [splitStartTime, setSplitStartTime] = useState(null);
    const [splitFormData, setSplitFormData] = useState(defSplitFormData);
    const [createRoutesFormData, setCreateRoutesFormData] = useState(defCreateRoutesFormData);

    const [splitLoadIndicatorVisible, setSplitLoadIndicatorVisible] = useState(false);
    const [createRoutesLoadIndicatorVisible, setCreateRoutesLoadIndicatorVisible] = useState(false);

    const [routePlanList, setRoutePlanList] = useState([]);
    const [routeNumberList, setRouteNumberList] = useState(
        [...Array(10).keys()].map((i) => ({ label: (i + 1).toString(), value: (i + 1).toString() })),
    );

    const [isMapLoading, setIsMapLoading] = useState(false);
    //Print
    const [selPrintTitle, setSelPrintTitle] = useState('Service Area Map');
    const [selPrintFormat, setSelPrintFormat] = useState('PDF');
    const [printButtonLoading, setPrintButtonLoading] = useState(false);

    //User Turn Exception
    const [openUserTurnExceptionDeleteDialog, setOpenUserTurnExceptionDeleteDialog] = useState(false);
    const [userTurnExceptionTarget, setUserTurnExceptionTarget] = useState({});

    //Alley Assignments
    const [openAlleyAssignmentsDeleteDialog, setOpenAlleyAssignmentsDeleteDialog] = useState(false);
    const [alleyAssignmentsTarget, setAlleyAssignmentsTarget] = useState(null);

    //Travel Path Layer
    const [selTravelPathRoute, _setSelTravelPathRoute] = useState(null);
    const selTravelPathRouteRef = React.useRef(selTravelPathRoute);
    const setSelTravelPathRoute = (x) => {
        selTravelPathRouteRef.current = x;
        _setSelTravelPathRoute(x);
    };

    const [selScenarioTravelPathRoute, _setSelScenarioTravelPathRoute] = useState(null);
    const selScenarioTravelPathRouteRef = React.useRef(selScenarioTravelPathRoute);
    const setSelScenarioTravelPathRoute = (x) => {
        selScenarioTravelPathRouteRef.current = x;
        _setSelScenarioTravelPathRoute(x);
    };

    const [showTravelPathLabel, _setShowTravelPathLabel] = useState(true);
    const showTravelPathLabelRef = React.useRef(showTravelPathLabel);
    const setShowTravelPathLabel = (x) => {
        showTravelPathLabelRef.current = x;
        _setShowTravelPathLabel(x);
    };

    const [showScenarioTravelPathLabel, _setShowScenarioTravelPathLabel] = useState(true);
    const showScenarioTravelPathLabelRef = React.useRef(showScenarioTravelPathLabel);
    const setShowScenarioTravelPathLabel = (x) => {
        showScenarioTravelPathLabelRef.current = x;
        _setShowScenarioTravelPathLabel(x);
    };

    const travelPathLabelTooltip = 'Show travel path labels';
    const [showTravelPathLabelBtnTooltip, setShowTravelPathLabelBtnTooltip] = useState(travelPathLabelTooltip);
    const [showScenarioTravelPathLabelBtnTooltip, setShowScenarioTravelPathLabelBtnTooltip] = useState(travelPathLabelTooltip);
    const [travelPathLayer, setTravelPathLayer] = useState(null);
    const [travelPathLabelLayer, setTravelPathLabelLayer] = useState(null);
    const [scenarioTravelPathLayer, setScenarioTravelPathLayer] = useState(null);
    const [scenarioTravelPathLabelLayer, setScenarioTravelPathLabelLayer] = useState(null);

    //Sequence Layer
    const [selSequenceRoute, _setSelSequenceRoute] = useState(null);
    const selSequenceRoutelRef = React.useRef(selSequenceRoute);
    const setSelSequenceRoute = (x) => {
        selSequenceRoutelRef.current = x;
        _setSelSequenceRoute(x);
    };
    const [selScenarioSequenceRoute, _setSelScenarioSequenceRoute] = useState(null);
    const selScenarioSequenceRouteRef = React.useRef(selScenarioSequenceRoute);
    const setSelScenarioSequenceRoute = (x) => {
        selScenarioSequenceRouteRef.current = x;
        _setSelScenarioSequenceRoute(x);
    };
    const [showSequenceLabel, _setShowSequenceLabel] = useState(false);
    const showSequenceLabelRef = React.useRef(showSequenceLabel);
    const setShowSequenceLabel = (x) => {
        showSequenceLabelRef.current = x;
        _setShowSequenceLabel(x);
    };
    const [showScenarioSequenceLabel, _setShowScenarioSequenceLabel] = useState(false);
    const showScenarioSequenceLabelRef = React.useRef(showScenarioSequenceLabel);
    const setShowScenarioSequenceLabel = (x) => {
        showScenarioSequenceLabelRef.current = x;
        _setShowScenarioSequenceLabel(x);
    };
    const [sequenceLayer, setSequenceLayer] = useState(null);
    const [sequenceLabelLayer, setSequenceLabelLayer] = useState(null);
    const [scenarioSequenceLayer, setScenarioSequenceLayer] = useState(null);
    const [scenarioSequenceLabelLayer, setScenarioSequenceLabelLayer] = useState(null);
    // Reports
    const [showReport, setShowReport] = useState(false);
    const [reportTemplates, setReportTemplates] = useState([]);
    const [selectedReport, setSelectedReport] = useState(null);
    const [selectedScenarioReport, setSelectedScenarioReport] = useState(null);
    const [separateReports, setSeparateReports] = useState(false);
    const [separateScenarioReports, setSeparateScenarioReports] = useState(false);
    // Export
    const [showExport, setShowExport] = useState(false);
    const [exportFormats, setExportFormats] = useState([]);
    const [selectedFormat, setSelectedFormat] = useState(null);
    const [selectedScenarioFormat, setSelectedScenarioFormat] = useState(null);
    const defaultExportOptions = { selectedRecords: false };
    const [exportOptions, setExportOptions] = useState(defaultExportOptions);
    const [dispatchExportOptions, setDispatchExportOptions] = useState(defaultExportOptions);

    //Generic
    const [openMapAlert, setOpenMapAlert] = useState(false);
    const [mapAlertTitle, setMapAlertTitle] = useState('');
    const [mapAlertMessage, setMapAlertMessage] = useState('');

    const [openConfirmation, setOpenConfirmation] = useState(false);
    const [confirmationTitle, setConfirmationTitle] = useState(null);
    const [confirmationMessage, setConfirmationMessage] = useState(null);
    //Widgets in state
    const [homeWidget, setHomeWidget] = useState(null);
    const [zoomWidget, setZoomWidget] = useState(null);
    const [measurementWidget, setMeasurementWidget] = useState(null);
    const [legendWidget, setLegendWidget] = useState(null);

    const [pinFacility, setPinFacility] = useState(false);

    const [buttonLoading, setButtonLoading] = useState(false);

    const [timeWindowToggle, _setTimeWindowToggle] = useState(false);
    const timeWindowToggleRef = React.useRef(timeWindowToggle);
    const setTimeWindowToggle = (x) => {
        timeWindowToggleRef.current = x;
        _setTimeWindowToggle(x);
    };

    const [editSLId, setEditSLId] = useState(null);

    const [refreshMap, setRefreshMap] = useState(false);

    /////Zones//////
    const [neighborhoodData, _setNeighborhoodData] = useState([]);
    const neighborhoodDataRef = React.useRef(neighborhoodData);
    const setNeighborhoodData = (x) => {
        neighborhoodDataRef.current = x;
        _setNeighborhoodData(x);
    };

    const [sequenceClusterData, _setSequenceClusterData] = useState([]);
    const sequenceClusterDataRef = React.useRef(sequenceClusterData);
    const setSequenceClusterData = (x) => {
        sequenceClusterDataRef.current = x;
        _setSequenceClusterData(x);
    };

    const [zoneRouteList, _setZoneRouteList] = useState([]);
    const routeListRef = React.useRef(zoneRouteList);
    const setZoneRouteList = (x) => {
        routeListRef.current = x;
        _setZoneRouteList(x);
    };

    const [selectedZoneRoutes, _setSelectedZoneRoutes] = useState([]);
    const selectedZoneRoutesRef = React.useRef(selectedZoneRoutes);
    const setSelectedZoneRoutes = (x) => {
        selectedZoneRoutesRef.current = x;
        _setSelectedZoneRoutes(x);
    };

    const [zoneFacilityLayer, setZoneFacilityLayer] = useState(null);
    const [zoneServiceLocationLayer, setZoneServiceLocationLayer] = useState(null);
    const [zoneFacilityLayerVisibility, setZoneFacilityLayerVisibility] = useState(false);
    const [zoneServiceLocationLayerVisibility, setZoneServiceLocationLayerVisibility] = useState(true);

    const [editingMode, setEditingMode] = useState(null);
    const [title, setTitle] = useState(null);
    const [reshapeDialogOpen, setReshapeDialogOpen] = useState(false);

    const [zoneGraphicLayer, _setZoneGraphicLayer] = useState(null);
    const zoneGraphicLayerRef = React.useRef(zoneGraphicLayer);
    const setZoneGraphicLayer = (x) => {
        zoneGraphicLayerRef.current = x;
        _setZoneGraphicLayer(x);
    };

    const [neighborhoodLayer, _setNeighborhoodLayer] = useState(null);
    const neighborhoodLayerRef = React.useRef(neighborhoodLayer);
    const setNeighborhoodLayer = (x) => {
        neighborhoodLayerRef.current = x;
        _setNeighborhoodLayer(x);
    };
    const [sketch, setSketch] = useState(null);
    const [editSketch, _setEditSketch] = useState(null);
    const editSketchRef = React.useRef(editSketch);
    const setEditSketch = (x) => {
        editSketchRef.current = x;
        _setEditSketch(x);
    };

    const [selectedNeighborhoodClusters, setSelectedNeighborhoodClusters] = useState([]);

    const [openNeighborhoodClusterDialog, setOpenNeighborhoodClusterDialog] = useState(false);
    const [openDeleteNeighborhoodClusterDialog, setOpenDeleteNeighborhoodClusterDialog] = useState(false);
    const [targetNeighborhoodData, _setTargetNeighborhoodData] = useState({});
    const targetNeighborhoodDataRef = React.useRef(targetNeighborhoodData);
    const setTargetNeighborhoodData = (x) => {
        targetNeighborhoodDataRef.current = x;
        _setTargetNeighborhoodData(x);
    };

    const [zoneMode, _setZoneMode] = useState('VIEW');
    const zoneModeRef = React.useRef(zoneMode);
    const setZoneMode = (x) => {
        zoneModeRef.current = x;
        _setZoneMode(x);
    };

    const [sequenceMode, _setSequenceMode] = useState(null);
    const sequenceModeRef = React.useRef(sequenceMode);
    const setSequenceMode = (x) => {
        sequenceModeRef.current = x;
        _setSequenceMode(x);
    };
    const [firstZone, _setFirstZone] = useState([]);
    const firstZoneRef = React.useRef(firstZone);
    const setFirstZone = (x) => {
        firstZoneRef.current = x;
        _setFirstZone(x);
    };

    const [lastZone, _setLastZone] = useState([]);
    const lastZoneRef = React.useRef(lastZone);
    const setLastZone = (x) => {
        lastZoneRef.current = x;
        _setLastZone(x);
    };

    const [sequencedZone, _setSequencedZone] = useState([]);
    const sequencedZoneRef = React.useRef(sequencedZone);
    const setSequencedZone = (x) => {
        sequencedZoneRef.current = x;
        _setSequencedZone(x);
    };

    const [ncAssignment, _setNCAssignment] = useState([]);
    const ncAssignmentRef = React.useRef(ncAssignment);
    const setNCAssignment = (x) => {
        ncAssignmentRef.current = x;
        _setNCAssignment(x);
    };

    const [unSavedChanges, setUnSavedChanges] = useState(false);

    const [autoIncrementEnabled, _setAutoIncrementEnabled] = useState(true);
    const autoIncrementEnabledRef = React.useRef(autoIncrementEnabled);
    const setAutoIncrementEnabled = (x) => {
        autoIncrementEnabledRef.current = x;
        _setAutoIncrementEnabled(x);
    };

    const [lockSequence, _setLockSequence] = useState(false);
    const lockSequenceRef = React.useRef(lockSequence);
    const setLockSequence = (x) => {
        lockSequenceRef.current = x;
        _setLockSequence(x);
    };

    const [nextSequence, _setNextSequence] = useState(1);
    const nextSequenceRef = React.useRef(nextSequence);
    const setNextSequence = (x) => {
        nextSequenceRef.current = x;
        _setNextSequence(x);
    };

    const [previousRoute, setPreviousRoute] = useState(null);
    const [nextRoute, setNextRoute] = useState(null);

    const [warningDialogOpen, setWarningDialogOpen] = useState(false);

    const [projectFacilityLayerVisibility, setProjectFacilityLayerVisibility] = useState(false);
    const [projectServiceLocationLayerVisibility, setProjectServiceLocationLayerVisibility] = useState(true);

    const [serviceLocationData, setServiceLocationData] = useState([]);
    const [refreshServiceLocations, setRefreshServiceLocations] = useState(false);

    const [routeSummaryData, setRouteSummaryData] = useState([]);
    const [healthScore, setHealthScore] = useState([]);

    const [selectionDetailOpen, setSelectionDetailOpen] = useState(false);

    const [swapRouteList, setSwapRouteList] = useState([]);
    const [swapRouteSettings, setSwapRouteSettings] = useState({ targetRoute: '0', serviceDay: '' });
    const [swapServicePatternSettings, setSwapServicePatternSettings] = useState({ lockPattern: false });

    const [swapConfirmDialogOpen, setSwapConfirmDialogOpen] = useState(false);

    const [sandboxUpdateRouteStatsDialogOpen, setSandboxUpdateRouteStatsDialogOpen] = useState(false);
    const [routeStatsSettings, setRouteStatsSettings] = useState({ routePlan: '', serviceDay: '' });

    const [sandboxSolverDialogOpen, setSandboxSolverDialogOpen] = useState(false);
    const [solverSettings, setSolverSettings] = useState({ scenario: 'Create', anchorWithRoute: false });

    const [loadScenario, setLoadScenario] = useState(false);

    const [slFilter, setSlFilter] = useState(null);

    const [routePlans, setRoutePlans] = useState([]);

    const [showTrips, setShowTrips] = useState(false);
    const [tripsData, setTripsData] = useState([]);

    const [defaultTruckCapacity, setDefaultTruckCapacity] = useState(0);

    const [showServicePattern, setShowServicePattern] = useState(false);
    const [updateServicePatternDialogOpen, setUpdateServicePatternDialogOpen] = useState(false);
    const [selectedServicePattern, _setSelectedServicePattern] = useState([]);
    const selectedServicePatternRef = React.useRef(selectedServicePattern);
    const setSelectedServicePattern = (x) => {
        selectedServicePatternRef.current = x;
        _setSelectedServicePattern(x);
    };

    const [selectedServicePatternForAdd, setSelectedServicePatternForAdd] = useState([]);
    const [servicePatternData, setServicePatternData] = useState([]);
    const [servicePatternIdToRemove, setServicePatternIdToRemove] = useState(null);

    //For the down selection found in the route view tab
    //is the selection and selections of the drop down
    const [routeViewTabCategorization, setRouteViewTabCategorization] = React.useState(ROUTE_VIEW_TAB_CATEGORIES[0]);
    const [routeViewTableData, setRouteViewTableData] = React.useState(initialRouteViewTableData);

    const [isRouteViewTabLoading, setIsRouteViewTabLoading] = React.useState(true);

    const [showServiceAreaBoundaryWidget, setShowServiceAreaBoundaryWidget] = React.useState(false);
    const [serviceAreaBoundary, _setServiceAreaBoundary] = React.useState(null);
    const serviceAreaBoundaryRef = React.useRef(serviceAreaBoundary);
    const setServiceAreaBoundary = (x) => {
        serviceAreaBoundaryRef.current = x;
        _setServiceAreaBoundary(x);
    };
    const [isServiceAreaBoundaryWidgetClicked, setIsServiceAreaBoundaryWidgetClicked] = React.useState(false);
    //const [editServiceAreaGraphicLayer, setDefineServiceAreaGraphicLayer] = React.useState(null);
    //const [serviceAreaGraphicLayer, setServiceAreaGraphicLayer] = React.useState(null);
    //const [defineServiceAreaMode, setDefineServiceAreaMode] = React.useState(null);
    const [isServiceAreaBoundaryReshapeDialogOpen, setIsServiceAreaBoundaryReshapeDialogOpen] = React.useState(false);
    const [isServiceAreaBoundaryDeleteDialogOpen, setIsServiceAreaBoundaryDeleteDialogOpen] = React.useState(false);
    const [polygonForServiceAreaBoundary, setPolygonForServiceAreaBoundary] = React.useState(null);

    const [autoCollapse, setAutoCollapse] = React.useState(sessionStorage.getItem('autoCollapse') === 'true' || false);

    const [alleyAssignmentMode, _setAlleyAssignmentMode] = React.useState(false);
    const alleyAssignmentModeRef = React.useRef(alleyAssignmentMode);
    const setAlleyAssignmentMode = (x) => {
        alleyAssignmentModeRef.current = x;
        _setAlleyAssignmentMode(x);
    };
    const [alleyAssignmentsData, setAlleyAssignmentsData] = React.useState([]);
    const [showSendSmartSuiteDialog, setShowSendSmartSuiteDialog] = React.useState(false);

    const [sequenceDialogOpen, setSequenceDialogOpen] = React.useState(false);

    const [topHeight, setTopHeight] = React.useState(580);
    const [bottomHeight, setBottomHeight] = React.useState(240);

    const [serviceLocationSpecialties, setServiceLocationSpecialties] = React.useState([]);

    const [manageSpecialtyDialogOpen, setManageSpecialtyDialogOpen] = React.useState(false);
    const [specialtyList, setSpecialtyList] = useState([]);

    const [scenarioServiceLocation, setScenarioServiceLocation] = useState([]);
    const [refreshScenarioServiceLocation, setRefreshScenarioServiceLocation] = useState(false);

    const [deleteServiceLocationDialogOpen, setDeleteServiceLocationDialogOpen] = useState(false);
    ////////////////

    React.useEffect(() => {
        window.userSelect = false;

        setShowWorkspaceList(true);
        setShowServiceDayList(true);
        setShowRoutePlanList(false);
        setShowDownloadWorkspace(true);

        esriConfig.request.timeout = 120000; //default 60s
        if (!isDev) {
            esriConfig.request.proxyUrl = config.ProxyURL;
            urlUtils.addProxyRule({
                urlPrefix: config.AGS_URL,
                proxyUrl: config.ProxyURL,
            });
            urlUtils.addProxyRule({
                urlPrefix: config.RAAS_AGS_URL,
                proxyUrl: config.RaaSProxyURL,
            });
        }

        esriConfig.request.interceptors.push({
            urls: /FeatureServer\/\d+$/,
            after: function (response) {
                response.data.supportedQueryFormats = 'JSON';
            },
        });
        let userInfo = sessionStorage.getItem('userInfo');
        if (userInfo) {
            userInfo = JSON.parse(userInfo);
            if (userInfo.adminToken) {
                setShowServiceAreaBoundaryWidget(true);
            }
        }

        //Map
        const map = new Map({
            basemap: hereVersion === 'v3' ? HEREStreetBaseMap_V3 : HEREStreetBaseMap_V2,
        });
        setMap(map);

        const lang = localStorage.getItem('i18nextLng');
        if (lang === 'es') {
            intl.setLocale('es');
        }

        const debouncedHandleResize = debounce(() => {
            setWindowDimensions({
                height: window.innerHeight,
                width: window.innerWidth,
            });
        }, 1000);

        const handleEsc = (event) => {
            if (event.keyCode === 27 && editingMode === 'REDRAW') {
                editSketch?.complete();
            } else if (event.keyCode === 27) {
                setSelStreetMaintenanceMode(null);
                setMapInfoMessage('');
                if (pickXYGraphicsLayer) {
                    pickXYGraphicsLayer.graphics.removeAll();
                }
                if (pickXYSketch) {
                    pickXYSketch.cancel();
                }
                if (slSelectByStreetSideHighlightLayer) {
                    slSelectByStreetSideHighlightLayer.graphics.removeAll();
                }
            }
        };

        window.addEventListener('load', debouncedHandleResize);
        window.addEventListener('resize', debouncedHandleResize);
        window.addEventListener('keydown', handleEsc);
        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);

        updateUserActivity({ ActionId: 201, Mode: siteMode, WorkspaceId: workspace?.value });

        return () => {
            window.removeEventListener('resize', debouncedHandleResize);
            window.removeEventListener('load', debouncedHandleResize);
            window.removeEventListener('keydown', handleEsc);
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
            delete window.userSelect;
            setShowDownloadWorkspace(false);
            setShowServiceDayList(false);
            setShowRoutePlanList(false);
            setShowWeekList(false);
            setShowFrequencyList(false);
            setShowServicePatternList(false);
            const savedServiceDay = sessionStorage.getItem('serviceDayInfo');
            if (savedServiceDay) {
                setServiceDay(JSON.parse(savedServiceDay));
            } else {
                let today = new Date();
                setServiceDay({ label: serviceDayList[today.getDay()].label, value: today.getDay() === 0 ? 7 : today.getDay() });
            }
        };
    }, []);

    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    React.useEffect(() => {
        workspaceId.current = workspace;
        if (workspace) {
            getReportTemplates(workspace.value).then((res) => {
                let templates = [];
                res.data.map((r) => {
                    templates.push({ label: r.Report, value: r.FileName });
                });
                templates.sort((a, b) => a.label.localeCompare(b.label));
                setReportTemplates(templates);
                if (templates.length > 0) {
                    setSelectedReport(templates[0]);
                    setSelectedScenarioReport(templates[0]);
                }
            });
            getExportFormats(workspace.value).then((res) => {
                let formats = [];
                res.data.map((f) => {
                    formats.push({ label: f.Label, value: f.Url, header: f.Header, extension: f.Extension });
                });
                formats.sort((a, b) => a.label.localeCompare(b.label));
                setExportFormats(formats);
                if (formats.length > 0) {
                    setSelectedFormat(formats[0]);
                    setSelectedScenarioFormat(formats[0]);
                }
            });
            getTrucks(workspace.value).then(
                (res) => {
                    let defaultCapacity = 0;
                    let defaultTruck = res.data.find((t) => t.TruckName === 'Default');
                    if (defaultTruck) {
                        defaultCapacity = defaultTruck.VehicleCapacity[0];
                    }
                    setDefaultTruckCapacity(defaultCapacity);
                },
                (err) => {
                    setDefaultTruckCapacity(0);
                },
            );

            removeServiceAreaBoundary();

            getServiceAreaBoundary(workspace.value).then(
                (res) => {
                    setServiceAreaBoundary(res.data);
                },
                (err) => {
                    setServiceAreaBoundary(null);
                },
            );

            getSpecialties(workspace.value).then((res) => {
                let s = res.data.map((d) => {
                    return {
                        label: d.Specialty,
                        value: d.Id,
                    };
                });
                setSpecialtyList(s);
            });

            if (closedStreetsLayer) {
                closedStreetsLayer.findSublayerById(4).definitionExpression = 'workspace_id=' + workspace.value;
                closedStreetsLayer.refresh();
            }
            if (serviceSidesLayer) {
                serviceSidesLayer.findSublayerById(5).definitionExpression = "workspace_id='" + workspace.value + "'";
                serviceSidesLayer.refresh();
            }
            if (turnExceptionsLayer) {
                turnExceptionsLayer.findSublayerById(7).definitionExpression = 'workspace_id=' + workspace.value;
                turnExceptionsLayer.refresh();
            }
        }
    }, [workspace]);

    React.useEffect(() => {
        selectedServiceDay.current = serviceDay;
        if (serviceDay.value === 0) {
            setShowReport(false);
        }
    }, [serviceDay]);

    React.useEffect(() => {
        setServicePatternView(false);
        if (siteMode === 'DISPATCHER') {
            if (mapMode === 0) {
                setBottomPaneVisibility(false);
                setBottomPaneGrid(null);
            } else if (mapMode === 2) {
                setBottomPaneVisibility(true);
                setBottomPaneGrid(null);
            }
        } else {
            setMapMode(0);
        }
    }, [siteMode]);

    React.useEffect(() => {
        setServicePatternView(false);
        handleMapReset();
    }, [mapMode]);

    React.useEffect(() => {
        setSelTravelPathRoute(null);
        setSelSequenceRoute(null);
        setShowTrips(false);
        if (workspace && project) {
            if (!initExtent) {
                getWorkspaceExtent(workspace.value, serviceDay.value).then(
                    (res) => {
                        if (res && res.data) {
                            let routeExtent = res.data.split(',');
                            let minX = routeExtent[0].trim();
                            let minY = routeExtent[1].trim();
                            let maxX = routeExtent[2].trim();
                            let maxY = routeExtent[3].trim();
                            let extent = new Extent({ xmin: minX, ymin: minY, xmax: maxX, ymax: maxY });
                            setInitExtent(extent);
                        } else {
                            setInitExtent({ xmin: -56.11, ymin: 52.33, xmax: -139.34, ymax: 24.98 });
                        }
                    },
                    () => {
                        setInitExtent({ xmin: -56.11, ymin: 52.33, xmax: -139.34, ymax: 24.98 });
                    },
                );
            } else {
                getWorkspaceExtent(workspace.value, serviceDay.value).then(
                    (res) => {
                        if (res && res.data) {
                            setHomeWidgetExtent(res.data);
                        }
                    },
                    () => {
                        setInitExtent({ xmin: -56.11, ymin: 52.33, xmax: -139.34, ymax: 24.98 });
                    },
                );
            }
            refreshRoutePlanList();
            handleMapReset();
        } else if (workspace && serviceDay) {
            if (!initExtent) {
                getWorkspaceExtent(workspace.value, serviceDay.value).then(
                    (res) => {
                        if (res && res.data) {
                            let routeExtent = res.data.split(',');
                            let minX = routeExtent[0].trim();
                            let minY = routeExtent[1].trim();
                            let maxX = routeExtent[2].trim();
                            let maxY = routeExtent[3].trim();
                            let extent = new Extent({ xmin: minX, ymin: minY, xmax: maxX, ymax: maxY });
                            setInitExtent(extent);
                        } else {
                            setInitExtent({ xmin: -56.11, ymin: 52.33, xmax: -139.34, ymax: 24.98 });
                        }
                    },
                    () => {
                        setInitExtent({ xmin: -56.11, ymin: 52.33, xmax: -139.34, ymax: 24.98 });
                    },
                );
            } else {
                getWorkspaceExtent(workspace.value, serviceDay.value).then(
                    (res) => {
                        if (res && res.data) {
                            setHomeWidgetExtent(res.data);
                        }
                    },
                    () => {
                        setInitExtent({ xmin: -56.11, ymin: 52.33, xmax: -139.34, ymax: 24.98 });
                    },
                );
            }
            refreshScenarioList();
            refreshRoutePlanList();
            handleMapReset();
        }
    }, [workspace, serviceDay, siteMode, mapMode, project]);

    React.useEffect(() => {
        if (map && initExtent) {
            const view = new MapView({
                map,
                container: 'viewDiv',
                extent: initExtent,
                ui: {
                    components: ['attribution'],
                },
                popup: new Popup(),
            });
            setView(view);

            showROFacilityLayer(map);
            showROServiceLocationLayer(map);
            showZoneFacilityLayer(map);
            showZoneServiceLocationLayer(map);
            showProjectFacilityLayer(map);
            showProjectServiceLocationLayer(map);
            showServicePatternLayer(map);
            showProjectServicePatternLayer(map);

            initStreetLayer(map);
            initClosedStreetLayer(map);
            initServiceSideLayer(map);
            initTurnExceptionLayer(map);
            initAlleyAssignmentsLayer(map);
            initTrafficLayer(map);

            const pickXYGraphicsLayer = new GraphicsLayer();
            map.add(pickXYGraphicsLayer);
            setPickXYGraphicsLayer(pickXYGraphicsLayer);

            //travel path layer
            const travelPathLayer = new GraphicsLayer();
            map.add(travelPathLayer);
            setTravelPathLayer(travelPathLayer);

            const travelPathLabelLayer = new GraphicsLayer();
            map.add(travelPathLabelLayer);
            setTravelPathLabelLayer(travelPathLabelLayer);

            const scenarioTravelPathLayer = new GraphicsLayer();
            map.add(scenarioTravelPathLayer);
            setScenarioTravelPathLayer(scenarioTravelPathLayer);

            const scenarioTravelPathLabelLayer = new GraphicsLayer();
            map.add(scenarioTravelPathLabelLayer);
            setScenarioTravelPathLabelLayer(scenarioTravelPathLabelLayer);

            //sequence layer
            const sequenceLayer = new GraphicsLayer();
            map.add(sequenceLayer);
            setSequenceLayer(sequenceLayer);

            const sequenceLabelLayer = new GraphicsLayer();
            map.add(sequenceLabelLayer);
            setSequenceLabelLayer(sequenceLabelLayer);

            const scenarioSequenceLayer = new GraphicsLayer();
            map.add(scenarioSequenceLayer);
            setScenarioSequenceLayer(scenarioSequenceLayer);

            const scenarioSequenceLabelLayer = new GraphicsLayer();
            map.add(scenarioSequenceLabelLayer);
            setScenarioSequenceLabelLayer(scenarioSequenceLabelLayer);

            const slHighlightGraphicsLayer = new GraphicsLayer();
            map.add(slHighlightGraphicsLayer);
            setSlHighlightGraphicsLayer(slHighlightGraphicsLayer);

            const slSelectByStreetSideHighlightLayer = new GraphicsLayer();
            map.add(slSelectByStreetSideHighlightLayer);
            setSlSelectByStreetSideHighlightLayer(slSelectByStreetSideHighlightLayer);

            setMapMode(0);
        }
    }, [map, initExtent]);

    React.useEffect(() => {
        if (view) {
            initMainidget(view);
            initSearchWidget(view);
            initHomeWidget(view);
            initZoomWidget(view);
            initBasemapWidget(view);
            initOverviewWidget(view);
            initLegendWidget(view);
            initPrintWidget(view);
            initMeasurementWidget(view);
            initSelectionWidget(view);
            initStreetMaintenanceWidget(view);
            initSelectionDetailWidget(view);
            initSketchTool(view);
            initCoordinatesWidget(view);
            initZoneGraphicLayers(view, map);

            //if(admin)
            if (window.location.hostname.includes('admin') || (window.location.hostname.includes('localhost') && showServiceAreaBoundaryWidget)) {
                initServiceAreaSketchTool(view);
            }

            view.ui.add('collapseBottom', 'bottom-left');
            //Map hover

            view.on(
                'pointer-move',
                debouncePointerMove((event) => {
                    if (streetHighlightGraphicsLayer) {
                        streetHighlightGraphicsLayer.graphics.removeAll();
                    }
                    if (view && selStreetMaintenanceModeRef && selStreetMaintenanceModeRef.current && event.x && event.y) {
                        let mapPoint = view.toMap({ x: event.x, y: event.y });
                        if (mapPoint.latitude && mapPoint.longitude) {
                            //Highlight street segments when in street selection mode
                            if (selStreetMaintenanceModeRef.current === config.ALLEY_ASSIGNMENTS) {
                                blockFaceLayer
                                    .findSublayerById(12)
                                    .queryFeatures({
                                        geometry: new Point({
                                            latitude: mapPoint.latitude,
                                            longitude: mapPoint.longitude,
                                        }),
                                        distance: config.STREET_HIGHLIGHT_DISTANCE_FEET,
                                        units: 'feet',
                                        spatialRelationship: 'intersects',
                                        returnGeometry: true,
                                        outFields: ['*'],
                                        where: 'workspace_id=' + workspaceId.current.value,
                                    })
                                    .then(async (featureSet) => {
                                        if (featureSet.features.length === 1 && streetHighlightGraphicsLayer) {
                                            let feature = featureSet.features[0];
                                            let highlightStrtSegGraphic = new Graphic({
                                                geometry: feature.geometry,
                                                attributes: feature.attributes,
                                                symbol: {
                                                    type: 'simple-line',
                                                    width: 12,
                                                    color: [255, 255, 0, 0.5],
                                                    style: 'solid',
                                                },
                                            });
                                            streetHighlightGraphicsLayer.graphics.removeAll();
                                            streetHighlightGraphicsLayer.graphics.add(highlightStrtSegGraphic);
                                        }
                                    });
                            } else {
                                streetsLayer
                                    .findSublayerById(10)
                                    .queryFeatures({
                                        geometry: new Point({
                                            latitude: mapPoint.latitude,
                                            longitude: mapPoint.longitude,
                                        }),
                                        distance: config.STREET_HIGHLIGHT_DISTANCE_FEET,
                                        units: 'feet',
                                        spatialRelationship: 'intersects',
                                        returnGeometry: true,
                                        outFields: ['*'],
                                    })
                                    .then(async (featureSet) => {
                                        if (featureSet.features.length == 1 && streetHighlightGraphicsLayer) {
                                            let feature = featureSet.features[0];
                                            let highlightStrtSegGraphic = new Graphic({
                                                geometry: feature.geometry,
                                                attributes: feature.attributes,
                                                symbol: {
                                                    type: 'simple-line',
                                                    width: 12,
                                                    color: [255, 255, 0, 0.5],
                                                    style: 'solid',
                                                },
                                            });
                                            streetHighlightGraphicsLayer.graphics.removeAll();
                                            streetHighlightGraphicsLayer.graphics.add(highlightStrtSegGraphic);
                                        }
                                    });
                            }

                            //Highlight User Turn Exceptions
                            if (turnExceptionsLayer && selStreetMaintenanceModeRef?.current == config.HERETURNEXCEPTION) {
                                turnExceptionsLayer
                                    .findSublayerById(7)
                                    .queryFeatures({
                                        geometry: new Point({
                                            latitude: mapPoint.latitude,
                                            longitude: mapPoint.longitude,
                                        }),
                                        distance: config.STREET_HIGHLIGHT_DISTANCE_FEET,
                                        units: 'feet',
                                        spatialRelationship: 'intersects',
                                        returnGeometry: true,
                                        where: 'workspace_id=' + workspaceId.current.value,
                                        outFields: ['*'],
                                    })
                                    .then(async (featureSet) => {
                                        if (featureSet.features.length == 1 && streetHighlightGraphicsLayer) {
                                            let feature = featureSet.features[0];
                                            let highlightStrtSegGraphic = new Graphic({
                                                geometry: feature.geometry,
                                                attributes: feature.attributes,
                                                symbol: {
                                                    type: 'simple-line',
                                                    width: 12,
                                                    color: [255, 0, 0, 0.5],
                                                    style: 'solid',
                                                },
                                            });
                                            streetHighlightGraphicsLayer.graphics.removeAll();
                                            streetHighlightGraphicsLayer.graphics.add(highlightStrtSegGraphic);
                                        }
                                    });
                            } else if (alleyAssignmentsLayer && selStreetMaintenanceModeRef?.current === config.ALLEY_ASSIGNMENTS) {
                                alleyAssignmentsLayer
                                    .findSublayerById(16)
                                    .queryFeatures({
                                        geometry: new Point({
                                            latitude: mapPoint.latitude,
                                            longitude: mapPoint.longitude,
                                        }),
                                        distance: config.STREET_HIGHLIGHT_DISTANCE_FEET,
                                        units: 'feet',
                                        spatialRelationship: 'intersects',
                                        returnGeometry: true,
                                        where: 'workspace_id=' + workspaceId.current.value,
                                        outFields: ['*'],
                                    })
                                    .then(async (featureSet) => {
                                        if (featureSet.features.length == 1 && streetHighlightGraphicsLayer) {
                                            let feature = featureSet.features[0];
                                            let highlightStrtSegGraphic = new Graphic({
                                                geometry: feature.geometry,
                                                attributes: feature.attributes,
                                                symbol: {
                                                    type: 'simple-line',
                                                    width: 12,
                                                    color: [255, 0, 0, 0.5],
                                                    style: 'solid',
                                                },
                                            });
                                            streetHighlightGraphicsLayer.graphics.removeAll();
                                            streetHighlightGraphicsLayer.graphics.add(highlightStrtSegGraphic);
                                        }
                                    });
                            }
                        }
                    }
                }, 250),
            );

            //Map click
            view.on('click', (event) => {
                if (mapModeRef && mapModeRef.current === 2) {
                    const currentRoute = selectedZoneRoutesRef.current[0];
                    if (currentRoute) {
                        if (zoneModeRef.current === 'ASSIGN') {
                            const screenPoint = event.screenPoint;
                            view.hitTest(screenPoint).then((response) => {
                                response.results.forEach((res) => {
                                    if (res.graphic.layer.id === 'neighborhoodLayer') {
                                        const id = res.graphic.attributes.id;
                                        let ncAssigned = [...ncAssignmentRef.current];
                                        const index = ncAssigned.indexOf(id);
                                        if (index > -1) {
                                            ncAssigned.splice(index, 1);
                                            setNCAssignment(ncAssigned);
                                            setSelectedNeighborhoodClusters(ncAssigned);
                                            setUnSavedChanges(true);
                                            let assignedToOtherRoute = false;
                                            let previousAssigned = sequenceClusterDataRef.current.find(
                                                (s) => s.AnchorAreaId === id && s.Route !== currentRoute,
                                            );
                                            if (previousAssigned) {
                                                assignedToOtherRoute = true;
                                            }
                                            if (assignedToOtherRoute) {
                                                res.graphic.symbol = otherRouteSymbol;
                                            } else {
                                                res.graphic.symbol = regularSymbol;
                                            }
                                        } else {
                                            ncAssigned.push(id);
                                            setNCAssignment(ncAssigned);
                                            setSelectedNeighborhoodClusters(ncAssigned);
                                            setUnSavedChanges(true);
                                            res.graphic.symbol = assignedSymbol;
                                        }
                                    }
                                });
                            });
                        } else if (zoneModeRef.current === 'SEQUENCE') {
                            const screenPoint = event.screenPoint;
                            view.hitTest(screenPoint).then((response) => {
                                response.results.forEach((res) => {
                                    if (res.graphic?.layer?.id === 'neighborhoodLayer') {
                                        const id = res.graphic.attributes.id;
                                        let fz = [...firstZoneRef.current];
                                        let lz = [...lastZoneRef.current];
                                        let sq = [...sequencedZoneRef.current];
                                        if (sequenceModeRef.current === 'FIRST') {
                                            const first = fz.indexOf(id);
                                            const isLast = lz.indexOf(id);
                                            if (isLast > -1) {
                                                lz.splice(isLast, 1);
                                            }
                                            if (first > -1) {
                                                fz.splice(first, 1);
                                            } else {
                                                fz.push(id);
                                            }
                                            sq = sq.filter((s) => s.Id !== id);
                                            setFirstZone(fz);
                                            setLastZone(lz);
                                            setSequencedZone(sq);
                                            setUnSavedChanges(true);
                                            showSequenceModeNeighborhoodClusters();
                                        } else if (sequenceModeRef.current === 'LAST') {
                                            const isFirst = fz.indexOf(id);
                                            const last = lz.indexOf(id);
                                            if (isFirst > -1) {
                                                fz.splice(isFirst, 1);
                                            }
                                            if (last > -1) {
                                                lz.splice(last, 1);
                                            } else {
                                                lz.push(id);
                                            }
                                            sq = sq.filter((s) => s.Id !== id);
                                            setFirstZone(fz);
                                            setLastZone(lz);
                                            setSequencedZone(sq);
                                            setUnSavedChanges(true);
                                            showSequenceModeNeighborhoodClusters();
                                        } else if (sequenceModeRef.current === 'SEQUENCE') {
                                            const exists = sq.filter((as) => as.Id === id);
                                            if (exists.length === 1) {
                                                const newSequence = sq.filter((as) => as.Id !== id);
                                                setSequencedZone(newSequence);
                                                setUnSavedChanges(true);
                                                showSequenceModeNeighborhoodClusters();
                                            } else {
                                                const isFirst = fz.indexOf(id);
                                                const isLast = lz.indexOf(id);
                                                if (isFirst > -1) fz.splice(isFirst, 1);
                                                if (isLast > -1) lz.splice(isLast, 1);
                                                let newSequence = [...sq];
                                                newSequence.sort((a, b) => a.Sequence - b.Sequence);
                                                const existingSequence = newSequence.map((a) => a.Sequence);
                                                if (!lockSequenceRef.current && autoIncrementEnabledRef.current) {
                                                    for (let i = 0; i < newSequence.length; i++) {
                                                        if (newSequence[i].Sequence === nextSequenceRef.current) {
                                                            newSequence[i].Sequence++;
                                                            if (newSequence[i].Sequence > 100) newSequence[i].Sequence = 100;
                                                        } else if (newSequence[i].Sequence > nextSequenceRef.current) {
                                                            if (existingSequence.includes(newSequence[i].Sequence - 1)) {
                                                                newSequence[i].Sequence++;
                                                                if (newSequence[i].Sequence > 100) newSequence[i].Sequences = 100;
                                                            } else {
                                                                break;
                                                            }
                                                        }
                                                    }
                                                }
                                                newSequence.push({ Id: id, Sequence: nextSequenceRef.current });

                                                let next = nextSequenceRef.current;

                                                if (lockSequenceRef.current === false) {
                                                    next += 1;
                                                    if (next > 100) next = 100;
                                                }

                                                setFirstZone(fz);
                                                setLastZone(lz);
                                                setSequencedZone(newSequence);
                                                setUnSavedChanges(true);
                                                setNextSequence(next);
                                                showSequenceModeNeighborhoodClusters();
                                            }
                                        }
                                    }
                                });
                            });
                        }
                    }
                } else if (selStreetMaintenanceModeRef && selStreetMaintenanceModeRef.current != '' && workspaceId.current && streetsLayer) {
                    if (event.mapPoint && event.mapPoint.latitude && event.mapPoint.longitude) {
                        const mapPoint = event.mapPoint;
                        if (turnExceptionsLayer && selStreetMaintenanceModeRef.current == config.HERETURNEXCEPTION) {
                            turnExceptionsLayer
                                .findSublayerById(7)
                                .queryFeatures({
                                    geometry: new Point({
                                        latitude: mapPoint.latitude,
                                        longitude: mapPoint.longitude,
                                    }),
                                    distance: config.STREET_HIGHLIGHT_DISTANCE_FEET,
                                    units: 'feet',
                                    spatialRelationship: 'intersects',
                                    returnGeometry: true,
                                    where: 'workspace_id=' + workspaceId.current.value,
                                    outFields: ['*'],
                                })
                                .then(async (featureSet) => {
                                    if (featureSet.features.length == 1 && streetHighlightGraphicsLayer) {
                                        setOpenUserTurnExceptionDeleteDialog(true);
                                        setUserTurnExceptionTarget({
                                            WorkspaceId: workspaceId.current.value,
                                            Gid: featureSet.features[0].attributes['gid'],
                                        });
                                    }
                                });
                        } else if (alleyAssignmentsLayer && selStreetMaintenanceModeRef.current === config.ALLEY_ASSIGNMENTS) {
                            alleyAssignmentsLayer
                                .findSublayerById(16)
                                .queryFeatures({
                                    geometry: new Point({
                                        latitude: mapPoint.latitude,
                                        longitude: mapPoint.longitude,
                                    }),
                                    distance: config.STREET_HIGHLIGHT_DISTANCE_FEET,
                                    units: 'feet',
                                    spatialRelationship: 'intersects',
                                    returnGeometry: true,
                                    where: 'workspace_id=' + workspaceId.current.value,
                                    outFields: ['*'],
                                })
                                .then(async (featureSet) => {
                                    if (
                                        featureSet.features.length == 1 &&
                                        streetHighlightGraphicsLayer &&
                                        alleyAssignmentsGraphicsLayer.graphics.length === 0
                                    ) {
                                        setOpenAlleyAssignmentsDeleteDialog(true);
                                        setAlleyAssignmentsTarget(featureSet.features[0].attributes['id']);
                                    }
                                });

                            blockFaceLayer
                                .findSublayerById(12)
                                .queryFeatures({
                                    geometry: new Point({
                                        latitude: mapPoint.latitude,
                                        longitude: mapPoint.longitude,
                                    }),
                                    distance: config.STREET_HIGHLIGHT_DISTANCE_FEET,
                                    units: 'feet',
                                    spatialRelationship: 'intersects',
                                    returnGeometry: true,
                                    outFields: ['*'],
                                    where: 'workspace_id=' + workspaceId.current.value,
                                })
                                .then((featureSet) => {
                                    if (featureSet.features.length === 1) {
                                        //Highlight street segment
                                        if (alleyAssignmentsGraphicsLayer) {
                                            if (
                                                alleyAssignmentsGraphicsLayer.graphics.length === 1 &&
                                                alleyAssignmentsGraphicsLayer.graphics.getItemAt(0).attributes['blockface_id'] !==
                                                    featureSet.features[0].attributes['blockface_id']
                                            ) {
                                                let data = {
                                                    Id: 0,
                                                    WorkspaceId: workspaceId.current.value,
                                                    BlockfaceIdStreet: alleyAssignmentsGraphicsLayer.graphics.getItemAt(0).attributes['blockface_id'],
                                                    BlockfaceIdAlley: featureSet.features[0].attributes['blockface_id'],
                                                    NameStreet: alleyAssignmentsGraphicsLayer.graphics.getItemAt(0).attributes['st_name'],
                                                    NameAlley: featureSet.features[0].attributes['st_name'],
                                                };

                                                createAlleyAssignments(data).then(
                                                    (res) => {
                                                        alleyAssignmentsGraphicsLayer.graphics.removeAll();
                                                        alleyAssignmentsLayer.refresh();
                                                        getAlleyAssignments(workspace.value).then(
                                                            (res) => {
                                                                setAlleyAssignmentsData(res.data);
                                                            },
                                                            (err) => {
                                                                setAlleyAssignmentsData([]);
                                                            },
                                                        );
                                                        setMapInfoMessage(
                                                            'Click on a street to set alley assignment. Click on an alley assignment to delete it',
                                                        );
                                                    },
                                                    (err) => {
                                                        setMapInfoMessage('Click on a second street to set alley assignment.');
                                                    },
                                                );
                                            } else if (alleyAssignmentsGraphicsLayer.graphics.length === 0) {
                                                addStreetGraphic(featureSet.features[0], alleyAssignmentsGraphicsLayer);
                                                setMapInfoMessage('Click on a second street to set alley assignment.');
                                            }
                                        }
                                    }
                                });
                        }

                        streetsLayer
                            .findSublayerById(10)
                            .queryFeatures({
                                geometry: new Point({
                                    latitude: mapPoint.latitude,
                                    longitude: mapPoint.longitude,
                                }),
                                distance: config.STREET_HIGHLIGHT_DISTANCE_FEET,
                                units: 'feet',
                                spatialRelationship: 'intersects',
                                returnGeometry: true,
                                outFields: ['*'],
                            })
                            .then((featureSet) => {
                                let service_day = selectedServiceDay.current.value;
                                if (service_day === 0) {
                                    service_day = '1, 2, 3, 4, 5, 6, 7';
                                }
                                if (featureSet.features.length == 1) {
                                    switch (selStreetMaintenanceModeRef.current) {
                                        case config.FACILITY:
                                            setPickedLinkId(featureSet.features[0].attributes['link_id']);
                                            if (pickXYGraphicsLayer) {
                                                let highlightStrtSegGraphic = new Graphic({
                                                    geometry: featureSet.features[0].geometry,
                                                    symbol: {
                                                        type: 'simple-line',
                                                        width: 12,
                                                        color: [255, 0, 0, 0.5],
                                                        style: 'solid',
                                                    },
                                                });
                                                pickXYGraphicsLayer.graphics.add(highlightStrtSegGraphic);
                                            }
                                            streetsLayer.visible = false;
                                            setFacilityDialogMode('New');
                                            setFacilityTarget({ Address: '', StartEnd: false, Intermediate: false });
                                            setEditFacilityDialog(true);
                                            setIsFacilityFormValid(false);
                                            break;
                                        case config.FACILITY + '_PIN':
                                            if (pickXYGraphicsLayer) {
                                                let highlightStrtSegGraphic = new Graphic({
                                                    geometry: featureSet.features[0].geometry,
                                                    symbol: {
                                                        type: 'simple-line',
                                                        width: 12,
                                                        color: [255, 0, 0, 0.5],
                                                        style: 'solid',
                                                    },
                                                });
                                                pickXYGraphicsLayer.graphics.add(highlightStrtSegGraphic);
                                            }
                                            setPickedLinkId(featureSet.features[0].attributes['link_id']);
                                            streetsLayer.visible = false;
                                            setPinFacility(true);
                                            break;
                                        case config.SERVICE_LOCATION + '_PIN':
                                            //Highlight street segment
                                            if (pickXYGraphicsLayer) {
                                                let highlightStrtSegGraphic = new Graphic({
                                                    geometry: featureSet.features[0].geometry,
                                                    symbol: {
                                                        type: 'simple-line', // autocasts as new SimpleMarkerSymbol()
                                                        width: 12,
                                                        //color: "#bee8ff",
                                                        color: [255, 0, 0, 0.5],
                                                        style: 'solid',
                                                    },
                                                });
                                                pickXYGraphicsLayer.graphics.add(highlightStrtSegGraphic);
                                            }
                                            setPickedLinkId(featureSet.features[0].attributes['link_id']);
                                            streetsLayer.visible = false;
                                            if (sessionStorage.getItem('mdr') === 'true') {
                                                if (
                                                    selectedServiceLocationIdsRef.current.length === 1 ||
                                                    selectedServicePatternRef.current.length === 1
                                                ) {
                                                    handlePinServiceLocationsDialogClose('PIN');
                                                } else {
                                                    setPinServiceLocationDialogOpen(true);
                                                }
                                            } else {
                                                if (selectedServiceLocationIdsRef.current.length === 1) {
                                                    handlePinServiceLocationsDialogClose('PIN');
                                                } else {
                                                    setPinServiceLocationDialogOpen(true);
                                                }
                                            }
                                            break;
                                        case config.SERVICE_LOCATION + '_SEL_STREET':
                                            if (sessionStorage.getItem('siteMode') === 'DISPATCHER') {
                                                if (mapModeRef.current === 0) {
                                                    if (serviceLocationLayer) {
                                                        let strWhere =
                                                            'ref_id=' +
                                                            featureSet.features[0].attributes['link_id'] +
                                                            ' and ' +
                                                            'workspace_id=' +
                                                            workspaceId.current.value +
                                                            ' and ' +
                                                            'service_day in (' +
                                                            service_day +
                                                            ')';
                                                        serviceLocationLayer
                                                            .queryFeatures({
                                                                returnGeometry: false,
                                                                where: strWhere,
                                                                outFields: ['*'],
                                                            })
                                                            .then((featureSet) => {
                                                                if (featureSet && featureSet.features.length > 0) {
                                                                    let selSvcLocationIds = [...selectedServiceLocationIdsRef.current];
                                                                    featureSet.features.forEach((feature) => {
                                                                        if (!selSvcLocationIds.includes(feature.attributes['gid'])) {
                                                                            selSvcLocationIds.push(feature.attributes['gid']);
                                                                        }
                                                                    });
                                                                    setSelectedServiceLocationIds(selSvcLocationIds);
                                                                }
                                                            });
                                                    }
                                                } else if (mapModeRef.current === 1) {
                                                    if (RAASServiceLocationLayer) {
                                                        let strWhere =
                                                            'ref_id=' +
                                                            featureSet.features[0].attributes['link_id'] +
                                                            ' and processid=' +
                                                            selectedScenarioId;
                                                        RAASServiceLocationLayer.queryFeatures({
                                                            returnGeometry: false,
                                                            where: strWhere,
                                                            outFields: ['*'],
                                                        }).then((featureSet) => {
                                                            if (featureSet && featureSet.features.length > 0) {
                                                                let selSvcLocationIds = [...selectedRaaSServiceLocationIdsRef.current];
                                                                featureSet.features.forEach((feature) => {
                                                                    if (!selSvcLocationIds.includes(feature.attributes['record_id'])) {
                                                                        selSvcLocationIds.push(feature.attributes['record_id']);
                                                                    }
                                                                });
                                                                setSelectedRaaSServiceLocationIds(selSvcLocationIds);
                                                            }
                                                        });
                                                    }
                                                }
                                            } else {
                                                if (projectServiceLocationLayer) {
                                                    let strWhere =
                                                        'ref_id=' +
                                                        featureSet.features[0].attributes['link_id'] +
                                                        ' and ' +
                                                        'sandbox_id=' +
                                                        sessionStorage.getItem('project') +
                                                        ' and ' +
                                                        'service_day in (' +
                                                        service_day +
                                                        ')';
                                                    projectServiceLocationLayer
                                                        .queryFeatures({
                                                            returnGeometry: false,
                                                            where: strWhere,
                                                            outFields: ['*'],
                                                        })
                                                        .then((featureSet) => {
                                                            if (featureSet && featureSet.features.length > 0) {
                                                                let selSvcLocationIds = [...selectedServiceLocationIdsRef.current];
                                                                featureSet.features.forEach((feature) => {
                                                                    if (!selSvcLocationIds.includes(feature.attributes['gid'])) {
                                                                        selSvcLocationIds.push(feature.attributes['gid']);
                                                                    }
                                                                });
                                                                setSelectedServiceLocationIds(selSvcLocationIds);
                                                            }
                                                        });
                                                }
                                            }
                                            streetsLayer.visible = false;
                                            setSelStreetMaintenanceMode(null);
                                            break;
                                        case config.SERVICE_LOCATION + '_SEL_STREET_SIDE':
                                            let slSelectStreetSideLinkId = featureSet.features[0].attributes['link_id'];
                                            let highlightStrtSegGraphic = new Graphic({
                                                geometry: featureSet.features[0].geometry,
                                                attributes: featureSet.features[0].attributes,
                                                symbol: {
                                                    type: 'simple-line', // autocasts as new SimpleMarkerSymbol()
                                                    width: 12,
                                                    //color: "#bee8ff",
                                                    color: [255, 0, 0, 0.5],
                                                    style: 'solid',
                                                },
                                            });
                                            slSelectByStreetSideHighlightLayer.graphics.add(highlightStrtSegGraphic);
                                            setMapInfoMessage('Click on a side to select stops');
                                            let slSelectBySideDraw = new Draw({ view: view });
                                            let slSelectBySideDrawAction = slSelectBySideDraw.create('point', { mode: 'click' });
                                            slSelectBySideDrawAction.on('draw-complete', async (evt) => {
                                                try {
                                                    let lngLat = webMercatorUtils.xyToLngLat(evt.coordinates[0], evt.coordinates[1]);
                                                    let sideOfStreetResult = await getSideOfStreet(slSelectStreetSideLinkId, lngLat[0], lngLat[1]);
                                                    let sideOfStreet = sideOfStreetResult.data;
                                                    slSelectByStreetSideHighlightLayer.graphics.removeAll();
                                                    if (sessionStorage.getItem('siteMode') === 'DISPATCHER') {
                                                        if (mapModeRef.current === 0) {
                                                            if (serviceLocationLayer) {
                                                                let strWhere =
                                                                    'ref_id=' +
                                                                    featureSet.features[0].attributes['link_id'] +
                                                                    ' and ' +
                                                                    "side='" +
                                                                    sideOfStreet +
                                                                    "' and " +
                                                                    'workspace_id=' +
                                                                    workspaceId.current.value +
                                                                    ' and ' +
                                                                    'service_day in (' +
                                                                    service_day +
                                                                    ')';
                                                                serviceLocationLayer
                                                                    .queryFeatures({
                                                                        returnGeometry: false,
                                                                        where: strWhere,
                                                                        outFields: ['*'],
                                                                    })
                                                                    .then((featureSet) => {
                                                                        if (featureSet && featureSet.features.length > 0) {
                                                                            let selSvcLocationIds = [...selectedServiceLocationIdsRef.current];
                                                                            featureSet.features.forEach((feature) => {
                                                                                if (!selSvcLocationIds.includes(feature.attributes['gid'])) {
                                                                                    selSvcLocationIds.push(feature.attributes['gid']);
                                                                                }
                                                                            });
                                                                            setSelectedServiceLocationIds(selSvcLocationIds);
                                                                        }
                                                                    });
                                                            }
                                                        } else if (mapModeRef.current === 1) {
                                                            if (RAASServiceLocationLayer) {
                                                                let strWhere =
                                                                    'ref_id=' +
                                                                    featureSet.features[0].attributes['link_id'] +
                                                                    " and side='" +
                                                                    sideOfStreet +
                                                                    ' and processid=' +
                                                                    selectedScenarioId;
                                                                RAASServiceLocationLayer.queryFeatures({
                                                                    returnGeometry: false,
                                                                    where: strWhere,
                                                                    outFields: ['*'],
                                                                }).then((featureSet) => {
                                                                    if (featureSet && featureSet.features.length > 0) {
                                                                        let selSvcLocationIds = [...selectedRaaSServiceLocationIdsRef.current];
                                                                        featureSet.features.forEach((feature) => {
                                                                            if (!selSvcLocationIds.includes(feature.attributes['record_id'])) {
                                                                                selSvcLocationIds.push(feature.attributes['record_id']);
                                                                            }
                                                                        });
                                                                        setSelectedRaaSServiceLocationIds(selSvcLocationIds);
                                                                    }
                                                                });
                                                            }
                                                        }
                                                    } else {
                                                        if (projectServiceLocationLayer) {
                                                            let strWhere =
                                                                'ref_id=' +
                                                                featureSet.features[0].attributes['link_id'] +
                                                                ' and ' +
                                                                "side='" +
                                                                sideOfStreet +
                                                                "' and " +
                                                                'sandbox_id=' +
                                                                sessionStorage.getItem('project') +
                                                                ' and ' +
                                                                'service_day in (' +
                                                                service_day +
                                                                ')';
                                                            projectServiceLocationLayer
                                                                .queryFeatures({
                                                                    returnGeometry: false,
                                                                    where: strWhere,
                                                                    outFields: ['*'],
                                                                })
                                                                .then((featureSet) => {
                                                                    if (featureSet && featureSet.features.length > 0) {
                                                                        let selSvcLocationIds = [...selectedServiceLocationIdsRef.current];
                                                                        featureSet.features.forEach((feature) => {
                                                                            if (!selSvcLocationIds.includes(feature.attributes['gid'])) {
                                                                                selSvcLocationIds.push(feature.attributes['gid']);
                                                                            }
                                                                        });
                                                                        setSelectedServiceLocationIds(selSvcLocationIds);
                                                                    }
                                                                });
                                                        }
                                                    }
                                                    setMapInfoMessage('');
                                                    streetsLayer.visible = false;
                                                    setSelStreetMaintenanceMode(null);
                                                } catch (e) {
                                                    console.error(e);
                                                }
                                            });
                                            break;
                                        case config.CLOSED_STREETS:
                                            updateStreetClosure(workspaceId.current.value, featureSet.features[0].attributes['link_id'])
                                                .then((response) => {
                                                    const logData = {
                                                        WorkspaceId: workspaceId.current.value,
                                                        ActionId: 211,
                                                        Mode: sessionStorage.getItem('siteMode'),
                                                        Success: true,
                                                        Metadata: featureSet.features[0].attributes['link_id'],
                                                    };
                                                    updateUserActivity(logData);
                                                    closedStreetsLayer.refresh();
                                                })
                                                .catch((error) => {
                                                    const logData = {
                                                        WorkspaceId: workspaceId.current.value,
                                                        ActionId: 211,
                                                        Mode: sessionStorage.getItem('siteMode'),
                                                        Success: false,
                                                        Metadata: featureSet.features[0].attributes['link_id'],
                                                    };
                                                    updateUserActivity(logData);
                                                });
                                            break;
                                        case config.SERVICE_SIDES:
                                            updateServiceSide(
                                                workspaceId.current.value,
                                                featureSet.features[0].attributes['link_id'],
                                                featureSet.features[0].attributes['meander'],
                                            )
                                                .then((response) => {
                                                    const logData = {
                                                        WorkspaceId: workspaceId.current.value,
                                                        ActionId: 212,
                                                        Mode: sessionStorage.getItem('siteMode'),
                                                        Success: true,
                                                        Metadata: featureSet.features[0].attributes['link_id'],
                                                    };
                                                    updateUserActivity(logData);
                                                    serviceSidesLayer.refresh();
                                                })
                                                .catch((error) => {
                                                    const logData = {
                                                        WorkspaceId: workspaceId.current.value,
                                                        ActionId: 212,
                                                        Mode: sessionStorage.getItem('siteMode'),
                                                        Success: false,
                                                        Metadata: featureSet.features[0].attributes['link_id'],
                                                    };
                                                    updateUserActivity(logData);
                                                });
                                            break;
                                        case config.HERETURNEXCEPTION:
                                            //Highlight street segment
                                            if (userTurnExceptionsGraphicsLayer) {
                                                if (
                                                    userTurnExceptionsGraphicsLayer.graphics.length == 1 &&
                                                    userTurnExceptionsGraphicsLayer.graphics.getItemAt(0).attributes['link_id'] !==
                                                        featureSet.features[0].attributes['link_id']
                                                ) {
                                                    putTurnException(
                                                        workspaceId.current.value,
                                                        userTurnExceptionsGraphicsLayer.graphics.getItemAt(0).attributes['link_id'],
                                                        featureSet.features[0].attributes['link_id'],
                                                    ).then(
                                                        (res) => {
                                                            const logData = {
                                                                WorkspaceId: workspaceId.current.value,
                                                                ActionId: 213,
                                                                Mode: sessionStorage.getItem('siteMode'),
                                                                Success: true,
                                                                Metadata:
                                                                    userTurnExceptionsGraphicsLayer.graphics.getItemAt(0).attributes['link_id'] +
                                                                    '|' +
                                                                    featureSet.features[0].attributes['link_id'],
                                                            };
                                                            updateUserActivity(logData);

                                                            userTurnExceptionsGraphicsLayer.graphics.removeAll();
                                                            turnExceptionsLayer.refresh();
                                                            setMapInfoMessage(
                                                                'Click on a street to set turn restriction. Click on turn restriction to delete it',
                                                            );
                                                        },
                                                        (err) => {
                                                            const logData = {
                                                                WorkspaceId: workspaceId.current.value,
                                                                ActionId: 213,
                                                                Mode: sessionStorage.getItem('siteMode'),
                                                                Success: false,
                                                                Metadata:
                                                                    userTurnExceptionsGraphicsLayer.graphics.getItemAt(0).attributes['link_id'] +
                                                                    '|' +
                                                                    featureSet.features[0].attributes['link_id'],
                                                            };
                                                            updateUserActivity(logData);
                                                        },
                                                    );
                                                } else if (
                                                    userTurnExceptionsGraphicsLayer.graphics.length == 1 &&
                                                    userTurnExceptionsGraphicsLayer.graphics.getItemAt(0).attributes['link_id'] ===
                                                        featureSet.features[0].attributes['link_id']
                                                ) {
                                                    setMapInfoMessage('Click on u-turn point.');
                                                    addStreetGraphic(featureSet.features[0], userTurnExceptionsGraphicsLayer);
                                                    let turnExceptionUturnDraw = new Draw({ view: view });
                                                    let turnExceptionUturnDrawAction = turnExceptionUturnDraw.create('point', { mode: 'click' });
                                                    turnExceptionUturnDrawAction.on('draw-complete', (evt) => {
                                                        let lngLat = webMercatorUtils.xyToLngLat(evt.coordinates[0], evt.coordinates[1]);
                                                        putUTurnException(
                                                            workspaceId.current.value,
                                                            userTurnExceptionsGraphicsLayer.graphics.getItemAt(0).attributes['link_id'],
                                                            userTurnExceptionsGraphicsLayer.graphics.getItemAt(1).attributes['link_id'],
                                                            lngLat[0],
                                                            lngLat[1],
                                                        ).then(
                                                            (res) => {
                                                                const logData = {
                                                                    WorkspaceId: workspaceId.current.value,
                                                                    ActionId: 213,
                                                                    Success: true,
                                                                    Metadata:
                                                                        userTurnExceptionsGraphicsLayer.graphics.getItemAt(0).attributes['link_id'] +
                                                                        '|' +
                                                                        userTurnExceptionsGraphicsLayer.graphics.getItemAt(1).attributes['link_id'],
                                                                };
                                                                updateUserActivity(logData);
                                                                userTurnExceptionsGraphicsLayer.graphics.removeAll();
                                                                turnExceptionsLayer.refresh();
                                                                setMapInfoMessage(
                                                                    'Click on a street to set turn restriction. Click on turn restriction to delete it',
                                                                );
                                                            },
                                                            (err) => {
                                                                const logData = {
                                                                    WorkspaceId: workspaceId.current.value,
                                                                    ActionId: 213,
                                                                    Success: false,
                                                                    Metadata:
                                                                        userTurnExceptionsGraphicsLayer.graphics.getItemAt(0).attributes['link_id'] +
                                                                        '|' +
                                                                        userTurnExceptionsGraphicsLayer.graphics.getItemAt(1).attributes['link_id'],
                                                                };
                                                                updateUserActivity(logData);
                                                            },
                                                        );
                                                    });
                                                } else if (userTurnExceptionsGraphicsLayer.graphics.length == 0) {
                                                    addStreetGraphic(featureSet.features[0], userTurnExceptionsGraphicsLayer);
                                                    setMapInfoMessage('Click on a second street to set turn restriction.');
                                                }
                                            }
                                            break;
                                    }
                                }
                            });
                    }
                }
            });

            view.popup.on('trigger-action', function (event) {
                if (mapModeRef && mapModeRef.current === 2) {
                    const id = view.popup.selectedFeature.attributes.id;
                    if (event.action.id === 'editNeighborhoodCluster') {
                        handleEditNeighborhoodCluster(id);
                        view.popup.close();
                    } else if (event.action.id === 'reshapeNeighborhoodCluster') {
                        handleReshapeNeighborhoodCluster(id);
                        view.popup.close();
                    }
                }
                if (event.action.id === 'zoomToServiceLocation') {
                    view.goTo({
                        center: [view.popup.selectedFeature.geometry.longitude, view.popup.selectedFeature.geometry.latitude],
                        zoom: 15,
                    });
                } else if (event.action.id === 'updateServiceLocation') {
                    setEditSLId(view.popup.selectedFeature.attributes.gid);
                } else if (event.action.id === 'redrawServiceAreaBoundary') {
                    editServiceAreaBoundaryInitial();
                    view.popup.close();
                } else if (event.action.id === 'deleteServiceAreaBoundary') {
                    deleteServiceAreaBoundaryInitial();
                    view.popup.close();
                }
            });

            view.watch('extent', () => {
                if (selTravelPathRouteRef.current && selTravelPathRouteRef.current != null) {
                    if (view.scale < config.TRAVEL_PATH_LABEL_VISIBILITY_SCALE) {
                        travelPathLabelLayer.visible = showTravelPathLabelRef.current;
                        setShowTravelPathLabelBtnTooltip(travelPathLabelTooltip);
                    } else {
                        travelPathLabelLayer.visible = false;
                        setShowTravelPathLabelBtnTooltip(travelPathLabelTooltip + ' (Please zoom in to see labels)');
                    }
                }
                if (selScenarioTravelPathRouteRef && selScenarioTravelPathRouteRef.current != null) {
                    if (view.scale < config.TRAVEL_PATH_LABEL_VISIBILITY_SCALE) {
                        scenarioTravelPathLabelLayer.visible = showScenarioTravelPathLabelRef.current;
                        setShowScenarioTravelPathLabelBtnTooltip(travelPathLabelTooltip);
                    } else {
                        scenarioTravelPathLabelLayer.visible = false;
                        setShowScenarioTravelPathLabelBtnTooltip(travelPathLabelTooltip + ' (Please zoom in to see labels)');
                    }
                }
            });
        }
    }, [view]);

    React.useEffect(() => {
        setShowScenarioExport(false);
        if (selDispatchTool === 'SplitRoute') {
            if (RAASFacilityLayer) RAASFacilityLayer.visible = false;
            if (RAASServiceLocationLayer) RAASServiceLocationLayer.visible = false;
            setSelScenarioTravelPathRoute(null);
            setSelScenarioSequenceRoute(null);
            setBottomPaneVisibility(false);
            setBottomPaneGrid(null);
            setSelectedRaaSServiceLocationIds([]);
        } else if (selDispatchTool === 'CreateRoutes') {
            setRouteNumberList([...Array(10).keys()].map((i) => ({ label: (i + 1).toString(), value: (i + 1).toString() })));
            updateCreateRoutesFormData('NumberOfRoutes', 1);
            if (RAASFacilityLayer) RAASFacilityLayer.visible = false;
            if (RAASServiceLocationLayer) RAASServiceLocationLayer.visible = false;
            setSelScenarioTravelPathRoute(null);
            setSelScenarioSequenceRoute(null);
            setBottomPaneVisibility(false);
            setBottomPaneGrid(null);
            setSelectedRaaSServiceLocationIds([]);
        } else if (selDispatchTool === 'SavedScenarios') {
            setRAASFacilityLayerVisibility(true);
            setBottomPaneVisibility(true);
            setBottomPaneGrid('ScenarioRouteSummary');
            setSelectedRaaSServiceLocationIds([]);
            if (selectedScenarioId) {
                refreshScenarioRouteList();
                if (RAASFacilityLayer) {
                    RAASFacilityLayer.findSublayerById(0).definitionExpression = 'processid=' + selectedScenarioId;
                    RAASFacilityLayer.visible = RAASFacilityLayerVisibility;
                }
                if (RAASServiceLocationLayer) {
                    RAASServiceLocationLayer.definitionExpression = 'processid=' + selectedScenarioId;
                    RAASServiceLocationLayer.visible = true;
                }
            }
        }
    }, [selDispatchTool]);

    React.useEffect(() => {
        if (map && RAASServiceLocationLayer) {
            RAASServiceLocationLayer.renderer = getRouteRenderer();
        }
    }, [scenarioRouteList]);

    React.useEffect(() => {
        if (refreshServiceLocations) {
            setRefreshServiceLocations(false);
            if (mapMode === 0) {
                getServiceLocations(workspace.value, serviceDay.value, siteMode, project)
                    .then((res) => {
                        if (res && res.data) {
                            setServiceLocationData(res.data);
                        } else {
                            setServiceLocationData([]);
                        }
                    })
                    .catch((err) => {
                        setServiceLocationData([]);
                    })
                    .finally(() => setIsRouteViewTabLoading(false));
                if (siteMode === 'DISPATCHER') {
                    if (map && serviceLocationLayer) {
                        serviceLocationLayer.renderer = getRouteRenderer();
                    }
                    refreshServiceLocationsInMap();
                }
            }
            setIsRouteViewTabLoading(true);
            getRouteDay(workspace.value, siteMode, project)
                .then((resRouteDay) => {
                    if (Array.isArray(resRouteDay?.data)) {
                        resRouteDay.data.sort((a, b) => a.Route.localeCompare(b.Route, 'en', { numeric: true }));
                    }
                    if (Array.isArray(resRouteDay?.data) && serviceDay.value) {
                        resRouteDay.data = resRouteDay.data.filter((v) => v.Day === serviceDay.value);
                    }
                    let dict = createRouteTabViewTableData(Array.isArray(resRouteDay?.data) ? resRouteDay?.data : []);
                    setRouteViewTableData(dict);
                })
                .catch((err) => {
                    setRouteViewTableData(initialRouteViewTableData);
                })
                .finally(() => setIsRouteViewTabLoading(false));
        }
    }, [refreshServiceLocations]);

    React.useEffect(() => {
        if (map && RAASServiceLocationLayer) {
            RAASServiceLocationLayer.renderer = getRouteRenderer();
        }
        refreshScenarioServiceLocationsInMap();
    }, [selectedScenarioRoutes]);

    React.useEffect(() => {
        if (facilityLayer) {
            facilityLayer.visible = facilityLayerVisibility;
        }
    }, [facilityLayerVisibility]);

    React.useEffect(() => {
        if (RAASFacilityLayer) {
            RAASFacilityLayer.visible = RAASFacilityLayerVisibility;
        }
    }, [RAASFacilityLayerVisibility]);

    React.useEffect(() => {
        if (zoneFacilityLayer) {
            zoneFacilityLayer.findSublayerById(parseInt(config.FACILITY)).visible = zoneFacilityLayerVisibility;
        }
    }, [zoneFacilityLayerVisibility]);

    React.useEffect(() => {
        if (zoneServiceLocationLayer) {
            if (siteMode === 'DISPATCHER') {
                zoneServiceLocationLayer.findSublayerById(parseInt(config.SERVICE_LOCATION)).visible = zoneServiceLocationLayerVisibility;
            } else {
                zoneServiceLocationLayer.findSublayerById(parseInt(config.SANDBOXSERVICELOCATION)).visible = zoneServiceLocationLayerVisibility;
            }
        }
    }, [zoneServiceLocationLayerVisibility]);

    React.useEffect(() => {
        if (projectFacilityLayer) {
            projectFacilityLayer.visible = projectFacilityLayerVisibility;
        }
    }, [projectFacilityLayerVisibility]);

    React.useEffect(() => {
        if (projectServiceLocationLayer) {
            projectServiceLocationLayer.visible = projectServiceLocationLayerVisibility;
        }
    }, [projectServiceLocationLayerVisibility]);

    React.useEffect(() => {
        if (streetsLayer) {
            streetsLayer.findSublayerById(10).popupEnabled = streetLayerVisibility;
            streetsLayer.findSublayerById(10).renderer = STREET_RENDERER;
            streetsLayer.visible = streetLayerVisibility;
        }
    }, [streetLayerVisibility]);

    React.useEffect(() => {
        if (trafficLayer) {
            trafficLayer.visible = trafficLayerVisibility;
        }
    }, [trafficLayerVisibility]);

    React.useEffect(() => {
        if (blockFaceLayerVisbility) {
            if (siteMode === 'DISPATCHER') {
                showRouteBlockfaceLayer();
            } else if (siteMode === 'PLANNER') {
                showSandboxRouteBlockfaceLayer();
            }
        } else {
            if (map) {
                if (map.findLayerById('routeBlockfaceLayer')) map.remove(map.findLayerById('routeBlockfaceLayer'));
                if (map.findLayerById('sandboxRouteBlockfaceLayer')) map.remove(map.findLayerById('sandboxRouteBlockfaceLayer'));
                routeBlockfaceLayer = null;
                sandboxRouteBlockfaceLayer = null;
            }
        }
    }, [blockFaceLayerVisbility]);

    React.useEffect(() => {
        if (selTravelPathRoute !== null) {
            if (serviceDay.value === 0) {
                if (routeViewTabCategorization.value.startsWith('R')) {
                    //need adjustment here
                    let travelPathRoute = selTravelPathRoute.split('|')[0];
                    let travelPathServiceDay = selTravelPathRoute.split('|')[1];
                    let travelPathType = selTravelPathRoute.split('|')[2];
                    getTravelPathData(workspace.value, travelPathServiceDay, travelPathRoute, siteMode, project).then(
                        (res) => {
                            if (res.data && res.data.length > 0) {
                                setShowTravelPathLabel(true);
                                renderTravelPath(travelPathRoute, res.data, travelPathType, 'route');
                            }
                            setIsMapLoading(false);
                        },
                        (err) => {
                            setIsMapLoading(false);
                            setShowTravelPathLabel(false);
                            setSelTravelPathRoute(null);
                            showAPICallbackDialog('Error', err.response.data);
                        },
                    );
                } else {
                    let travelPathServiceDay = selTravelPathRoute.split('|')[0];
                    let travelPathRoute = selTravelPathRoute.split('|')[1];
                    let travelPathType = selTravelPathRoute.split('|')[2];
                    getTravelPathData(workspace.value, travelPathServiceDay, travelPathRoute, siteMode, project).then(
                        (res) => {
                            if (res.data && res.data.length > 0) {
                                setShowTravelPathLabel(true);
                                renderTravelPath(travelPathRoute, res.data, travelPathType, 'route');
                            }
                            setIsMapLoading(false);
                        },
                        (err) => {
                            setIsMapLoading(false);
                            setShowTravelPathLabel(false);
                            setSelTravelPathRoute(null);
                            showAPICallbackDialog('Error', err.response.data);
                        },
                    );
                }
            } else {
                let travelPathRoute = selTravelPathRoute.split('|')[0];
                let travelPathType = selTravelPathRoute.split('|')[1];
                getTravelPathData(workspace.value, serviceDay.value, travelPathRoute, siteMode, project).then(
                    (res) => {
                        if (res.data && res.data.length > 0) {
                            setShowTravelPathLabel(true);
                            renderTravelPath(travelPathRoute, res.data, travelPathType, 'route');
                        }
                        setIsMapLoading(false);
                    },
                    (err) => {
                        setIsMapLoading(false);
                        setShowTravelPathLabel(false);
                        setSelTravelPathRoute(null);
                        showAPICallbackDialog('Error', err.response.data);
                    },
                );
            }
        } else {
            if (travelPathLayer) travelPathLayer.graphics.removeAll();
            if (travelPathLabelLayer) travelPathLabelLayer.graphics.removeAll();
            setShowTravelPathLabel(false);
        }
    }, [selTravelPathRoute]);

    React.useEffect(() => {
        if (selScenarioTravelPathRoute !== null) {
            setShowScenarioTravelPathLabel(true);
            let travelPathRoute = selScenarioTravelPathRoute.split('|')[0];
            let travelPathType = selScenarioTravelPathRoute.split('|')[1];
            getJobRouteTravelPath(workspace.value, selectedScenarioId, travelPathRoute, siteMode, project).then(
                (res) => {
                    if (res.data && res.data.length > 0) {
                        renderTravelPath(travelPathRoute, res.data, travelPathType, 'scenarioRoute');
                    }
                    setIsMapLoading(false);
                },
                (err) => {
                    setIsMapLoading(false);
                    showAPICallbackDialog('Error', err.response.data);
                },
            );
        } else {
            if (scenarioTravelPathLayer) scenarioTravelPathLayer.graphics.removeAll();
            if (scenarioTravelPathLabelLayer) scenarioTravelPathLabelLayer.graphics.removeAll();
            setShowScenarioTravelPathLabel(false);
        }
    }, [selScenarioTravelPathRoute]);

    React.useEffect(() => {
        if (selSequenceRoute !== null) {
            if (serviceDay.value === 0) {
                if (routeViewTabCategorization.value.startsWith('R')) {
                    //need adjustment here
                    let sequenceRoute = selSequenceRoute.split('|')[0];
                    let sequenceServiceDay = selSequenceRoute.split('|')[1];
                    getSequenceLayerData(workspace.value, sequenceRoute, sequenceServiceDay, siteMode, project).then(
                        (res) => {
                            if (res.data && res.data.length > 0) {
                                renderSequence(res.data, 'route');
                            }
                            setIsMapLoading(false);
                        },
                        (err) => {
                            setIsMapLoading(false);
                            showAPICallbackDialog('Error', err.response.data);
                        },
                    );
                } else {
                    let sequenceServiceDay = selSequenceRoute.split('|')[0];
                    let sequenceRoute = selSequenceRoute.split('|')[1];
                    getSequenceLayerData(workspace.value, sequenceRoute, sequenceServiceDay, siteMode, project).then(
                        (res) => {
                            if (res.data && res.data.length > 0) {
                                renderSequence(res.data, 'route');
                            }
                            setIsMapLoading(false);
                        },
                        (err) => {
                            setIsMapLoading(false);
                            showAPICallbackDialog('Error', err.response.data);
                        },
                    );
                }
            } else {
                getSequenceLayerData(workspace.value, selSequenceRoute, serviceDay.value, siteMode, project).then(
                    (res) => {
                        if (res.data && res.data.length > 0) {
                            renderSequence(res.data, 'route');
                        }
                        setIsMapLoading(false);
                    },
                    (err) => {
                        setIsMapLoading(false);
                        showAPICallbackDialog('Error', err.response.data);
                    },
                );
            }
        } else {
            if (sequenceLayer) {
                sequenceLayer.graphics.removeAll();
            }
            if (sequenceLabelLayer) {
                sequenceLabelLayer.graphics.removeAll();
            }
        }
    }, [selSequenceRoute]);

    React.useEffect(() => {
        if (selScenarioSequenceRoute !== null) {
            getJobRouteSequence(workspace.value, selectedScenarioId, selScenarioSequenceRoute).then(
                (res) => {
                    if (res.data && res.data.length > 0) {
                        renderSequence(res.data, 'scenarioRoute');
                    }
                    setIsMapLoading(false);
                },
                (err) => {
                    showAPICallbackDialog('Error', err.response.data);
                    setIsMapLoading(false);
                },
            );
        } else {
            if (scenarioSequenceLayer) {
                scenarioSequenceLayer.graphics.removeAll();
            }
            if (scenarioSequenceLabelLayer) {
                scenarioSequenceLabelLayer.graphics.removeAll();
            }
            setShowScenarioSequenceLabel(false);
        }
    }, [selScenarioSequenceRoute]);

    React.useEffect(() => {
        if (showSequenceLabel && sequenceLabelLayer) {
            sequenceLabelLayer.visible = true;
        } else if (sequenceLabelLayer) {
            sequenceLabelLayer.visible = false;
        }
    }, [showSequenceLabel]);

    React.useEffect(() => {
        if (showScenarioSequenceLabel && scenarioSequenceLabelLayer) {
            scenarioSequenceLabelLayer.visible = true;
        } else if (scenarioSequenceLabelLayer) {
            scenarioSequenceLabelLayer.visible = false;
        }
    }, [showScenarioSequenceLabel]);

    React.useEffect(() => {
        if (showTravelPathLabel && travelPathLabelLayer) {
            if (view.scale > config.TRAVEL_PATH_LABEL_VISIBILITY_SCALE) {
                travelPathLabelLayer.visible = false;
                setShowTravelPathLabelBtnTooltip(travelPathLabelTooltip + ' (Please zoom in to see labels)');
            } else {
                travelPathLabelLayer.visible = true;
                setShowTravelPathLabelBtnTooltip(travelPathLabelTooltip);
            }
        } else if (travelPathLabelLayer) {
            travelPathLabelLayer.visible = false;
            setShowTravelPathLabelBtnTooltip(travelPathLabelTooltip);
        }
    }, [showTravelPathLabel]);

    React.useEffect(() => {
        if (showScenarioTravelPathLabel && scenarioTravelPathLabelLayer) {
            if (view.scale > config.TRAVEL_PATH_LABEL_VISIBILITY_SCALE) {
                scenarioTravelPathLabelLayer.visible = false;
                setShowScenarioTravelPathLabelBtnTooltip(travelPathLabelTooltip + ' (Please zoom in to see labels)');
            } else if (scenarioTravelPathLabelLayer) {
                scenarioTravelPathLabelLayer.visible = true;
                setShowScenarioTravelPathLabelBtnTooltip(travelPathLabelTooltip);
            }
        } else if (scenarioTravelPathLabelLayer) {
            scenarioTravelPathLabelLayer.visible = false;
            setShowScenarioTravelPathLabelBtnTooltip(travelPathLabelTooltip);
        }
    }, [showScenarioTravelPathLabel]);

    React.useEffect(() => {
        if (siteMode === 'DISPATCHER') {
            if (map && serviceLocationLayer) {
                serviceLocationLayer.renderer = getRouteRenderer();
            }
        } else {
            if (map && projectServiceLocationLayer) {
                projectServiceLocationLayer.renderer = getRouteRenderer();
            }
        }
        refreshServiceLocationsInMap();
    }, [timeWindowToggle]);

    React.useEffect(() => {
        if (refreshMap && siteMode === 'DISPATCH') {
            if (map && serviceLocationLayer) {
                serviceLocationLayer.renderer = getRouteRenderer();
            }
        } else if (refreshMap && siteMode === 'PLANNER') {
            if (map && projectServiceLocationLayer) {
                projectServiceLocationLayer.renderer = getRouteRenderer();
            }
        }
        refreshServiceLocationsInMap();
        setRefreshMap(false);
    }, [refreshMap]);

    React.useEffect(() => {
        if (loadScenario) {
            setLoadScenario(false);
            refreshServiceLocationList();
            refreshServiceLocationsInMap();
            setBottomPaneGrid('RouteSummary');
            setIsMapLoading(false);
        }
    }, [loadScenario]);
    ///////////////////////////////////////////////////////////////////
    React.useEffect(() => {
        if (mapMode === 2) {
            setZoneMode('VIEW');
            setSelectedNeighborhoodClusters([]);
            handleDrawNeighborhoodClusters([]);
            neighborhoodLayer?.removeAll();
            zoneGraphicLayer?.removeAll();
            if (workspace && workspace.value !== 0 && routePlanId) {
                refreshAAFromRoutePlan();
                if (zoneServiceLocationLayer) {
                    if (siteMode === 'DISPATCHER') {
                        let queryStr = 'workspace_id=' + workspace.value;
                        if (serviceDay && serviceDay.value != 0) {
                            queryStr += ' and service_day=' + serviceDay.value;
                        }
                        zoneServiceLocationLayer.findSublayerById(parseInt(config.SERVICE_LOCATION)).definitionExpression = queryStr;
                        zoneServiceLocationLayer.findSublayerById(parseInt(config.SERVICE_LOCATION)).renderer = {
                            type: 'simple',
                            symbol: {
                                type: 'simple-marker',
                                style: 'circle',
                                color: 'white',
                                size: 5,
                                outline: { width: 1, color: 'black' },
                            },
                        };
                        zoneServiceLocationLayer.refresh();
                    } else {
                        let queryStr = 'sandbox_id=' + project;
                        if (serviceDay.value !== 0) {
                            queryStr += ' and service_day in (' + serviceDay.value + ')';
                        }
                        zoneServiceLocationLayer.findSublayerById(parseInt(config.SANDBOXSERVICELOCATION)).definitionExpression = queryStr;
                        zoneServiceLocationLayer.findSublayerById(parseInt(config.SANDBOXSERVICELOCATION)).renderer = {
                            type: 'simple',
                            symbol: {
                                type: 'simple-marker',
                                style: 'circle',
                                color: 'white',
                                size: 5,
                                outline: { width: 1, color: 'black' },
                            },
                        };
                        zoneServiceLocationLayer.refresh();
                    }
                }
            } else {
                if (siteMode === 'DISPATCHER') {
                    let queryStr = 'workspace_id=' + workspace.value;
                    if (serviceDay.value !== 0) {
                        queryStr += ' and service_day=' + serviceDay.value;
                    }
                    if (zoneServiceLocationLayer) {
                        zoneServiceLocationLayer.findSublayerById(parseInt(config.SERVICE_LOCATION)).definitionExpression = queryStr;
                        zoneServiceLocationLayer.refresh();
                    }
                } else {
                    let queryStr = 'sandbox_id=' + project;
                    if (serviceDay.value !== 0) {
                        queryStr += ' and service_day in (' + serviceDay.value + ')';
                    }
                    if (zoneServiceLocationLayer) {
                        zoneServiceLocationLayer.findSublayerById(parseInt(config.SANDBOXSERVICELOCATION)).definitionExpression = queryStr;
                        zoneServiceLocationLayer.refresh();
                    }
                }
                if (!routePlanId && serviceDay.value !== 0) {
                    getRouteList(siteMode, workspace.value, serviceDay.value, project).then(
                        (res) => {
                            if (res.status === 200 && res.data) {
                                let rtlist = [];
                                if (Array.isArray(res.data)) {
                                    res.data = res.data.sort((a, b) => a.Id.localeCompare(b.Id, 'en', { numeric: true }));
                                    rtlist = getRouteRenderList(res.data, 'zoneRouteList');
                                } else {
                                    rtlist = getRouteRenderList(res.data, 'projectZoneRouteList');
                                }
                                setZoneRouteList(rtlist);
                                if (rtlist.length > 0) {
                                    const allRoutes = rtlist.map((r) => r.Id);
                                    setSelectedZoneRoutes(allRoutes);
                                }
                            } else {
                                setZoneRouteList([]);
                                setSelectedZoneRoutes([]);
                            }
                        },
                        () => {
                            setZoneRouteList([]);
                            setSelectedZoneRoutes([]);
                        },
                    );
                } else if (!routePlanId && serviceDay.value === 0) {
                    getRouteList(siteMode, workspace.value, serviceDay.value, project).then(
                        (res) => {
                            let rtlist = [];
                            if (sessionStorage.getItem('mdr') === 'true') {
                                if (Array.isArray(res.data)) {
                                    res.data = res.data.sort((a, b) => a.Id.localeCompare(b.Id, 'en', { numeric: true }));
                                    rtlist = getRouteRenderList(res.data, 'zoneRouteList');
                                } else {
                                    rtlist = getRouteRenderList(res.data, 'projectZoneRouteList');
                                }
                                setZoneRouteList(rtlist);
                                if (rtlist.length > 0) {
                                    const allRoutes = rtlist.map((r) => r.Id);
                                    setSelectedZoneRoutes(allRoutes);
                                }
                            } else {
                                rtlist = getRouteRenderListForAllDays();
                                setZoneRouteList(rtlist);
                                if (rtlist.length > 0) {
                                    const allRoutes = rtlist.map((r) => r.Id);
                                    setSelectedZoneRoutes(allRoutes);
                                }
                            }
                        },
                        () => {
                            let rtlist = [];
                            rtlist = getRouteRenderListForAllDays();
                            setZoneRouteList(rtlist);
                            if (rtlist.length > 0) {
                                const allRoutes = rtlist.map((r) => r.Id);
                                setSelectedZoneRoutes(allRoutes);
                            }
                        },
                    );
                }
            }
        }
    }, [routePlanId]);

    React.useEffect(() => {
        neighborhoodLayer?.removeAll();
        zoneGraphicLayer?.removeAll();
        editSketch?.cancel();
        if (zoneMode === 'ASSIGN') {
            if (selectedZoneRoutes.length !== 1) {
                if (zoneRouteList.length > 0) {
                    setSelectedZoneRoutes([zoneRouteList[0].Id]);
                }
            }
            handleDrawNeighborhoodClusters();
        } else if (zoneMode === 'SEQUENCE') {
            if (selectedZoneRoutes.length !== 1) {
                if (zoneRouteList.length > 0) {
                    setSelectedZoneRoutes([zoneRouteList[0].Id]);
                }
            }
            const currentRoute = selectedZoneRoutes[0];
            let first = [],
                last = [],
                selectedSequenced = [];
            const selectedNC = sequenceClusterData.filter((v) => v.Route === currentRoute);
            const selected = selectedNC.map((a) => a.AnchorAreaId);

            selectedNC.map((snc) => {
                if (snc.Priority === 'F') {
                    first.push(snc.AnchorAreaId);
                } else if (snc.Priority === 'L') {
                    last.push(snc.AnchorAreaId);
                } else if (snc.Sequence) {
                    selectedSequenced.push({ Id: snc.AnchorAreaId, Sequence: snc.Sequence });
                }
            });

            setSelectedNeighborhoodClusters(selected);
            setFirstZone(first);
            setLastZone(last);
            setSequencedZone(selectedSequenced);

            const existingSequence = selectedSequenced.map((a) => a.Sequence);
            let next = nextSequenceRef.current;
            [...Array(100).keys()].every((i) => {
                if (existingSequence.indexOf(i + 1) < 0) {
                    next = i + 1;
                }
                return existingSequence.indexOf(i + 1) > -1;
            });
            if (next > 100) next = nextSequenceRef.current;
            setNextSequence(next);

            handleDrawNeighborhoodClusters();
        } else if (zoneMode === 'VIEW') {
            const rt = zoneRouteList.map((r) => r.Id);
            setSelectedZoneRoutes(rt);
            //need to change to select all the assigned NC and display on map.
            setSelectedNeighborhoodClusters([]);
            handleDrawNeighborhoodClusters([]);
        }
    }, [zoneMode]);

    React.useEffect(() => {
        if (mapMode === 2) {
            if (routePlanId) {
                if (zoneMode === 'ASSIGN') {
                    showAssignModeNeighborhoodClusters();
                } else if (zoneMode === 'SEQUENCE') {
                    const currentRoute = selectedZoneRoutes[0];
                    let first = [],
                        last = [],
                        selectedSequenced = [];
                    const selectedNC = sequenceClusterData.filter((v) => v.Route === currentRoute);
                    const selected = selectedNC.map((a) => a.AnchorAreaId);

                    selectedNC.map((snc) => {
                        if (snc.Priority === 'F') {
                            first.push(snc.AnchorAreaId);
                        } else if (snc.Priority === 'L') {
                            last.push(snc.AnchorAreaId);
                        } else if (snc.Sequence) {
                            selectedSequenced.push({ Id: snc.AnchorAreaId, Sequence: snc.Sequence });
                        }
                    });

                    setSelectedNeighborhoodClusters(selected);
                    setFirstZone(first);
                    setLastZone(last);
                    setSequencedZone(selectedSequenced);

                    const existingSequence = selectedSequenced.map((a) => a.Sequence);
                    let next = nextSequenceRef.current;
                    [...Array(100).keys()].every((i) => {
                        if (existingSequence.indexOf(i + 1) < 0) {
                            next = i + 1;
                        }
                        return existingSequence.indexOf(i + 1) > -1;
                    });
                    if (next > 100) next = nextSequenceRef.current;
                    setNextSequence(next);

                    handleDrawNeighborhoodClusters();
                } else {
                    if (sequenceClusterData && sequenceClusterData.length > 0) {
                        let selected = [];
                        let filteredSC = sequenceClusterData.filter((sc) => selectedZoneRoutes.includes(sc.Route));

                        filteredSC.map((n) => {
                            selected.push(n.AnchorAreaId);
                        });
                        handleDrawNeighborhoodClusters(selected);
                    } else {
                        handleDrawNeighborhoodClusters([]);
                    }
                }
            } else {
                if (zoneServiceLocationLayer) {
                    if (siteMode === 'DISPATCHER') {
                        let queryStr = 'workspace_id=' + workspace.value;
                        if (serviceDay.value === 0) {
                            zoneServiceLocationLayer.findSublayerById(parseInt(config.SERVICE_LOCATION)).renderer = getRouteRenderer();
                            if (selectedZoneRoutes && selectedZoneRoutes.length > 0) {
                                let sd = selectedZoneRoutes.join(',');
                                if (sessionStorage.getItem('mdr') === 'true') {
                                    let rt = selectedZoneRoutes.join("','").replace('No Route', '0');
                                    queryStr += " and route in ('" + rt + "')";
                                } else {
                                    let sd = selectedZoneRoutes.join(',');
                                    queryStr += ' and service_day in (' + sd + ')';
                                }
                                zoneServiceLocationLayer.findSublayerById(parseInt(config.SERVICE_LOCATION)).visible =
                                    zoneServiceLocationLayerVisibility;
                            } else {
                                queryStr += " and service_day='-1'";
                                zoneServiceLocationLayer.findSublayerById(parseInt(config.SERVICE_LOCATION)).visible = false;
                            }
                        } else {
                            //a weekday, add route renderer
                            queryStr += ' and service_day=' + serviceDay.value;
                            if (selectedZoneRoutes && selectedZoneRoutes.length > 0) {
                                let rt = selectedZoneRoutes.join("','").replace('No Route', '0');
                                queryStr += " and route in ('" + rt + "')";
                                zoneServiceLocationLayer.findSublayerById(parseInt(config.SERVICE_LOCATION)).visible =
                                    zoneServiceLocationLayerVisibility;
                            } else {
                                queryStr += " and route='-1'";
                                zoneServiceLocationLayer.findSublayerById(parseInt(config.SERVICE_LOCATION)).visible = false;
                            }
                            zoneServiceLocationLayer.findSublayerById(parseInt(config.SERVICE_LOCATION)).renderer = getRouteRenderer();
                        }
                        zoneServiceLocationLayer.findSublayerById(parseInt(config.SERVICE_LOCATION)).definitionExpression = queryStr;
                    } else {
                        let queryStr = 'sandbox_id=' + project;
                        if (serviceDay.value === 0) {
                            //add day, add weekly renderer,
                            zoneServiceLocationLayer.findSublayerById(parseInt(config.SANDBOXSERVICELOCATION)).renderer = getRouteRenderer();
                            if (selectedZoneRoutes && selectedZoneRoutes.length > 0) {
                                if (sessionStorage.getItem('mdr') === 'true') {
                                    let rt = selectedZoneRoutes.join("','").replace('No Route', '0');
                                    queryStr += " and route in ('" + rt + "')";
                                } else {
                                    let sd = selectedZoneRoutes.join(',');
                                    queryStr += ' and service_day in (' + sd + ')';
                                }
                                zoneServiceLocationLayer.findSublayerById(parseInt(config.SANDBOXSERVICELOCATION)).visible =
                                    zoneServiceLocationLayerVisibility;
                            } else {
                                queryStr += " and service_day='-1'";
                                zoneServiceLocationLayer.findSublayerById(parseInt(config.SANDBOXSERVICELOCATION)).visible = false;
                            }
                        } else {
                            //a weekday, add route renderer
                            queryStr += ' and service_day in (' + serviceDay.value + ')';
                            if (selectedZoneRoutes && selectedZoneRoutes.length > 0) {
                                let rt = selectedZoneRoutes.join("','").replace('No Route', '0');
                                queryStr += " and route in ('" + rt + "')";
                                zoneServiceLocationLayer.findSublayerById(parseInt(config.SANDBOXSERVICELOCATION)).visible =
                                    zoneServiceLocationLayerVisibility;
                            } else {
                                queryStr += " and route='-1'";
                                zoneServiceLocationLayer.findSublayerById(parseInt(config.SANDBOXSERVICELOCATION)).visible = false;
                            }
                            zoneServiceLocationLayer.findSublayerById(parseInt(config.SANDBOXSERVICELOCATION)).renderer = getRouteRenderer();
                        }
                        zoneServiceLocationLayer.findSublayerById(parseInt(config.SANDBOXSERVICELOCATION)).definitionExpression = queryStr;
                    }
                    zoneServiceLocationLayer.refresh();
                }
            }
        }
    }, [selectedZoneRoutes]);

    React.useEffect(() => {
        if (showReport) {
            setShowExport(false);
            setShowScenarioReport(false);
            setShowScenarioExport(false);
        }
    }, [showReport]);

    React.useEffect(() => {
        if (showExport) {
            setShowReport(false);
            setShowScenarioReport(false);
            setShowScenarioExport(false);
        }
    }, [showExport]);

    React.useEffect(() => {
        if (showScenarioReport) {
            setShowReport(false);
            setShowExport(false);
            setShowScenarioExport(false);
        }
    }, [showScenarioReport]);

    React.useEffect(() => {
        if (showScenarioExport) {
            setShowReport(false);
            setShowExport(false);
            setShowScenarioReport(false);
        }
    }, [showScenarioExport]);

    React.useEffect(() => {
        if (editSLId) {
            setEditSLId(null);
            const d = serviceLocationData.find((d) => d.Id === editSLId);
            if (d) {
                handleEditServiceLocation(d);
            }
        }
    }, [editSLId]);

    React.useEffect(() => {
        if (selectedScenarioId && workspace) {
            RaaSArcgisAPI = RaaSArcgisAPI.replace('[workspace_id]', workspace.value);
            RaaSArcgisAPI = RaaSArcgisAPI.replace('[RaasJobId]', selectedScenarioId);

            RaaSPrintURL = RaaSPrintURL.replace('[workspace_id]', workspace.value);
            RaaSPrintURL = RaaSPrintURL.replace('[RaasJobId]', selectedScenarioId);

            //RaaS Facility
            var oldFLayer = map.findLayerById('RaasFacilityLayer');
            map.remove(oldFLayer);
            //let RAASFacilityLayer = null;
            showRAASFacilityLayer(map);

            //RaaS SL
            var oldSlLayer = map.findLayerById('RaasSlLayer');
            map.remove(oldSlLayer);
            showRAASServiceLocationLayer(map);

            refreshScenarioRouteList();
            setSelScenarioTravelPathRoute(null);
            setSelScenarioSequenceRoute(null);
            setRefreshScenarioServiceLocation(true);
            if (RAASFacilityLayer) {
                RAASFacilityLayer.findSublayerById(0).definitionExpression = 'processid=' + selectedScenarioId;
                RAASFacilityLayer.visible = RAASFacilityLayerVisibility;
            }
            if (RAASServiceLocationLayer) {
                RAASServiceLocationLayer.definitionExpression = 'processid=' + selectedScenarioId;
                RAASServiceLocationLayer.visible = true;
            }
        }
    }, [selectedScenarioId]);

    //Selected facility change
    React.useEffect(() => {
        if (view && selectedFacilityIds && selectedFacilityIds.length > 0) {
            view.whenLayerView(facilityLayer).then((layerView) => {
                let query = facilityLayer.findSublayerById(0).createQuery();
                query.where = `workspace_id=${workspace.value} AND gid in (${selectedFacilityIds.join(',')})`;
                query.returnGeometry = true;
                facilityLayer
                    .findSublayerById(0)
                    .queryFeatures(query)
                    .then(function (result) {
                        if (facilityHighlight) {
                            facilityHighlight.remove();
                        }
                        let highlight = layerView.highlight(result.features);
                        setFacilityHighlight(highlight);
                    });
            });
        } else {
            if (facilityHighlight) {
                facilityHighlight.remove();
            }
        }
        window.userSelect = false;
    }, [selectedFacilityIds]);

    React.useEffect(() => {
        if (view && selectedServiceLocationIds && selectedServiceLocationIds.length > 0) {
            if (!selectionDetailOpen && !autoCollapse) {
                setSelectionDetailOpen(true);
            }
            if (siteMode === 'DISPATCHER') {
                let query = serviceLocationLayer.createQuery();
                query.where = `workspace_id=${workspace.value} AND gid in (${selectedServiceLocationIds.join(',')})`;
                query.returnGeometry = true;
                query.outSpatialReference = { wkid: 4326 };
                serviceLocationLayer.queryFeatures(query).then(function (result) {
                    slHighlightGraphicsLayer.graphics.removeAll();
                    result.features.forEach((feature) => {
                        const graphic = new Graphic({
                            geometry: {
                                type: 'point',
                                longitude: feature.attributes.x,
                                latitude: feature.attributes.y,
                            },
                            symbol: {
                                type: 'simple-marker',
                                style: 'circle',
                                color: [0, 255, 255],
                                size: '10px', // pixels
                                outline: {
                                    color: [0, 255, 255],
                                    width: 3, // points
                                },
                            },
                        });
                        slHighlightGraphicsLayer.graphics.add(graphic);
                    });
                });

                if (sessionStorage.getItem('mdr') === 'true' && servicePatternView) {
                    let matchingSL = serviceLocationData.filter((s) => selectedServiceLocationIds.includes(s.Id));
                    let SDids = [...new Set(matchingSL.map((m) => m.SdId))];
                    if (SDids.length > 0) {
                        let q = servicePatternLayer.findSublayerById(14).createQuery();

                        q.where = `workspace_id=${workspace.value} and sd_id in ('${SDids.join("','")}')`;
                        q.returnGeometry = true;
                        q.outSpatialReference = { wkid: 4326 };
                        q.outFields = [
                            'workspace_id',
                            'sd_id',
                            'gid',
                            'route',
                            'week',
                            'month',
                            'frequency',
                            'service_pattern',
                            'demand',
                            'service_time',
                            'quantity',
                            'service_pattern_group',
                        ];

                        let queryParamValue = { workspace_id: workspace.value };
                        servicePatternLayer.customParameters.parameterValues = JSON.stringify(queryParamValue);

                        servicePatternLayer
                            .findSublayerById(14)
                            .queryFeatures(q)
                            .then(function (results) {
                                let selectedPatterns = [];
                                if (results.features.length > 0) {
                                    results.features.forEach((r) => {
                                        selectedPatterns.push({
                                            workspace_id: r.attributes.workspace_id,
                                            sd_id: r.attributes.sd_id,
                                            gid: r.attributes.gid,
                                            route: r.attributes.route,
                                            week: r.attributes.week,
                                            month: r.attributes.month,
                                            frequency: r.attributes.frequency,
                                            service_pattern: r.attributes.service_pattern,
                                            demand: r.attributes.demand,
                                            service_time: r.attributes.service_time,
                                            quantity: r.attributes.quantity,
                                            service_pattern_group: r.attributes.service_pattern_group,
                                        });
                                    });
                                }
                                setSelectedServicePattern(selectedPatterns);
                            });
                    } else {
                        setSelectedServicePattern([]);
                    }
                }
            } else {
                let query = projectServiceLocationLayer.createQuery();
                query.where = `sandbox_id=${project} AND gid in (${selectedServiceLocationIds.join(',')})`;
                query.returnGeometry = true;
                query.outSpatialReference = { wkid: 4326 };
                projectServiceLocationLayer.queryFeatures(query).then(function (result) {
                    slHighlightGraphicsLayer.graphics.removeAll();
                    result.features.forEach((feature) => {
                        const graphic = new Graphic({
                            geometry: {
                                type: 'point',
                                longitude: feature.attributes.x,
                                latitude: feature.attributes.y,
                            },
                            symbol: {
                                type: 'simple-marker',
                                style: 'circle',
                                color: [0, 255, 255],
                                size: '10px', // pixels
                                outline: {
                                    color: [0, 255, 255],
                                    width: 3, // points
                                },
                            },
                        });
                        slHighlightGraphicsLayer.graphics.add(graphic);
                    });
                });

                if (sessionStorage.getItem('mdr') === 'true' && servicePatternView) {
                    let matchingSL = serviceLocationData.filter((s) => selectedServiceLocationIds.includes(s.Id));
                    let SDids = [...new Set(matchingSL.map((m) => m.SdId))];
                    if (SDids.length > 0) {
                        let q = projectServicePatternLayer.findSublayerById(15).createQuery();

                        q.where = `sandbox_id=${project} and sd_id in ('${SDids.join("','")}')`;
                        q.returnGeometry = true;
                        q.outSpatialReference = { wkid: 4326 };
                        q.outFields = [
                            'sandbox_id',
                            'sd_id',
                            'gid',
                            'route',
                            'week',
                            'month',
                            'frequency',
                            'service_pattern',
                            'demand',
                            'service_time',
                            'quantity',
                            'service_pattern_group',
                        ];

                        let queryParamValue = { sandbox_id: project };
                        projectServicePatternLayer.customParameters.parameterValues = JSON.stringify(queryParamValue);

                        projectServicePatternLayer
                            .findSublayerById(15)
                            .queryFeatures(q)
                            .then(function (results) {
                                let selectedPatterns = [];
                                if (results.features.length > 0) {
                                    results.features.forEach((r) => {
                                        selectedPatterns.push({
                                            sandbox_id: r.attributes.sandbox_id,
                                            sd_id: r.attributes.sd_id,
                                            gid: r.attributes.gid,
                                            route: r.attributes.route,
                                            week: r.attributes.week,
                                            month: r.attributes.month,
                                            frequency: r.attributes.frequency,
                                            service_pattern: r.attributes.service_pattern,
                                            demand: r.attributes.demand,
                                            service_time: r.attributes.service_time,
                                            quantity: r.attributes.quantity,
                                            service_pattern_group: r.attributes.service_pattern_group,
                                        });
                                    });
                                }
                                setSelectedServicePattern(selectedPatterns);
                            });
                    } else {
                        setSelectedServicePattern([]);
                    }
                }
            }
        } else {
            if (slHighlightGraphicsLayer) slHighlightGraphicsLayer.graphics.removeAll();
            if (selectionDetailOpen) {
                setSelectionDetailOpen(false);
            }
            if (selectedServicePattern.length > 0) {
                setSelectedServicePattern([]);
            }
        }
    }, [selectedServiceLocationIds]);

    React.useEffect(() => {
        if (view && selectedRaaSServiceLocationIds && selectedRaaSServiceLocationIds.length > 0) {
            setSelectionDetailOpen(false);
            let query = RAASServiceLocationLayer.createQuery();
            query.where = `processid=${selectedScenarioId} and record_id in (${selectedRaaSServiceLocationIds.join(',')})`;
            query.returnGeometry = true;
            query.outSpatialReference = { wkid: 4326 };
            RAASServiceLocationLayer.queryFeatures(query).then(function (result) {
                slHighlightGraphicsLayer.graphics.removeAll();
                result.features.forEach((feature) => {
                    const graphic = new Graphic({
                        geometry: {
                            type: 'point',
                            longitude: feature.attributes.x,
                            latitude: feature.attributes.y,
                        },
                        symbol: {
                            type: 'simple-marker',
                            style: 'circle',
                            color: [0, 255, 255],
                            size: '10px', // pixels
                            outline: {
                                color: [0, 255, 255],
                                width: 3, // points
                            },
                        },
                    });
                    slHighlightGraphicsLayer.graphics.add(graphic);
                });
            });
        } else {
            if (slHighlightGraphicsLayer) slHighlightGraphicsLayer.graphics.removeAll();
        }
    }, [selectedRaaSServiceLocationIds]);

    React.useEffect(() => {
        if (servicePatternView) {
            if (view && selectedServicePattern && selectedServicePattern.length > 0) {
                if (!selectionDetailOpen && !autoCollapse) {
                    setSelectionDetailOpen(true);
                }

                const ids = selectedServicePattern.map((s) => s.sd_id);

                if (siteMode === 'DISPATCHER') {
                    let query = servicePatternLayer.findSublayerById(14).createQuery();

                    query.where = `workspace_id=${workspace.value} and sd_id in ('${ids.join("','")}')`;
                    query.returnGeometry = true;
                    query.outSpatialReference = { wkid: 4326 };

                    let queryParamValue = { workspace_id: workspace.value };
                    servicePatternLayer.customParameters.parameterValues = JSON.stringify(queryParamValue);

                    servicePatternLayer
                        .findSublayerById(14)
                        .queryFeatures(query)
                        .then(function (result) {
                            slHighlightGraphicsLayer.graphics.removeAll();
                            result.features.forEach((feature) => {
                                const graphic = new Graphic({
                                    geometry: feature.geometry,
                                    symbol: {
                                        type: 'simple-marker',
                                        style: 'circle',
                                        color: [0, 255, 255],
                                        size: '10px', // pixels
                                        outline: {
                                            color: [0, 255, 255],
                                            width: 3, // points
                                        },
                                    },
                                });
                                slHighlightGraphicsLayer.graphics.add(graphic);
                            });
                        });

                    let matchingSL = serviceLocationData.filter((s) => ids.includes(s.SdId));
                    let SLids = matchingSL.map((m) => m.Id);
                    if (!compareArray(selectedServiceLocationIds, SLids)) {
                        setSelectedServiceLocationIds(SLids);
                    }
                } else {
                    let query = projectServicePatternLayer.findSublayerById(15).createQuery();
                    query.where = `sandbox_id=${project} and sd_id in ('${ids.join("','")}')`;
                    query.returnGeometry = true;
                    query.outSpatialReference = { wkid: 4326 };

                    let queryParamValue = { sandbox_id: sessionStorage.getItem('project') };
                    projectServicePatternLayer.customParameters.parameterValues = JSON.stringify(queryParamValue);

                    projectServicePatternLayer
                        .findSublayerById(15)
                        .queryFeatures(query)
                        .then(function (result) {
                            slHighlightGraphicsLayer.graphics.removeAll();
                            result.features.forEach((feature) => {
                                const graphic = new Graphic({
                                    geometry: feature.geometry,
                                    symbol: {
                                        type: 'simple-marker',
                                        style: 'circle',
                                        color: [0, 255, 255],
                                        size: '10px', // pixels
                                        outline: {
                                            color: [0, 255, 255],
                                            width: 3, // points
                                        },
                                    },
                                });
                                slHighlightGraphicsLayer.graphics.add(graphic);
                            });
                        });

                    let matchingSL = serviceLocationData.filter((s) => ids.includes(s.SdId));
                    let SLids = matchingSL.map((m) => m.Id);
                    if (!compareArray(selectedServiceLocationIds, SLids)) {
                        setSelectedServiceLocationIds(SLids);
                    }
                }
            } else {
                if (slHighlightGraphicsLayer) slHighlightGraphicsLayer.graphics.removeAll();
                if (selectionDetailOpen) {
                    setSelectionDetailOpen(false);
                }
                if (selectedServiceLocationIds.length > 0) {
                    setSelectedServiceLocationIds([]);
                }
            }
        }
    }, [selectedServicePattern]);

    //Street maintenance mode toolbar event change
    React.useEffect(() => {
        if (map && closedStreetsLayer && serviceSidesLayer && turnExceptionsLayer && streetsLayer) {
            if (selStreetMaintenanceMode) {
                if (legendWidget && !legendWidget.expanded) {
                    legendWidget.expand();
                }
                switch (selStreetMaintenanceMode) {
                    case config.CLOSED_STREETS:
                        closedStreetsLayer.findSublayerById(4).definitionExpression = 'workspace_id=' + workspace.value;
                        closedStreetsLayer.refresh();
                        streetsLayer.findSublayerById(10).popupEnabled = false;
                        streetsLayer.findSublayerById(10).renderer = INVISIBLE_STREET_RENDERER;
                        setAlleyAssignmentMode(false);
                        streetsLayer.visible = true;
                        closedStreetsLayer.visible = true;
                        serviceSidesLayer.visible = false;
                        turnExceptionsLayer.visible = false;
                        alleyAssignmentsLayer.visible = false;
                        blockFaceLayer.visible = false;
                        setMapInfoMessage('Zoom to a street segment and click to switch its closed off status');
                        break;
                    case config.SERVICE_SIDES:
                        serviceSidesLayer.findSublayerById(5).definitionExpression = "workspace_id='" + workspace.value + "'";
                        serviceSidesLayer.refresh();
                        streetsLayer.findSublayerById(10).popupEnabled = false;
                        streetsLayer.findSublayerById(10).renderer = INVISIBLE_STREET_RENDERER;
                        setAlleyAssignmentMode(false);
                        streetsLayer.visible = true;
                        closedStreetsLayer.visible = false;
                        serviceSidesLayer.visible = true;
                        turnExceptionsLayer.visible = false;
                        alleyAssignmentsLayer.visible = false;
                        blockFaceLayer.visible = false;
                        setMapInfoMessage('Zoom to a street segment and click to edit its service side');
                        if (view && view.scale > config.SERVICE_SIDES_DISPLAY_SCALE) {
                            view.scale = config.SERVICE_SIDES_DISPLAY_SCALE;
                        }
                        break;
                    case config.HERETURNEXCEPTION:
                        turnExceptionsLayer.findSublayerById(7).definitionExpression = 'workspace_id=' + workspace.value;
                        streetsLayer.findSublayerById(10).popupEnabled = false;
                        streetsLayer.findSublayerById(10).renderer = INVISIBLE_STREET_RENDERER;
                        setAlleyAssignmentMode(false);
                        streetsLayer.visible = true;
                        closedStreetsLayer.visible = false;
                        serviceSidesLayer.visible = false;
                        turnExceptionsLayer.visible = true;
                        alleyAssignmentsLayer.visible = false;
                        blockFaceLayer.visible = false;
                        setMapInfoMessage('Click on a street to set turn restriction. Click on turn restriction to delete it');
                        break;
                    case config.ALLEY_ASSIGNMENTS:
                        getAlleyAssignments(workspace.value).then(
                            (res) => {
                                setAlleyAssignmentsData(res.data);
                            },
                            (err) => {
                                setAlleyAssignmentsData([]);
                            },
                        );
                        setBottomPaneVisibility(true);
                        setAlleyAssignmentMode(true);
                        alleyAssignmentsLayer.findSublayerById(16).definitionExpression = 'workspace_id=' + workspace.value;
                        blockFaceLayer.findSublayerById(12).definitionExpression = 'workspace_id=' + workspace.value;
                        streetsLayer.findSublayerById(10).popupEnabled = streetLayerVisibility;
                        streetsLayer.findSublayerById(10).renderer = STREET_RENDERER;
                        streetsLayer.visible = false;
                        closedStreetsLayer.visible = false;
                        serviceSidesLayer.visible = false;
                        turnExceptionsLayer.visible = false;
                        alleyAssignmentsLayer.visible = true;
                        blockFaceLayer.visible = true;
                        setMapInfoMessage('Click on a street to set alley assignment. Click on an alley assignment to delete it');
                }
                document.getElementById('viewDiv').style.cursor = 'crosshair';
            } else {
                if (legendWidget && legendWidget.expanded) {
                    legendWidget.collapse();
                }
                document.getElementById('viewDiv').style.cursor = 'default';
                streetsLayer.findSublayerById(10).popupEnabled = streetLayerVisibility;
                streetsLayer.findSublayerById(10).renderer = STREET_RENDERER;
                streetsLayer.visible = false;
                closedStreetsLayer.visible = false;
                serviceSidesLayer.visible = false;
                turnExceptionsLayer.visible = false;
                alleyAssignmentsLayer.visible = false;
                blockFaceLayer.visible = false;
                setAlleyAssignmentMode(false);
                setMapInfoMessage('');
                if (userTurnExceptionsGraphicsLayer) {
                    userTurnExceptionsGraphicsLayer.graphics.removeAll();
                }
                if (streetHighlightGraphicsLayer) {
                    streetHighlightGraphicsLayer.graphics.removeAll();
                }
                if (alleyAssignmentsGraphicsLayer) {
                    alleyAssignmentsGraphicsLayer.graphics.removeAll();
                }
            }
        }
    }, [selStreetMaintenanceMode]);

    React.useEffect(() => {
        if (sandboxUpdateRouteStatsDialogOpen) {
            let s = { ...routeStatsSettings };
            s['serviceDay'] = serviceDay.value;
            if (serviceDay.value === 0) {
                s['routePlan'] = 0;
            } else {
                s['routePlan'] = getDailyRoutePlan(serviceDay.value);
            }
            setRouteStatsSettings(s);
        }
    }, [sandboxUpdateRouteStatsDialogOpen]);

    React.useEffect(() => {
        if (sandboxSolverDialogOpen) {
            let s = { ...solverSettings };
            if (serviceDay.value === 0) {
                if (sessionStorage.getItem('mdr') === 'true') {
                    s['scenario'] = 'CreateRouteTerritories';
                    s['serviceDay'] = serviceDay.value;
                    s['routePlan'] = routePlanList[0].value;
                    s['balanceOn'] = 'Time';
                    const plan = routePlanList[0];
                    if (plan) {
                        s['method'] = plan.GenerateMethod;
                        s['zones'] = plan.Zones ? plan.Zones : 'Off';
                        if (plan.GenerateMethod === 'Number') {
                            s['number'] = plan.NumRoutes.toString();
                        } else {
                            s['number'] = '';
                        }
                    }
                } else {
                    s['scenario'] = 'CreateServiceDays';
                    s['anchorWithServiceDay'] = true;
                    s['numberOfDays'] = '5';
                    s['balanceOn'] = 'Time';
                    s['truckCapacity'] = defaultTruckCapacity;
                    s['serviceDay'] = serviceDay.value;
                }
            } else {
                s['scenario'] = 'Create';
                s['serviceDay'] = serviceDay.value;
                s['routePlan'] = routePlanList[0].value;
                s['balanceOn'] = 'Time';
                s['anchorWithRoute'] = false;
                const plan = routePlanList[0];
                if (plan) {
                    s['method'] = plan.GenerateMethod;
                    if (plan.RouteCount < 1) {
                        s['zones'] = 'Off';
                    } else {
                        s['zones'] = plan.Zones ? plan.Zones : 'Off';
                    }
                    if (plan.GenerateMethod === 'Number') {
                        s['number'] = plan.NumRoutes.toString();
                    } else {
                        s['number'] = '';
                    }
                }
            }
            setSolverSettings(s);
        }
    }, [sandboxSolverDialogOpen]);

    React.useEffect(() => {
        if (servicePatternView) {
            if (selectedServiceLocationIds.length > 0) {
                if (siteMode === 'DISPATCHER') {
                    let matchingSL = serviceLocationData.filter((s) => selectedServiceLocationIds.includes(s.Id));
                    let SDids = matchingSL.map((m) => m.SdId);

                    if (SDids.length > 0) {
                        let q = servicePatternLayer.findSublayerById(14).createQuery();

                        q.where = `workspace_id=${workspace.value} and sd_id in ('${SDids.join("','")}')`;
                        q.returnGeometry = true;
                        q.outSpatialReference = { wkid: 4326 };
                        q.outFields = [
                            'workspace_id',
                            'sd_id',
                            'gid',
                            'route',
                            'week',
                            'month',
                            'frequency',
                            'service_pattern',
                            'demand',
                            'service_time',
                            'quantity',
                            'service_pattern_group',
                        ];

                        let queryParamValue = { workspace_id: workspace.value };
                        servicePatternLayer.customParameters.parameterValues = JSON.stringify(queryParamValue);

                        servicePatternLayer
                            .findSublayerById(14)
                            .queryFeatures(q)
                            .then(function (results) {
                                //let selectedPatterns = [...selectedServicePattern];
                                let selectedPatterns = [];
                                if (results.features.length > 0) {
                                    results.features.forEach((r) => {
                                        if (
                                            r.attributes['sd_id'] &&
                                            selectedPatterns.filter((s) => s['sd_id'] === r.attributes['sd_id']).length === 0
                                        ) {
                                            selectedPatterns.push({
                                                workspace_id: r.attributes.workspace_id,
                                                sd_id: r.attributes.sd_id,
                                                gid: r.attributes.gid,
                                                route: r.attributes.route,
                                                week: r.attributes.week,
                                                month: r.attributes.month,
                                                frequency: r.attributes.frequency,
                                                service_pattern: r.attributes.service_pattern,
                                                demand: r.attributes.demand,
                                                service_time: r.attributes.service_time,
                                                quantity: r.attributes.quantity,
                                                service_pattern_group: r.attributes.service_pattern_group,
                                            });
                                        }
                                    });
                                }
                                setSelectedServicePattern(selectedPatterns);
                            });
                    }
                } else {
                    let matchingSL = serviceLocationData.filter((s) => selectedServiceLocationIds.includes(s.Id));
                    let SDids = matchingSL.map((m) => m.SdId);
                    if (SDids.length > 0) {
                        let q = projectServicePatternLayer.findSublayerById(15).createQuery();

                        q.where = `sandbox_id=${project} and sd_id in ('${SDids.join("','")}')`;
                        q.returnGeometry = true;
                        q.outSpatialReference = { wkid: 4326 };
                        q.outFields = [
                            'sandbox_id',
                            'sd_id',
                            'gid',
                            'route',
                            'week',
                            'month',
                            'frequency',
                            'service_pattern',
                            'demand',
                            'service_time',
                            'quantity',
                            'service_pattern_group',
                        ];

                        let queryParamValue = { sandbox_id: project };
                        projectServicePatternLayer.customParameters.parameterValues = JSON.stringify(queryParamValue);

                        projectServicePatternLayer
                            .findSublayerById(15)
                            .queryFeatures(q)
                            .then(function (results) {
                                //let selectedPatterns = [...selectedServicePattern];
                                let selectedPatterns = [];
                                if (results.features.length > 0) {
                                    results.features.forEach((r) => {
                                        if (
                                            r.attributes['sd_id'] &&
                                            selectedPatterns.filter((s) => s['sd_id'] === r.attributes['sd_id']).length === 0
                                        ) {
                                            selectedPatterns.push({
                                                sandbox_id: r.attributes.sandbox_id,
                                                sd_id: r.attributes.sd_id,
                                                gid: r.attributes.gid,
                                                route: r.attributes.route,
                                                week: r.attributes.week,
                                                month: r.attributes.month,
                                                frequency: r.attributes.frequency,
                                                service_pattern: r.attributes.service_pattern,
                                                demand: r.attributes.demand,
                                                service_time: r.attributes.service_time,
                                                quantity: r.attributes.quantity,
                                                service_pattern_group: r.attributes.service_pattern_group,
                                            });
                                        }
                                    });
                                }
                                setSelectedServicePattern(selectedPatterns);
                            });
                    }
                }
            }
            setShowServicePatternList(true);
            if (siteMode === 'DISPATCHER') {
                if (serviceLocationLayer) serviceLocationLayer.visible = false;
                if (servicePatternLayer) servicePatternLayer.findSublayerById(14).visible = true;
            } else {
                if (projectServiceLocationLayer) projectServiceLocationLayer.visible = false;
                if (projectServicePatternLayer) projectServicePatternLayer.findSublayerById(15).visible = true;
            }
            refreshServicePatternInMap();
        } else {
            if (frequency && Number(frequency) < 8) setFrequency(null);
            setSelectedServicePattern([]);
            setShowServicePatternList(false);
            if (siteMode === 'DISPATCHER') {
                if (serviceLocationLayer) serviceLocationLayer.visible = true;
                if (servicePatternLayer) servicePatternLayer.findSublayerById(14).visible = false;
                if (projectServicePatternLayer) projectServicePatternLayer.findSublayerById(15).visible = false;
            } else {
                if (projectServiceLocationLayer) projectServiceLocationLayer.visible = true;
                if (servicePatternLayer) servicePatternLayer.findSublayerById(14).visible = false;
                if (projectServicePatternLayer) projectServicePatternLayer.findSublayerById(15).visible = false;
            }
        }
    }, [servicePatternView]);

    React.useEffect(() => {
        if (servicePatternView) {
            refreshServicePatternInMap();
        }
    }, [serviceDay, week, frequency, servicePattern]);

    React.useEffect(() => {
        if (!servicePatternView) {
            refreshServiceLocationsInMap();
        }
    }, [week, frequency]);

    React.useEffect(() => {
        if (showTrips) {
            refreshTrips();
        }
    }, [showTrips]);

    React.useEffect(() => {
        if (selectionDetailOpen && sessionStorage.getItem('mdr') === 'true' && servicePatternView) {
            if (siteMode === 'PLANNER') {
                getServicePatterns(workspace.value, project).then(
                    (res) => {
                        setServicePatternData(res.data);
                    },
                    (err) => {
                        setServicePatternData([]);
                    },
                );
            }
        } else if (!selectionDetailOpen) {
            setSwapRouteSettings({ targetRoute: '0', serviceDay: '' });
            setSwapServicePatternSettings({ lockPattern: false });
        }
    }, [selectionDetailOpen, servicePatternView]);

    React.useEffect(() => {
        setSelTravelPathRoute(null);
        setSelSequenceRoute(null);
    }, [routeViewTabCategorization]);

    React.useEffect(() => {
        if (!sequenceDialogOpen) {
            refreshServiceLocationList();
        }
    }, [sequenceDialogOpen]);

    useEffect(() => {
        if (workspace && !manageSpecialtyDialogOpen) {
            getSpecialties(workspace.value).then((res) => {
                let s = res.data.map((d) => {
                    return {
                        label: d.Specialty,
                        value: d.Id,
                    };
                });
                setSpecialtyList(s);
            });
        }
    }, [manageSpecialtyDialogOpen]);

    React.useEffect(() => {
        if (refreshScenarioServiceLocation) {
            setRefreshScenarioServiceLocation(false);
            setSelectedRaaSServiceLocationIds([]);
            getScenarioServiceLocations(workspace.value, selectedScenarioId).then(
                (res) => {
                    setScenarioServiceLocation(res.data);
                },
                (err) => {
                    setScenarioServiceLocation([]);
                },
            );
        }
    }, [refreshScenarioServiceLocation]);
    ///////////////////////////////////////////////////////////////////

    const handleMapReset = () => {
        if (siteMode === 'DISPATCHER') {
            setProjectFacilityLayerVisibility(false);
            setProjectServiceLocationLayerVisibility(false);
            setSelectedServiceLocationIds([]);
            setSelectedRaaSServiceLocationIds([]);
            setSelectedServicePattern([]);
            setSelTravelPathRoute(null);
            setSelSequenceRoute(null);
            setSelScenarioTravelPathRoute(null);
            setSelScenarioSequenceRoute(null);
            setAlleyAssignmentMode(false);
            setScenarioRouteList([]);
            setSelectedScenarioId(null);
            if (mapMode === 0) {
                if (!servicePatternView) {
                    if (serviceLocationLayer) serviceLocationLayer.visible = true;
                } else {
                    if (serviceLocationLayer) serviceLocationLayer.visible = false;
                }
                if (RAASServiceLocationLayer) RAASServiceLocationLayer.visible = false;
                if (streetsLayer) {
                    streetsLayer.findSublayerById(10).popupEnabled = streetLayerVisibility;
                    streetsLayer.findSublayerById(10).renderer = STREET_RENDERER;
                    streetsLayer.visible = streetLayerVisibility;
                }
                if (sequenceLayer) sequenceLayer.visible = true;
                if (sequenceLabelLayer) sequenceLabelLayer.visible = showSequenceLabel;
                if (scenarioSequenceLayer) scenarioSequenceLayer.visible = false;
                if (scenarioSequenceLabelLayer) scenarioSequenceLabelLayer.visible = false;
                if (travelPathLayer) travelPathLayer.visible = true;
                if (travelPathLabelLayer) travelPathLabelLayer.visible = showTravelPathLabel;
                if (scenarioTravelPathLayer) scenarioTravelPathLayer.visible = false;
                if (scenarioTravelPathLabelLayer) scenarioTravelPathLabelLayer.visible = false;
                if (facilityLayer) {
                    facilityLayer.findSublayerById(0).definitionExpression = 'workspace_id=' + workspace.value;
                    facilityLayer.visible = facilityLayerVisibility;
                }
                if (RAASFacilityLayer) RAASFacilityLayer.visible = false;
                if (zoneFacilityLayer) zoneFacilityLayer.findSublayerById(parseInt(config.FACILITY)).visible = false;
                if (zoneServiceLocationLayer) {
                    zoneServiceLocationLayer.findSublayerById(parseInt(config.SERVICE_LOCATION)).visible = false;
                    zoneServiceLocationLayer.findSublayerById(parseInt(config.SANDBOXSERVICELOCATION)).visible = false;
                }
                setZoneMode('VIEW');
                neighborhoodLayer?.removeAll();
                zoneGraphicLayer?.removeAll();
                setShowServiceDayList(true);
                setShowRoutePlanList(false);
                setShowWeekList(true);
                setShowFrequencyList(true);
                refreshRouteSummary();
                refreshServiceLocationList();
                if (!bottomPaneVisibility || !bottomPaneGrid || bottomPaneGrid.includes('Scenario')) setBottomPaneGrid('RouteSummary');
                updateUserActivity({ ActionId: 241, WorkspaceId: workspace.value, Mode: siteMode });
            } else if (mapMode === 1) {
                setSelDispatchTool('SplitRoute');
                if (serviceLocationLayer) serviceLocationLayer.visible = false;
                if (streetsLayer) {
                    streetsLayer.findSublayerById(10).popupEnabled = streetLayerVisibility;
                    streetsLayer.findSublayerById(10).renderer = STREET_RENDERER;
                    streetsLayer.visible = streetLayerVisibility;
                }
                if (sequenceLayer) sequenceLayer.visible = false;
                if (sequenceLabelLayer) sequenceLabelLayer.visible = false;
                if (scenarioSequenceLayer) scenarioSequenceLayer.visible = true;
                if (scenarioSequenceLabelLayer) scenarioSequenceLabelLayer.visible = showScenarioSequenceLabel;
                if (travelPathLayer) travelPathLayer.visible = false;
                if (travelPathLabelLayer) travelPathLabelLayer.visible = false;
                if (scenarioTravelPathLayer) scenarioTravelPathLayer.visible = true;
                if (scenarioTravelPathLabelLayer) scenarioTravelPathLabelLayer.visible = showScenarioTravelPathLabel;
                setShowServiceDayList(false);
                setShowRoutePlanList(false);
                setShowWeekList(false);
                setShowFrequencyList(false);
                setShowServicePatternList(false);
                setBottomPaneVisibility(false);
                setBottomPaneGrid(null);
                if (blockFaceLayerVisbility) setBlockFaceLayerVisbility(false);
                if (RAASFacilityLayer) {
                    RAASFacilityLayer.findSublayerById(0).definitionExpression = 'processid=' + selectedScenarioId;
                }
                if (facilityLayer) {
                    facilityLayer.visible = false;
                }
                if (zoneFacilityLayer) zoneFacilityLayer.findSublayerById(parseInt(config.FACILITY)).visible = false;
                if (zoneServiceLocationLayer) {
                    zoneServiceLocationLayer.findSublayerById(parseInt(config.SERVICE_LOCATION)).visible = false;
                    zoneServiceLocationLayer.findSublayerById(parseInt(config.SANDBOXSERVICELOCATION)).visible = false;
                }
                setZoneMode('VIEW');
                setSelectedNeighborhoodClusters([]);
                neighborhoodLayer?.removeAll();
                zoneGraphicLayer?.removeAll();
                updateUserActivity({ ActionId: 251, WorkspaceId: workspace.value, Mode: siteMode });
            } else if (mapMode === 2) {
                updateUserActivity({ ActionId: 261, WorkspaceId: workspace.value, Mode: siteMode });
                setShowServiceDayList(true);
                setShowRoutePlanList(true);
                setShowWeekList(false);
                setShowFrequencyList(false);
                setShowServicePatternList(false);
                if (blockFaceLayerVisbility) setBlockFaceLayerVisbility(false);
                if (serviceLocationLayer) serviceLocationLayer.visible = false;
                if (sequenceLayer) sequenceLayer.visible = false;
                if (facilityLayer) facilityLayer.visible = false;
                if (RAASFacilityLayer) RAASFacilityLayer.visible = false;
                if (RAASServiceLocationLayer) RAASServiceLocationLayer.visible = false;
                if (streetsLayer) streetsLayer.visible = false;
                if (sequenceLabelLayer) sequenceLabelLayer.visible = false;
                if (scenarioSequenceLayer) scenarioSequenceLayer.visible = false;
                if (scenarioSequenceLabelLayer) scenarioSequenceLabelLayer.visible = false;
                if (travelPathLayer) travelPathLayer.visible = false;
                if (travelPathLabelLayer) travelPathLabelLayer.visible = false;
                if (scenarioTravelPathLayer) scenarioTravelPathLayer.visible = false;
                if (scenarioTravelPathLabelLayer) scenarioTravelPathLabelLayer.visible = false;
                setBottomPaneVisibility(true);
                setBottomPaneGrid(null);
                if (workspace) {
                    //setRoutePlanId(null);
                    let queryStr = 'workspace_id=' + workspace.value;
                    if (serviceDay.value !== 0) {
                        queryStr += ' and service_day=' + serviceDay.value;
                    }
                    if (zoneServiceLocationLayer) {
                        zoneServiceLocationLayer.findSublayerById(parseInt(config.SERVICE_LOCATION)).visible = zoneServiceLocationLayerVisibility;
                        zoneServiceLocationLayer.findSublayerById(parseInt(config.SANDBOXSERVICELOCATION)).visible = false;
                        zoneServiceLocationLayer.findSublayerById(parseInt(config.SERVICE_LOCATION)).definitionExpression = queryStr;
                        zoneServiceLocationLayer.refresh();
                    }
                    getNeighborhoodClusters(workspace.value, siteMode, project).then(
                        (res) => {
                            setNeighborhoodData(res.data);
                        },
                        () => {
                            setNeighborhoodData([]);
                        },
                    );
                    if (!routePlanId && serviceDay.value !== 0) {
                        getRouteList(siteMode, workspace.value, serviceDay.value, project).then(
                            (res) => {
                                if (res.status === 200 && res.data.length > 0) {
                                    res.data = res.data.sort((a, b) => a.Id.localeCompare(b.Id, 'en', { numeric: true }));
                                    let rtlist = [];
                                    rtlist = getRouteRenderList(res.data, 'zoneRouteList');
                                    setZoneRouteList(rtlist);
                                    if (rtlist.length > 0) {
                                        const allRoutes = rtlist.map((r) => r.Id);
                                        setSelectedZoneRoutes(allRoutes);
                                    }
                                } else {
                                    setZoneRouteList([]);
                                    setSelectedZoneRoutes([]);
                                }
                            },
                            () => {
                                setZoneRouteList([]);
                                setSelectedZoneRoutes([]);
                            },
                        );
                    } else if (!routePlanId && serviceDay.value === 0) {
                        getRouteList(siteMode, workspace.value, serviceDay.value, project).then(
                            (res) => {
                                let rtlist = [];
                                if (sessionStorage.getItem('mdr') === 'true') {
                                    if (Array.isArray(res.data)) {
                                        res.data = res.data.sort((a, b) => a.Id.localeCompare(b.Id, 'en', { numeric: true }));
                                        rtlist = getRouteRenderList(res.data, 'zoneRouteList');
                                    } else {
                                        rtlist = getRouteRenderList(res.data, 'projectZoneRouteList');
                                    }
                                    setZoneRouteList(rtlist);
                                    if (rtlist.length > 0) {
                                        const allRoutes = rtlist.map((r) => r.Id);
                                        setSelectedZoneRoutes(allRoutes);
                                    }
                                } else {
                                    rtlist = getRouteRenderListForAllDays();
                                    setZoneRouteList(rtlist);
                                    if (rtlist.length > 0) {
                                        const allRoutes = rtlist.map((r) => r.Id);
                                        setSelectedZoneRoutes(allRoutes);
                                    }
                                }
                            },
                            () => {
                                let rtlist = [];
                                rtlist = getRouteRenderListForAllDays();
                                setZoneRouteList(rtlist);
                                if (rtlist.length > 0) {
                                    const allRoutes = rtlist.map((r) => r.Id);
                                    setSelectedZoneRoutes(allRoutes);
                                }
                            },
                        );
                    } else {
                        refreshAAFromRoutePlan();
                    }
                    refreshZoneFacilityLayer();
                    refreshZoneServiceLocationLayer();
                }
            }
        } else if (siteMode === 'PLANNER') {
            if (serviceLocationLayer) serviceLocationLayer.visible = false;
            if (sequenceLayer) sequenceLayer.visible = false;
            if (facilityLayer) {
                facilityLayer.findSublayerById(0).definitionExpression = 'workspace_id=' + workspace.value;
                facilityLayer.visible = false;
            }
            if (RAASFacilityLayer) RAASFacilityLayer.visible = false;
            if (RAASServiceLocationLayer) RAASServiceLocationLayer.visible = false;
            if (streetsLayer) streetsLayer.visible = false;
            if (sequenceLabelLayer) sequenceLabelLayer.visible = false;
            if (scenarioSequenceLayer) scenarioSequenceLayer.visible = false;
            if (scenarioSequenceLabelLayer) scenarioSequenceLabelLayer.visible = false;
            if (travelPathLayer) travelPathLayer.visible = false;
            if (travelPathLabelLayer) travelPathLabelLayer.visible = false;
            if (scenarioTravelPathLayer) scenarioTravelPathLayer.visible = false;
            if (scenarioTravelPathLabelLayer) scenarioTravelPathLabelLayer.visible = false;
            if (zoneFacilityLayer) zoneFacilityLayer.findSublayerById(parseInt(config.FACILITY)).visible = false;
            if (zoneServiceLocationLayer) {
                zoneServiceLocationLayer.findSublayerById(parseInt(config.SERVICE_LOCATION)).visible = false;
                zoneServiceLocationLayer.findSublayerById(parseInt(config.SANDBOXSERVICELOCATION)).visible = false;
            }
            setSelectedNeighborhoodClusters([]);
            setSelectedServiceLocationIds([]);
            setSelectedRaaSServiceLocationIds([]);
            setSelectedServicePattern([]);
            neighborhoodLayer?.removeAll();
            zoneGraphicLayer?.removeAll();
            setAlleyAssignmentMode(false);
            setScenarioRouteList([]);
            setSelectedScenarioId(null);
            if (mapMode === 0) {
                if (sequenceLayer) sequenceLayer.visible = true;
                if (sequenceLabelLayer) sequenceLabelLayer.visible = showSequenceLabel;
                if (travelPathLayer) travelPathLayer.visible = true;
                if (travelPathLabelLayer) travelPathLabelLayer.visible = showTravelPathLabel;
                setProjectFacilityLayerVisibility(projectFacilityLayerVisibility);
                if (!servicePatternView) {
                    setProjectServiceLocationLayerVisibility(true);
                    if (projectServiceLocationLayer) projectServiceLocationLayer.visible = true;
                } else {
                    setProjectServiceLocationLayerVisibility(false);
                    if (projectServiceLocationLayer) projectServiceLocationLayer.visible = false;
                }
                refreshProjectFacilityLayer();
                refreshServiceLocationsInMap();
                refreshServiceLocationList();
                refreshRouteSummary();
                refreshSwapRouteList();
                if (!bottomPaneVisibility || !bottomPaneGrid || bottomPaneGrid.includes('Scenario')) setBottomPaneGrid('RouteSummary');
                setShowRoutePlanList(false);
                setShowWeekList(true);
                setShowFrequencyList(true);
                updateUserActivity({ ActionId: 241, WorkspaceId: workspace.value, Mode: siteMode });
            } else if (mapMode === 2) {
                setShowServiceDayList(true);
                setShowRoutePlanList(true);
                setShowWeekList(false);
                setShowFrequencyList(false);
                setShowServicePatternList(false);
                setBottomPaneVisibility(true);
                setBottomPaneGrid(null);
                setProjectFacilityLayerVisibility(false);
                setProjectServiceLocationLayerVisibility(false);
                if (blockFaceLayerVisbility) setBlockFaceLayerVisbility(false);
                updateUserActivity({ ActionId: 261, WorkspaceId: workspace.value, Mode: siteMode });
                if (serviceLocationLayer) serviceLocationLayer.visible = false;
                if (sequenceLayer) sequenceLayer.visible = false;
                if (facilityLayer) facilityLayer.visible = false;
                if (RAASFacilityLayer) RAASFacilityLayer.visible = false;
                if (RAASServiceLocationLayer) RAASServiceLocationLayer.visible = false;
                if (streetsLayer) streetsLayer.visible = false;
                if (sequenceLabelLayer) sequenceLabelLayer.visible = false;
                if (scenarioSequenceLayer) scenarioSequenceLayer.visible = false;
                if (scenarioSequenceLabelLayer) scenarioSequenceLabelLayer.visible = false;
                if (travelPathLayer) travelPathLayer.visible = false;
                if (travelPathLabelLayer) travelPathLabelLayer.visible = false;
                if (scenarioTravelPathLayer) scenarioTravelPathLayer.visible = false;
                if (scenarioTravelPathLabelLayer) scenarioTravelPathLabelLayer.visible = false;
                if (projectFacilityLayer) projectFacilityLayer.visible = false;
                if (projectServiceLocationLayer) projectServiceLocationLayer.visible = false;
                if (workspace) {
                    //setRoutePlanId(null);
                    let queryStr = 'sandbox_id=' + project;
                    if (serviceDay && serviceDay.value !== 0) {
                        queryStr += ' and service_day=' + serviceDay.value;
                    }
                    if (zoneServiceLocationLayer) {
                        zoneServiceLocationLayer.findSublayerById(parseInt(config.SERVICE_LOCATION)).visible = false;
                        zoneServiceLocationLayer.findSublayerById(parseInt(config.SANDBOXSERVICELOCATION)).visible =
                            zoneServiceLocationLayerVisibility;
                        zoneServiceLocationLayer.findSublayerById(parseInt(config.SANDBOXSERVICELOCATION)).definitionExpression = queryStr;
                    }
                    getNeighborhoodClusters(workspace.value, siteMode, project).then(
                        (res) => {
                            setNeighborhoodData(res.data);
                        },
                        () => {
                            setNeighborhoodData([]);
                        },
                    );

                    if (!routePlanId && serviceDay.value !== 0) {
                        getRouteList(siteMode, workspace.value, serviceDay.value, project).then(
                            (res) => {
                                if (res.status === 200 && res.data) {
                                    let rtlist = [];
                                    if (Array.isArray(res.data)) {
                                        res.data = res.data.sort((a, b) => a.Id.localeCompare(b.Id, 'en', { numeric: true }));
                                        rtlist = getRouteRenderList(res.data, 'zoneRouteList');
                                    } else {
                                        rtlist = getRouteRenderList(res.data, 'projectZoneRouteList');
                                    }
                                    setZoneRouteList(rtlist);
                                    if (rtlist.length > 0) {
                                        const allRoutes = rtlist.map((r) => r.Id);
                                        setSelectedZoneRoutes(allRoutes);
                                    }
                                } else {
                                    setZoneRouteList([]);
                                    setSelectedZoneRoutes([]);
                                }
                            },
                            () => {
                                setZoneRouteList([]);
                                setSelectedZoneRoutes([]);
                            },
                        );
                    } else if (!routePlanId && serviceDay.value === 0) {
                        getRouteList(siteMode, workspace.value, serviceDay.value, project).then(
                            (res) => {
                                let rtlist = [];
                                if (sessionStorage.getItem('mdr') === 'true') {
                                    if (Array.isArray(res.data)) {
                                        res.data = res.data.sort((a, b) => a.Id.localeCompare(b.Id, 'en', { numeric: true }));
                                        rtlist = getRouteRenderList(res.data, 'zoneRouteList');
                                    } else {
                                        rtlist = getRouteRenderList(res.data, 'projectZoneRouteList');
                                    }
                                    setZoneRouteList(rtlist);
                                    if (rtlist.length > 0) {
                                        const allRoutes = rtlist.map((r) => r.Id);
                                        setSelectedZoneRoutes(allRoutes);
                                    }
                                } else {
                                    rtlist = getRouteRenderListForAllDays();
                                    setZoneRouteList(rtlist);
                                    if (rtlist.length > 0) {
                                        const allRoutes = rtlist.map((r) => r.Id);
                                        setSelectedZoneRoutes(allRoutes);
                                    }
                                }
                            },
                            () => {
                                let rtlist = [];
                                rtlist = getRouteRenderListForAllDays();
                                setZoneRouteList(rtlist);
                                if (rtlist.length > 0) {
                                    const allRoutes = rtlist.map((r) => r.Id);
                                    setSelectedZoneRoutes(allRoutes);
                                }
                            },
                        );
                    } else {
                        refreshAAFromRoutePlan();
                    }
                    refreshZoneFacilityLayer();
                    refreshZoneServiceLocationLayer();
                }
            }
        }
    };

    const initMainidget = (view) => {
        const routeListDiv = document.getElementById('routeListDiv');
        const routeListExpand = new Expand({
            collapsedIconClass: 'esri-icon-collapse',
            expandIconClass: 'esri-icon-collection',
            expandTooltip: 'Route List',
            view: view,
            content: routeListDiv,
            expanded: true,
            group: 'left',
        });
        if (checkFunction(209) || checkFunction(214)) {
            view.ui.add(routeListExpand, 'top-left');
        }
    };

    const initSketchTool = (view) => {
        const xySketch = new SketchViewModel({
            view: view,
            layer: pickXYGraphicsLayer,
            pointSymbol: {
                type: 'text',
                color: '#7A003C',
                text: '\ue61d',
                font: {
                    size: 20,
                    family: 'CalciteWebCoreIcons',
                },
            },
            defaultCreateOptions: {
                mode: 'hybrid',
            },
        });

        xySketch.on('create', (evt) => {
            if (mapModeRef.current !== 1) {
                let queryString = 'workspace_id=' + workspaceId.current.value;
                if (sessionStorage.getItem('siteMode') === 'PLANNER') {
                    queryString = 'sandbox_id=' + sessionStorage.getItem('project');
                }

                if (sessionStorage.getItem('siteMode') === 'PLANNER' && projectServiceLocationQuery) {
                    queryString = projectServiceLocationQuery;
                }
                if (sessionStorage.getItem('siteMode') === 'DISPATCHER' && serviceLocationQuery) {
                    queryString = serviceLocationQuery;
                }

                if (evt.state === 'complete' && evt.tool === 'point') {
                    if (sessionStorage.getItem('siteMode') === 'PLANNER' && mapSelectionModeRef.current === 'TARGET_ROUTE') {
                        pickXYGraphicsLayer.graphics.removeAll();
                        setMapInfoMessage('');
                        setMapSelectionMode(null);
                        if (projectServiceLocationLayer) {
                            projectServiceLocationLayer
                                .queryFeatures({
                                    geometry: evt.graphic.geometry,
                                    distance: 10,
                                    units: 'feet',
                                    spatialRelationship: 'intersects',
                                    returnGeometry: true,
                                    where: queryString,
                                    outFields: ['*'],
                                })
                                .then((featureSet) => {
                                    if (featureSet && featureSet.features.length > 0) {
                                        let s = { ...swapRouteSettings };
                                        s.targetRoute = featureSet.features[0].attributes['route'];
                                        setSwapRouteSettings(s);
                                    }
                                });
                        }
                    } else {
                        xySketch.update(evt.graphic);
                        setPickedXY({ X: evt.graphic.geometry.longitude, Y: evt.graphic.geometry.latitude });
                        if (streetsLayer) {
                            streetsLayer.findSublayerById(10).popupEnabled = streetLayerVisibility;
                            streetsLayer.findSublayerById(10).renderer = STREET_RENDERER;
                            streetsLayer.visible = true;
                        }
                        switch (mapSelectionModeRef.current) {
                            case 'FC_ADD':
                                setMapInfoMessage('Please click on a street segment to associate to the Facility');
                                setSelStreetMaintenanceMode(config.FACILITY);
                                break;
                            case 'FC_PIN':
                                setMapInfoMessage('Please click on a street segment to associate to the Facility');
                                setSelStreetMaintenanceMode(config.FACILITY + '_PIN');
                                break;
                            case 'SL_PIN':
                                setMapInfoMessage('Please click on a street segment to associate to the Stop');
                                setSelStreetMaintenanceMode(config.SERVICE_LOCATION + '_PIN');
                                break;
                        }
                    }
                } else if (evt.state === 'complete' && evt.tool === 'polygon') {
                    switch (mapSelectionModeRef.current) {
                        case 'SL_SEL_STREET':
                            if (sessionStorage.getItem('servicePatternView') === 'true') {
                                if (sessionStorage.getItem('siteMode') === 'DISPATCHER') {
                                    if (servicePatternLayer) {
                                        //queryFeatures(evt.graphic.geometry);
                                        const query = servicePatternLayer.findSublayerById(14).createQuery();
                                        query.outFields = ['*'];
                                        query.geometry = evt.graphic.geometry;
                                        let queryStr = 'workspace_id=' + workspaceId.current.value;
                                        if (servicePatternQuery) {
                                            query.where = servicePatternQuery;
                                        } else {
                                            query.where = queryStr;
                                        }

                                        let queryParamValue = { workspace_id: workspaceId.current.value };
                                        servicePatternLayer.customParameters.parameterValues = JSON.stringify(queryParamValue);

                                        servicePatternLayer
                                            .findSublayerById(14)
                                            .queryFeatures(query)
                                            .then((results) => {
                                                let selectedPatterns = [...selectedServicePatternRef.current];
                                                if (results.features.length > 0) {
                                                    results.features.forEach((r) => {
                                                        if (
                                                            r.attributes['sd_id'] &&
                                                            selectedPatterns.filter((s) => s['sd_id'] === r.attributes['sd_id']).length === 0
                                                        ) {
                                                            selectedPatterns.push({
                                                                workspace_id: r.attributes.workspace_id,
                                                                sd_id: r.attributes.sd_id,
                                                                gid: r.attributes.gid,
                                                                route: r.attributes.route,
                                                                week: r.attributes.week,
                                                                month: r.attributes.month,
                                                                frequency: r.attributes.frequency,
                                                                service_pattern: r.attributes.service_pattern,
                                                                demand: r.attributes.demand,
                                                                service_time: r.attributes.service_time,
                                                                quantity: r.attributes.quantity,
                                                                service_pattern_group: r.attributes.service_pattern_group,
                                                            });
                                                        }
                                                    });
                                                }
                                                setSelectedServicePattern(selectedPatterns);

                                                pickXYGraphicsLayer.graphics.removeAll();
                                                setMapInfoMessage('');
                                                setMapSelectionMode(null);
                                            })
                                            .catch((error) => {
                                                pickXYGraphicsLayer.graphics.removeAll();
                                                setMapInfoMessage('');
                                                setMapSelectionMode(null);
                                            });
                                    }
                                } else {
                                    if (projectServicePatternLayer) {
                                        const query = projectServicePatternLayer.findSublayerById(15).createQuery();
                                        query.outFields = ['*'];
                                        query.geometry = evt.graphic.geometry;
                                        let queryStr = 'sandbox_id=' + sessionStorage.getItem('project');
                                        if (projectServicePatternQuery) {
                                            query.where = projectServicePatternQuery;
                                        } else {
                                            query.where = queryStr;
                                        }

                                        let queryParamValue = { sandbox_id: sessionStorage.getItem('project') };
                                        projectServicePatternLayer.customParameters.parameterValues = JSON.stringify(queryParamValue);

                                        projectServicePatternLayer
                                            .findSublayerById(15)
                                            .queryFeatures(query)
                                            .then((results) => {
                                                let selectedPatterns = [...selectedServicePatternRef.current];
                                                if (results.features.length > 0) {
                                                    results.features.forEach((r) => {
                                                        if (
                                                            r.attributes['sd_id'] &&
                                                            selectedPatterns.filter((s) => s['sd_id'] === r.attributes['sd_id']).length === 0
                                                        ) {
                                                            selectedPatterns.push({
                                                                sandbox_id: r.attributes.sandbox_id,
                                                                sd_id: r.attributes.sd_id,
                                                                gid: r.attributes.gid,
                                                                route: r.attributes.route,
                                                                week: r.attributes.week,
                                                                month: r.attributes.month,
                                                                frequency: r.attributes.frequency,
                                                                service_pattern: r.attributes.service_pattern,
                                                                demand: r.attributes.demand,
                                                                service_time: r.attributes.service_time,
                                                                quantity: r.attributes.quantity,
                                                                service_pattern_group: r.attributes.service_pattern_group,
                                                            });
                                                        }
                                                    });
                                                }
                                                setSelectedServicePattern(selectedPatterns);

                                                pickXYGraphicsLayer.graphics.removeAll();
                                                setMapInfoMessage('');
                                                setMapSelectionMode(null);
                                            })
                                            .catch((error) => {
                                                pickXYGraphicsLayer.graphics.removeAll();
                                                setMapInfoMessage('');
                                                setMapSelectionMode(null);
                                            });
                                    }
                                }
                            } else {
                                if (sessionStorage.getItem('siteMode') === 'DISPATCHER') {
                                    if (serviceLocationLayer) {
                                        serviceLocationLayer
                                            .queryFeatures({
                                                geometry: evt.graphic.geometry,
                                                spatialRelationship: 'contains',
                                                returnGeometry: false,
                                                where: queryString,
                                                outFields: ['*'],
                                            })
                                            .then((featureSet) => {
                                                if (featureSet && featureSet.features.length > 0) {
                                                    let selSvcLocationIds = [...selectedServiceLocationIdsRef.current];
                                                    featureSet.features.forEach((feature) => {
                                                        if (!selSvcLocationIds.includes(feature.attributes['gid'])) {
                                                            selSvcLocationIds.push(feature.attributes['gid']);
                                                        }
                                                    });
                                                    setSelectedServiceLocationIds(selSvcLocationIds);
                                                }
                                            });
                                    }
                                } else if (sessionStorage.getItem('siteMode') === 'PLANNER') {
                                    if (projectServiceLocationLayer) {
                                        projectServiceLocationLayer
                                            .queryFeatures({
                                                geometry: evt.graphic.geometry,
                                                spatialRelationship: 'contains',
                                                returnGeometry: false,
                                                where: queryString,
                                                outFields: ['*'],
                                            })
                                            .then((featureSet) => {
                                                if (featureSet && featureSet.features.length > 0) {
                                                    let selSvcLocationIds = [...selectedServiceLocationIdsRef.current];
                                                    featureSet.features.forEach((feature) => {
                                                        if (!selSvcLocationIds.includes(feature.attributes['gid'])) {
                                                            selSvcLocationIds.push(feature.attributes['gid']);
                                                        }
                                                    });
                                                    setSelectedServiceLocationIds(selSvcLocationIds);
                                                }
                                            });
                                    }
                                }
                            }
                            pickXYGraphicsLayer.graphics.removeAll();
                            setMapInfoMessage('');
                            setMapSelectionMode(null);
                            break;
                        default:
                            break;
                    }
                } else if (evt.state === 'complete' && evt.tool === 'rectangle' && mapSelectionModeRef.current === 'SL_SEL_STREET') {
                    if (sessionStorage.getItem('servicePatternView') === 'true') {
                        if (sessionStorage.getItem('siteMode') === 'DISPATCHER') {
                            if (servicePatternLayer) {
                                //queryFeatures(evt.graphic.geometry);
                                const query = servicePatternLayer.findSublayerById(14).createQuery();
                                query.outFields = ['*'];
                                query.geometry = evt.graphic.geometry;
                                let queryStr = 'workspace_id=' + workspaceId.current.value;
                                if (servicePatternQuery) {
                                    query.where = servicePatternQuery;
                                } else {
                                    query.where = queryStr;
                                }

                                let queryParamValue = { workspace_id: workspaceId.current.value };
                                servicePatternLayer.customParameters.parameterValues = JSON.stringify(queryParamValue);

                                servicePatternLayer
                                    .findSublayerById(14)
                                    .queryFeatures(query)
                                    .then((results) => {
                                        let selectedPatterns = [...selectedServicePatternRef.current];
                                        if (results.features.length > 0) {
                                            results.features.forEach((r) => {
                                                if (
                                                    r.attributes['sd_id'] &&
                                                    selectedPatterns.filter((s) => s['sd_id'] === r.attributes['sd_id']).length === 0
                                                ) {
                                                    selectedPatterns.push({
                                                        workspace_id: r.attributes.workspace_id,
                                                        sd_id: r.attributes.sd_id,
                                                        gid: r.attributes.gid,
                                                        route: r.attributes.route,
                                                        week: r.attributes.week,
                                                        month: r.attributes.month,
                                                        frequency: r.attributes.frequency,
                                                        service_pattern: r.attributes.service_pattern,
                                                        demand: r.attributes.demand,
                                                        service_time: r.attributes.service_time,
                                                        quantity: r.attributes.quantity,
                                                        service_pattern_group: r.attributes.service_pattern_group,
                                                    });
                                                }
                                            });
                                        }
                                        setSelectedServicePattern(selectedPatterns);

                                        pickXYGraphicsLayer.graphics.removeAll();
                                        setMapInfoMessage('');
                                        setMapSelectionMode(null);
                                    })
                                    .catch((error) => {
                                        pickXYGraphicsLayer.graphics.removeAll();
                                        setMapInfoMessage('');
                                        setMapSelectionMode(null);
                                    });
                            }
                        } else {
                            if (projectServicePatternLayer) {
                                const query = projectServicePatternLayer.findSublayerById(15).createQuery();
                                query.outFields = ['*'];
                                query.geometry = evt.graphic.geometry;
                                let queryStr = 'sandbox_id=' + sessionStorage.getItem('project');
                                if (projectServicePatternQuery) {
                                    query.where = projectServicePatternQuery;
                                } else {
                                    query.where = queryStr;
                                }

                                let queryParamValue = { sandbox_id: sessionStorage.getItem('project') };
                                projectServicePatternLayer.customParameters.parameterValues = JSON.stringify(queryParamValue);

                                projectServicePatternLayer
                                    .findSublayerById(15)
                                    .queryFeatures(query)
                                    .then((results) => {
                                        let selectedPatterns = [...selectedServicePatternRef.current];
                                        if (results.features.length > 0) {
                                            results.features.forEach((r) => {
                                                if (
                                                    r.attributes['sd_id'] &&
                                                    selectedPatterns.filter((s) => s['sd_id'] === r.attributes['sd_id']).length === 0
                                                ) {
                                                    selectedPatterns.push({
                                                        sandbox_id: r.attributes.sandbox_id,
                                                        sd_id: r.attributes.sd_id,
                                                        gid: r.attributes.gid,
                                                        route: r.attributes.route,
                                                        week: r.attributes.week,
                                                        month: r.attributes.month,
                                                        frequency: r.attributes.frequency,
                                                        service_pattern: r.attributes.service_pattern,
                                                        demand: r.attributes.demand,
                                                        service_time: r.attributes.service_time,
                                                        quantity: r.attributes.quantity,
                                                        service_pattern_group: r.attributes.service_pattern_group,
                                                    });
                                                }
                                            });
                                        }
                                        setSelectedServicePattern(selectedPatterns);

                                        pickXYGraphicsLayer.graphics.removeAll();
                                        setMapInfoMessage('');
                                        setMapSelectionMode(null);
                                    })
                                    .catch((error) => {
                                        pickXYGraphicsLayer.graphics.removeAll();
                                        setMapInfoMessage('');
                                        setMapSelectionMode(null);
                                    });
                            }
                        }
                    } else {
                        if (sessionStorage.getItem('siteMode') === 'DISPATCHER') {
                            if (serviceLocationLayer) {
                                serviceLocationLayer
                                    .queryFeatures({
                                        geometry: evt.graphic.geometry,
                                        spatialRelationship: 'contains',
                                        returnGeometry: false,
                                        where: queryString,
                                        outFields: ['*'],
                                    })
                                    .then((featureSet) => {
                                        if (featureSet && featureSet.features.length > 0) {
                                            let selSvcLocationIds = [...selectedServiceLocationIdsRef.current];
                                            featureSet.features.forEach((feature) => {
                                                if (!selSvcLocationIds.includes(feature.attributes['gid'])) {
                                                    selSvcLocationIds.push(feature.attributes['gid']);
                                                }
                                            });
                                            setSelectedServiceLocationIds(selSvcLocationIds);
                                        }
                                    });
                            }
                        } else if (sessionStorage.getItem('siteMode') === 'PLANNER') {
                            if (projectServiceLocationLayer) {
                                projectServiceLocationLayer
                                    .queryFeatures({
                                        geometry: evt.graphic.geometry,
                                        spatialRelationship: 'contains',
                                        returnGeometry: false,
                                        where: queryString,
                                        outFields: ['*'],
                                    })
                                    .then((featureSet) => {
                                        if (featureSet && featureSet.features.length > 0) {
                                            let selSvcLocationIds = [...selectedServiceLocationIdsRef.current];
                                            featureSet.features.forEach((feature) => {
                                                if (!selSvcLocationIds.includes(feature.attributes['gid'])) {
                                                    selSvcLocationIds.push(feature.attributes['gid']);
                                                }
                                            });
                                            setSelectedServiceLocationIds(selSvcLocationIds);
                                        }
                                    });
                            }
                        }
                    }
                    pickXYGraphicsLayer.graphics.removeAll();
                    setMapInfoMessage('');
                    setMapSelectionMode(null);
                } else if (evt.state === 'complete' && evt.tool === 'rectangle') {
                    pickXYGraphicsLayer.graphics.removeAll();
                    setMapInfoMessage('');
                    view.extent = evt.graphic.geometry.extent;
                }
            } else {
                let queryString = RaaSServiceLocationQuery;
                if (evt.state === 'complete' && evt.tool === 'rectangle' && mapSelectionModeRef.current === 'SL_SEL_STREET') {
                    if (RAASServiceLocationLayer) {
                        pickXYGraphicsLayer.graphics.removeAll();
                        setMapInfoMessage('');
                        setMapSelectionMode(null);
                        RAASServiceLocationLayer.queryFeatures({
                            geometry: evt.graphic.geometry,
                            spatialRelationship: 'contains',
                            returnGeometry: false,
                            where: queryString,
                            outFields: ['*'],
                        }).then((featureSet) => {
                            if (featureSet && featureSet.features.length > 0) {
                                let selSvcLocationIds = [...selectedRaaSServiceLocationIdsRef.current];
                                featureSet.features.forEach((feature) => {
                                    if (!selSvcLocationIds.includes(feature.attributes['record_id'])) {
                                        selSvcLocationIds.push(feature.attributes['record_id']);
                                    }
                                });
                                setSelectedRaaSServiceLocationIds(selSvcLocationIds);
                            }
                        });
                    }
                } else if (evt.state === 'complete' && evt.tool === 'rectangle') {
                    pickXYGraphicsLayer.graphics.removeAll();
                    setMapInfoMessage('');
                    setMapSelectionMode(null);
                    RAASServiceLocationLayer.queryFeatures({
                        geometry: evt.graphic.geometry,
                        spatialRelationship: 'contains',
                        returnGeometry: false,
                        where: queryString,
                        outFields: ['*'],
                    }).then((featureSet) => {
                        if (featureSet && featureSet.features.length > 0) {
                            let selSvcLocationIds = [...selectedRaaSServiceLocationIdsRef.current];
                            featureSet.features.forEach((feature) => {
                                if (!selSvcLocationIds.includes(feature.attributes['record_id'])) {
                                    selSvcLocationIds.push(feature.attributes['record_id']);
                                }
                            });
                            setSelectedRaaSServiceLocationIds(selSvcLocationIds);
                        }
                    });
                } else if (evt.state === 'complete' && evt.tool === 'polygon') {
                    switch (mapSelectionModeRef.current) {
                        case 'SL_SEL_STREET':
                            if (RAASServiceLocationLayer) {
                                pickXYGraphicsLayer.graphics.removeAll();
                                setMapInfoMessage('');
                                setMapSelectionMode(null);
                                RAASServiceLocationLayer.queryFeatures({
                                    geometry: evt.graphic.geometry,
                                    spatialRelationship: 'contains',
                                    returnGeometry: false,
                                    where: queryString,
                                    outFields: ['*'],
                                }).then((featureSet) => {
                                    if (featureSet && featureSet.features.length > 0) {
                                        let selSvcLocationIds = [...selectedRaaSServiceLocationIdsRef.current];
                                        featureSet.features.forEach((feature) => {
                                            if (!selSvcLocationIds.includes(feature.attributes['record_id'])) {
                                                selSvcLocationIds.push(feature.attributes['record_id']);
                                            }
                                        });
                                        setSelectedRaaSServiceLocationIds(selSvcLocationIds);
                                    }
                                });
                            }
                            break;
                        default:
                            break;
                    }
                }
            }
        });
        setPickXYSketch(xySketch);
    };

    const initServiceAreaSketchTool = (view) => {
        view.ui.add(document.getElementById('serviceAreaBoundaryWidget'), {
            position: 'top-right',
            index: 14,
        });

        serviceAreaGraphicLayer = new GraphicsLayer();
        map.add(serviceAreaGraphicLayer);
        //setServiceAreaGraphicLayer(SAGraphicLayer);

        editServiceAreaGraphicLayer = new GraphicsLayer();
        map.add(editServiceAreaGraphicLayer);
        //setDefineServiceAreaGraphicLayer(editServiceAreaGraphicLayer);

        defineServiceAreaSketch = new SketchViewModel({
            view: view,
            layer: editServiceAreaGraphicLayer,
        });

        editServiceAreaSketch = new SketchViewModel({
            view: view,
            layer: editServiceAreaGraphicLayer,
        });

        defineServiceAreaSketch.on('create', (event) => {
            if (event.state === 'complete') {
                if (event.graphic.geometry.rings[0] && event.graphic.geometry.rings[0].length < 4) {
                    if (editServiceAreaGraphicLayer) editServiceAreaGraphicLayer.removeAll();
                    showAPICallbackDialog('Error', 'The polygon you drew has invalid geometry. Please try again.');
                } else if (event.graphic.geometry.isSelfIntersecting) {
                    if (editServiceAreaGraphicLayer) editServiceAreaGraphicLayer.removeAll();
                    showAPICallbackDialog('Error', 'The polygon you drew is self-intersecting. Please try again.');
                } else {
                    createUpdateServiceAreaBoundaryConfirmed(event.graphic.geometry);
                }
            } else if (event.state === 'cancel') {
                if (defineServiceAreaSketch) defineServiceAreaSketch.cancel();
                setIsServiceAreaBoundaryWidgetClicked(false);
            }
        });

        editServiceAreaSketch.on('update', (event) => {
            setIsServiceAreaBoundaryReshapeDialogOpen(false);
            if (event.state === 'complete' || event.state === 'cancel') {
                if (event.graphics[0].geometry.rings[0] && event.graphics[0].geometry.rings[0].length < 4) {
                    showAPICallbackDialog('Error', 'The polygon you drew has invalid geometry. Please try again.');
                } else if (event.graphics[0].geometry.isSelfIntersecting) {
                    editServiceAreaSketch.update(event.graphics[0], { tool: 'reshape' });
                    showAPICallbackDialog('Error', 'The polygon you drew is self-intersecting. Please try again.');
                } else {
                    setPolygonForServiceAreaBoundary(event.graphics[0].geometry);
                    setIsServiceAreaBoundaryReshapeDialogOpen(true);
                }
            }
        });
    };

    const initSearchWidget = (view) => {
        const searchWidget = new Search({
            view: view,
            allPlaceholder: 'Find address or place',
            includeDefaultSources: false,
            locationEnabled: false,
            sources: hereVersion === 'v3' ? [customSearchSource_V3] : [customSearchSource_V2],
        });
        view.ui.add(searchWidget, {
            position: 'top-right',
            index: 0,
        });
    };

    const initHomeWidget = (view) => {
        const homeWidget = new Home({
            view: view,
            label: 'Zoom to Workspace',
        });
        view.ui.add(homeWidget, {
            position: 'top-right',
            index: 1,
        });
        setHomeWidget(homeWidget);
    };

    const initZoomWidget = (view) => {
        const zoomWidget = new Zoom({
            view: view,
            layout: 'horizontal',
        });
        setZoomWidget(zoomWidget);
        view.ui.add(document.getElementById('map_ZoomIn'), {
            position: 'top-right',
            index: 2,
        });
        view.ui.add(document.getElementById('map_ZoomOut'), {
            position: 'top-right',
            index: 3,
        });
        view.ui.add(document.getElementById('zoomByDrawingPolygon'), {
            position: 'top-right',
            index: 4,
        });
    };

    const initSelectionWidget = (view) => {
        view.ui.add(document.getElementById('clearSelectionButton'), {
            position: 'top-right',
            index: 5,
        });
        view.ui.add(document.getElementById('selectionToolsButton'), {
            position: 'top-right',
            index: 6,
        });
    };

    const initBasemapWidget = (view) => {
        let basemapGallery = new BasemapGallery({
            view: view,
            container: document.createElement('div'),
            source:
                hereVersion === 'v3'
                    ? [HEREStreetBaseMap_V3, HERESatelliteBaseMap_V3, HEREHybridBaseMap_V3]
                    : [HEREStreetBaseMap_V2, HERESatelliteBaseMap_V2, HEREHybridBaseMap_V2],
        });
        let bgExpand = new Expand({
            view: view,
            content: basemapGallery,
            expandTooltip: 'Basemap Gallery',
            group: 'right',
            autoCollapse: true,
        });
        basemapGallery.watch('activeBasemap', (e) => {
            const mobileSize = view.heightBreakpoint === 'xsmall' || view.widthBreakpoint === 'xsmall';
            updateUserActivity({ ActionId: 221, WorkspaceId: workspace.value, Metadata: e.title });
            if (mobileSize) {
                bgExpand.collapse();
            }
        });
        view.ui.add(bgExpand, {
            position: 'top-right',
            index: 7,
        });
    };

    const initOverviewWidget = (view) => {
        const overviewDiv = document.getElementById('overviewDiv');
        const overviewExpand = new Expand({
            collapsedIconClass: 'esri-icon-overview-arrow-top-right',
            expandIconClass: 'esri-icon-overview-arrow-bottom-left',
            expandTooltip: 'Overview Map',
            view: view,
            content: overviewDiv,
            expanded: false,
            group: 'right',
        });

        view.ui.add(overviewExpand, {
            position: 'top-right',
            index: 8,
        });

        const overviewMap = new Map({
            basemap: hereVersion === 'v3' ? HEREStreetBaseMap_V3 : HEREStreetBaseMap_V2,
        });

        const overviewMapView = new MapView({
            container: 'overviewDiv',
            map: overviewMap,
            center: view.center,
            scale: view.scale * 12 * Math.max(view.width / view.width, view.height / view.height),
            constraints: {
                rotationEnabled: false,
            },
        });

        const extentGraphic = new Graphic({
            geometry: view.extent,
            symbol: {
                type: 'simple-fill',
                color: [0, 0, 0, 0.5],
                outline: null,
            },
        });

        overviewMapView.graphics.add(extentGraphic);

        overviewMapView.ui.components = [];

        view.when(() => {
            overviewMapView.when(() => {
                reactiveUtils.watch(
                    () => view.extent,
                    (extent) => {
                        overviewMapView.goTo({
                            center: view.center,
                            scale: view.scale * 12 * Math.max(view.width / view.width, view.height / view.height),
                        });
                        extentGraphic.geometry = extent;
                    },
                );
            });
        });
    };

    const initLegendWidget = (view) => {
        const legend = new Expand({
            content: new Legend({
                view: view,
                style: 'classic', // other styles include 'card'
                layerInfos: [
                    // {
                    //     layer: asseyAssignmentsLayer,
                    //     title: "Alley Assignments"
                    // },
                    {
                        layer: closedStreetsLayer,
                        title: 'Closed Streets',
                    },
                    {
                        layer: serviceSidesLayer,
                        title: 'Service Sides',
                    },
                    {
                        layer: turnExceptionsLayer,
                        title: 'Turn Exceptions',
                    },
                ],
            }),
            view: view,
            expanded: false,
            expandTooltip: 'Map Legend',
            group: 'right',
        });
        view.ui.add(legend, {
            position: 'top-right',
            index: 9,
        });
        setLegendWidget(legend);
    };

    const initPrintWidget = (view) => {
        const printDiv = document.getElementById('printDiv');
        const printExpand = new Expand({
            collapsedIconClass: 'esri-icon-collapse',
            expandIconClass: 'RS_PRINT',
            expandTooltip: 'Print Map',
            view: view,
            content: printDiv,
            expanded: false,
            group: 'right',
        });
        view.ui.add(printExpand, {
            position: 'top-right',
            index: 10,
        });
    };

    const initMeasurementWidget = (view) => {
        const measurement = new Measurement({
            view: view,
        });
        view.ui.add(measurement, 'bottom-right');
        setMeasurementWidget(measurement);
        view.ui.add('measurementToolsButton', {
            position: 'top-right',
            index: 11,
        });
    };

    const initStreetMaintenanceWidget = (view) => {
        const steetModeExpand = new Expand({
            collapsedIconClass: 'esri-icon-collapse',
            expandIconClass: 'esri-icon-applications',
            expandTooltip: 'Street Maintenance',
            view: view,
            content: document.getElementById('streetModeDiv'),
            expanded: false,
            group: 'bottom-left',
        });
        if (checkFunction(202) || checkFunction(210) || checkFunction(218) || checkFunction(205)) {
            view.ui.add(steetModeExpand, {
                position: 'top-right',
                index: 12,
            });
        }
    };

    const initSelectionDetailWidget = (view) => {
        view.ui.add(document.getElementById('selectionDetailWidget'), {
            position: 'top-right',
            index: 13,
        });
    };

    const initCoordinatesWidget = (view) => {
        const ccWidget = new CoordinateConversion({
            view: view,
        });
        let ccExpand = new Expand({
            collapsedIconClass: 'esri-icon-collapse',
            expandIconClass: 'esri-icon-map-pin',
            expandTooltip: 'Display Coordinates',
            view: view,
            content: ccWidget,
            group: 'bottom-right',
        });
        view.ui.add(ccExpand, 'bottom-right');
    };

    const printMap = () => {
        if (view) {
            let url = PrintURL;
            if (mapMode === 1) {
                RaaSPrintURL = RaaSPrintURL.replace('[workspace_id]', workspace.value);
                RaaSPrintURL = RaaSPrintURL.replace('[RaasJobId]', selectedScenarioId);
                url = RaaSPrintURL;
            }

            const template = new PrintTemplate({
                format: selPrintFormat,
                exportOptions: {
                    dpi: 600,
                },
                scalePreserved: false,
                layout: 'SideLegend',
                templateOptions: {
                    title: 'Service Area Map',
                    legendEnabled: true,
                },
                layoutOptions: {
                    customTextElements: [
                        {
                            txt_workspace_name: workspace.label,
                        },
                    ],
                },
            });

            const params = new PrintParameters({
                view: view,
                template: template,
            });

            setPrintButtonLoading(true);

            if (facilityLayerVisibility) {
                facilityLayer.visible = mapMode === 0;
                if (RAASFacilityLayer) RAASFacilityLayer.visible = mapMode === 1;
            }

            if (servicePatternView) {
                if (siteMode === 'DISPATCHER') {
                    servicePatternLayer.findSublayerById(14).visible = false;
                    printServiceDayLayer.findSublayerById(14).visible = true;
                } else {
                    projectServicePatternLayer.findSublayerById(15).visible = false;
                    printProjectServiceDayLayer.findSublayerById(15).visible = true;
                }
            }

            print
                .execute(url, params)
                .then((res) => {
                    printResult(res);
                    setPrintButtonLoading(false);
                    if (facilityLayerVisibility) {
                        facilityLayer.visible = mapMode === 0 && facilityLayerVisibility;
                        if (RAASFacilityLayer)
                            RAASFacilityLayer.visible = mapMode === 1 && selDispatchTool === 'SavedScenarios' && RAASFacilityLayerVisibility;
                    }
                    if (servicePatternView) {
                        if (siteMode === 'DISPATCHER') {
                            servicePatternLayer.findSublayerById(14).visible = true;
                            printServiceDayLayer.findSublayerById(14).visible = false;
                        } else {
                            projectServicePatternLayer.findSublayerById(15).visible = true;
                            printProjectServiceDayLayer.findSublayerById(15).visible = false;
                        }
                    }
                    updateUserActivity({ WorkspaceId: workspace.value, ActionId: 230, Mode: siteMode });
                })
                .catch((err) => {
                    setPrintButtonLoading(false);
                    if (facilityLayerVisibility) {
                        facilityLayer.visible = mapMode === 0 && facilityLayerVisibility;
                        if (RAASFacilityLayer)
                            RAASFacilityLayer.visible = mapMode === 1 && selDispatchTool === 'SavedScenarios' && RAASFacilityLayerVisibility;
                    }
                    if (servicePatternView) {
                        if (siteMode === 'DISPATCHER') {
                            servicePatternLayer.findSublayerById(14).visible = true;
                            printServiceDayLayer.findSublayerById(14).visible = false;
                        } else {
                            projectServicePatternLayer.findSublayerById(15).visible = true;
                            printProjectServiceDayLayer.findSublayerById(15).visible = false;
                        }
                    }
                    updateUserActivity({ WorkspaceId: workspace.value, ActionId: 230, Mode: siteMode });
                });

            function printResult(result) {
                var fileType = 'application/pdf';
                if (selPrintFormat === 'JPG') fileType = 'image/jpeg';
                var xhr = new XMLHttpRequest();
                xhr.open('GET', result.url, true);
                xhr.responseType = 'blob';
                xhr.onload = function (e) {
                    if (this.status === 200) {
                        var file = new Blob([this.response], { type: fileType });
                        if (!window.navigator.msSaveOrOpenBlob) {
                            var link = document.createElement('a');
                            link.href = URL.createObjectURL(file);
                            link.download = selPrintTitle + '-' + formatFileTimeStamp() + '.' + selPrintFormat;
                            document.body.appendChild(link);
                            link.click();
                            document.body.removeChild(link);
                        } else {
                            window.navigator.msSaveOrOpenBlob(file, selPrintTitle + '-' + formatFileTimeStamp() + '.' + selPrintFormat);
                        }
                    }
                };
                xhr.send();
            }
        }
    };

    const toggleMapTravelPathLayer = (route, fullLabel) => {
        if (serviceDay.value === 0 && fullLabel) {
            if (selTravelPathRoute === fullLabel + '|1') {
                setSelTravelPathRoute(null);
            } else {
                setSelTravelPathRoute(fullLabel + '|1');
                setIsMapLoading(true);
                updateUserActivity({ ActionId: 229, WorkspaceId: workspace.value, Mode: siteMode, Metadata: 'Travel Path' });
            }
        } else {
            if (selTravelPathRoute === route + '|1') {
                setSelTravelPathRoute(null);
            } else {
                setSelTravelPathRoute(route + '|1');
                setIsMapLoading(true);
                updateUserActivity({ ActionId: 229, WorkspaceId: workspace.value, Mode: siteMode, Metadata: 'Travel Path' });
            }
        }
    };

    const toggleMapSequenceLayer = (route, fullLabel) => {
        if (serviceDay.value === 0 && fullLabel) {
            if (selSequenceRoute === fullLabel) {
                setSelSequenceRoute(null);
            } else {
                setSelSequenceRoute(fullLabel);
                setIsMapLoading(true);
                updateUserActivity({ ActionId: 229, WorkspaceId: workspace.value, Mode: siteMode, Metadata: 'Sequence' });
            }
        } else {
            if (selSequenceRoute === route) {
                setSelSequenceRoute(null);
            } else {
                setSelSequenceRoute(route);
                setIsMapLoading(true);
                updateUserActivity({ ActionId: 229, WorkspaceId: workspace.value, Mode: siteMode, Metadata: 'Sequence' });
            }
        }
    };

    const toggleScenarioTravelPathLayer = (route) => {
        if (selScenarioTravelPathRoute === route + '|1') {
            setSelScenarioTravelPathRoute(null);
        } else {
            if (!selectedScenarioRoutes.includes(route)) {
                let selection = [...selectedScenarioRoutes];
                selection.push(route);
                setSelectedScenarioRoutes(selection);
            }
            setSelScenarioTravelPathRoute(route + '|1');
            setIsMapLoading(true);
        }
    };

    const toggleScenarioSequenceLayer = (route) => {
        if (selScenarioSequenceRoute === route) {
            setSelScenarioSequenceRoute(null);
        } else {
            if (!selectedScenarioRoutes.includes(route)) {
                let selection = [...selectedScenarioRoutes];
                selection.push(route);
                setSelectedScenarioRoutes(selection);
            }
            setSelScenarioSequenceRoute(route);
            setIsMapLoading(true);
        }
    };

    const renderTravelPath = (route, result, TravelPathStyle, type) => {
        let currentScale = view.scale;
        if (type === 'route') {
            travelPathLayer.graphics.removeAll();
            travelPathLabelLayer.graphics.removeAll();
        } else {
            scenarioTravelPathLayer.graphics.removeAll();
            scenarioTravelPathLabelLayer.graphics.removeAll();
        }
        result.map((res, i) => {
            let Geometry = '';
            let coordinates,
                polyline = [];
            let GeometryText = res.GeometryText;
            let Mode = res.Mode;
            if (GeometryText.match(/\(\((.*?)\)\)/)) {
                Geometry = GeometryText.match(/\(\((.*?)\)\)/)[1];
            }
            let path = Geometry.split(',');
            let labelPoint = res.LabelPosition;
            labelPoint = labelPoint.replace('POINT(', '');
            labelPoint = labelPoint.replace(')', '');
            let lPtX = labelPoint.split(' ')[0];
            let lPtY = labelPoint.split(' ')[1];

            path.map((p) => {
                coordinates = p.split(' ');
                polyline.push(coordinates);
            });

            let TravelPath = new Polyline();
            TravelPath.addPath(polyline);

            let pathWidth = currentScale < config.TRAVEL_PATH_THICK_SCALE ? 2 : 1;

            let PathSymbol = {
                type: 'simple-line',
                path: polyline,
                marker: {
                    color: 'black',
                    placement: 'end',
                    style: 'arrow',
                },
            };

            if (TravelPathStyle == 1) {
                PathSymbol.width = pathWidth;
                let routeItem = null;
                if (type === 'route') {
                    if (serviceDay.value === 0) {
                        if (routeViewTabCategorization.value.startsWith('R')) {
                            let travelPathRoute = selTravelPathRoute.split('|')[0];
                            let travelPathServiceDay = selTravelPathRoute.split('|')[1];
                            let r = routeViewTableData[routeViewTabCategorization.value].find((rt) => rt.Id === travelPathRoute); //need adjustment here
                            if (r) {
                                routeItem = r.subRows.find((rs) => rs.Id.toString() === travelPathServiceDay);
                            }
                        } else {
                            let travelPathServiceDay = selTravelPathRoute.split('|')[0];
                            let travelPathRoute = selTravelPathRoute.split('|')[1];
                            let r = routeViewTableData[routeViewTabCategorization.value].find((rt) => rt.Id.toString() === travelPathServiceDay); //need adjustment here
                            if (r) {
                                routeItem = r.subRows.find((rs) => rs.Id === travelPathRoute);
                            }
                        }
                    } else {
                        routeItem = routeViewTableData['R'].find((r) => {
                            //need adjustment here
                            return r.Id === route;
                        });
                    }
                } else {
                    routeItem = scenarioRouteList.find((r) => {
                        return r.Id === route;
                    });
                }
                let pathColor = routeItem?.SvgColor || '#000';
                PathSymbol.color = hexToRGBA(pathColor, 0.6);
                PathSymbol.marker.color = hexToRGBA(pathColor, 0.6);
                if (Mode == 'DS' || Mode == 'WS') {
                    PathSymbol.style = 'solid';
                } else if (Mode == 'DN' || Mode == 'WN') {
                    PathSymbol.style = 'dash';
                }

                let pathGraphic = new Graphic({
                    geometry: TravelPath,
                    symbol: PathSymbol,
                    attributes: {
                        StreetType: res.StreetType,
                        StreetSuffix: res.StreetSuffix,
                        StreetSeq: res.StreetSeq,
                        StreetPref: res.StreetPref,
                        StreetName: res.StreetName,
                        StreetID: res.StreetID,
                        Side: res.Side,
                        ServiceSeq: res.ServiceSeq,
                        Route: res.Route,
                        Mode: res.Mode,
                        Meander: res.Meander,
                        LowAddress: res.LowAddress,
                        ID: res.Id,
                        HighAddress: res.HighAddress,
                        DriveTime: res.DriveTime,
                        Distance: res.Distance,
                    },
                });

                if (type === 'route') {
                    travelPathLayer.graphics.add(pathGraphic);
                } else {
                    scenarioTravelPathLayer.graphics.add(pathGraphic);
                }

                let travelPathLabel = new Graphic({
                    geometry: {
                        type: 'point',
                        longitude: lPtX,
                        latitude: lPtY,
                    },
                    symbol: {
                        type: 'text',
                        color: 'black',
                        text: res.StreetSeq,
                        xoffset: 10,
                        yoffset: 10,
                        verticalAlignment: 'middle',
                        font: {
                            size: 10,
                        },
                    },
                });

                var borderGraphic = new Graphic({
                    geometry: {
                        type: 'point',
                        longitude: lPtX,
                        latitude: lPtY,
                    },
                    symbol: {
                        type: 'simple-marker',
                        style: 'path',
                        path: res.StreetSeq.length > 5 ? 'M 0 0 L 30 0 L 30 6 L 0 6 L 0 0' : 'M 0 0 L 15 0 L 15 6 L 0 6 L 0 0',
                        color: [255, 255, 255, 1],
                        outline: { width: 1 },
                        size: res.StreetSeq.length > 5 ? 60 : 30,
                        xoffset: 10,
                        yoffset: 10,
                    },
                });

                if (type === 'route') {
                    travelPathLabelLayer.graphics.add(borderGraphic);
                    travelPathLabelLayer.graphics.add(travelPathLabel);
                } else {
                    scenarioTravelPathLabelLayer.graphics.add(borderGraphic);
                    scenarioTravelPathLabelLayer.graphics.add(travelPathLabel);
                }
            }
        });
    };

    const renderSequence = async (result, type) => {
        try {
            if (sequenceLayer) sequenceLayer.graphics.removeAll();
            if (sequenceLabelLayer) sequenceLabelLayer.graphics.removeAll();
            if (scenarioSequenceLayer) scenarioSequenceLayer.graphics.removeAll();
            if (scenarioSequenceLabelLayer) scenarioSequenceLabelLayer.graphics.removeAll();

            if (result) {
                var validLocations = result.filter(function (el) {
                    return el.Sequence !== 0;
                });
                var uniqueSequence = [],
                    last = 0;
                const seqGapResp = await getSeqGap('SeqGap');
                let seqGap = 1;
                if (seqGapResp && seqGapResp.data) {
                    seqGap = seqGapResp.data.Value;
                }
                validLocations.map(function (vl) {
                    if (vl.Sequence > 0) {
                        var repeat = validLocations.filter(function (a) {
                            return vl.X === a.X && vl.Y === a.Y;
                        });
                        var sequence = vl.Sequence;
                        if (repeat.length > 1) {
                            if (repeat[repeat.length - 1].Sequence - repeat[0].Sequence === (repeat.length - 1) * seqGap) {
                                sequence = repeat[0].Sequence + '-' + repeat[repeat.length - 1].Sequence;
                            } else {
                                sequence = '';
                                repeat.map(function (r) {
                                    sequence += r.Sequence + ', ';
                                });
                                sequence = sequence.substring(0, sequence.length - 2);
                            }
                        }
                        uniqueSequence.push(sequence);
                        last = vl.Sequence;
                    }
                });
                uniqueSequence.push(last + 1);
                for (var i = 0; i < validLocations.length; i++) {
                    var x1 = validLocations[i].X;
                    var y1 = validLocations[i].Y;
                    if (validLocations[i + 1]) {
                        var x2 = validLocations[i + 1].X;
                        var y2 = validLocations[i + 1].Y;

                        if (x1 != x2 || y1 != y2) {
                            var sequenceGraphic = new Graphic({
                                geometry: {
                                    type: 'polyline',
                                    paths: [
                                        [x1, y1],
                                        [x2, y2],
                                    ],
                                },
                                symbol: {
                                    type: 'simple-line',
                                    color: [0, 0, 0],
                                    width: 2,
                                    marker: {
                                        color: 'black',
                                        placement: 'end',
                                        style: 'arrow',
                                    },
                                },
                            });

                            if (type === 'route') {
                                sequenceLayer.graphics.add(sequenceGraphic);
                            } else {
                                scenarioSequenceLayer.graphics.add(sequenceGraphic);
                            }

                            var borderGraphic = new Graphic({
                                geometry: {
                                    type: 'point',
                                    longitude: x2,
                                    latitude: y2,
                                },
                                symbol: {
                                    type: 'simple-marker',
                                    style: 'path',
                                    path: uniqueSequence[i].length > 5 ? 'M 0 0 L 30 0 L 30 6 L 0 6 L 0 0' : 'M 0 0 L 15 0 L 15 6 L 0 6 L 0 0',
                                    color: [255, 255, 255, 1],
                                    outline: { width: 1 },
                                    size: uniqueSequence[i].length > 5 ? 60 : 30,
                                    xoffset: 10,
                                    yoffset: 10,
                                },
                            });

                            if (type === 'route') {
                                sequenceLabelLayer.graphics.add(borderGraphic);
                            } else {
                                scenarioSequenceLabelLayer.graphics.add(borderGraphic);
                            }

                            var sequenceLabel = new Graphic({
                                geometry: {
                                    type: 'point',
                                    longitude: x2,
                                    latitude: y2,
                                },
                                symbol: {
                                    text: uniqueSequence[i],
                                    type: 'text',
                                    color: 'black',
                                    xoffset: 10,
                                    yoffset: 10,
                                    verticalAlignment: 'middle',
                                    font: {
                                        size: 10,
                                    },
                                },
                            });

                            if (type === 'route') {
                                sequenceLabelLayer.graphics.add(sequenceLabel);
                            } else {
                                scenarioSequenceLabelLayer.graphics.add(sequenceLabel);
                            }
                        }
                    }
                }
                const graphic1 = new Graphic({
                    geometry: {
                        type: 'point',
                        longitude: validLocations[0].X,
                        latitude: validLocations[0].Y,
                    },
                    symbol: {
                        type: 'simple-marker',
                        size: 30,
                        color: [0, 255, 0, 0.25],
                    },
                });
                const graphic2 = new Graphic({
                    geometry: {
                        type: 'point',
                        longitude: validLocations[validLocations.length - 1].X,
                        latitude: validLocations[validLocations.length - 1].Y,
                    },
                    symbol: {
                        type: 'simple-marker',
                        style: 'square',
                        size: 30,
                        color: [255, 0, 0, 0.25],
                    },
                });
                if (type === 'route') {
                    sequenceLayer.graphics.add(graphic1);
                    sequenceLayer.graphics.add(graphic2);
                    sequenceLabelLayer.visible = showSequenceLabel;
                } else {
                    scenarioSequenceLayer.graphics.add(graphic1);
                    scenarioSequenceLayer.graphics.add(graphic2);
                    scenarioSequenceLabelLayer.visible = showScenarioSequenceLabel;
                }
            }
        } catch (e) {
            console.error(e);
        }
    };

    const initTrafficLayer = (map) => {
        if (hereVersion === 'v3') {
            setTrafficLayer(HERETrafficLayer_V3);
            map.add(HERETrafficLayer_V3);
        } else {
            setTrafficLayer(HERETrafficLayer_V2);
            map.add(HERETrafficLayer_V2);
        }
    };

    const initStreetLayer = (map) => {
        streetsLayer = new MapImageLayer({
            url: ArcgisAPI,
            visible: false,
            sublayers: [
                {
                    id: config.STREETS,
                    title: 'Streets',
                    renderer: {
                        type: 'simple',
                        symbol: {
                            type: 'simple-line',
                            width: 4,
                            color: 'lightgray',
                            style: 'solid',
                        },
                    },
                    popupEnabled: false,
                    popupTemplate: {
                        title: 'Street segment',
                        content:
                            "<ul class='no-bullets'>" +
                            '<li><b>Segment ID:</b> {link_id}</li>' +
                            '<li><b>Street Name:</b> {st_name}</li> ' +
                            '<li><b>Left Zip:</b> {l_postcode} &nbsp; <b>Right Zip:</b> {r_postcode} </li>' +
                            '<li><b>Left Range:</b> {l_refaddr} - {l_nrefaddr} &nbsp; <b>Right Range:</b> {r_refaddr} - {r_nrefaddr} </li></ul>',
                        outFields: ['gid', 'link_id', 'st_name', 'l_postcode', 'r_postcode', 'l_refaddr', 'r_refaddr'],
                    },
                },
            ],
        });
        map.add(streetsLayer);

        const streetHighlightGraphicsLayer = new GraphicsLayer();
        map.add(streetHighlightGraphicsLayer);
        setStreetHighlightGraphicsLayer(streetHighlightGraphicsLayer);
    };

    const initClosedStreetLayer = (map) => {
        closedStreetsLayer = new MapImageLayer({
            url: ArcgisAPI,
            visible: false,
            sublayers: [
                {
                    id: config.CLOSED_STREETS,
                    definitionExpression: workspace.value ? 'workspace_id=' + workspace.value : '0 = 1',
                    title: 'Closed  streets',
                    renderer: {
                        type: 'simple',
                        symbol: {
                            type: 'simple-line',
                            width: 12,
                            color: [255, 170, 0, 0.5],
                            style: 'solid',
                        },
                    },
                },
            ],
        });
        map.add(closedStreetsLayer);
    };

    const initServiceSideLayer = (map) => {
        serviceSidesLayer = new MapImageLayer({
            url: ArcgisAPI,
            visible: false,
            opacity: 0.8,
            sublayers: [
                {
                    id: config.SERVICE_SIDES,
                    definitionExpression: workspace.value ? "workspace_id='" + workspace.value + "'" : '0 = 1',
                    title: 'Service Side',
                    renderer: {
                        type: 'unique-value',
                        legendOptions: {
                            title: ' ',
                        },
                        field: 'meander',
                        uniqueValueInfos: [
                            {
                                value: 'N',
                                label: 'Same Side',
                                symbol: {
                                    type: 'simple-line',
                                    width: 12,
                                    color: [0, 115, 76, 0.5],
                                    style: 'solid',
                                },
                            },
                            {
                                value: 'Y',
                                label: 'Both Sides',
                                symbol: {
                                    type: 'simple-line',
                                    width: 12,
                                    color: [168, 0, 0, 0.5],
                                    style: 'solid',
                                },
                            },
                        ],
                    },
                },
            ],
        });
        map.add(serviceSidesLayer);
    };

    const initTurnExceptionLayer = (map) => {
        turnExceptionsLayer = new MapImageLayer({
            url: ArcgisAPI,
            sublayers: [
                {
                    id: config.HERETURNEXCEPTION,
                    definitionExpression: 'gid>0',
                    title: 'HERE Turn Exceptions',
                },
                {
                    id: config.USERTURNEXCEPTION,
                    definitionExpression: workspace.value ? 'workspace_id=' + workspace.value : '0 = 1',
                    title: 'User Turn Exceptions',
                },
            ],
            visible: false,
            opacity: 0.5,
        });
        map.add(turnExceptionsLayer);

        const userTurnExceptionsGraphicsLayer = new GraphicsLayer();
        map.add(userTurnExceptionsGraphicsLayer);
        setUserTurnExceptionsGraphicsLayer(userTurnExceptionsGraphicsLayer);
    };

    const addStreetGraphic = (feature, graphicLayer) => {
        let highlightStrtSegGraphic = new Graphic({
            geometry: feature.geometry,
            attributes: feature.attributes,
            symbol: {
                type: 'simple-line',
                width: 12,
                color: [255, 0, 0, 0.5],
                style: 'solid',
            },
        });
        graphicLayer.graphics.add(highlightStrtSegGraphic);
    };

    const handleUserTurnExceptionDeleteClose = async (option) => {
        if (option === 'CANCEL') {
            setOpenUserTurnExceptionDeleteDialog(false);
            setUserTurnExceptionTarget({});
        } else if (option === 'DELETE') {
            try {
                await deleteUserTurnException(userTurnExceptionTarget.WorkspaceId, userTurnExceptionTarget.Gid);
                const logData = {
                    WorkspaceId: workspace.value,
                    ActionId: 214,
                    Mode: siteMode,
                    Success: true,
                    Metadata: userTurnExceptionTarget.Gid,
                };
                updateUserActivity(logData);
                setOpenUserTurnExceptionDeleteDialog(false);
                setUserTurnExceptionTarget({});
                streetHighlightGraphicsLayer.graphics.removeAll();
                if (turnExceptionsLayer) {
                    turnExceptionsLayer.refresh();
                }
            } catch (e) {
                const logData = {
                    WorkspaceId: workspace.value,
                    ActionId: 214,
                    Mode: siteMode,
                    Success: false,
                    Metadata: userTurnExceptionTarget.Gid,
                };
                updateUserActivity(logData);
            }
        }
    };

    const handleValidAlleyAssignments = () => {
        checkAlleyAssignments(workspace.value).then(
            (res) => {
                getAlleyAssignments(workspace.value).then(
                    (res) => {
                        setAlleyAssignmentsData(res.data);
                    },
                    (err) => {
                        setAlleyAssignmentsData([]);
                    },
                );
            },
            (err) => {},
        );
    };

    const handleZoomToAlleyAssignment = (id) => {
        alleyAssignmentsLayer
            .findSublayerById(16)
            .queryFeatures({
                returnGeometry: true,
                where: 'workspace_id=' + workspaceId.current.value + ' and id=' + id,
            })
            .then((featureSet) => {
                if (featureSet.features.length === 1) {
                    view.goTo(featureSet.features[0].geometry);
                }
            });
    };

    const handleDeleteAlleyAssignment = (id) => {
        setOpenAlleyAssignmentsDeleteDialog(true);
        setAlleyAssignmentsTarget(id);
    };

    const handleAlleyAssignmentsDeleteClose = (option) => {
        if (option === 'CANCEL') {
            setOpenAlleyAssignmentsDeleteDialog(false);
            setAlleyAssignmentsTarget(null);
        } else if (option === 'DELETE') {
            if (alleyAssignmentsTarget) {
                deleteAlleyAssignments(workspace.value, alleyAssignmentsTarget).then(
                    (res) => {
                        setOpenAlleyAssignmentsDeleteDialog(false);
                        setAlleyAssignmentsTarget(null);
                        getAlleyAssignments(workspace.value).then(
                            (res) => {
                                setAlleyAssignmentsData(res.data);
                            },
                            (err) => {
                                setAlleyAssignmentsData([]);
                            },
                        );
                        alleyAssignmentsGraphicsLayer.graphics.removeAll();
                        if (alleyAssignmentsLayer) {
                            alleyAssignmentsLayer.refresh();
                        }
                    },
                    (err) => {
                        showAPICallbackDialog('Error', err.response.data);
                    },
                );
            }
        }
    };

    const initAlleyAssignmentsLayer = (map) => {
        alleyAssignmentsLayer = new MapImageLayer({
            url: ArcgisAPI,
            sublayers: [
                {
                    id: config.ALLEY_ASSIGNMENTS,
                    definitionExpression: workspace.value ? 'workspace_id=' + workspace.value : '0 = 1',
                    title: 'Alley Assignments',
                },
            ],
            visible: false,
        });
        map.add(alleyAssignmentsLayer);

        blockFaceLayer = new MapImageLayer({
            url: ArcgisAPI,
            sublayers: [
                {
                    id: config.BLOCKFACE,
                    definitionExpression: workspace.value ? 'workspace_id=' + workspace.value : '0 = 1',
                    title: 'Blockface',
                    renderer: STREET_RENDERER,
                },
            ],
            visible: false,
            opacity: 0.5,
        });
        map.add(blockFaceLayer);

        alleyAssignmentsGraphicsLayer = new GraphicsLayer({ id: 'alleyAssignmentsGraphicsLayer' });
        map.add(alleyAssignmentsGraphicsLayer);
    };

    const zoomToExtent = (extent) => {
        let routeExtent = extent.split(',');
        let minX = routeExtent[0].trim();
        let minY = routeExtent[1].trim();
        let maxX = routeExtent[2].trim();
        let maxY = routeExtent[3].trim();
        let ext = new Extent({ xmin: minX, ymin: minY, xmax: maxX, ymax: maxY });
        if (view) {
            view.extent = ext.expand(1.5);
        }
    };

    const facilityContent = (feature) => {
        let attributes = feature.graphic.attributes;
        return (
            "<ul class='no-bullets'>" +
            '<li><b>Facility:</b> {facility_name}</li>' +
            '<li><b>Address:</b> {address}</li> ' +
            '<li><b>City:</b> {city}' +
            ' &nbsp; <b>State:</b> {state}' +
            ' &nbsp; <b>ZIP:</b> {postal_code}</li > ' +
            '<li><b>Start/End:</b> ' +
            (attributes.startend > 0 ? 'Y' : 'N') +
            ' &nbsp; <b>Intermediate location:</b> ' +
            (attributes.intermediate > 0 ? 'Y' : 'N') +
            '</li>' +
            '<li><b>Open:</b> ' +
            (attributes.earliest_arrival == 0.0 ? 'Midnight' : getFormattedTime(attributes.earliest_arrival)) +
            ' &nbsp; <b>Close:</b> ' +
            (attributes.latest_arrival == 0.0 ? 'Midnight' : getFormattedTime(attributes.latest_arrival)) +
            '</li>' +
            '<li><b>Duration:</b> ' +
            getFormattedTime(attributes.duration) +
            ' &nbsp; <b>Penalty:</b> ' +
            getFormattedTime(attributes.penalty) +
            '</li></ul>'
        );
    };

    const serviceLocationContent = (feature) => {
        let attributes = feature.graphic.attributes;
        let routeLabel = '',
            serviceDayLabel = '';
        if (attributes.route === '0') {
            routeLabel = 'No Route';
        } else {
            routeLabel = attributes.route;
        }
        if (attributes.service_day === 0) {
            serviceDayLabel = 'No Service Day';
        } else {
            serviceDayLabel = defaultServiceDayList[attributes.service_day].label;
        }

        return (
            "<ul class='no-bullets'>" +
            '<li><b>Name:</b> {customer_name}</li>' +
            '<li><b>Account:</b> {account_id}' +
            ' &nbsp; <b>Address:</b> {address}' +
            ' &nbsp; <b>Apt:</b> {apartment}</li>' +
            '<li><b>City:</b> {city}' +
            ' &nbsp; <b>State:</b> {state}' +
            ' &nbsp; <b>ZIP:</b> {postal_code}</li>' +
            '<li><b>Comments:</b> {comments}</li>' +
            '<li><b>Route:</b> ' +
            routeLabel +
            ' &nbsp; <b>Sequence:</b> {sequence}' +
            ' &nbsp; <b>Frequency Code:</b> {frequency_code}</li>' +
            '<li><b>Service Type:</b> {service_type}' +
            ' &nbsp; <b>Quantity:</b> {quantity}' +
            ' &nbsp; <b>Size:</b> {bin_size}</li>' +
            '<li><b>Demand:</b> {demand}' +
            ' &nbsp; <b>Service Time:</b> ' +
            getFormattedTimeSeconds(attributes.service_time) +
            '</li>' +
            '<li><b>Service:</b> ' +
            renderServiceWindow({ EarliestArrival: attributes.earliest_arrival, LatestArrival: attributes.latest_arrival }) +
            '</li>' +
            '<li><b>Service Prohibited:</b> ' +
            renderServiceProhibited({ NoServiceStart: attributes.no_service_start, NoServiceEnd: attributes.no_service_end }) +
            '</li>' +
            '<li><b>Service Day:</b> ' +
            serviceDayLabel +
            '</li>' +
            '<li><b>Week:</b> {week} &nbsp; <b>Month:</b> {month}</li>' +
            '</ul>'
        );
    };

    const raasServiceLocationContent = (feature) => {
        let attributes = feature.graphic.attributes;
        return (
            "<ul class='no-bullets'>" +
            '<li><b>Name: </b>{customer_name}</li>' +
            '<li><b>Account: </b>{account_id}</li>' +
            '<li><b>Route: </b>{route}&nbsp; <b>Sequence:</b>{sequence}</li>' +
            '<li><b>Address: </b>{address}&nbsp; <b>Apt:</b>{apartment}</li>' +
            '<li><b>City: </b>{city}&nbsp; <b>State:</b>{state}&nbsp; <b>ZIP:</b>{postal_code}</li>' +
            '</ul>'
        );
    };

    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    const showROFacilityLayer = (map) => {
        facilityLayer = new MapImageLayer({
            url: ArcgisAPI,
            id: 'FacilityLayer',
            visible: true,
            sublayers: [
                {
                    id: config.FACILITY,
                    title: 'Facility',
                    definitionExpression: workspace.value ? 'workspace_id=' + workspace.value : '0 = 1',
                    popupEnabled: true,
                    popupTemplate: {
                        title: 'Facility',
                        content: facilityContent,
                        outFields: ['*'],
                    },
                },
            ],
        });
        map.add(facilityLayer);
        map.reorder(facilityLayer, 2);
    };

    const showRAASFacilityLayer = (map) => {
        RAASFacilityLayer = new MapImageLayer({
            url: RaaSArcgisAPI,
            id: 'RaasFacilityLayer',
            visible: true,
            sublayers: [
                {
                    id: config.RAAS_FACILITY,
                    title: 'Facility',
                    definitionExpression: selectedScenarioId ? 'processid=' + selectedScenarioId : '0 = 1',
                    popupTemplate: {
                        title: 'Facility',
                        content: facilityContent,
                        outFields: ['*'],
                    },
                },
            ],
        });
        map.add(RAASFacilityLayer);
        map.reorder(RAASFacilityLayer, 2);
    };

    const showROServiceLocationLayer = (map) => {
        serviceLocationLayer = new FeatureLayer({
            url: ArcgisAPI,
            layerId: config.SERVICE_LOCATION,
            title: 'Routes',
            definitionExpression: '0=1',
            visible: false,
            outFields: [
                'gid',
                'address',
                'route',
                'side',
                'earliest_arrival',
                'latest_arrival',
                'no_service_start',
                'no_service_end',
                'service_type',
                'week',
                'month',
            ],
            popupTemplate: {
                title: 'Stops',
                content: serviceLocationContent,
                outFields: slOutFields,
                overwriteActions: true,
                actions: [
                    {
                        id: 'zoomToServiceLocation',
                        className: 'esri-icon-zoom-in-magnifying-glass',
                    },
                    {
                        id: 'updateServiceLocation',
                        className: 'esri-icon-edit',
                    },
                ],
            },
        });
        map.add(serviceLocationLayer);
        map.reorder(serviceLocationLayer, 1);
    };

    const showRAASServiceLocationLayer = (map) => {
        RAASServiceLocationLayer = new FeatureLayer({
            url: RaaSArcgisAPI,
            id: 'RaasSlLayer',
            layerId: config.RAAS_SERVICE_LOCATION,
            title: 'Routes',
            definitionExpression: 'processid=' + selectedScenarioId,
            outFields: ['*'],
            renderer: getRouteRenderer(),
            visible: true,
            popupTemplate: {
                title: 'Stops',
                content: raasServiceLocationContent,
                outFields: slOutFields,
            },
        });
        map.add(RAASServiceLocationLayer);
        map.reorder(RAASServiceLocationLayer, 1);
    };

    const showZoneFacilityLayer = (map) => {
        const template = {
            title: 'Facility',
            content: facilityContent,
            outFields: ['*'],
            overwriteActions: true,
        };

        const zoneFacilityLayer = new MapImageLayer({
            url: ArcgisAPI,
            id: 'FacilityLayer',
            sublayers: [
                {
                    id: 0,
                    title: 'Facility',
                    visible: false,
                    popupTemplate: template,
                    definitionExpression: 'workspace_id=' + workspace.value ? workspace.value : '-1',
                },
                {
                    id: 1,
                    title: 'Facility',
                    visible: false,
                    popupTemplate: template,
                    definitionExpression: 'sandbox_id =' + project ? project : '-1',
                },
            ],
        });
        map.add(zoneFacilityLayer);
        setZoneFacilityLayer(zoneFacilityLayer);
        map.reorder(zoneFacilityLayer, 2);
    };

    const showZoneServiceLocationLayer = (map) => {
        let serviceLocationContent = (feature) => {
            let attributes = feature.graphic.attributes;
            let routeLabel = '',
                serviceDayLabel = '';
            if (attributes.route === '0') {
                routeLabel = 'No Route';
            } else {
                routeLabel = attributes.route;
            }
            if (attributes.service_day === 0) {
                serviceDayLabel = 'No Service Day';
            } else {
                serviceDayLabel = defaultServiceDayList[attributes.service_day].label;
            }

            return (
                "<ul class='no-bullets'>" +
                '<li><b>Name:</b> {customer_name}</li>' +
                '<li><b>Account:</b> {account_id}' +
                ' &nbsp; <b>Address:</b> {address}' +
                ' &nbsp; <b>Apt:</b> {apartment}</li>' +
                '<li><b>City:</b> {city}' +
                ' &nbsp; <b>State:</b> {state}' +
                ' &nbsp; <b>ZIP:</b> {postal_code}</li>' +
                '<li><b>Comments:</b> {comments}</li>' +
                '<li><b>Route:</b> ' +
                routeLabel +
                ' &nbsp; <b>Sequence:</b> {sequence}' +
                ' &nbsp; <b>Frequency Code:</b> {frequency_code}</li>' +
                '<li><b>Service Type:</b> {service_type}' +
                ' &nbsp; <b>Quantity:</b> {quantity}' +
                ' &nbsp; <b>Size:</b> {bin_size}</li>' +
                '<li><b>Demand:</b> {demand}' +
                ' &nbsp; <b>Service Time:</b> ' +
                getFormattedTimeSeconds(attributes.service_time) +
                '</li>' +
                '<li><b>Earliest Arrival:</b> ' +
                getFormattedTime(attributes.earliest_arrival) +
                ' &nbsp; <b>Latest Arrival:</b> ' +
                getFormattedTime(attributes.latest_arrival) +
                '</li>' +
                '<li><b>Service Day:</b> ' +
                serviceDayLabel +
                '</li>' +
                '<li><b>Week:</b> {week} &nbsp; <b>Month:</b> {month}</li>' +
                '</ul>'
            );
        };
        const template = {
            title: 'Stops',
            content: serviceLocationContent,
            outFields: slOutFields,
            overwriteActions: true,
        };

        const zoneServiceLocationLayer = new MapImageLayer({
            url: ArcgisAPI,
            id: 'ServiceLocationLayer',
            sublayers: [
                {
                    id: 2,
                    title: 'Routes',
                    visible: false,
                    popupTemplate: template,
                    definitionExpression: 'workspace_id=' + workspace.value ? workspace.value : '-1',
                    renderer: {
                        type: 'simple',
                        symbol: {
                            type: 'simple-marker',
                            style: 'circle',
                            color: 'white',
                            size: 5,
                            outline: { width: 1, color: 'black' },
                        },
                    },
                },
                {
                    id: 3,
                    title: 'Routes',
                    visible: false,
                    popupTemplate: template,
                    definitionExpression: 'workspace_id=' + workspace.value ? workspace.value : '-1',
                    renderer: {
                        type: 'simple',
                        symbol: {
                            type: 'simple-marker',
                            style: 'circle',
                            color: 'white',
                            size: 5,
                            outline: { width: 1, color: 'black' },
                        },
                    },
                },
            ],
        });
        map.add(zoneServiceLocationLayer);
        setZoneServiceLocationLayer(zoneServiceLocationLayer);
        map.reorder(zoneServiceLocationLayer, 1);
    };

    const showProjectFacilityLayer = (map) => {
        projectFacilityLayer = new MapImageLayer({
            url: ArcgisAPI,
            id: 'FacilityLayer',
            visible: false,
            sublayers: [
                {
                    id: config.SANDBOXFACILITY,
                    title: 'Facility',
                    popupEnabled: true,
                    popupTemplate: {
                        title: 'Facility',
                        content: facilityContent,
                        outFields: ['*'],
                    },
                    definitionExpression: 'sandbox_id =' + project ? project : '-1',
                },
            ],
        });
        map.add(projectFacilityLayer);
        map.reorder(projectFacilityLayer, 2);
    };

    const showProjectServiceLocationLayer = (map) => {
        let serviceLocationContent = (feature) => {
            let attributes = feature.graphic.attributes;

            let routeLabel = '',
                serviceDayLabel = '';
            if (attributes.route === '0') {
                routeLabel = 'No Route';
            } else {
                routeLabel = attributes.route;
            }
            if (attributes.service_day === 0) {
                serviceDayLabel = 'No Service Day';
            } else {
                serviceDayLabel = defaultServiceDayList[attributes.service_day].label;
            }

            return (
                "<ul class='no-bullets'>" +
                '<li><b>Name:</b> {customer_name}</li>' +
                '<li><b>Account:</b> {account_id}' +
                ' &nbsp; <b>Address:</b> {address}' +
                ' &nbsp; <b>Apt:</b> {apartment}</li>' +
                '<li><b>City:</b> {city}' +
                ' &nbsp; <b>State:</b> {state}' +
                ' &nbsp; <b>ZIP:</b> {postal_code}</li>' +
                '<li><b>Comments:</b> {comments}</li>' +
                '<li><b>Route:</b> ' +
                routeLabel +
                ' &nbsp; <b>Sequence:</b> {sequence}' +
                ' &nbsp; <b>Frequency Code:</b> {frequency_code}</li>' +
                '<li><b>Service Type:</b> {service_type}' +
                ' &nbsp; <b>Quantity:</b> {quantity}' +
                ' &nbsp; <b>Size:</b> {bin_size}</li>' +
                '<li><b>Demand:</b> {demand}' +
                ' &nbsp; <b>Service Time:</b> ' +
                getFormattedTimeSeconds(attributes.service_time) +
                '</li>' +
                '<li><b>Earliest Arrival:</b> ' +
                getFormattedTime(attributes.earliest_arrival) +
                ' &nbsp; <b>Latest Arrival:</b> ' +
                getFormattedTime(attributes.latest_arrival) +
                '</li>' +
                '<li><b>Service Day:</b> ' +
                serviceDayLabel +
                '</li>' +
                '<li><b>Week:</b> {week} &nbsp; <b>Month:</b> {month}</li>' +
                '</ul>'
            );
        };

        projectServiceLocationLayer = new FeatureLayer({
            url: ArcgisAPI,
            layerId: config.SANDBOXSERVICELOCATION,
            title: 'Routes',
            definitionExpression: '0=1',
            visible: false,
            outFields: [
                'gid',
                'address',
                'route',
                'side',
                'earliest_arrival',
                'latest_arrival',
                'no_service_start',
                'no_service_end',
                'service_type',
                'week',
                'month',
            ],
            popupTemplate: {
                title: 'Stops',
                content: serviceLocationContent,
                outFields: slOutFields,
                overwriteActions: true,
                actions: [
                    {
                        id: 'zoomToServiceLocation',
                        className: 'esri-icon-zoom-in-magnifying-glass',
                    },
                    // {
                    //     id: "updateServiceLocation",
                    //     className: "esri-icon-edit",
                    // }
                ],
            },
        });
        map.add(projectServiceLocationLayer);
        map.reorder(projectServiceLocationLayer, 1);
    };

    const showServicePatternLayer = (map) => {
        let servicePatternContent = (feature) => {
            let attributes = feature.graphic.attributes;

            let sp = attributes.service_pattern;
            let patternDict = { 1: 'M', 2: 'T', 3: 'W', 4: 'R', 5: 'F', 6: 'S', 7: 'U' };
            let patternLabel = '';
            if (sp.includes('0')) {
                patternLabel = 'No Service Pattern';
            } else {
                patternLabel = sp.substring(1).replace(/[1-7]/gi, (s) => patternDict[s]);
            }

            let route = attributes.route;
            if (route === '0') route = 'No Route';

            return (
                "<ul class='no-bullets'>" +
                '<li><b>Route:</b> ' +
                route +
                '</li>' +
                '<li><b>Service Pattern:</b> ' +
                patternLabel +
                '</li>' +
                '<li><b>Service Pattern Group:</b> {service_pattern_group}</li>' +
                '<li><b>Frequency:</b> {frequency}</li>' +
                '<li><b>Week:</b> {week}</li>' +
                '<li><b>Month:</b> {month}</li>' +
                '</ul>'
            );
        };
        const template = {
            title: 'Service Pattern',
            content: servicePatternContent,
            outFields: ['*'],
            overwriteActions: true,
        };

        let queryParamValue = { workspace_id: workspace.value };
        let paramString = '[{"14": ' + JSON.stringify(queryParamValue) + '}]';

        servicePatternLayer = new MapImageLayer({
            url: ArcgisAPI,
            id: 'servicePatternLayer',
            sublayers: [
                {
                    id: 14,
                    title: 'ServiceDay',
                    visible: false,
                    definitionExpression: `workspace_id=${workspace.value ? workspace.value : -1}`,
                    popupTemplate: template,
                },
            ],
            customParameters: {
                parameterValues: JSON.stringify(queryParamValue),
                layerParameterValues: paramString,
            },
        });
        map.add(servicePatternLayer);

        const printServiceDayLayer = new MapImageLayer({
            url: ArcgisAPI,
            id: 'servicePatternLayer',
            sublayers: [
                {
                    id: 14,
                    title: 'Service Pattern',
                    visible: false,
                    outFields: ['*'],
                    definitionExpression: `workspace_id=${workspace.value ? workspace.value : -1}`,
                },
            ],
            customParameters: {
                layerParameterValues: paramString,
            },
        });
        setPrintServiceDayLayer(printServiceDayLayer);
        map.add(printServiceDayLayer);
    };

    const showProjectServicePatternLayer = (map) => {
        let servicePatternContent = (feature) => {
            let attributes = feature.graphic.attributes;

            let sp = attributes.service_pattern;
            let patternDict = { 1: 'M', 2: 'T', 3: 'W', 4: 'R', 5: 'F', 6: 'S', 7: 'U' };
            let patternLabel = '';
            if (sp.includes('0')) {
                patternLabel = 'No Service Pattern';
            } else {
                patternLabel = sp.substring(1).replace(/[1-7]/gi, (s) => patternDict[s]);
            }
            let route = attributes.route;
            if (route === '0') route = 'No Route';

            return (
                "<ul class='no-bullets'>" +
                '<li><b>Route:</b> ' +
                route +
                '</li>' +
                '<li><b>Service Pattern:</b> ' +
                patternLabel +
                '</li>' +
                '<li><b>Service Pattern Group:</b> {service_pattern_group}</li>' +
                '<li><b>Frequency:</b> {frequency}</li>' +
                '<li><b>Week:</b> {week}</li>' +
                '<li><b>Month:</b> {month}</li>' +
                '</ul>'
            );
        };
        const template = {
            title: 'Service Pattern',
            content: servicePatternContent,
            outFields: ['*'],
            overwriteActions: true,
        };
        let queryParamValue = { sandbox_id: project };
        let paramString = '[{"15": ' + JSON.stringify(queryParamValue) + '}]';

        projectServicePatternLayer = new MapImageLayer({
            url: ArcgisAPI,
            id: 'projectServicePattern',
            sublayers: [
                {
                    id: 15,
                    title: 'projectServicePattern',
                    visible: false,
                    definitionExpression: `sandbox_id=${project ? project : -1}`,
                    outFields: ['*'],
                    popupTemplate: template,
                },
            ],
            customParameters: {
                parameterValues: JSON.stringify(queryParamValue),
                layerParameterValues: paramString,
            },
        });
        map.add(projectServicePatternLayer);

        const printProjectServiceDayLayer = new MapImageLayer({
            url: ArcgisAPI,
            id: 'servicePatternLayer',
            sublayers: [
                {
                    id: 15,
                    title: 'Service Pattern',
                    visible: false,
                    definitionExpression: `sandbox_id=${project ? project : -1}`,
                },
            ],
            customParameters: {
                layerParameterValues: paramString,
            },
        });
        setPrintProjectServiceDayLayer(printProjectServiceDayLayer);
        map.add(printProjectServiceDayLayer);
    };

    const getRouteRenderList = (data, listType) => {
        let rtlist = [];
        if (listType === 'projectRouteList') {
            data = data.toString().split(',');
        } else if (listType === 'routePlanRouteList') {
            data = data.filter((d) => d.Route !== 'Default');
        } else if (listType === 'projectZoneRouteList') {
            data = data.toString().split(',');
        }
        if (data && data.length > 0) {
            data.forEach((v, i) => {
                let svgRouteIcon = '<svg height="10" width="13"> <circle cx="5" cy="5" r="4" stroke="black" stroke-width="1" fill="black" /></svg> ';
                let svgColor = null;
                let svgStyle = 'circle';
                let id = '';
                if (listType === 'scenarioRouteList') {
                    id = v.RouteId;
                } else if (listType === 'projectRouteList' || listType === 'projectZoneRouteList') {
                    id = v;
                } else if (listType === 'routePlanRouteList') {
                    id = v.Route;
                } else {
                    id = v.Id;
                }
                if (id === '' || id === '0' || id === 0) {
                    id = 'No Route';
                } else {
                    let cc = i % 10;
                    let cnt = i % 30;
                    if (cnt < 10) {
                        svgRouteIcon =
                            '<svg height="10" width="13"> <circle cx="5" cy="5" r="4" stroke="black" stroke-width="1" fill="' +
                            colors[cc] +
                            '" /></svg>';
                        svgStyle = 'circle';
                    } else if (cnt < 20) {
                        svgRouteIcon =
                            '<svg height="10" width="13"> <rect width="8" height="8" stroke="black" stroke-width="1" fill="' +
                            colors[cc] +
                            '" /></svg>';
                        svgStyle = 'square';
                    } else {
                        svgRouteIcon =
                            '<svg height="10" width="13"> <polygon points="0,5 5,0 10,5 5,10" stroke="black" stroke-width="1" fill="' +
                            colors[cc] +
                            '" /></svg>';
                        svgStyle = 'diamond';
                    }
                    svgColor = colors[cc];
                }
                let label = id;
                let rt = {
                    Id: id,
                    Label: label,
                    Svg: svgRouteIcon,
                    SvgColor: svgColor,
                    SvgStyle: svgStyle,
                };
                if (listType === 'routeList') {
                    rt.Label = label + ' (' + v.UnmatchedCount + ')';
                    rt.UnmatchedCount = v.UnmatchedCount;
                    rt.Time = getFormattedTime(v.TotalTime);
                    rt.Stops = v.TotalServiceLocations;
                } else if (listType === 'zoneRouteList' || listType === 'routePlanRouteList') {
                    rt.RouteSetId = v.Id;
                }
                rtlist.push(rt);
            });
            rtlist.sort((a, b) => {
                if (isNaN(a['Id']) && isNaN(b['Id'])) {
                    return String('0000000000' + a['Id'])
                        .slice(-10)
                        .localeCompare(String('0000000000' + b['Id']).slice(-10), undefined, { numeric: true, sensitivity: 'base' });
                } else {
                    return String('0000000000' + a['Id'])
                        .slice(-10)
                        .localeCompare(String('0000000000' + b['Id']).slice(-10), undefined, { numeric: false, sensitivity: 'base' });
                }
            });
        }
        return rtlist;
    };

    const getRouteRenderListForAllDays = () => {
        let rtList = [];
        let data = [
            { Id: '1', Label: 'Monday', icon: '#FF0000' },
            { Id: '2', Label: 'Tuesday', icon: '#00FF00' },
            { Id: '3', Label: 'Wednesday', icon: '#4C7300' },
            { Id: '4', Label: 'Thursday', icon: '#00FFFF' },
            { Id: '5', Label: 'Friday', icon: '#0000FF' },
            { Id: '6', Label: 'Saturday', icon: '#770081' },
            { Id: '7', Label: 'Sunday', icon: '#A06632' },
        ];

        data.map((d, i) => {
            const svgRouteIcon =
                '<svg height="10" width="13"> <circle cx="5" cy="5" r="4" stroke="black" stroke-width="1" fill="' + d['icon'] + '" /></svg>';
            rtList.push({
                Id: d.Id,
                Label: d.Label,
                LabelNoCounts: d.Label,
                Svg: svgRouteIcon,
                SvgColor: d['icon'],
                SvgStyle: 'circle',
            });
        });
        return rtList;
    };

    const getRouteRenderer = () => {
        let symbol = {
            type: 'simple-marker',
            color: 'white',
            size: 6,
            style: 'circle',
            outline: {
                width: '0px',
                color: 'white',
            },
        };
        let renderer;
        let rtList = [];

        if (mapMode === 0) {
            if (serviceDay.value === 0) {
                if (routeViewTabCategorization.value.startsWith('R')) {
                    routeViewTableData[routeViewTabCategorization.value].forEach((r) => {
                        //need adjustment here
                        let selected = r.subRows.filter((rs) => rs.checked === true);
                        rtList = [...rtList, ...selected];
                    });
                } else {
                    routeViewTableData[routeViewTabCategorization.value].forEach((r) => {
                        //need adjustment here
                        let selected = r.subRows.filter((rs) => rs.checked === true);
                        rtList = [...rtList, ...selected];
                    });
                }
            } else {
                rtList = structuredClone(routeViewTableData['R']);
                rtList = rtList.filter((r) => r.checked === true);
            }
        }
        if (mapMode === 2) {
            rtList = [...zoneRouteList];
            rtList = rtList.filter((r) => selectedZoneRoutes.includes(r.Id));
        } else if (mapMode === 1) {
            rtList = [...scenarioRouteList];
            rtList = rtList.filter((r) => selectedScenarioRoutes.includes(r.Id));
        }

        if (timeWindowToggle) {
            if (serviceDay.value === 0) {
                if (routeViewTabCategorization.value.startsWith('R')) {
                    //need adjustment here
                    renderer = new UniqueValueRenderer({
                        valueExpression: `
                        if($feature.earliest_arrival > 0 || $feature.latest_arrival > 0 || $feature.no_service_start > 0 || $feature.no_service_end > 0){
                            return 'TIMEWINDOW_' + $feature.route + '|' + $feature.service_day;
                            } else {
                                return $feature.route + '|' + $feature.service_day;
                            }
                        `,
                        defaultSymbol: symbol,
                    });

                    rtList.map((item) => {
                        renderer.addUniqueValueInfo({
                            value: 'TIMEWINDOW_' + item.fullLabel,
                            label: 'Time Window',
                            symbol: {
                                type: 'text',
                                color: new Color(item.SvgColor),
                                text: '\ue676',
                                font: {
                                    size: 14,
                                    family: 'CalciteWebCoreIcons',
                                },
                            },
                        });
                        renderer.addUniqueValueInfo({
                            value: item.fullLabel,
                            label: item.fullLabel
                                ? item.fullLabel
                                      .replace('|1', ', M')
                                      .replace('|2', ', T')
                                      .replace('|3', ', W')
                                      .replace('|4', ', R')
                                      .replace('|5', ', F')
                                      .replace('|6', ', S')
                                      .replace('|7', ', U')
                                : item.Id,
                            symbol: {
                                type: 'simple-marker',
                                color: new Color(item.SvgColor),
                                size: 6,
                                style: item.SvgStyle,
                                outline: {
                                    width: '0.6px',
                                },
                            },
                        });
                    });
                } else {
                    renderer = new UniqueValueRenderer({
                        valueExpression: `
                        if($feature.earliest_arrival > 0 || $feature.latest_arrival > 0 || $feature.no_service_start > 0 || $feature.no_service_end > 0){
                            return 'TIMEWINDOW_' + $feature.service_day + '|' + $feature.route;
                            } else {
                                return $feature.service_day + '|' + $feature.route;
                            }
                        `,
                        defaultSymbol: symbol,
                    });

                    rtList.map((item) => {
                        renderer.addUniqueValueInfo({
                            value: 'TIMEWINDOW_' + item.fullLabel,
                            label: 'Time Window',
                            symbol: {
                                type: 'text',
                                color: new Color(item.SvgColor),
                                text: '\ue676',
                                font: {
                                    size: 14,
                                    family: 'CalciteWebCoreIcons',
                                },
                            },
                        });
                        renderer.addUniqueValueInfo({
                            value: item.fullLabel,
                            label: item.fullLabel
                                ? item.fullLabel
                                      .replace('1|', 'M, ')
                                      .replace('2|', 'T, ')
                                      .replace('3|', 'W, ')
                                      .replace('4|', 'R, ')
                                      .replace('5|', 'F, ')
                                      .replace('6|', 'S')
                                      .replace('7|', 'U, ')
                                : item.Id,
                            symbol: {
                                type: 'simple-marker',
                                color: new Color(item.SvgColor),
                                size: 6,
                                style: item.SvgStyle,
                                outline: {
                                    width: '0.6px',
                                },
                            },
                        });
                    });
                }
            } else {
                renderer = new UniqueValueRenderer({
                    valueExpression: `
                    if($feature.earliest_arrival > 0 || $feature.latest_arrival > 0 || $feature.no_service_start > 0 || $feature.no_service_end > 0){
                        return 'TIMEWINDOW_' + $feature.route;
                        } else {
                            return $feature.route;
                        }
                    `,
                    defaultSymbol: symbol,
                });

                rtList.map((item) => {
                    renderer.addUniqueValueInfo({
                        value: 'TIMEWINDOW_' + (item.Id === 'No Route' ? '0' : item.Id),
                        label: 'Time Window',
                        symbol: {
                            type: 'text',
                            color: new Color(item.SvgColor),
                            text: '\ue676',
                            font: {
                                size: 14,
                                family: 'CalciteWebCoreIcons',
                            },
                        },
                    });
                    renderer.addUniqueValueInfo({
                        //value: item.Id,
                        value: serviceDay.value === 0 ? parseInt(item.Id) : item.Id,
                        label: item.Id === 'No Route' ? '0' : item.Id,
                        symbol: {
                            type: 'simple-marker',
                            color: new Color(item.SvgColor),
                            size: 6,
                            style: item.SvgStyle,
                            outline: {
                                width: '0.6px',
                            },
                        },
                    });
                });
            }
        } else {
            if (mapMode === 0) {
                if (serviceDay.value === 0) {
                    if (routeViewTabCategorization.value.startsWith('R')) {
                        renderer = new UniqueValueRenderer({
                            valueExpression: `$feature.route + '|' + $feature.service_day`,
                            defaultSymbol: symbol,
                        });

                        rtList.forEach((item) => {
                            renderer.addUniqueValueInfo({
                                value: item.fullLabel,
                                label: item.fullLabel
                                    ? item.fullLabel
                                          .replace('|1', ', M')
                                          .replace('|2', ', T')
                                          .replace('|3', ', W')
                                          .replace('|4', ', R')
                                          .replace('|5', ', F')
                                          .replace('|6', ', S')
                                          .replace('|7', ', U')
                                    : item.Id,
                                symbol: {
                                    type: 'simple-marker',
                                    color: new Color(item.SvgColor),
                                    size: 6,
                                    style: item.SvgStyle,
                                    outline: {
                                        width: '0.6px',
                                    },
                                },
                            });
                        });
                    } else {
                        renderer = new UniqueValueRenderer({
                            valueExpression: `$feature.service_day + '|' + $feature.route`,
                            defaultSymbol: symbol,
                        });

                        rtList.forEach((item) => {
                            renderer.addUniqueValueInfo({
                                value: item.fullLabel,
                                label: item.fullLabel
                                    ? item.fullLabel
                                          .replace('1|', 'M, ')
                                          .replace('2|', 'T, ')
                                          .replace('3|', 'W, ')
                                          .replace('4|', 'R, ')
                                          .replace('5|', 'F, ')
                                          .replace('6|', 'S')
                                          .replace('7|', 'U, ')
                                    : item.Id,
                                symbol: {
                                    type: 'simple-marker',
                                    color: new Color(item.SvgColor),
                                    size: 6,
                                    style: item.SvgStyle,
                                    outline: {
                                        width: '0.6px',
                                    },
                                },
                            });
                        });
                    }
                } else {
                    renderer = new UniqueValueRenderer({
                        field: 'route',
                        defaultSymbol: symbol,
                    });

                    rtList.map((item) => {
                        renderer.addUniqueValueInfo({
                            value: item.Id,
                            label: item.Id,
                            symbol: {
                                type: 'simple-marker',
                                color: new Color(item.SvgColor),
                                size: 6,
                                style: item.SvgStyle,
                                outline: {
                                    width: '0.6px',
                                },
                            },
                        });
                    });
                }
            } else {
                renderer = new UniqueValueRenderer({
                    field: serviceDay.value === 0 && sessionStorage.getItem('mdr') !== 'true' ? 'service_day' : 'route',
                    defaultSymbol: symbol,
                });

                rtList.map((item) => {
                    renderer.addUniqueValueInfo({
                        value: serviceDay.value === 0 && sessionStorage.getItem('mdr') !== 'true' ? parseInt(item.Id) : item.Id,
                        label: item.Id,
                        symbol: {
                            type: 'simple-marker',
                            color: new Color(item.SvgColor),
                            size: 6,
                            style: item.SvgStyle,
                            outline: {
                                width: '0.6px',
                            },
                        },
                    });
                });
            }
        }
        return renderer;
    };

    const blockfaceContent = (feature) => {
        const attributes = feature.graphic.attributes;
        const serviceDayLabel = defaultServiceDayList[attributes.service_day].label;
        return (
            "<ul class='no-bullets'>" +
            '<li><b>Route: </b>{route}&nbsp; <b>Service Day: </b>' +
            serviceDayLabel +
            '</li>' +
            '<li><b>Link ID: </b>{link_id}&nbsp; <b>Side: </b>{side}</li>' +
            '<li><b>Street Name: </b>{st_name}</li>' +
            '<li><b>Left ZIP: </b>{l_postcode}&nbsp; <b>Right ZIP:</b>{r_postcode}</li>' +
            '<li><b>Left Range: </b>{l_refaddr} - {l_nrefaddr}&nbsp; <b>Right Range:</b>{r_refaddr} - {r_nrefaddr}</li>' +
            '<li><b>Meander: </b>{meander}</li>' +
            '</ul>'
        );
    };

    const showRouteBlockfaceLayer = () => {
        let queryParamValue = { workspace_id: workspace.value };
        let paramString = '[{"17": ' + JSON.stringify(queryParamValue) + '}]';

        if (map) map.remove(map.findLayerById('routeBlockfaceLayer'));
        if (map) map.remove(map.findLayerById('sandboxRouteBlockfaceLayer'));
        routeBlockfaceLayer = null;
        sandboxRouteBlockfaceLayer = null;

        routeBlockfaceLayer = new MapImageLayer({
            url: ArcgisAPI,
            id: 'routeBlockfaceLayer',
            visible: true,
            sublayers: [
                {
                    id: config.ROUTEBLOCKFACE,
                    title: 'Route Blockface Layer',
                    popupEnabled: true,
                    popupTemplate: {
                        title: 'Blockface',
                        content: blockfaceContent,
                        outFields: ['*'],
                    },
                    definitionExpression: 'workspace_id=' + workspace.value,
                    renderer: getBlockfaceRenderer(),
                },
            ],
            customParameters: {
                parameterValues: JSON.stringify(queryParamValue),
                layerParameterValues: paramString,
            },
        });
        map.add(routeBlockfaceLayer);
    };

    const showSandboxRouteBlockfaceLayer = () => {
        let queryParamValue = { sandbox_id: project };
        let paramString = '[{"18": ' + JSON.stringify(queryParamValue) + '}]';

        if (map) map.remove(map.findLayerById('routeBlockfaceLayer'));
        if (map) map.remove(map.findLayerById('sandboxRouteBlockfaceLayer'));
        routeBlockfaceLayer = null;
        sandboxRouteBlockfaceLayer = null;

        sandboxRouteBlockfaceLayer = new MapImageLayer({
            url: ArcgisAPI,
            id: 'sandboxRouteBlockfaceLayer',
            visible: true,
            sublayers: [
                {
                    id: config.SANDBOXROUTEBLOCKFACE,
                    title: 'Sandbox Route Blockface Layer',
                    popupEnabled: true,
                    popupTemplate: {
                        title: 'Blockface',
                        content: blockfaceContent,
                        outFields: ['*'],
                    },
                    definitionExpression: 'sandbox_id=' + project,
                    renderer: getBlockfaceRenderer(),
                },
            ],
            customParameters: {
                parameterValues: JSON.stringify(queryParamValue),
                layerParameterValues: paramString,
            },
        });
        map.add(sandboxRouteBlockfaceLayer);
    };

    const refreshRouteBlockfaceLayer = () => {
        let qryStr = '',
            hide = false;
        if (serviceDay.value === 0) {
            if (routeViewTabCategorization.value.startsWith('R')) {
                if (routeViewTableData[routeViewTabCategorization.value].filter((r) => r.checked === true || r.indeterminate === true).length > 0) {
                    qryStr += ' and (';
                    routeViewTableData[routeViewTabCategorization.value].forEach((r) => {
                        if (r.checked === true || r.indeterminate === true) {
                            qryStr += "(route='" + r.Id + "'";
                            let sd = r.subRows
                                .filter((rs) => rs.checked === true)
                                .map((rs) => parseInt(rs.Id))
                                .toString();
                            qryStr += ' and service_day in (' + sd + ')) or ';
                        }
                    });
                    qryStr = qryStr.slice(0, -4);
                    qryStr += ')';
                } else {
                    hide = true;
                }
            } else {
                if (routeViewTableData[routeViewTabCategorization.value].filter((r) => r.checked === true || r.indeterminate === true).length > 0) {
                    qryStr += ' and (';
                    routeViewTableData[routeViewTabCategorization.value].forEach((r) => {
                        if (r.checked === true || r.indeterminate === true) {
                            qryStr += '(service_day=' + parseInt(r.Id);
                            let selected = r.subRows.filter((rs) => rs.checked === true).map((rs) => rs.Id);
                            let routes = selected.join("','").replace('No Route', '0');
                            qryStr += " and route in ('" + routes + "')) or ";
                        }
                    });
                    qryStr = qryStr.slice(0, -4);
                    qryStr += ')';
                } else {
                    hide = true;
                }
            }
        } else {
            let selected = routeViewTableData['R'].filter((r) => r.checked === true).map((r) => r.Id);
            if (selected.length > 0) {
                let routes = selected.join("','").replace('No Route', '0');
                qryStr += ' and service_day=' + serviceDay.value + " and route in ('" + routes + "')";
            } else {
                hide = true;
            }
        }
        if (siteMode === 'DISPATCHER') {
            if (map) {
                showRouteBlockfaceLayer();
                if (hide) {
                    routeBlockfaceLayer.visible = false;
                } else {
                    qryStr = 'workspace_id=' + workspace.value + qryStr;
                    routeBlockfaceLayer.findSublayerById(17).definitionExpression = qryStr;
                    routeBlockfaceLayer.findSublayerById(17).renderer = getBlockfaceRenderer();
                    routeBlockfaceLayer.visible = true;
                    routeBlockfaceLayer.refresh();
                }
            }
        } else if (siteMode === 'PLANNER') {
            if (map) {
                showSandboxRouteBlockfaceLayer();
                if (hide) {
                    sandboxRouteBlockfaceLayer.visible = false;
                } else {
                    sandboxRouteBlockfaceLayer.visible = true;
                    qryStr = 'sandbox_id=' + project + qryStr;
                    sandboxRouteBlockfaceLayer.findSublayerById(18).definitionExpression = qryStr;
                    sandboxRouteBlockfaceLayer.findSublayerById(18).renderer = getBlockfaceRenderer();
                    sandboxRouteBlockfaceLayer.refresh();
                }
            }
        }
    };

    const getBlockfaceRenderer = () => {
        let symbol = {
            type: 'simple-line',
            color: 'black',
            width: 5,
            style: 'solid',
        };
        let renderer;
        let rtList = [];

        if (serviceDay.value === 0) {
            if (routeViewTabCategorization.value.startsWith('R')) {
                routeViewTableData[routeViewTabCategorization.value].forEach((r) => {
                    let selected = r.subRows.filter((rs) => rs.checked === true);
                    rtList = [...rtList, ...selected];
                });
            } else {
                routeViewTableData[routeViewTabCategorization.value].forEach((r) => {
                    let selected = r.subRows.filter((rs) => rs.checked === true);
                    rtList = [...rtList, ...selected];
                });
            }
        } else {
            rtList = structuredClone(routeViewTableData['R']);
            rtList = rtList.filter((r) => r.checked === true);
        }

        if (serviceDay.value === 0) {
            if (routeViewTabCategorization.value.startsWith('R')) {
                renderer = new UniqueValueRenderer({
                    valueExpression: `$feature.route + '|' + $feature.service_day`,
                    defaultSymbol: symbol,
                });

                rtList.forEach((item) => {
                    renderer.addUniqueValueInfo({
                        value: item.fullLabel,
                        label: item.fullLabel
                            ? item.fullLabel
                                  .replace('|1', ', M')
                                  .replace('|2', ', T')
                                  .replace('|3', ', W')
                                  .replace('|4', ', R')
                                  .replace('|5', ', F')
                                  .replace('|6', ', S')
                                  .replace('|7', ', U')
                            : item.Id,
                        symbol: {
                            type: 'simple-line',
                            color: new Color(item.SvgColor),
                            width: 5,
                            style: 'solid',
                        },
                    });
                });
            } else {
                renderer = new UniqueValueRenderer({
                    valueExpression: `$feature.service_day + '|' + $feature.route`,
                    defaultSymbol: symbol,
                });

                rtList.forEach((item) => {
                    renderer.addUniqueValueInfo({
                        value: item.fullLabel,
                        label: item.fullLabel
                            ? item.fullLabel
                                  .replace('1|', 'M, ')
                                  .replace('2|', 'T, ')
                                  .replace('3|', 'W, ')
                                  .replace('4|', 'R, ')
                                  .replace('5|', 'F, ')
                                  .replace('6|', 'S')
                                  .replace('7|', 'U, ')
                            : item.Id,
                        symbol: {
                            type: 'simple-line',
                            color: new Color(item.SvgColor),
                            width: 5,
                            style: 'solid',
                        },
                    });
                });
            }
        } else {
            renderer = new UniqueValueRenderer({
                field: 'route',
                defaultSymbol: symbol,
            });

            rtList.map((item) => {
                renderer.addUniqueValueInfo({
                    value: item.Id,
                    label: item.Id,
                    symbol: {
                        type: 'simple-line',
                        color: new Color(item.SvgColor),
                        width: 5,
                        style: 'solid',
                    },
                });
            });
        }

        return renderer;
    };

    const refreshScenarioRouteList = (skipZoom) => {
        getRouteSummary(workspace.value, selectedScenarioId).then(
            (res) => {
                if (res.status === 200 && res.data.length > 0) {
                    setScenarioRouteSummary(res.data);
                    let rtlist = getRouteRenderList(res.data, 'scenarioRouteList');
                    setScenarioRouteList(rtlist);
                    let selScenarioRoutes = [];
                    rtlist.map((row) => {
                        selScenarioRoutes.push(row.Id);
                    });
                    setSelectedScenarioRoutes(selScenarioRoutes);
                    if (mapMode === 1 && !skipZoom && selScenarioRoutes.length > 0) {
                        handleZoomToSelectedScenarioRoutes(selScenarioRoutes);
                    }
                } else {
                    setScenarioRouteList([]);
                }
            },
            (err) => {
                setScenarioRouteList([]);
                setSelectedScenarioRoutes([]);
            },
        );
    };

    const refreshScenarioList = (setScenario) => {
        getDispatchHistory(workspace.value, serviceDay.value).then(
            (res) => {
                if (res.status === 200 && res.data.length > 0) {
                    let list = [];
                    res.data.map((item) => {
                        list.push({
                            ...item,
                            label: `${item.Action} ${item.OldRoutes} ${serviceDayMap[item.ServiceDay]}`,
                            value: item.ID,
                        });
                    });
                    setScenarioList(list);
                    if (setScenario) {
                        setSelectedScenarioId(list[0].RaaSJobID);
                    } else {
                        setSelectedScenarioId(null);
                    }
                } else {
                    setScenarioList([]);
                    setScenarioRouteList([]);
                    setSelectedScenarioId(null);
                }
            },
            (err) => {
                setScenarioList([]);
                setScenarioRouteList([]);
                setSelectedScenarioId(null);
            },
        );

        let flist = [].concat(dispatchStartList);
        getFacilities(siteMode, workspace.value, project).then(
            (res) => {
                if (res.status === 200 && res.data.length > 0) {
                    res.data
                        .filter((d) => d.StartEnd)
                        .map((item) => {
                            flist.push({
                                Label: item.Name,
                                Id: item.Id,
                            });
                        });
                    setDispatchFacilityList(flist);
                }
            },
            (err) => {
                setDispatchFacilityList(flist);
            },
        );
    };

    const refreshRoutePlanList = () => {
        getRoutePlans(workspace.value, siteMode, project).then(
            (res) => {
                setRoutePlans(res.data);
                let routePlan;
                if (res && res.data.length > 0) {
                    let today = new Date().getDay();
                    switch (today) {
                        case 1:
                            routePlan = res.data.find((d) => d.ServDayMonday === true);
                            break;
                        case 2:
                            routePlan = res.data.find((d) => d.ServDayTuesday === true);
                            break;
                        case 3:
                            routePlan = res.data.find((d) => d.ServDayWednesday === true);
                            break;
                        case 4:
                            routePlan = res.data.find((d) => d.ServDayThursday === true);
                            break;
                        case 5:
                            routePlan = res.data.find((d) => d.ServDayFriday === true);
                            break;
                        case 6:
                            routePlan = res.data.find((d) => d.ServDaySaturday === true);
                            break;
                        case 7:
                            routePlan = res.data.find((d) => d.ServDaySunday === true);
                            break;
                        default:
                            routePlan = res.data[0];
                    }
                    let rpList = [];
                    res.data.sort((a, b) => a.PlanName.localeCompare(b.PlanName, 'en', { numeric: true }));
                    res.data.map((rp) => {
                        let routeCount = '- -';
                        if (rp.RouteCount > 0) {
                            routeCount = rp.RouteCount;
                        }
                        rpList.push({
                            label: rp.PlanName + ' (' + routeCount + ')',
                            value: rp.Id,
                            GenerateMethod: rp.GenerateMethod,
                            Zones: rp.Zones,
                            NumRoutes: rp.NumRoutes,
                            RouteCount: rp.RouteCount,
                        });
                    });

                    setRoutePlanList(rpList);

                    let t = { ...createRoutesFormData };
                    let rss = { ...routeStatsSettings };
                    rss.serviceDay = serviceDay.value;
                    let ss = { ...solverSettings };
                    if (routePlan) {
                        t.RoutePlan = routePlan.Id;
                        t.GenerateMethod = routePlan.GenerateMethod;
                        //t.Zones = routePlan.Zones;
                        t.NumRoutes = routePlan.NumRoutes;
                        rss.routePlan = routePlan.Id;
                        ss.routePlan = routePlan.Id;

                        ss.method = routePlan.GenerateMethod;
                        if (routePlan.RouteCount < 1) {
                            ss.zones = 'Off';
                            t.Zones = 'Off';
                        } else {
                            t.Zones = routePlan.Zones;
                            ss.zones = routePlan.Zones ? routePlan.Zones : 'Off';
                        }
                        if (routePlan.GenerateMethod === 'Number') {
                            ss.number = routePlan.NumRoutes.toString();
                        } else {
                            ss.number = '';
                        }
                    }
                    setCreateRoutesFormData(t);
                    setRouteStatsSettings(rss);
                    setSolverSettings(ss);
                }
            },
            () => {
                setRoutePlanId(null);
                setRoutePlanList([]);
                updateCreateRoutesFormData('RoutePlan', null);
            },
        );
    };

    const refreshServiceLocationList = async () => {
        if (workspace && serviceDay) {
            getServiceLocations(workspace.value, serviceDay.value, siteMode, project)
                .then((res) => {
                    if (res && res.data) {
                        setServiceLocationData(res.data);
                    } else {
                        setServiceLocationData([]);
                    }
                })
                .catch((err) => {
                    setServiceLocationData([]);
                });
            try {
                setIsRouteViewTabLoading(true);
                let resRouteDay = await getRouteDay(workspace.value, siteMode, project);
                if (Array.isArray(resRouteDay?.data)) {
                    resRouteDay.data.sort((a, b) => a.Route.localeCompare(b.Route, 'en', { numeric: true }));
                }
                if (Array.isArray(resRouteDay?.data) && serviceDay.value) {
                    resRouteDay.data = resRouteDay.data.filter((v) => v.Day === serviceDay.value);
                }
                let dict = createRouteTabViewTableData(Array.isArray(resRouteDay?.data) ? resRouteDay?.data : []);
                setRouteViewTableData(dict);
            } catch (err) {
                setRouteViewTableData(initialRouteViewTableData);
            } finally {
                setIsRouteViewTabLoading(false);
            }
        }
    };

    const refreshServiceLocationListAfterPin = async () => {
        getServiceLocations(workspaceId.current.value, selectedServiceDay.current.value, siteMode, project)
            .then((res) => {
                if (res && res.data) {
                    setServiceLocationData(res.data);
                } else {
                    setServiceLocationData([]);
                }
            })
            .catch((err) => {
                setServiceLocationData([]);
            });

        try {
            setIsRouteViewTabLoading(true);
            let resRouteDay = await getRouteDay(workspaceId.current.value, siteMode, project);
            if (Array.isArray(resRouteDay?.data)) {
                resRouteDay.data.sort((a, b) => a.Route.localeCompare(b.Route, 'en', { numeric: true }));
            }
            if (Array.isArray(resRouteDay?.data) && selectedServiceDay.current.value) {
                resRouteDay.data = resRouteDay.data.filter((v) => v.Day === selectedServiceDay.current.value);
            }

            //from the newly created object from calling the api: /routedays, apply the checked dictionary
            let dict = createRouteTabViewTableData(
                Array.isArray(resRouteDay?.data) ? resRouteDay?.data : [],
                //selectedServiceDay.current.value
            );

            //previous state
            let prev = {};

            setRouteViewTableData((rsd) => {
                prev = rsd;
                return rsd;
            });

            for (let k in dict) {
                dict[k] = dict[k]
                    .map((v) => {
                        if (v.subRows) {
                            return {
                                ...v,
                                subRows: v.subRows.map((vv) => {
                                    let checked = true;
                                    try {
                                        let search = v.subRows.find((el) => el.Id === vv.Id);
                                        checked = search.checked;
                                    } catch {}

                                    return {
                                        ...vv,
                                        checked,
                                    };
                                }),
                            };
                        } else {
                            let checked = true;
                            try {
                                let search = prev[k].find((el) => el.Id === v.Id);
                                checked = search.checked;
                            } catch {}

                            return {
                                ...v,
                                checked,
                            };
                        }
                    })
                    .map((v) => {
                        if (v.subRows) {
                            return {
                                ...v,
                                checked: v.subRows.every((el) => el.checked),
                                indeterminate: !v.subRows.every((el) => el.checked) && !v.subRows.every((el) => el.checked === false),
                            };
                        }
                    });
            }
            setRouteViewTableData(dict);
        } catch (err) {
            setRouteViewTableData(initialRouteViewTableData);
        } finally {
            setIsRouteViewTabLoading(false);
        }
    };

    const refreshFacilityLayer = () => {
        if (siteMode === 'DISPATCHER') {
            facilityLayer.refresh();
        } else {
            projectFacilityLayer.refresh();
        }
    };

    const refreshRouteSummary = () => {
        getRouteSummaries(workspace.value, serviceDay.value, siteMode, project).then(
            (res) => {
                setRouteSummaryData(res.data);
            },
            (err) => {
                setRouteSummaryData([]);
            },
        );
        getWorkspaceServiceDayHealthScore(workspace.value, serviceDay.value, siteMode, project).then(
            (res) => {
                setHealthScore(res.data);
            },
            (err) => {
                setHealthScore([]);
            },
        );
    };

    const refreshTrips = () => {
        getTrips(workspace.value, siteMode, project).then(
            (res) => {
                setTripsData(res.data);
            },
            (err) => {
                setTripsData([]);
            },
        );
    };

    const setHomeWidgetExtent = (extString) => {
        let routeExtent = extString.split(',');
        let minX = routeExtent[0].trim();
        let minY = routeExtent[1].trim();
        let maxX = routeExtent[2].trim();
        let maxY = routeExtent[3].trim();
        let extent = new Extent({ xmin: minX, ymin: minY, xmax: maxX, ymax: maxY });
        let vp = new Viewpoint({ targetGeometry: extent });
        if (homeWidget) homeWidget.viewpoint = vp;
        if (view) view.goTo(vp);
    };

    const refreshServiceLocationsInMap = () => {
        let layer = serviceLocationLayer;
        if (siteMode === 'PLANNER') {
            layer = projectServiceLocationLayer;
        }
        if (map && layer) {
            let qryStr = 'workspace_id=' + workspace.value;
            if (siteMode === 'PLANNER') {
                qryStr = 'sandbox_id=' + project;
            }
            let hide = false;
            if (serviceDay.value === 0) {
                if (routeViewTabCategorization.value.startsWith('R')) {
                    if (
                        routeViewTableData[routeViewTabCategorization.value].filter((r) => r.checked === true || r.indeterminate === true).length > 0
                    ) {
                        qryStr += ' and (';
                        routeViewTableData[routeViewTabCategorization.value].forEach((r) => {
                            if (r.checked === true || r.indeterminate === true) {
                                qryStr += "(route='" + r.Id + "'";
                                let sd = r.subRows
                                    .filter((rs) => rs.checked === true)
                                    .map((rs) => parseInt(rs.Id))
                                    .toString();
                                qryStr += ' and service_day in (' + sd + ')) or ';
                            }
                        });
                        qryStr = qryStr.slice(0, -4);
                        qryStr += ')';
                    } else {
                        hide = true;
                    }
                } else {
                    if (
                        routeViewTableData[routeViewTabCategorization.value].filter((r) => r.checked === true || r.indeterminate === true).length > 0
                    ) {
                        //need adjustment here
                        qryStr += ' and (';
                        routeViewTableData[routeViewTabCategorization.value].forEach((r) => {
                            //need adjustment here
                            if (r.checked === true || r.indeterminate === true) {
                                qryStr += '(service_day=' + parseInt(r.Id);
                                let selected = r.subRows.filter((rs) => rs.checked === true).map((rs) => rs.Id);
                                let routes = selected.join("','").replace('No Route', '0');
                                qryStr += " and route in ('" + routes + "')) or ";
                            }
                        });
                        qryStr = qryStr.slice(0, -4);
                        qryStr += ')';
                    } else {
                        hide = true;
                    }
                }
            } else {
                let selected = routeViewTableData['R'].filter((r) => r.checked === true).map((r) => r.Id); //need adjustment here
                let routes = selected.join("','").replace('No Route', '0');
                qryStr += ' and service_day=' + serviceDay.value + " and route in ('" + routes + "')";
            }
            if (week) {
                switch (week) {
                    case '1':
                        qryStr += ` and (week='1' or week is null)`;
                        break;
                    case '2':
                        qryStr += ` and (week='2' or week is null)`;
                        break;
                    case '3':
                        qryStr += ` and ((week='3' or week is null) or (week='1' and month is null))`;
                        break;
                    case '4':
                        qryStr += ` and ((week='4' or week is null) or (week='2' and month is null))`;
                        break;
                    case '5':
                        qryStr += ` and (week='1' or week is null or week='5')`;
                        break;
                    case '6':
                        qryStr += ` and (week='2' or week is null or week='6')`;
                        break;
                    case '7':
                        qryStr += ` and ((week='3' or week is null or week='7') or (week='1' and month is null))`;
                        break;
                    case '8':
                        qryStr += ` and ((week='4' or week is null or week='8') or (week='2' and month is null))`;
                        break;
                    default:
                        qryStr += ` and week=${week}`;
                        break;
                }
            }
            if (frequency) {
                switch (frequency) {
                    case '8':
                        qryStr += ` and week is null`;
                        break;
                    case '9':
                        qryStr += ` and week is not null and month is null`;
                        break;
                    case '10':
                        qryStr += ` and week>='1' and week<='4' and month='1'`;
                        break;
                    case '11':
                        qryStr += ` and week>'4'`;
                        break;
                    default:
                        break;
                }
            }
            if (siteMode === 'PLANNER') {
                projectServiceLocationQuery = qryStr;
            } else {
                serviceLocationQuery = qryStr;
            }
            layer.definitionExpression = qryStr;
            if (!servicePatternView) {
                layer.visible = !hide;
            }
            if (siteMode === 'PLANNER') {
                projectServiceLocationLayer.refresh();
            } else {
                serviceLocationLayer.refresh();
            }
            if (blockFaceLayerVisbility) refreshRouteBlockfaceLayer();
        }
    };

    const refreshScenarioServiceLocationsInMap = () => {
        if (map && RAASServiceLocationLayer) {
            let queryStr = '1 = 0';
            if (selectedScenarioRoutes.length > 0) {
                queryStr = 'processid=' + selectedScenarioId;
                if (selectedScenarioRoutes.length === 1) {
                    if (selectedScenarioRoutes[0] === '0' || selectedScenarioRoutes[0] === 'No Route') {
                        queryStr += " and ( route='' or route is null or route='0' )";
                    } else {
                        queryStr += " and route='" + selectedScenarioRoutes[0].replace(/'/g, "''") + "'";
                    }
                } else if (selectedScenarioRoutes.length > 1) {
                    if (selectedScenarioRoutes[0] === '0' || selectedScenarioRoutes[0] === 'No Route') {
                        queryStr += " and ( route='' or route is null or route='0'";
                    } else {
                        queryStr += " and ( route='" + selectedScenarioRoutes[0].replace(/'/g, "''") + "'";
                    }
                    for (var i = 1; i < selectedScenarioRoutes.length; i++) {
                        if (selectedScenarioRoutes[i] === '0' || selectedScenarioRoutes[i] === 'No Route') {
                            queryStr += " or route='' or route is null or route='0'";
                        } else {
                            queryStr += " or route='" + selectedScenarioRoutes[i].replace(/'/g, "''") + "'";
                        }
                    }
                    queryStr += ' )';
                }
                RaaSServiceLocationQuery = queryStr;
                RAASServiceLocationLayer.definitionExpression = queryStr;
                RAASServiceLocationLayer.visible = mapMode === 1;
                RAASServiceLocationLayer.refresh();
            } else {
                RaaSServiceLocationQuery = '1 = 0';
                RAASServiceLocationLayer.definitionExpression = '1 = 0';
                RAASServiceLocationLayer.visible = false;
                RAASServiceLocationLayer.refresh();
            }
        }
    };

    const refreshServicePatternInMap = () => {
        let hide = false;
        let queryParamValue = { workspace_id: workspace.value };
        let paramString = '[{"14": ' + JSON.stringify(queryParamValue) + '}]';
        let qryStr = `workspace_id=${workspace.value}`;
        if (siteMode === 'PLANNER') {
            queryParamValue = { sandbox_id: project };
            paramString = '[{"15": ' + JSON.stringify(queryParamValue) + '}]';
            qryStr = `sandbox_id=${project}`;
        }
        if (week) {
            switch (week) {
                case '1':
                    qryStr += ` and (week='1' or week is null)`;
                    break;
                case '2':
                    qryStr += ` and (week='2' or week is null)`;
                    break;
                case '3':
                    qryStr += ` and ((week='3' or week is null) or (week='1' and month is null))`;
                    break;
                case '4':
                    qryStr += ` and ((week='4' or week is null) or (week='2' and month is null))`;
                    break;
                case '5':
                    qryStr += ` and (week='1' or week is null or week='5')`;
                    break;
                case '6':
                    qryStr += ` and (week='2' or week is null or week='6')`;
                    break;
                case '7':
                    qryStr += ` and ((week='3' or week is null or week='7') or (week='1' and month is null))`;
                    break;
                case '8':
                    qryStr += ` and ((week='4' or week is null or week='8') or (week='2' and month is null))`;
                    break;
                default:
                    qryStr += ` and week=${week}`;
                    break;
            }
        }
        if (frequency) {
            switch (frequency) {
                case '8':
                    qryStr += ` and week is null`;
                    break;
                case '9':
                    qryStr += ` and week is not null and month is null`;
                    break;
                case '10':
                    qryStr += ` and week>='1' and week<='4' and month='1'`;
                    break;
                case '11':
                    qryStr += ` and week>'4'`;
                    break;
                default:
                    qryStr += ` and frequency=${frequency}`;
                    break;
            }
        }
        if (servicePattern && servicePattern.length > 0) {
            qryStr += ` and service_pattern like '_%${servicePattern.toString().replace(/,/g, '%')}%'`;
        }
        if (serviceDay.value === 0) {
            if (routeViewTabCategorization.value.startsWith('R')) {
                if (routeViewTableData[routeViewTabCategorization.value].filter((r) => r.checked === true || r.indeterminate === true).length > 0) {
                    //need adjustment here
                    qryStr += ' and (';
                    routeViewTableData[routeViewTabCategorization.value].forEach((r) => {
                        //need adjustment here
                        if (r.checked === true || r.indeterminate === true) {
                            qryStr += "(route='" + r.Id + "'";
                            let sd = r.subRows.filter((rs) => rs.checked === true).map((rs) => rs.Id);
                            qryStr += ' and (';
                            sd.forEach((s) => {
                                qryStr += `service_pattern like '_%${s.toString().replace(/,/g, '%')}%' or `;
                            });
                            qryStr = qryStr.slice(0, -4);
                            qryStr += ')) or ';
                        }
                    });
                    qryStr = qryStr.slice(0, -4);
                    qryStr += ')';
                } else {
                    hide = true;
                }
            } else {
                if (routeViewTableData[routeViewTabCategorization.value].filter((r) => r.checked === true || r.indeterminate === true).length > 0) {
                    //need adjustment here
                    qryStr += ' and (';
                    routeViewTableData[routeViewTabCategorization.value].forEach((r) => {
                        //need adjustment here
                        if (r.checked === true || r.indeterminate === true) {
                            qryStr += `(service_pattern like '_%${r.Id.toString().replace(/,/g, '%')}%'`;
                            let selected = r.subRows.filter((rs) => rs.checked === true).map((rs) => rs.Id);
                            let routes = selected.join("','").replace('No Route', '0');
                            qryStr += " and route in ('" + routes + "')) or ";
                        }
                    });
                    qryStr = qryStr.slice(0, -4);
                    qryStr += ')';
                } else {
                    hide = true;
                }
            }
        } else {
            let routes = structuredClone(routeViewTableData['R'])
                .filter((r) => r.checked === true)
                .map((r) => r.Id); //need adjustment here
            routes = routes.join("','").replace('No Route', '0');
            qryStr += ` and service_pattern like '_%${serviceDay.value.toString()}%'`;
            qryStr += " and route in ('" + routes + "')";
        }
        if (siteMode === 'DISPATCHER') {
            if (map && servicePatternLayer) {
                servicePatternQuery = qryStr;
                servicePatternLayer.findSublayerById(14).definitionExpression = qryStr;
                printServiceDayLayer.findSublayerById(14).definitionExpression = qryStr;
                servicePatternLayer.customParameters.parameterValues = JSON.stringify(queryParamValue);
                servicePatternLayer.customParameters.layerParameterValues = paramString;
                printServiceDayLayer.customParameters.layerParameterValues = paramString;
                servicePatternLayer.findSublayerById(14).visible = !hide;
                projectServicePatternLayer.findSublayerById(15).visible = false;
                servicePatternLayer.refresh();
            }
        } else {
            if (map && projectServicePatternLayer) {
                projectServicePatternQuery = qryStr;
                projectServicePatternLayer.findSublayerById(15).definitionExpression = qryStr;
                printProjectServiceDayLayer.findSublayerById(15).definitionExpression = qryStr;
                projectServicePatternLayer.customParameters.parameterValues = JSON.stringify(queryParamValue);
                projectServicePatternLayer.customParameters.layerParameterValues = paramString;
                printProjectServiceDayLayer.customParameters.layerParameterValues = paramString;
                projectServicePatternLayer.findSublayerById(15).visible = !hide;
                servicePatternLayer.findSublayerById(14).visible = false;
                projectServicePatternLayer.refresh();
            }
        }
    };

    const selMapToolChangeHandler = (newValue) => {
        switch (newValue) {
            case 'GeocodeFacilities':
                if (bottomPaneVisibility && bottomPaneGrid === 'FacilityList') {
                    setBottomPaneVisibility(false);
                    setBottomPaneGrid(null);
                } else if (bottomPaneVisibility) {
                    setBottomPaneGrid('FacilityList');
                } else {
                    setBottomPaneVisibility(true);
                    setBottomPaneGrid('FacilityList');
                }
                break;
            case 'GeocodeServiceLocations':
                if (bottomPaneVisibility && bottomPaneGrid === 'ServiceLocationList') {
                    setBottomPaneVisibility(false);
                    setBottomPaneGrid(null);
                } else if (bottomPaneVisibility) {
                    setBottomPaneGrid('ServiceLocationList');
                } else {
                    setBottomPaneVisibility(true);
                    setBottomPaneGrid('ServiceLocationList');
                }
                break;
            case 'RouteSummary':
                if (bottomPaneVisibility && bottomPaneGrid === 'RouteSummary') {
                    setBottomPaneVisibility(false);
                    setBottomPaneGrid(null);
                } else if (bottomPaneVisibility) {
                    setBottomPaneGrid('RouteSummary');
                    refreshRouteSummary();
                } else {
                    setBottomPaneVisibility(true);
                    setBottomPaneGrid('RouteSummary');
                    refreshRouteSummary();
                }
                break;
            case 'ProjectGrid':
                if (bottomPaneVisibility && bottomPaneGrid === 'ProjectGrid') {
                    setBottomPaneVisibility(false);
                    setBottomPaneGrid(null);
                } else if (bottomPaneVisibility) {
                    setBottomPaneGrid('ProjectGrid');
                } else {
                    setBottomPaneVisibility(true);
                    setBottomPaneGrid('ProjectGrid');
                }
                break;
            case 'ScenarioGrid':
                if (bottomPaneVisibility && bottomPaneGrid === 'ScenarioGrid') {
                    setBottomPaneVisibility(false);
                    setBottomPaneGrid(null);
                } else if (bottomPaneVisibility) {
                    setBottomPaneGrid('ScenarioGrid');
                } else {
                    setBottomPaneVisibility(true);
                    setBottomPaneGrid('ScenarioGrid');
                }
                break;
            case 'ReportSelRoutes':
                if (showExport) {
                    setShowExport(!showExport);
                }
                setShowReport(!showReport);
                break;
            case 'ExportSelRoutes':
                if (showReport) {
                    setShowReport(!showReport);
                }
                setShowExport(!showExport);
                break;
            default:
                break;
        }
    };

    const selScenarioMapToolChangeHandler = (newValue) => {
        setSelectedRaaSServiceLocationIds([]);
        switch (newValue) {
            case 'ScenarioRouteSummary':
                if (bottomPaneVisibility && bottomPaneGrid === 'ScenarioRouteSummary') {
                    setBottomPaneVisibility(false);
                    setBottomPaneGrid(null);
                } else if (bottomPaneVisibility) {
                    setBottomPaneGrid('ScenarioRouteSummary');
                } else {
                    setBottomPaneVisibility(true);
                    setBottomPaneGrid('ScenarioRouteSummary');
                }
                break;
            case 'ScenarioServiceLocation':
                if (bottomPaneVisibility && bottomPaneGrid === 'ScenarioServiceLocation') {
                    setBottomPaneVisibility(false);
                    setBottomPaneGrid(null);
                } else if (bottomPaneVisibility) {
                    setBottomPaneGrid('ScenarioServiceLocation');
                    setRefreshScenarioServiceLocation(true);
                } else {
                    setBottomPaneVisibility(true);
                    setBottomPaneGrid('ScenarioServiceLocation');
                    setRefreshScenarioServiceLocation(true);
                }
                break;
            default:
                break;
        }
    };

    const handleSelectReport = (e) => {
        setSelectedReport(e);
    };

    const handleSeparateReports = (e) => {
        setSeparateReports(e.target.checked);
    };

    const handleDownloadReport = () => {
        try {
            let download = selectedReport.label + ' ' + formatFileTimeStamp() + '.pdf';
            if (separateReports) {
                if (serviceDay.value !== 0) {
                    setButtonLoading(true);
                    let selected = structuredClone(routeViewTableData['R'])
                        .filter((r) => r.checked === true)
                        .map((r) => r.Id); //need adjustment here
                    selected.forEach((route) => handleDownloadReportAsync(route, route + ' ' + download));
                }
            } else {
                if (serviceDay.value !== 0) {
                    setButtonLoading(true);
                    let selected = structuredClone(routeViewTableData['R'])
                        .filter((r) => r.checked === true)
                        .map((r) => r.Id); //need adjustment here
                    handleDownloadReportAsync(selected.join(), download);
                }
            }
        } catch (e) {
            setButtonLoading(false);
            const enc = new TextDecoder('utf-8');
            const arr = new Uint8Array(e.response.data);
            const str = enc.decode(arr);
            //const jsn = JSON.parse(str);
            showAPICallbackDialog('Error', str);
        }
    };

    const handleDownloadReportAsync = async (routes, download) => {
        let windowReference = null;
        if (isMobile()) {
            windowReference = window.open();
            windowReference.document.write('<h1>Generating report...Please wait...</h1>');
        }
        let RT = routes.split(',').length == 1 ? routes.toString() : routes.split(',').length + ' routes selected';
        let DetailStr = '|ServiceDay|' + serviceDay.label.toString() + '|Route|' + RT + '|Report|' + selectedReport.value;
        try {
            let res = await getReports(workspace.value, serviceDay.label.toString(), routes, selectedReport.value, null, siteMode, project);

            if (res.status === 200) {
                setButtonLoading(false);
                let blob = new Blob([res.data], { type: 'application/pdf' });

                if (blob.size > 0) {
                    let url = window.URL.createObjectURL(blob);
                    if (isMobile()) {
                        windowReference.document.body.innerHTML = '';
                        windowReference.location = url;
                    } else {
                        if (!window.navigator.msSaveOrOpenBlob) {
                            let anchor = document.createElement('a');
                            anchor.href = url;
                            anchor.download = download;
                            anchor.click();
                        } else {
                            window.navigator.msSaveOrOpenBlob(blob, download);
                        }
                    }
                    setTimeout(() => window.URL.revokeObjectURL(url), 0);
                } else {
                    if (isMobile()) {
                        windowReference.document.body.innerHTML = '';
                        windowReference.document.write('<h1>No content under selected service day.</h1>');
                    }
                }

                const logData = {
                    WorkspaceId: workspace.value,
                    ActionId: 242,
                    Mode: siteMode,
                    Success: true,
                    Metadata: workspace.value + DetailStr,
                };
                updateUserActivity(logData);
            } else if (res.status === 204) {
                setButtonLoading(false);
                showAPICallbackDialog('Error', 'No content under selected service day.');

                const logData = {
                    WorkspaceId: workspace.value,
                    ActionId: 242,
                    Mode: siteMode,
                    ErrorMessage: 'No content under selected service day.',
                    Success: false,
                    Metadata: workspace.value + DetailStr,
                };
                updateUserActivity(logData);
            }
        } catch (e) {
            setButtonLoading(false);
            const enc = new TextDecoder('utf-8');
            const arr = new Uint8Array(e.response.data);
            const str = enc.decode(arr);
            //const jsn = JSON.parse(str);

            showAPICallbackDialog('Error', str);

            const logData = {
                WorkspaceId: workspace.value,
                ActionId: 302,
                ErrorMessage: `${str}` || 'Unknown error.',
                Success: true,
                Metadata: workspace.value + DetailStr,
            };
            updateUserActivity(logData);
        }
    };

    const handleDownloadScenarioReport = () => {
        try {
            let download = selectedScenarioReport.label + ' ' + formatFileTimeStamp() + '.pdf';

            if (separateScenarioReports) {
                selectedScenarioRoutes.forEach((route) => handleDownloadScenarioReportAsync(route, route + ' ' + download));
            } else {
                setButtonLoading(true);
                handleDownloadScenarioReportAsync(selectedScenarioRoutes.join(), download);
            }
        } catch (e) {
            const enc = new TextDecoder('utf-8');
            const arr = new Uint8Array(e.response.data);
            const str = enc.decode(arr);
            //const jsn = JSON.parse(str);
            showAPICallbackDialog('Error', str);
        }
    };

    const handleDownloadScenarioReportAsync = async (routes, download) => {
        let windowReference = null;
        if (isMobile()) {
            windowReference = window.open();
            windowReference.document.write('<h1>Generating report...Please wait...</h1>');
        }
        let RT = routes.split(',').length == 1 ? routes.toString() : routes.split(',').length + ' routes selected';
        let DetailStr = '|ServiceDay|' + serviceDay.label.toString() + '|Route|' + RT + '|Report|' + selectedScenarioReport.value;

        try {
            let res = await getReports(workspace.value, null, routes, selectedScenarioReport.value, selectedScenarioId);

            if (res.status === 200) {
                setButtonLoading(false);
                let blob = new Blob([res.data], { type: 'application/pdf' });

                if (blob.size > 0) {
                    let url = window.URL.createObjectURL(blob);
                    if (isMobile()) {
                        windowReference.document.body.innerHTML = '';
                        windowReference.location = url;
                    } else {
                        if (!window.navigator.msSaveOrOpenBlob) {
                            let anchor = document.createElement('a');
                            anchor.href = url;
                            anchor.download = download;
                            anchor.click();
                        } else {
                            window.navigator.msSaveOrOpenBlob(blob, download);
                        }
                    }
                    setTimeout(() => window.URL.revokeObjectURL(url), 0);
                } else {
                    if (isMobile()) {
                        windowReference.document.body.innerHTML = '';
                        windowReference.document.write('<h1>No content under selected service day.</h1>');
                    }
                }

                const logData = {
                    WorkspaceId: workspace.value,
                    ActionId: 252,
                    Success: true,
                    Metadata: workspace.value + DetailStr,
                };
                updateUserActivity(logData);
            } else if (res.status === 204) {
                setButtonLoading(false);
                showAPICallbackDialog('Error', 'No content under selected service day.');

                const logData = {
                    WorkspaceId: workspace.value,
                    ActionId: 252,
                    Success: false,
                    ErrorMessage: 'No content under selected service day.',
                    Metadata: workspace.value + DetailStr,
                };
                updateUserActivity(logData);
            }
        } catch (e) {
            setButtonLoading(false);
            const enc = new TextDecoder('utf-8');
            const arr = new Uint8Array(e.response.data);
            const str = enc.decode(arr);
            //const jsn = JSON.parse(str);

            showAPICallbackDialog('Error', str);

            const logData = {
                WorkspaceId: workspace.value,
                ActionId: 252,
                Success: false,
                ErrorMessage: `${str}` || 'Unknown error.',
                Metadata: workspace.value + DetailStr,
            };
            updateUserActivity(logData);
        }
    };

    const handleSelectFormat = (e) => {
        setSelectedFormat(e);
    };

    const handleSetExportOptions = (name, value) => {
        let o = { ...exportOptions };
        o[name] = value;
        setExportOptions(o);
    };

    const handleSetDispatchExportOptions = (name, value) => {
        let o = { ...dispatchExportOptions };
        o[name] = value;
        setDispatchExportOptions(o);
    };

    const handleDownloadExport = () => {
        try {
            let download = 'ServiceLocation-' + formatFileTimeStamp() + '.' + selectedFormat.extension;
            setButtonLoading(true);
            let routes = '';
            if (serviceDay.value !== 0) {
                let selected = structuredClone(routeViewTableData['R'])
                    .filter((r) => r.checked === true)
                    .map((r) => r.Id); //need adjustment here
                routes = selected.join();
            }
            handleDownloadExportAsync(routes, download, selectedFormat);
        } catch (e) {
            const enc = new TextDecoder('utf-8');
            const arr = new Uint8Array(e.response.data);
            const str = enc.decode(arr);
            //const jsn = JSON.parse(str);
            showAPICallbackDialog('Error', str);
        }
    };

    const handleDownloadExportAsync = async (routes, download, selectedFormat) => {
        let windowReference = null;
        if (isMobile()) {
            windowReference = window.open();
            windowReference.document.write('<h1>Generating export...Please wait...</h1>');
        }
        let RT = routes.split(',').length == 1 ? routes.toString() : routes.split(',').length + ' routes selected';
        let DetailStr = '|ServiceDay|' + serviceDay.label.toString() + '|Route|' + RT + '|Format|' + selectedFormat.label;
        if (serviceDay.value === 0) {
            let data = [];
            if (routeViewTabCategorization.value.startsWith('R')) {
                routeViewTableData[routeViewTabCategorization.value].forEach((rs) => {
                    //need adjustment here
                    rs.subRows.forEach((rss) => {
                        if (rss.checked) {
                            data.push({ route: rs.Id, day: parseInt(rss.Id) });
                        }
                    });
                });
            } else {
                routeViewTableData[routeViewTabCategorization.value].forEach((rs) => {
                    //need adjustment here
                    rs.subRows.forEach((rss) => {
                        if (rss.checked) {
                            data.push({ route: rss.Id, day: parseInt(rs.Id) });
                        }
                    });
                });
            }
            try {
                let obj = {
                    workspace: workspace.value,
                    serviceDay: serviceDay.value,
                    siteMode: siteMode,
                    project: project,
                    header: selectedFormat.header,
                    url: selectedFormat.value,
                };

                if (exportOptions.selectedRecords && selectedServiceLocationIds.length > 0) {
                    obj.gids = [...selectedServiceLocationIds];
                } else {
                    obj.gids = [];
                }

                let res = await exportRouteDayServiceLocations(obj, data);
                if (res.status === 200) {
                    setButtonLoading(false);
                    let blob = new Blob([res.data], { type: selectedFormat.header });
                    if (blob.size > 0) {
                        let url = window.URL.createObjectURL(blob);

                        if (isMobile()) {
                            windowReference.document.body.innerHTML = '';
                            windowReference.location = url;
                        } else {
                            if (!window.navigator.msSaveOrOpenBlob) {
                                let anchor = document.createElement('a');
                                anchor.href = url;
                                anchor.download = download;
                                anchor.click();
                            } else {
                                window.navigator.msSaveOrOpenBlob(blob, download);
                            }
                        }
                        setTimeout(() => window.URL.revokeObjectURL(url), 0);
                    } else {
                        if (isMobile()) {
                            windowReference.document.body.innerHTML = '';
                            windowReference.document.write('<h1>No content under selected service day.</h1>');
                        }
                    }

                    const logData = {
                        WorkspaceId: workspace.value,
                        ActionId: 243,
                        Mode: siteMode,
                        Success: true,
                        Metadata: workspace.value + DetailStr,
                    };
                    updateUserActivity(logData);
                } else if (res.status === 204) {
                    setButtonLoading(false);
                    showAPICallbackDialog('Error', 'No content under selected service day.');

                    const logData = {
                        WorkspaceId: workspace.value,
                        ActionId: 243,
                        Mode: siteMode,
                        Success: false,
                        ErrorMessage: 'No content under selected service day.',
                        Metadata: workspace.value + DetailStr,
                    };
                    updateUserActivity(logData);
                }
            } catch (e) {
                setButtonLoading(false);
                const enc = new TextDecoder('utf-8');
                const arr = new Uint8Array(e.response.data);
                const str = enc.decode(arr);
                //const jsn = JSON.parse(str);
                showAPICallbackDialog('Error', str);

                const logData = {
                    WorkspaceId: workspace.value,
                    ActionId: 243,
                    Mode: siteMode,
                    Success: false,
                    ErrorMessage: `${str}` || 'Unknown error.',
                    Metadata: workspace.value + DetailStr,
                };
                updateUserActivity(logData);
            }
        } else {
            try {
                let data = {
                    workspace: workspace.value,
                    serviceDay: serviceDay.value,
                    routes: routes,
                    siteMode: siteMode,
                    project: project,
                    header: selectedFormat.header,
                    url: selectedFormat.value,
                };

                if (exportOptions.selectedRecords && selectedServiceLocationIds.length > 0) {
                    data.gids = [...selectedServiceLocationIds];
                } else {
                    data.gids = [];
                }

                if (selectedFormat.value) {
                    let res = await exportServiceLocations(data);
                    if (res.status === 200) {
                        setButtonLoading(false);
                        let blob = new Blob([res.data], { type: selectedFormat.header });
                        if (blob.size > 0) {
                            let url = window.URL.createObjectURL(blob);

                            if (isMobile()) {
                                windowReference.document.body.innerHTML = '';
                                windowReference.location = url;
                            } else {
                                if (!window.navigator.msSaveOrOpenBlob) {
                                    let anchor = document.createElement('a');
                                    anchor.href = url;
                                    anchor.download = download;
                                    anchor.click();
                                } else {
                                    window.navigator.msSaveOrOpenBlob(blob, download);
                                }
                            }
                            setTimeout(() => window.URL.revokeObjectURL(url), 0);
                        } else {
                            if (isMobile()) {
                                windowReference.document.body.innerHTML = '';
                                windowReference.document.write('<h1>No content under selected service day.</h1>');
                            }
                        }

                        const logData = {
                            WorkspaceId: workspace.value,
                            ActionId: 243,
                            Mode: siteMode,
                            Success: true,
                            Metadata: workspace.value + DetailStr,
                        };
                        updateUserActivity(logData);
                    } else if (res.status === 204) {
                        setButtonLoading(false);
                        showAPICallbackDialog('Error', 'No content under selected service day.');

                        const logData = {
                            WorkspaceId: workspace.value,
                            ActionId: 243,
                            Mode: siteMode,
                            Success: false,
                            ErrorMessage: 'No content under selected service day.',
                            Metadata: workspace.value + DetailStr,
                        };
                        updateUserActivity(logData);
                    }
                } else {
                    setButtonLoading(false);
                    showAPICallbackDialog('Error', 'Format not found.');
                }
            } catch (e) {
                setButtonLoading(false);
                const enc = new TextDecoder('utf-8');
                const arr = new Uint8Array(e.response.data);
                const str = enc.decode(arr);
                //const jsn = JSON.parse(str);
                showAPICallbackDialog('Error', str);

                const logData = {
                    WorkspaceId: workspace.value,
                    ActionId: 243,
                    Mode: siteMode,
                    Success: false,
                    ErrorMessage: `${str}` || 'Unknown error.',
                    Metadata: workspace.value + DetailStr,
                };
                updateUserActivity(logData);
            }
        }
    };

    const handleDownloadScenarioExport = () => {
        try {
            let download = 'ServiceLocation-' + formatFileTimeStamp() + '.' + selectedScenarioFormat.extension;
            setButtonLoading(true);
            handleDownloadScenarioExportAsync(selectedScenarioRoutes.join(), download, selectedScenarioFormat.value);
        } catch (e) {
            const enc = new TextDecoder('utf-8');
            const arr = new Uint8Array(e.response.data);
            const str = enc.decode(arr);
            //const jsn = JSON.parse(str);
            showAPICallbackDialog('Error', str);
        }
    };

    const handleDownloadScenarioExportAsync = async (routes, download, format) => {
        let windowReference = null;
        if (isMobile()) {
            windowReference = window.open();
            windowReference.document.write('<h1>Generating export...Please wait...</h1>');
        }
        let RT = routes.length == 1 ? routes.toString() : routes.length + ' routes selected';
        let DetailStr = '|ServiceDay|' + serviceDay.label.toString() + '|Route|' + RT + '|Format|' + format;
        try {
            let data = {
                workspace: workspace.value,
                routes: routes,
                processId: selectedScenarioId,
                header: selectedFormat.header,
                url: selectedFormat.value,
            };

            if (dispatchExportOptions.selectedRecords && selectedRaaSServiceLocationIds.length > 0) {
                data.gids = [...selectedRaaSServiceLocationIds];
            } else {
                data.gids = [];
            }

            if (selectedFormat.value) {
                let res = await exportServiceLocations(data);
                if (res.status === 200) {
                    setButtonLoading(false);
                    let blob = new Blob([res.data], { type: selectedFormat.header });
                    if (blob.size > 0) {
                        let url = window.URL.createObjectURL(blob);

                        if (isMobile()) {
                            windowReference.document.body.innerHTML = '';
                            windowReference.location = url;
                        } else {
                            if (!window.navigator.msSaveOrOpenBlob) {
                                let anchor = document.createElement('a');
                                anchor.href = url;
                                anchor.download = download;
                                anchor.click();
                            } else {
                                window.navigator.msSaveOrOpenBlob(blob, download);
                            }
                        }
                        setTimeout(() => window.URL.revokeObjectURL(url), 0);
                    } else {
                        if (isMobile()) {
                            windowReference.document.body.innerHTML = '';
                            windowReference.document.write('<h1>No content under selected service day.</h1>');
                        }
                    }

                    const logData = {
                        WorkspaceId: workspace.value,
                        ActionId: 253,
                        Success: true,
                        Metadata: workspace.value + DetailStr,
                    };
                    updateUserActivity(logData);
                } else if (res.status === 204) {
                    setButtonLoading(false);
                    showAPICallbackDialog('Error', 'No content under selected service day.');
                    const logData = {
                        WorkspaceId: workspace.value,
                        ActionId: 253,
                        Success: false,
                        ErrorMessage: 'No content under selected service day.',
                        Metadata: workspace.value + DetailStr,
                    };
                    updateUserActivity(logData);
                }
            } else {
                setButtonLoading(false);
                showAPICallbackDialog('Error', 'Format not found.');
            }
        } catch (e) {
            setButtonLoading(false);
            const enc = new TextDecoder('utf-8');
            const arr = new Uint8Array(e.response.data);
            const str = enc.decode(arr);
            //const jsn = JSON.parse(str);
            showAPICallbackDialog('Error', str);
            const logData = {
                WorkspaceId: workspace.value,
                ActionId: 253,
                Success: false,
                ErrorMessage: `${str}` || 'Unknown error.',
                Metadata: workspace.value + DetailStr,
            };
            updateUserActivity(logData);
        }
    };

    const updateSplitFormData = (field, value) => {
        let t = { ...splitFormData };
        t[field] = value;
        setSplitFormData(t);
    };

    const updateCreateRoutesFormData = (field, value) => {
        let t = { ...createRoutesFormData };
        if (field === 'RoutePlan') {
            let targetRoutePlan = routePlanList.find((r) => r.value === value);
            if (targetRoutePlan) {
                t.RoutePlan = value;
                t.GenerateMethod = targetRoutePlan.GenerateMethod;
                if (targetRoutePlan.RouteCount < 1) {
                    t.Zones = 'Off';
                } else {
                    t.Zones = targetRoutePlan.Zones;
                }
                t.NumRoutes = targetRoutePlan.NumRoutes;
            } else {
                t[field] = value;
            }
        } else if (field === 'Zones') {
            t['Zones'] = value ? 'Routes' : 'Off';
        } else {
            t[field] = value;
            if (field === 'GenerateMethod' && value === 'Number') {
                t['Zones'] = 'Off';
            }
        }
        setCreateRoutesFormData(t);
    };

    const refreshSplitSeqList = (route) => {
        let servDayItem = serviceDayList.filter((item) => item.value === splitFormData.ServiceDay);
        if (servDayItem.length > 0) {
            getServiceLocations(workspace.value, servDayItem[0].serviceDay, siteMode, project)
                .then((response) => {
                    if (response && response.data) {
                        let list = [];
                        response.data.forEach((item) => {
                            if (item.Sequence && item.Sequence > 0 && item.Route === route) {
                                list.push({ Id: item.Sequence });
                            }
                        });
                        list.sort((a, b) => a['Id'] - b['Id']);
                        setSplitSeqList(list);
                    } else {
                        setSplitSeqList([]);
                    }
                })
                .catch((err) => {
                    setSplitSeqList([]);
                });
        }
    };

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    const initZoneGraphicLayers = (view, map) => {
        const zoneGraphicLayer = new GraphicsLayer();
        const neighborhoodLayer = new GraphicsLayer({ id: 'neighborhoodLayer' });

        setZoneGraphicLayer(zoneGraphicLayer);
        setNeighborhoodLayer(neighborhoodLayer);

        map.add(zoneGraphicLayer);
        map.add(neighborhoodLayer);

        initZoneSketchTools(view, zoneGraphicLayer);
    };

    const initZoneSketchTools = (view, zoneGraphicLayer) => {
        const sketch = new Sketch({
            view: view,
            layer: zoneGraphicLayer,
        });

        const editSketch = new Sketch({
            view: view,
            layer: zoneGraphicLayer,
        });

        setSketch(sketch);
        setEditSketch(editSketch);

        sketch.on('create', (event) => {
            if (event.state === 'complete') {
                if (event.graphic.geometry.rings[0] && event.graphic.geometry.rings[0].length < 4) {
                    zoneGraphicLayer?.removeAll();
                    setMapInfoMessage('The polygon you drew has invalid geometry. Please try again.');
                } else if (event.graphic.geometry.isSelfIntersecting) {
                    zoneGraphicLayer?.removeAll();
                    setMapInfoMessage('The polygon you drew is self-intersecting. Please try again.');
                } else {
                    let data = [];
                    data.Shape = event.graphic.geometry;
                    setTargetNeighborhoodData(data);
                    setOpenNeighborhoodClusterDialog(true);
                    setMapInfoMessage('');
                }
            }
        });

        editSketch.on('update', (event) => {
            if (event.state === 'complete' || event.state === 'cancel') {
                if (event.graphics[0].geometry.rings[0] && event.graphics[0].geometry.rings[0].length < 4) {
                    setMapInfoMessage('The polygon you drew has invalid geometry. Please try again.');
                } else if (event.graphics[0].geometry.isSelfIntersecting) {
                    editSketch.update(event.graphics[0], { tool: 'reshape' });
                    setMapInfoMessage('The polygon you drew is self-intersecting. Please try again.');
                } else {
                    let data = { ...targetNeighborhoodDataRef.current };
                    data.Shape = event.graphics[0].geometry;
                    setTargetNeighborhoodData(data);
                    setMapInfoMessage('');
                    setReshapeDialogOpen(true);
                }
            }
        });
    };

    const refreshZoneFacilityLayer = () => {
        if (map && workspace) {
            let queryStr = 'workspace_id=' + workspace.value;
            if (zoneFacilityLayer) {
                if (siteMode === 'DISPATCHER') {
                    zoneFacilityLayer.findSublayerById(parseInt(config.FACILITY)).definitionExpression = 'workspace_id=' + workspace.value;
                    zoneFacilityLayer.findSublayerById(parseInt(config.FACILITY)).visible = zoneFacilityLayerVisibility;
                    zoneFacilityLayer.refresh();
                } else {
                    zoneFacilityLayer.findSublayerById(parseInt(config.SANDBOXFACILITY)).definitionExpression = 'sandbox_id=' + project;
                    zoneFacilityLayer.findSublayerById(parseInt(config.SANDBOXFACILITY)).visible = zoneFacilityLayerVisibility;
                    zoneFacilityLayer.refresh();
                }
            }
        }
    };

    const refreshZoneServiceLocationLayer = () => {
        if (map && workspace) {
            let queryStr = 'workspace_id=' + workspace.value;
            if (zoneServiceLocationLayer) {
                if (siteMode === 'DISPATCHER') {
                    if (serviceDay && serviceDay.value !== 0) {
                        queryStr += ' and service_day=' + serviceDay.value;
                    }
                    zoneServiceLocationLayer.findSublayerById(parseInt(config.SERVICE_LOCATION)).definitionExpression = queryStr;
                    zoneServiceLocationLayer.findSublayerById(parseInt(config.SERVICE_LOCATION)).visible = zoneServiceLocationLayerVisibility;
                    zoneServiceLocationLayer.refresh();
                } else {
                    queryStr = 'sandbox_id=' + project;
                    if (serviceDay && serviceDay.value !== 0) {
                        queryStr += ' and service_day in (' + serviceDay.value + ')';
                    }
                    zoneServiceLocationLayer.findSublayerById(parseInt(config.SANDBOXSERVICELOCATION)).definitionExpression = queryStr;
                    zoneServiceLocationLayer.findSublayerById(parseInt(config.SANDBOXSERVICELOCATION)).visible = zoneServiceLocationLayerVisibility;
                    zoneServiceLocationLayer.refresh();
                }
            }
        }
    };

    const refreshProjectFacilityLayer = () => {
        if (map && project) {
            if (projectFacilityLayer) {
                projectFacilityLayer.findSublayerById(1).definitionExpression = 'sandbox_id=' + project;
                projectFacilityLayer.visible = projectFacilityLayerVisibility;
                projectFacilityLayer.refresh();
            }
        }
    };

    const handleNewNeighborhood = () => {
        zoneGraphicLayer?.removeAll();
        editSketch?.cancel();
        setEditingMode('ADD');
        setTitle('Add Zone');
        setMapInfoMessage('Click on map to draw a polygon, double click to finish.');
        sketch?.create('polygon', { mode: 'click' });
    };

    const updateNeighborhoodForm = (e) => {
        let t = { ...targetNeighborhoodData };
        t[e.target.name] = e.target.value;
        setTargetNeighborhoodData(t);
    };

    const handleEditNeighborhoodCluster = (id) => {
        setEditingMode('EDIT');
        setTitle('Edit Zone');
        let data = neighborhoodDataRef.current.find((d) => d.Id === id);
        if (data) {
            setTargetNeighborhoodData(data);
            setOpenNeighborhoodClusterDialog(true);
        }
    };

    const handleNeighborhoodClusterDialogClose = (mode) => {
        sketch?.cancel();
        editSketch?.cancel();
        if (mode === 'CANCEL') {
            zoneGraphicLayer?.removeAll();
            setEditingMode(null);
            setTargetNeighborhoodData({});
            setOpenNeighborhoodClusterDialog(false);
        } else if (mode === 'SAVE') {
            let data = {
                Id: 0,
                WorkspaceId: workspace.value,
                Name: targetNeighborhoodData.Name,
                Label: targetNeighborhoodData.Label,
                Shape: targetNeighborhoodData.Shape,
            };

            if (editingMode === 'ADD') {
                data.Shape = JSON.stringify(targetNeighborhoodData.Shape);
                if (siteMode === 'PLANNER' && project) {
                    data.SandboxId = project;
                }
                createNeighborhoodClusters(workspace.value, data).then(
                    (res) => {
                        const Id = res.data.Id;
                        zoneGraphicLayer?.removeAll();
                        const logData = {
                            WorkspaceId: workspace.value,
                            ActionId: 262,
                            Mode: siteMode,
                            Success: true,
                            Metadata: data.Name,
                        };
                        updateUserActivity(logData);
                        getNeighborhoodClusters(workspace.value, siteMode, project).then(
                            (res) => {
                                let NC = res.data;
                                if (sequenceClusterData && sequenceClusterData.length > 0) {
                                    NC.map((n) => {
                                        let t = sequenceClusterData.find((r) => r.AnchorAreaId === n.Id);
                                        if (t) {
                                            n.Route = t.Route;
                                            n.Sequence = t.Sequence;
                                            n.Priority = t.Priority;
                                        }
                                    });
                                }
                                setNeighborhoodData(NC);
                                setEditingMode(null);
                                setTargetNeighborhoodData({});
                                setOpenNeighborhoodClusterDialog(false);

                                let selected = [...selectedNeighborhoodClusters];
                                selected.push(Id);
                                handleDrawNeighborhoodClusters(selected);
                            },
                            () => {
                                setNeighborhoodData([]);
                            },
                        );
                    },
                    (err) => {
                        const logData = {
                            WorkspaceId: workspace.value,
                            ActionId: 262,
                            Mode: siteMode,
                            Success: false,
                            Metadata: data.Name,
                        };
                        updateUserActivity(logData);
                        showAPICallbackDialog('Error', err.response.data);
                    },
                );
            } else if (editingMode === 'EDIT') {
                data.Id = targetNeighborhoodData.Id;
                if (siteMode === 'PLANNER' && project) {
                    data.SandboxId = project;
                }
                updateNeighborhoodClusters(workspace.value, data.Id, data).then(
                    (res) => {
                        zoneGraphicLayer?.removeAll();

                        const logData = {
                            WorkspaceId: workspace.value,
                            ActionId: 265,
                            Mode: siteMode,
                            Success: true,
                            Metadata: data.Name,
                        };
                        updateUserActivity(logData);

                        getNeighborhoodClusters(workspace.value, siteMode, project).then(
                            (res) => {
                                let NC = res.data;
                                if (sequenceClusterData && sequenceClusterData.length > 0) {
                                    NC.map((n) => {
                                        let t = sequenceClusterData.find((r) => r.AnchorAreaId === n.Id);
                                        if (t) {
                                            n.Route = t.Route;
                                            n.Sequence = t.Sequence;
                                            n.Priority = t.Priority;
                                        }
                                    });
                                }
                                setNeighborhoodData(NC);
                                setEditingMode(null);
                                setTargetNeighborhoodData({});
                                setOpenNeighborhoodClusterDialog(false);
                            },
                            () => {
                                setNeighborhoodData([]);
                            },
                        );
                    },
                    (err) => {
                        const logData = {
                            WorkspaceId: workspace.value,
                            ActionId: 265,
                            Mode: siteMode,
                            Success: false,
                            Metadata: data.Name,
                        };
                        updateUserActivity(logData);
                    },
                );
            }
        }
    };

    const handleDeleteClose = (mode) => {
        if (mode === 'CANCEL') {
            setOpenDeleteNeighborhoodClusterDialog(false);
            setTargetNeighborhoodData({});
        } else if (mode === 'DELETE') {
            if (targetNeighborhoodData.Id) {
                deleteNeighborhoodClusters(workspace.value, targetNeighborhoodData.Id).then(
                    (res) => {
                        const logData = {
                            WorkspaceId: workspace.value,
                            ActionId: 263,
                            Mode: siteMode,
                            Success: true,
                            Metadata: targetNeighborhoodData.Name,
                        };
                        updateUserActivity(logData);
                        getNeighborhoodClusters(workspace.value, siteMode, project).then(
                            (res) => {
                                let NC = res.data;
                                if (sequenceClusterData && sequenceClusterData.length > 0) {
                                    NC.map((n) => {
                                        let t = sequenceClusterData.find((r) => r.AnchorAreaId === n.Id);
                                        if (t) {
                                            n.Route = t.Route;
                                            n.Sequence = t.Sequence;
                                            n.Priority = t.Priority;
                                        }
                                    });
                                }
                                setNeighborhoodData(NC);
                                setOpenNeighborhoodClusterDialog(false);
                                setEditingMode(null);
                                setTargetNeighborhoodData({});

                                let selected = [...selectedNeighborhoodClusters];
                                let index = selected.indexOf(targetNeighborhoodData.Id);
                                selected.splice(index, 1);
                                handleDrawNeighborhoodClusters(selected);
                            },
                            () => {
                                setNeighborhoodData([]);
                            },
                        );
                        setOpenDeleteNeighborhoodClusterDialog(false);
                        setTargetNeighborhoodData({});
                    },
                    (err) => {
                        setOpenDeleteNeighborhoodClusterDialog(false);

                        const logData = {
                            WorkspaceId: workspace.value,
                            ActionId: 263,
                            Mode: siteMode,
                            Success: false,
                            Metadata: targetNeighborhoodData.Name,
                        };
                        updateUserActivity(logData);

                        setTargetNeighborhoodData({});
                    },
                );
            } else {
                deleteSelectedZones(workspace.value, selectedNeighborhoodClusters).then(
                    (res) => {
                        const logData = {
                            WorkspaceId: workspace.value,
                            ActionId: 263,
                            Mode: siteMode,
                            Success: true,
                            Metadata: selectedNeighborhoodClusters.length,
                        };
                        updateUserActivity(logData);
                        getNeighborhoodClusters(workspace.value, siteMode, project).then(
                            (res) => {
                                let NC = res.data;
                                if (sequenceClusterData && sequenceClusterData.length > 0) {
                                    NC.map((n) => {
                                        let t = sequenceClusterData.find((r) => r.AnchorAreaId === n.Id);
                                        if (t) {
                                            n.Route = t.Route;
                                            n.Sequence = t.Sequence;
                                            n.Priority = t.Priority;
                                        }
                                    });
                                }
                                setNeighborhoodData(NC);
                                setOpenNeighborhoodClusterDialog(false);
                                setEditingMode(null);
                                setSelectedNeighborhoodClusters([]);

                                let selected = [...selectedNeighborhoodClusters];
                                let index = selected.indexOf(targetNeighborhoodData.Id);
                                selected.splice(index, 1);
                                handleDrawNeighborhoodClusters(selected);
                            },
                            () => {
                                setNeighborhoodData([]);
                            },
                        );
                        setOpenDeleteNeighborhoodClusterDialog(false);
                        setSelectedNeighborhoodClusters([]);
                    },
                    (err) => {
                        setOpenDeleteNeighborhoodClusterDialog(false);

                        const logData = {
                            WorkspaceId: workspace.value,
                            ActionId: 263,
                            Mode: siteMode,
                            Success: false,
                            Metadata: selectedNeighborhoodClusters.length,
                        };
                        updateUserActivity(logData);
                    },
                );
            }
        }
    };

    const handleReshapeNeighborhoodCluster = (id) => {
        handleZoomToNeighborhoodCluster(id);
        zoneGraphicLayer?.removeAll();
        sketch?.cancel();
        editSketch?.cancel();
        let target = neighborhoodLayerRef.current.graphics.items.find((d) => d.attributes.id === id);
        const data = neighborhoodDataRef.current.find((d) => d.Id === id);
        if (data) {
            setTargetNeighborhoodData(data);
            setEditingMode('REDRAW');
            setMapInfoMessage('When finished reshaping the zone, or to cancel reshaping, press the <ESC> key.');
            if (target) {
                target.symbol = editingSymbol;
                const clone = target.clone();
                zoneGraphicLayerRef.current.graphics.add(clone);
                zoneGraphicLayerRef.current.visible = true;
                editSketchRef.current.update(clone, { tool: 'reshape' });
            }
        }
    };

    const handleDeleteNeighborhoodCluster = (id) => {
        let data = neighborhoodData.find((d) => d.Id === id);
        setTargetNeighborhoodData(data);
        setOpenDeleteNeighborhoodClusterDialog(true);
    };

    const handleDeleteSelectedNeighborhood = () => {
        console.log(selectedNeighborhoodClusters);
        setOpenDeleteNeighborhoodClusterDialog(true);
    };

    const handleDrawNeighborhoodClusters = (selected) => {
        neighborhoodLayer?.removeAll();
        zoneGraphicLayer?.removeAll();
        if (zoneModeRef.current === 'ASSIGN') {
            showAssignModeNeighborhoodClusters();
        } else if (zoneModeRef.current === 'SEQUENCE') {
            showSequenceModeNeighborhoodClusters();
        } else {
            setSelectedNeighborhoodClusters(selected);
            if (selected && selected.length > 0 && neighborhoodLayer) {
                neighborhoodDataRef.current.forEach((nc, i) => {
                    if (selected.includes(nc.Id)) {
                        const shape = JSON.parse(nc.Shape);
                        const neighborhoodClusterAttributes = {
                            id: nc.Id,
                            name: nc.Name,
                            label: nc.Label,
                            route: nc.Route,
                            priority: nc.Priority,
                            sequence: nc.Sequence,
                        };

                        const polygon = {
                            type: 'polygon',
                            rings: shape.rings,
                            spatialReference: shape.spatialReference,
                        };

                        const neighborhoodClusterPopupTemplate = {
                            title: 'Zone',
                            content: generateZonePopup,
                            overwriteActions: true,
                            actions: [
                                {
                                    id: 'editNeighborhoodCluster',
                                    title: 'Edit',
                                    className: 'esri-icon-edit',
                                },
                                {
                                    id: 'reshapeNeighborhoodCluster',
                                    title: 'Reshape',
                                    className: 'esri-icon-maps',
                                },
                            ],
                        };

                        let symbol = { ...regularSymbol };
                        if (routePlanId) {
                            let targetRoute = routeListRef.current.find((el) => el.Id === nc.Route);
                            if (targetRoute) {
                                symbol.color = hexToRGBA(targetRoute.SvgColor, 0.25);
                            } else {
                                symbol.color = [150, 150, 150, 0.35];
                            }
                        }

                        const neighborhoodClusterGraphic = new Graphic({
                            geometry: polygon,
                            symbol: symbol,
                            attributes: neighborhoodClusterAttributes,
                            popupTemplate: neighborhoodClusterPopupTemplate,
                        });

                        neighborhoodLayer.graphics.add(neighborhoodClusterGraphic);
                    }
                });
            }
        }
    };

    const generateZonePopup = (feature) => {
        var div = document.createElement('div');
        if (routePlanId) {
            let route = '',
                priority = '',
                sequence = '';
            if (feature.graphic.attributes.route !== undefined) {
                route = feature.graphic.attributes.route;
            }
            if (feature.graphic.attributes.priority !== undefined) {
                priority = feature.graphic.attributes.priority;
            }
            if (feature.graphic.attributes.route !== undefined) {
                sequence = feature.graphic.attributes.sequence;
            }
            getZoneInfo(workspaceId.current.value, feature.graphic.attributes.id, selectedServiceDay.current.value).then(
                (res) => {
                    let st = '';
                    if (res.data.ServiceTime) {
                        var n = new Date(0, 0);
                        n.setSeconds(+res.data.ServiceTime * 60 * 60);
                        st = n.toTimeString().slice(0, 8);
                    }
                    let innerHTML = "<table><tr style='vertical-align: top'><td>";
                    innerHTML += "<ul style='list-style: none'>";
                    innerHTML += '<li><b>Name: </b>' + feature.graphic.attributes.name || '' + '</li>';
                    innerHTML += '<li><b>Label: </b>' + feature.graphic.attributes.label || '' + '</li>';
                    innerHTML += '<li><b>Route: </b>' + route + '</li>';
                    innerHTML += '<li><b>Priority: </b>' + priority + '</li>';
                    innerHTML += '<li><b>Sequence: </b>' + sequence + '</li>';
                    innerHTML += '</ul>';
                    innerHTML += '</td>';
                    innerHTML += '<td>';
                    innerHTML += "<ul style='list-style: none'>";
                    innerHTML += '<li><b>Stops: </b>' + res.data.Stops + '</li>';
                    innerHTML += '<li><b>Lifts: </b>' + res.data.Lifts + '</li>';
                    innerHTML += '<li><b>Demand: </b>' + res.data.Demand + '</li>';
                    innerHTML += '<li><b>Service Time: </b>' + st + '</li>';
                    innerHTML += '</ul>';
                    innerHTML += '</td></tr></table>';
                    div.innerHTML = innerHTML;
                },
                () => {
                    let innerHTML = "<ul style='list-style: none'>";
                    innerHTML += '<li><b>Name: </b>' + feature.graphic.attributes.name || '' + '</li>';
                    innerHTML += '<li><b>Label: </b>' + feature.graphic.attributes.label || '' + '</li>';
                    innerHTML += '<li><b>Route: </b>' + route + '</li>';
                    innerHTML += '<li><b>Priority: </b>' + priority + '</li>';
                    innerHTML += '<li><b>Sequence: </b>' + sequence + '</li>';
                    innerHTML += '</ul>';
                    div.innerHTML = innerHTML;
                },
            );
        } else {
            getZoneInfo(workspaceId.current.value, feature.graphic.attributes.id, selectedServiceDay.current.value).then(
                (res) => {
                    let st = '';
                    if (res.data.ServiceTime) {
                        var n = new Date(0, 0);
                        n.setSeconds(+res.data.ServiceTime * 60 * 60);
                        st = n.toTimeString().slice(0, 8);
                    }
                    let innerHTML = "<table><tr style='vertical-align: top'><td>";
                    innerHTML += "<ul style='list-style: none'>";
                    innerHTML += '<li><b>Name: </b>' + feature.graphic.attributes.name || '' + '</li>';
                    innerHTML += '<li><b>Label: </b>' + feature.graphic.attributes.label || '' + '</li>';
                    innerHTML += '</ul>';
                    innerHTML += '</td>';
                    innerHTML += '<td>';
                    innerHTML += "<ul style='list-style: none'>";
                    innerHTML += '<li><b>Stops: </b>' + res.data.Stops + '</li>';
                    innerHTML += '<li><b>Lifts: </b>' + res.data.Lifts + '</li>';
                    innerHTML += '<li><b>Demand: </b>' + res.data.Demand + '</li>';
                    innerHTML += '<li><b>Service Time: </b>' + st + '</li>';
                    innerHTML += '</ul>';
                    innerHTML += '</td></tr></table>';
                    div.innerHTML = innerHTML;
                },
                () => {
                    let innerHTML = "<ul style='list-style: none'>";
                    innerHTML += '<li><b>Name: </b>' + feature.graphic.attributes.name || '' + '</li>';
                    innerHTML += '<li><b>Label: </b>' + feature.graphic.attributes.label || '' + '</li>';
                    innerHTML += '</ul>';
                    div.innerHTML = innerHTML;
                },
            );
        }
        return div;
    };

    const showAssignModeNeighborhoodClusters = () => {
        neighborhoodLayer?.removeAll();
        zoneGraphicLayer?.removeAll();

        const currentRoute = selectedZoneRoutes[0];

        if (neighborhoodLayer) {
            neighborhoodDataRef.current.forEach((nc, i) => {
                const shape = JSON.parse(nc.Shape);
                const neighborhoodClusterAttributes = {
                    id: nc.Id,
                    name: nc.Name,
                    label: nc.Label,
                    route: nc.Route,
                };

                const polygon = {
                    type: 'polygon',
                    rings: shape.rings,
                    spatialReference: shape.spatialReference,
                };

                let symbol = regularSymbol;
                regularSymbol.color = [150, 150, 150, 0.35];

                const assignedNC = sequenceClusterDataRef.current.filter((v) => v.Route === currentRoute);
                const assignedNCIds = assignedNC.map((a) => a.AnchorAreaId);
                const assignedToOtherRoutes = sequenceClusterDataRef.current.filter((v) => v.Route !== currentRoute);
                const assignedToOtherRoutesIds = assignedToOtherRoutes.map((a) => a.AnchorAreaId);

                setNCAssignment(assignedNCIds);
                setSelectedNeighborhoodClusters(assignedNCIds);

                if (assignedNCIds.includes(nc.Id)) {
                    symbol = assignedSymbol;
                } else if (assignedToOtherRoutesIds.includes(nc.Id)) {
                    symbol = otherRouteSymbol;
                }

                const neighborhoodClusterGraphic = new Graphic({
                    geometry: polygon,
                    symbol: symbol,
                    attributes: neighborhoodClusterAttributes,
                });

                neighborhoodLayer.add(neighborhoodClusterGraphic);
            });
        }
    };

    const showSequenceModeNeighborhoodClusters = () => {
        neighborhoodLayerRef.current?.removeAll();
        zoneGraphicLayerRef.current?.removeAll();
        const currentRoute = selectedZoneRoutesRef.current[0];
        if (neighborhoodLayerRef.current) {
            let selected = [];
            sequenceClusterDataRef.current.map((sc) => {
                if (sc.Route === currentRoute) {
                    selected.push(sc.AnchorAreaId);
                }
            });
            neighborhoodDataRef.current.forEach((nc, i) => {
                if (selected.includes(nc.Id)) {
                    const shape = JSON.parse(nc.Shape);

                    const polygon = {
                        type: 'polygon',
                        rings: shape.rings,
                        spatialReference: shape.spatialReference,
                    };

                    let symbol = regularSymbol;
                    regularSymbol.color = [150, 150, 150, 0.35];

                    const sequenced = sequencedZoneRef.current.find((s) => s.Id === nc.Id);

                    if (firstZoneRef.current.includes(nc.Id)) {
                        symbol = firstSymbol;
                    } else if (lastZoneRef.current.includes(nc.Id)) {
                        symbol = lastSymbol;
                    } else if (sequenced) {
                        symbol = assignedSymbol;
                    }

                    const neighborhoodClusterAttributes = {
                        id: nc.Id,
                        name: nc.Name,
                        label: nc.Label,
                        priority: nc.Priority || '',
                        sequence: nc.Sequence || '',
                    };

                    const neighborhoodClusterGraphic = new Graphic({
                        geometry: polygon,
                        symbol: symbol,
                        attributes: neighborhoodClusterAttributes,
                    });

                    neighborhoodLayerRef.current.add(neighborhoodClusterGraphic);

                    if (sequenced) {
                        const sequenceGraphic = new Graphic({
                            geometry: neighborhoodClusterGraphic.geometry.centroid,
                            symbol: { type: 'text', text: sequenced.Sequence, font: { size: 20, weight: 'bold' } },
                            attributes: {
                                id: sequenced.Id,
                                sequence: sequenced.Sequence,
                                type: 'text',
                            },
                        });
                        zoneGraphicLayerRef.current.add(sequenceGraphic);
                    }
                }
            });
        }
    };

    const handleZoomToNeighborhoodCluster = (id) => {
        if (!selectedNeighborhoodClusters.includes(id)) {
            let s = [...selectedNeighborhoodClusters];
            s.push(id);
            handleDrawNeighborhoodClusters(s);
        }
        if (neighborhoodLayerRef.current) {
            let data = neighborhoodLayerRef.current.graphics.items.find((d) => d.attributes.id === id);
            if (data) {
                const VP = new Viewpoint({
                    targetGeometry: data.geometry.extent,
                });
                view.goTo(VP);
            }
        }
    };

    const handleReshapeClose = (param) => {
        if (param === 'CANCEL') {
            zoneGraphicLayer?.removeAll();
            sketch?.cancel();
            editSketch?.cancel();
            setEditingMode(null);
            setReshapeDialogOpen(false);
            handleDrawNeighborhoodClusters(selectedNeighborhoodClusters);
        } else if (param === 'BACK') {
            editSketch?.update(zoneGraphicLayer.graphics.items[0], { tool: 'reshape' });
            //handleZoom(this.state.selectedId);
            setReshapeDialogOpen(false);
        } else if (param === 'SAVE') {
            sketch?.cancel();
            editSketch?.cancel();
            setEditingMode(null);
            let data = {
                WorkspaceId: workspace.value,
                Id: targetNeighborhoodData.Id,
                Name: targetNeighborhoodData.Name,
                Label: targetNeighborhoodData.Label,
                Shape: JSON.stringify(targetNeighborhoodData.Shape),
            };
            if (siteMode === 'PLANNER' && project) {
                data.SandboxId = project;
            }
            zoneGraphicLayer.removeAll();
            neighborhoodLayer.removeAll();
            updateNeighborhoodClusters(workspace.value, data.Id, data).then(
                (res) => {
                    const logData = {
                        WorkspaceId: workspace.value,
                        ActionId: 264,
                        Mode: siteMode,
                        Success: true,
                        Metadata: data.Name,
                    };
                    updateUserActivity(logData);

                    getNeighborhoodClusters(workspace.value, siteMode, project).then(
                        (res) => {
                            setNeighborhoodData(res.data);
                            setReshapeDialogOpen(false);
                            handleDrawNeighborhoodClusters(selectedNeighborhoodClusters);
                        },
                        () => {
                            setNeighborhoodData([]);
                        },
                    );
                },
                (err) => {
                    const logData = {
                        WorkspaceId: workspace.value,
                        ActionId: 264,
                        Mode: siteMode,
                        Success: false,
                        Metadata: data.Name,
                    };
                    updateUserActivity(logData);
                },
            );
        }
    };

    const handleSaveAssignment = (mode) => {
        if (mode === 'SAVE') {
            let data = [];
            let routeSet = zoneRouteList.find((z) => z.Id === selectedZoneRoutesRef.current[0]);
            ncAssignment.map((na) => {
                let exists = sequenceClusterDataRef.current.find((s) => s.AnchorAreaId === na);
                if (exists) {
                    let d = { ...exists };
                    if (d.RouteSetId !== routeSet.RouteSetId) {
                        d.RouteSetId = routeSet.RouteSetId;
                        d.Priority = 'N';
                        d.Sequence = 0;
                    }
                    data.push(d);
                } else {
                    data.push({
                        Id: 0,
                        anchorAreaId: na,
                        RoutePlanId: routePlanId,
                        RouteSetId: routeSet.RouteSetId,
                        Priority: 'N',
                        Sequence: 0,
                    });
                }
            });

            updateNeighborhoodClustersAssignment(workspace.value, routePlanId, routeSet.RouteSetId, data).then(
                (res) => {
                    const logData = {
                        WorkspaceId: workspace.value,
                        ActionId: 266,
                        Mode: siteMode,
                        Success: true,
                        Metadata: data.anchorAreaId,
                    };
                    updateUserActivity(logData);

                    //setZoneMode('VIEW');
                    setNCAssignment([]);
                    setUnSavedChanges(false);
                    saveSequenceClusterCallBack();
                },
                (err) => {
                    const logData = {
                        WorkspaceId: workspace.value,
                        ActionId: 266,
                        Mode: siteMode,
                        Success: false,
                        Metadata: data.anchorAreaId,
                    };
                    updateUserActivity(logData);
                },
            );
        } else if (mode === 'CANCEL') {
            if (unSavedChanges) {
                setWarningDialogOpen(true);
            } else {
                setZoneMode('VIEW');
                setNCAssignment([]);
                setUnSavedChanges(false);
                saveSequenceClusterCallBack();
            }
        }
    };

    const handleSaveSequence = (mode) => {
        if (mode === 'SAVE') {
            let currentRoute = selectedZoneRoutes[0];
            let data = [...sequenceClusterData];
            let routeSet = zoneRouteList.find((z) => z.Id === selectedZoneRoutesRef.current[0]);
            data = data.filter((d) => d.RouteSetId === routeSet.RouteSetId);
            data.map((sc) => {
                if (firstZone.includes(sc.AnchorAreaId)) {
                    sc.Priority = 'F';
                    sc.Sequence = 0;
                } else if (lastZone.includes(sc.AnchorAreaId)) {
                    sc.Priority = 'L';
                    sc.Sequence = 0;
                } else {
                    let t = sequencedZone.find((s) => s.Id === sc.AnchorAreaId);
                    if (t) {
                        sc.Priority = 'S';
                        sc.Sequence = t.Sequence;
                    } else {
                        sc.Priority = 'N';
                        sc.Sequence = 0;
                    }
                }
            });

            updateNeighborhoodClustersAssignment(workspace.value, routePlanId, routeSet.RouteSetId, data).then(
                (res) => {
                    const logData = {
                        WorkspaceId: workspace.value,
                        ActionId: 267,
                        Mode: siteMode,
                        Success: true,
                        Metadata: data.anchorAreaId,
                    };
                    updateUserActivity(logData);

                    setSequenceMode(null);
                    setUnSavedChanges(false);
                    saveSequenceClusterCallBack();
                },
                (err) => {
                    const logData = {
                        WorkspaceId: workspace.value,
                        ActionId: 267,
                        Mode: siteMode,
                        Success: false,
                        Metadata: data.anchorAreaId,
                    };
                    updateUserActivity(logData);
                },
            );
        } else if (mode === 'CANCEL') {
            if (unSavedChanges) {
                setWarningDialogOpen(true);
            } else {
                setZoneMode('VIEW');
                setSequenceMode(null);
                setFirstZone([]);
                setLastZone([]);
                setSequencedZone([]);
                setUnSavedChanges(false);
                saveSequenceClusterCallBack();
            }
        }
    };

    const saveSequenceClusterCallBack = () => {
        getSequenceClusters(workspace.value, routePlanId).then(
            (res) => {
                if (res && res.data && res.data.length > 0) {
                    res.data.map((d) => {
                        let routeSet = zoneRouteList.find((z) => z.RouteSetId === d.RouteSetId);
                        if (routeSet) {
                            d.Route = routeSet.Label;
                        }
                    });
                    setSequenceClusterData(res.data);
                    let selected = [];
                    let NC = [...neighborhoodDataRef.current];
                    NC.map((n) => {
                        let t = res.data.find((r) => r.AnchorAreaId === n.Id);
                        if (t) {
                            n.Route = t.Route;
                            n.RouteSetId = t.RouteSetId;
                            n.Sequence = t.Sequence;
                            n.Priority = t.Priority;
                            selected.push(t.AnchorAreaId);
                        } else {
                            n.Route = '';
                            n.RouteSetId = '';
                            n.Sequence = '';
                            n.Priority = '';
                        }
                    });
                    setNeighborhoodData(NC);
                    handleDrawNeighborhoodClusters(selected);
                } else {
                    setSequenceClusterData([]);
                    let NC = [...neighborhoodData];
                    NC.map((n) => {
                        delete n.Route;
                        delete n.RouteSetId;
                        delete n.Sequence;
                        delete n.Priority;
                    });
                    setNeighborhoodData(NC);
                    handleDrawNeighborhoodClusters([]);
                }
            },
            () => {
                setSequenceClusterData([]);
                let NC = [...neighborhoodData];
                NC.map((n) => {
                    delete n.Route;
                    delete n.RouteSetId;
                    delete n.Sequence;
                    delete n.Priority;
                });
                setNeighborhoodData(NC);
                handleDrawNeighborhoodClusters([]);
            },
        );
    };

    const zoneRouteSelectionChanged = (selectedRowKeys) => {
        if (zoneMode !== 'VIEW') {
            if (unSavedChanges) {
                setWarningDialogOpen(true);
                setPreviousRoute(selectedZoneRoutes);
                setNextRoute(selectedRowKeys);
            } else {
                setPreviousRoute(null);
                setNextRoute(null);
                setSelectedZoneRoutes(selectedRowKeys);
            }
        } else {
            setPreviousRoute(null);
            setNextRoute(null);
            setSelectedZoneRoutes(selectedRowKeys);
        }
    };

    const handleStay = () => {
        setWarningDialogOpen(false);
        setNextRoute(null);
        if (previousRoute) {
            setSelectedZoneRoutes(previousRoute);
            setPreviousRoute(null);
        }
    };

    const handleLeave = () => {
        setWarningDialogOpen(false);
        setUnSavedChanges(false);
        setNCAssignment([]);
        setFirstZone([]);
        setLastZone([]);
        setSequencedZone([]);
        setSequenceMode(null);
        if (nextRoute) {
            setSelectedZoneRoutes(nextRoute);
        } else {
            setZoneMode('VIEW');
        }
    };

    const refreshAAFromRoutePlan = () => {
        if (workspace && workspace.value !== 0 && routePlanId) {
            getRouteSets(workspace.value, routePlanId).then(
                (res) => {
                    const rtList = getRouteRenderList(res.data, 'routePlanRouteList');
                    setZoneRouteList(rtList);
                    if (rtList.length > 0) {
                        const allRoutes = rtList.map((r) => r.Id);
                        setSelectedZoneRoutes(allRoutes);
                    }
                    getSequenceClusters(workspace.value, routePlanId).then(
                        (res) => {
                            if (res && res.data && res.data.length > 0) {
                                res.data.map((d) => {
                                    let routeSet = rtList.find((z) => z.RouteSetId === d.RouteSetId);
                                    if (routeSet) {
                                        d.Route = routeSet.Label;
                                    }
                                });
                                setSequenceClusterData(res.data);
                                let selected = [];
                                let NC = [...neighborhoodData];
                                NC.map((n) => {
                                    let t = res.data.find((r) => r.AnchorAreaId === n.Id);
                                    if (t) {
                                        n.Route = t.Route;
                                        n.RouteSetId = t.RouteSetId;
                                        n.Sequence = t.Sequence;
                                        n.Priority = t.Priority;
                                        selected.push(t.AnchorAreaId);
                                    }
                                });
                                setNeighborhoodData(NC);
                                handleDrawNeighborhoodClusters(selected);
                            } else {
                                setSequenceClusterData([]);
                                let NC = [...neighborhoodData];
                                NC.map((n) => {
                                    delete n.RouteSetId;
                                    delete n.Sequence;
                                    delete n.Priority;
                                });
                                setNeighborhoodData(NC);
                                handleDrawNeighborhoodClusters([]);
                            }
                        },
                        () => {
                            setSequenceClusterData([]);
                            let NC = [...neighborhoodData];
                            NC.map((n) => {
                                delete n.RouteSetId;
                                delete n.Sequence;
                                delete n.Priority;
                            });
                            setNeighborhoodData(NC);
                            handleDrawNeighborhoodClusters([]);
                        },
                    );
                },
                () => {
                    setZoneRouteList([]);
                    setSelectedZoneRoutes([]);
                },
            );
        }
    };

    const handleSelectionDetailClose = () => {
        setSelectionDetailOpen(false);
    };

    const refreshSwapRouteList = () => {
        getRoutesFromProject(workspace.value, project, serviceDay.value).then((res) => {
            if (serviceDay.value === 0) {
                const isMDR = sessionStorage.getItem('mdr') === 'true';
                if (isMDR) {
                    if (res && res.data !== '') {
                        let arr = [];
                        let list = res.data.toString().split(',');
                        list.forEach((l) => {
                            if (l == 0) {
                                arr.push({ label: 'Unassign', value: l });
                            } else {
                                arr.push({ label: l, value: l });
                            }
                        });
                        arr.sort((a, b) => a.label.localeCompare(b.label, 'en', { numeric: true }));
                        setSwapRouteList(arr);
                    } else {
                        setSwapRouteList([]);
                    }
                }
            } else {
                if (res && res.data !== '') {
                    let arr = [];
                    let list = res.data.toString().split(',');
                    list.forEach((l) => {
                        if (l == 0) {
                            arr.push({ label: 'Unassign', value: l });
                        } else {
                            arr.push({ label: l, value: l });
                        }
                    });
                    arr.sort((a, b) => a.label.localeCompare(b.label, 'en', { numeric: true }));
                    setSwapRouteList(arr);
                } else {
                    setSwapRouteList([]);
                }
            }
        });
    };

    const handleSwapClose = (mode) => {
        if (mode === 'CANCEL') {
            setSwapConfirmDialogOpen(false);
        } else if (servicePatternView) {
            let patternIds = selectedServicePattern.map((s) => s.sd_id);
            let data = {
                SdIds: patternIds.toString(),
                LockPattern: swapServicePatternSettings.lockPattern.toString(),
                ServicePattern: swapServicePatternSettings.servicePattern,
                Week: swapServicePatternSettings.week,
                Route: swapServicePatternSettings.targetRoute,
            };
            swapPatterns(workspace.value, project, data).then(
                (res) => {
                    setSwapConfirmDialogOpen(false);
                    refreshServiceLocationList();
                    setSelectedServiceLocationIds([]);
                    setSelectedServicePattern([]);
                    setSwapServicePatternSettings({ lockPattern: false });
                    refreshServicePatternInMap();
                    refreshRouteSummary();
                    //setBottomPaneGrid('RouteSummary');
                },
                (err) => {
                    setSwapConfirmDialogOpen(false);
                    showAPICallbackDialog('Error', err.response.data);
                },
            );
        } else {
            const selected = serviceLocationData.filter((s) => selectedServiceLocationIds.includes(s.Id));
            const oldRoutes = [...new Set(selected.map((item) => item.Route))];
            let data = [
                {
                    OldRoutes: oldRoutes.toString(),
                    NewRoute: swapRouteSettings.targetRoute,
                    SL_GIDs: selectedServiceLocationIds.toString(),
                },
            ];
            if (sessionStorage.getItem('mdr') === 'true') {
                //skip service day fields
            } else {
                if (serviceDay.value === 0) {
                    const oldServiceDay = [...new Set(selected.map((item) => item.ServiceDay))];
                    let newServiceDay = swapRouteSettings.serviceDay;
                    if (newServiceDay === '') {
                        newServiceDay = oldServiceDay;
                    }
                    data[0].oldServiceDay = oldServiceDay.toString();
                    data[0].newServiceDay = newServiceDay.toString();
                }
            }

            swapStops(workspace.value, project, data).then(
                (res) => {
                    setSwapConfirmDialogOpen(false);
                    // if(projectServiceLocationLayer){
                    //     projectServiceLocationLayer.definitionExpression = `workspace_id=-1`;
                    // }
                    refreshServiceLocationList();
                    refreshServiceLocationsInMap();
                    setSelectedServiceLocationIds([]);
                    setSelectedServicePattern([]);
                    setSwapRouteSettings({ targetRoute: '0', serviceDay: '' });
                    refreshRouteSummary();
                    setBottomPaneGrid('RouteSummary');
                },
                (err) => {
                    setSwapConfirmDialogOpen(false);
                    showAPICallbackDialog('Error', err.response.data);
                },
            );
        }
    };

    const handleCreateSwapRoutes = (input) => {
        if (!input) {
            return;
        }
        if (/^\w{1,10}$/.test(input)) {
            const newItem = {
                label: input,
                value: input,
            };
            let options = [...swapRouteList];
            if (options.filter((l) => l.value == input).length === 0) {
                options.push(newItem);
            }
            options.sort((a, b) => a.label.localeCompare(b.label, 'en', { numeric: true }));
            setSwapRouteList(options);
            if (sessionStorage.getItem('mdr') === 'true' && servicePatternView) {
                let s = { ...swapServicePatternSettings };
                s.targetRoute = input;
                setSwapServicePatternSettings(s);
            } else {
                let s = { ...swapRouteSettings };
                s.targetRoute = input;
                setSwapRouteSettings(s);
            }
        } else {
            return;
        }
    };

    const updateSwapRouteSettings = (e, item) => {
        let s = { ...swapRouteSettings };
        if (item === 'serviceDay') {
            if (e) {
                s[item] = e.value;
                getRoutesFromProject(workspace.value, project, e.value).then(
                    (res) => {
                        if (res && res.data !== '') {
                            let arr = [];
                            let list = res.data.toString().split(',');
                            list.forEach((l) => {
                                if (l == 0) {
                                    arr.push({ label: 'Unassign', value: l });
                                } else {
                                    arr.push({ label: l, value: l });
                                }
                            });
                            arr.sort((a, b) => a.label.localeCompare(b.label, 'en', { numeric: true }));
                            setSwapRouteList(arr);
                        } else {
                            setSwapRouteList([]);
                        }
                    },
                    (err) => {
                        setSwapRouteList([]);
                    },
                );
            } else {
                s[item] = '';
                s['targetRoute'] = '0';
                setSwapRouteList([]);
            }
        } else {
            if (e) {
                s[item] = e.value;
            } else {
                s[item] = '';
            }
        }
        setSwapRouteSettings(s);
    };

    const updateSwapServicePatternSettings = (e, item) => {
        let s = { ...swapServicePatternSettings };
        if (item === 'lockPattern') {
            s['lockPattern'] = e.target.checked;
        } else {
            if (e) {
                s[item] = e.value;
            } else {
                s[item] = null;
            }
        }
        setSwapServicePatternSettings(s);
    };

    const updateRotueStatsSettings = (e, item) => {
        let s = { ...routeStatsSettings };
        s[item] = e.value;
        if (item === 'serviceDay') {
            if (e.value === 0) {
                s['routePlan'] = 0;
            } else {
                s['routePlan'] = getDailyRoutePlan(e.value);
            }
        }
        setRouteStatsSettings(s);
    };

    const handleSandboxUpdateRouteStatsDialogClose = (mode) => {
        if (mode === 'CANCEL') {
            setSandboxUpdateRouteStatsDialogOpen(false);
        } else {
            checkDisableSandbox(workspace.value, project).then(
                (res) => {
                    if (res.data === false) {
                        setIsMapLoading(true);
                        setRunningJob(true);
                        const data = {
                            WorkspaceId: workspace.value,
                            SandboxId: project,
                            SolutionMode: 'Planner',
                            SolverMode: 'Insert',
                            Routes: '*',
                            RoutePlanId: routeStatsSettings.routePlan,
                            ServiceDay: serviceDay.value === 0 ? 'ALL DAYS' : routeStatsSettings.serviceDay,
                        };
                        submitJob(data).then(
                            (res) => {
                                setSandboxUpdateRouteStatsDialogOpen(false);
                                signalRService.startConnection(workspace.value, project);
                                signalRService.onReceiveMessage((msg) => {
                                    console.log(msg);
                                    if (msg) {
                                        let message = JSON.parse(msg);
                                        if (!message.Disabled) {
                                            if (message.Errors) {
                                                showNotificationCallbackDialog('Error', <div dangerouslySetInnerHTML={{ __html: message.Errors }} />);
                                            } else {
                                                refreshServiceLocationList();
                                                refreshSwapRouteList();
                                                refreshServiceLocationsInMap();
                                                setBottomPaneGrid('RouteSummary');
                                                refreshRouteSummary();
                                                setIsMapLoading(false);
                                                setRefreshProject(true);
                                            }
                                            setIsMapLoading(false);
                                            setRunningJob(false);
                                            signalRService.disconnect();
                                        }
                                    }
                                });
                                const logData = {
                                    WorkspaceId: workspace.value,
                                    ActionId: 1210,
                                    Mode: siteMode,
                                    Success: true,
                                    Metadata: `${routingProjects.find((r) => r.ID === project)['SandboxName']}|Solver|Insert`,
                                };
                                updateUserActivity(logData);
                            },
                            (err) => {
                                setIsMapLoading(false);
                                setRunningJob(false);
                                showAPICallbackDialog('Error', err.response.data);
                                const logData = {
                                    WorkspaceId: workspace.value,
                                    ActionId: 1210,
                                    Mode: siteMode,
                                    Success: false,
                                    Metadata: `${routingProjects.find((r) => r.ID === project)['SandboxName']}|Solver|Insert`,
                                };
                                updateUserActivity(logData);
                            },
                        );
                    } else {
                        showAPICallbackDialog('Error', 'There is a job running on this project, please trying again later!');
                    }
                },
                (err) => {
                    showAPICallbackDialog('Error', 'There is a job running on this project, please trying again later!');
                },
            );
        }
    };

    const updateSolverSettings = (e, item) => {
        let s = { ...solverSettings };
        if (item === 'number' || item === 'truckCapacity') {
            s[item] = e.target.value;
        } else if (item === 'anchorWithServiceDay' || item === 'anchorWithRoute') {
            s[item] = e.target.checked;
        } else if (item.includes('Load')) {
            if (e) {
                s[item] = e.value;
            } else {
                s[item] = null;
            }
        } else {
            s[item] = e.value;
        }

        if (item === 'routePlan') {
            const plan = routePlanList.find((r) => r.value === e.value);
            if (plan) {
                s['method'] = plan.GenerateMethod;
                if (plan.RouteCount < 1) {
                    s['zones'] = 'Off';
                } else {
                    s['zones'] = plan.Zones ? plan.Zones : 'Off';
                }
                if (plan.GenerateMethod === 'Number') {
                    s['number'] = plan.NumRoutes.toString();
                } else {
                    s['number'] = '';
                }
            }
        } else if (item === 'scenario') {
            s['zones'] = 'Off';
            s['method'] = '';
            if (e.value === 'CreateServiceDays') {
                s['anchorWithServiceDay'] = true;
                s['numberOfDays'] = '5';
                s['balanceOn'] = 'Time';
                s['truckCapacity'] = defaultTruckCapacity;
            } else if (e.value === 'CreateRouteTerritories') {
                s['serviceDay'] = serviceDay.value;
                s['routePlan'] = routePlanList[0].value;
                s['balanceOn'] = 'Time';
                s['anchorWithRoute'] = false;
                const plan = routePlanList[0];
                if (plan) {
                    s['method'] = plan.GenerateMethod;
                    if (plan.RouteCount < 1) {
                        s['zones'] = 'Off';
                    } else {
                        s['zones'] = plan.Zones ? plan.Zones : 'Off';
                    }
                    if (plan.GenerateMethod === 'Number') {
                        s['number'] = plan.NumRoutes.toString();
                    } else {
                        s['number'] = '';
                    }
                }
            } else if (e.value === 'BalanceServiceDays') {
                s['dailyRouteTime'] = '8.00';
                s['monLoad'] = '1.00';
                s['tuesLoad'] = '1.00';
                s['wedLoad'] = '1.00';
                s['thurLoad'] = '1.00';
                s['friLoad'] = '1.00';
                s['satLoad'] = null;
            } else if (e.value === 'Sequence') {
                s['serviceDay'] = serviceDay.value;
            }
        } else if (item === 'method') {
            s['number'] = '';
            s['zones'] = 'Off';
            if (s['scenario'] === 'CreateRouteTerritories') {
                s['balanceOn'] = 'Time';
            } else if (e.value === 'Full') {
                s['balanceOn'] = 'Time';
            }
        } else if (item == 'serviceDay') {
            if (e.value === 0) {
                s['routePlan'] = 0;
            } else {
                s['routePlan'] = getDailyRoutePlan(e.value);
            }
        } else if (item === 'zones') {
            if ((s['scenario'] === 'CreateRouteTerritories' || s['scenario'] === 'Create') && e.value === 'Routes') {
                s['anchorWithRoute'] = false;
            }
        }
        setSolverSettings(s);
    };

    const handleSandboxSolverDialogClose = (mode) => {
        let ss = { ...solverSettings };
        if (routePlanList.length === 1) {
            ss.routePlan = routePlanList[0].value;
            ss.method = routePlanList[0].GenerateMethod;
            if (routePlanList[0].RouteCount < 1) {
                ss.zones = 'Off';
            } else {
                ss.zones = routePlanList[0].Zones ? routePlanList[0].Zones : 'Off';
            }
            if (routePlanList[0].GenerateMethod === 'Number') {
                ss.number = routePlanList[0].NumRoutes.toString();
            } else {
                ss.number = '';
            }
        }
        if (mode === 'CANCEL') {
            setSolverSettings(ss);
            setSandboxSolverDialogOpen(false);
        } else {
            checkDisableSandbox(workspace.value, project).then(
                (res) => {
                    if (res.data === false) {
                        setIsMapLoading(true);
                        setRunningJob(true);
                        let data = {
                            WorkspaceId: workspace.value,
                            SandboxId: project,
                            SolutionMode: 'Planner',
                            SolverMode: solverSettings.scenario,
                            Routes: '*',
                        };
                        if (solverSettings.scenario === 'CreateServiceDays') {
                            data.NumberOfRoutes = solverSettings.numberOfDays;
                            data.BalanceOn = solverSettings.balanceOn;
                            data.ReuseExistPart = solverSettings.anchorWithServiceDay ? 1 : 0;
                            data.VehicleCapacity = solverSettings.truckCapacity;
                        } else if (solverSettings.scenario === 'CreateRouteTerritories') {
                            data.RoutePlanId = solverSettings.routePlan;
                            data.GenerateMethod = solverSettings.method;
                            data.Zones = solverSettings.zones;
                            if (solverSettings.method === 'Number') {
                                data.NumberOfRoutes = solverSettings.number;
                                data.BalanceOn = solverSettings.balanceOn;
                            } else if (solverSettings.method === 'Full') {
                                data.BalanceOn = solverSettings.balanceOn;
                            }
                            data.ReuseExistPart = solverSettings.anchorWithRoute ? 1 : 0;
                        } else if (solverSettings.scenario === 'BalanceServiceDays') {
                            data.InsDesiredLength = solverSettings.dailyRouteTime;
                            data.MDServDayMult1 = solverSettings.monLoad;
                            data.MDServDayMult2 = solverSettings.tuesLoad;
                            data.MDServDayMult3 = solverSettings.wedLoad;
                            data.MDServDayMult4 = solverSettings.thurLoad;
                            data.MDServDayMult5 = solverSettings.friLoad;
                            data.MDServDayMult6 = solverSettings.satLoad ? solverSettings.satLoad : '0.00';
                            if (data.MDServDayMult6 === '0.00') {
                                data.multiUseSat = '0';
                            } else {
                                data.multiUseSat = '1';
                            }
                        } else if (solverSettings.scenario === 'Create') {
                            data.ServiceDay = serviceDay.value === 0 ? 'ALL DAYS' : serviceDay.value.toString();
                            data.RoutePlanId = solverSettings.routePlan;
                            data.ReuseExistPart = solverSettings.anchorWithRoute ? 1 : 0;
                            if (serviceDay.value !== 0) {
                                data.Zones = solverSettings.zones;
                                data.GenerateMethod = solverSettings.method;
                                if (solverSettings.method === 'Number') {
                                    data.NumberOfRoutes = solverSettings.number;
                                }
                            }
                        } else if (solverSettings.scenario === 'Sequence') {
                            data.ServiceDay = serviceDay.value === 0 ? 'ALL DAYS' : serviceDay.value.toString();
                            if (serviceDay.value !== 0) {
                                data.RoutePlanId = solverSettings.routePlan;
                                if (solverSettings.zones) {
                                    data.Zones = solverSettings.zones;
                                }
                            }
                        } else if (solverSettings.scenario === 'Insert') {
                            data.ServiceDay = serviceDay.value === 0 ? 'ALL DAYS' : serviceDay.value.toString();
                            data.RoutePlanId = solverSettings.routePlan;
                        }
                        submitJob(data).then(
                            (res) => {
                                setSolverSettings(ss);
                                setSandboxSolverDialogOpen(false);
                                signalRService.startConnection(workspace.value, project);
                                signalRService.onReceiveMessage((msg) => {
                                    console.log(msg);
                                    if (msg) {
                                        let message = JSON.parse(msg);
                                        if (!message.Disabled) {
                                            if (message.Errors) {
                                                showNotificationCallbackDialog('Error', <div dangerouslySetInnerHTML={{ __html: message.Errors }} />);
                                            } else {
                                                refreshServiceLocationList();
                                                refreshSwapRouteList();
                                                refreshServiceLocationsInMap();
                                                setBottomPaneGrid('RouteSummary');
                                                refreshRouteSummary();
                                                setRefreshProject(true);
                                            }
                                            setRunningJob(false);
                                            setIsMapLoading(false);
                                            signalRService.disconnect();
                                        }
                                    }
                                });

                                const logData = {
                                    WorkspaceId: workspace.value,
                                    ActionId: 1210,
                                    Mode: siteMode,
                                    Success: true,
                                    Metadata: `${routingProjects.find((r) => r.ID === project)['SandboxName']}|Solver|${data.SolverMode}`,
                                };
                                updateUserActivity(logData);
                            },
                            (err) => {
                                setIsMapLoading(false);
                                setRunningJob(false);
                                showAPICallbackDialog('Error', JSON.stringify(err.response.data));
                                const logData = {
                                    WorkspaceId: workspace.value,
                                    ActionId: 1210,
                                    Mode: siteMode,
                                    Success: false,
                                    Metadata: `${routingProjects.find((r) => r.ID === project)['SandboxName']}|Solver|${data.SolverMode}`,
                                };
                                updateUserActivity(logData);
                            },
                        );
                    } else {
                        showAPICallbackDialog('Error', 'There is a job running on this project, please trying again later!');
                    }
                },
                (err) => {
                    showAPICallbackDialog('Error', 'There is a job running on this project, please trying again later!');
                },
            );
        }
    };

    const handleZoomToRoute = (id, fullLabel) => {
        if (serviceDay.value === 0) {
            if (fullLabel) {
                if (routeViewTabCategorization.value.startsWith('R')) {
                    //need adjustment here
                    let route_day = fullLabel.split('|');
                    getSelectedRouteExtent(workspace.value, route_day[1], [route_day[0]], siteMode, project).then((res) => {
                        zoomToExtent(res.data);
                        updateUserActivity({ ActionId: 228, WorkspaceId: workspace.value, Mode: siteMode, Metadata: id });
                    });
                } else {
                    let day_route = fullLabel.split('|');
                    getSelectedRouteExtent(workspace.value, day_route[0], [day_route[1]], siteMode, project).then((res) => {
                        zoomToExtent(res.data);
                        updateUserActivity({ ActionId: 228, WorkspaceId: workspace.value, Mode: siteMode, Metadata: id });
                    });
                }
            } else {
                if (routeViewTabCategorization.value.startsWith('R')) {
                    //need adjustment here
                    if (id === 'No Route') id = '0';
                    getSelectedRouteExtent(workspace.value, serviceDay.value, [id], siteMode, project).then((res) => {
                        zoomToExtent(res.data);
                        updateUserActivity({ ActionId: 228, WorkspaceId: workspace.value, Mode: siteMode, Metadata: id });
                    });
                } else {
                    getSelectedRouteExtent(workspace.value, id, [], siteMode, project).then((res) => {
                        zoomToExtent(res.data);
                        updateUserActivity({ ActionId: 228, WorkspaceId: workspace.value, Mode: siteMode, Metadata: id });
                    });
                }
            }
        } else {
            if (id === 'No Route') id = '0';
            getSelectedRouteExtent(workspace.value, serviceDay.value, [id], siteMode, project).then((res) => {
                zoomToExtent(res.data);
                updateUserActivity({ ActionId: 228, WorkspaceId: workspace.value, Mode: siteMode, Metadata: id });
            });
        }
    };

    const handleZoomToSelectedRoutes = () => {
        if (serviceDay.value === 0) {
            let data = [];
            if (routeViewTabCategorization.value.startsWith('R')) {
                routeViewTableData[routeViewTabCategorization.value].forEach((rs) => {
                    //need adjustment here
                    rs.subRows.forEach((rss) => {
                        if (rss.checked) {
                            data.push({ route: rs.Id, day: parseInt(rss.Id) });
                        }
                    });
                });
            } else {
                routeViewTableData[routeViewTabCategorization.value].forEach((rs) => {
                    //need adjustment here
                    rs.subRows.forEach((rss) => {
                        if (rss.checked) {
                            data.push({ route: rss.Id, day: parseInt(rs.Id) });
                        }
                    });
                });
            }
            getRouteDayExtent(workspace.value, data, siteMode, project).then((res) => {
                zoomToExtent(res.data);
                updateUserActivity({ ActionId: 228, WorkspaceId: workspace.value, Mode: siteMode, Metadata: workspace.value });
            });
        } else {
            let routes = [];
            routes = structuredClone(routeViewTableData['R'])
                .filter((r) => r.checked === true)
                .map((r) => r.Id); //need adjustment here
            routes = routes.map((item) => (item === 'No Route' ? '0' : item));
            getSelectedRouteExtent(workspace.value, serviceDay.value, routes, siteMode, project).then((res) => {
                zoomToExtent(res.data);
                updateUserActivity({ ActionId: 223, WorkspaceId: workspace.value });
            });
        }
    };

    const handleZoomToScenarioRoute = (id) => {
        getJobRouteExtent(workspace.value, selectedScenarioId, id).then((res) => {
            if (res && res.data) {
                zoomToExtent(res.data);
            }
        });
    };

    const handleZoomToSelectedScenarioRoutes = (selScenarioRoutes) => {
        let ids;
        if (!selScenarioRoutes || !Array.isArray(selScenarioRoutes)) {
            ids = selectedScenarioRoutes.toString();
        } else {
            ids = selScenarioRoutes.toString();
        }
        getJobRouteExtent(workspace.value, selectedScenarioId, ids).then((res) => {
            if (res && res.data) {
                zoomToExtent(res.data);
                updateUserActivity({ ActionId: 223, WorkspaceId: workspace.value });
            }
        });
    };

    const handleDownloadNavusoft = () => {
        sendToNavusoft(workspace.value, siteMode, project).then(
            (res) => {
                if (res.status === 200) {
                    showAPICallbackDialog('Success', 'Sent to Navusoft successfully!');
                } else {
                    showAPICallbackDialog('Error', 'Failed! Please try again later');
                }
            },
            (err) => {
                showAPICallbackDialog('Error', err.response.data);
            },
        );
    };

    const handlePinServiceLocations = () => {
        setMapSelectionMode('SL_PIN');
        setMapInfoMessage('Click on map to specify stop');
        pickXYSketch.create('point');
    };

    const handlePinServiceLocationsDialogClose = (option) => {
        if (option === 'CANCEL') {
            pickXYGraphicsLayer.removeAll();
            setSelStreetMaintenanceMode(null);
            setPinServiceLocationDialogOpen(false);
        } else if (option === 'PIN') {
            pickXYGraphicsLayer.removeAll();
            setSelStreetMaintenanceMode(null);
            let Ids = [...selectedServiceLocationIdsRef.current];
            pinServiceLocations(
                workspaceId.current.value,
                selectedServiceLocationIdsRef.current,
                pickedLinkIdRef.current,
                pickedXYRef.current.X,
                pickedXYRef.current.Y,
            ).then(
                (res) => {
                    Ids.forEach((s) => {
                        const logData = {
                            WorkspaceId: workspaceId.current.value,
                            ActionId: 208,
                            Mode: siteMode,
                            Success: true,
                            Metadata: s.toString(),
                        };
                        updateUserActivity(logData);
                    });
                    setPinServiceLocationDialogOpen(false);
                    setSelectedServiceLocationIds([]);
                    setSelectionDetailOpen(false);
                    refreshServiceLocationListAfterPin();
                    serviceLocationLayer.refresh();
                },
                (err) => {
                    showAPICallbackDialog('Error', err.response.data);
                    Ids.forEach((s) => {
                        const logData = {
                            WorkspaceId: workspaceId.current.value,
                            ActionId: 208,
                            Mode: siteMode,
                            Success: false,
                            Metadata: s.toString(),
                        };
                        updateUserActivity(logData);
                    });
                },
            );
        }
    };

    const handleUnpinServiceLocationsDialogClose = (option) => {
        if (option === 'CANCEL') {
            setUnpinServiceLocationDialogOpen(false);
        } else if (option === 'UNPIN') {
            unpinServiceLocations(workspace.value, selectedServiceLocationIds).then(
                (res) => {
                    selectedServiceLocationIds.forEach((s) => {
                        const logData = {
                            WorkspaceId: workspace.value,
                            ActionId: 209,
                            Mode: siteMode,
                            Success: true,
                            Metadata: s.toString(),
                        };
                        updateUserActivity(logData);
                    });
                    setUnpinServiceLocationDialogOpen(false);
                    setSelectedServiceLocationIds([]);
                    refreshServiceLocationListAfterPin();
                    serviceLocationLayer.refresh();
                },
                (err) => {
                    showAPICallbackDialog('Error', err.response.data);
                    selectedServiceLocationIds.forEach((s) => {
                        const logData = {
                            WorkspaceId: workspace.value,
                            ActionId: 209,
                            Mode: siteMode,
                            Success: false,
                            Metadata: s.toString(),
                        };
                        updateUserActivity(logData);
                    });
                },
            );
        }
    };

    const handleFlipServiceLocations = () => {
        if (selectedServiceLocationIds.length > 0) {
            flipServiceLocations(workspace.value, selectedServiceLocationIds).then(
                (res) => {
                    selectedServiceLocationIds.forEach((s) => {
                        const logData = {
                            WorkspaceId: workspace.value,
                            ActionId: 210,
                            Mode: siteMode,
                            Success: true,
                            Metadata: s.toString(),
                        };
                        updateUserActivity(logData);
                    });
                    setSelectedServiceLocationIds([]);
                    setRefreshServiceLocations(true);
                    refreshServiceLocationListAfterPin();
                    serviceLocationLayer.refresh();
                },
                (err) => {
                    showAPICallbackDialog('Error', err.response.data);
                    selectedServiceLocationIds.forEach((s) => {
                        const logData = {
                            WorkspaceId: workspace.value,
                            ActionId: 210,
                            Mode: siteMode,
                            Success: false,
                            Metadata: s.toString(),
                        };
                        updateUserActivity(logData);
                    });
                },
            );
        }
    };

    const handleEditServiceLocation = (data) => {
        if (data) {
            let time = null;
            if (data.ServiceTime) {
                time = new Date(data.ServiceTime * 3600 * 1000).toISOString().slice(11, 19);
            }
            let serviceWindowData = {};
            serviceWindowData.WorkspaceID = workspace.value;
            serviceWindowData.AccountId = data.AccountId;
            serviceWindowData.SequenceId = data.SequenceId;
            serviceWindowData.ServiceDay = data.ServiceDay;
            serviceWindowData.EarliestArrival = data.EarliestArrival;
            serviceWindowData.LatestArrival = data.LatestArrival;
            serviceWindowData.NoServiceStart = data.NoServiceStart;
            serviceWindowData.NoServiceEnd = data.NoServiceEnd;
            serviceWindowData.Address = data.Address;
            serviceWindowData.Demand = data.Demand;
            serviceWindowData.ServiceTime = time;
            serviceWindowData.AlleyService = data.AlleyService;
            setServiceLocationTarget(serviceWindowData);
            if (data.SkillSetProperties && data.SkillSetProperties.length > 0) {
                let s = [];
                data.SkillSetProperties.forEach((ss, i) => {
                    let ds = specialtyList.find((d) => d.value === ss);
                    if (ds) {
                        s.push({ ...ds, Id: i });
                    }
                });
                setServiceLocationSpecialties(s);
            } else {
                setServiceLocationSpecialties([]);
            }
            setEditServiceLocationOpen(true);
        }
    };

    const editSelectedServiceLocations = () => {
        let target = {};
        if (selectedServiceLocationIds.length > 0) {
            if (selectedServiceLocationIds.length === 1) {
                let data = serviceLocationData.find((s) => s.Id === selectedServiceLocationIds[0]);
                if (data) {
                    let time = null;
                    if (data.ServiceTime) {
                        time = new Date(data.ServiceTime * 3600 * 1000).toISOString().slice(11, 19);
                    }
                    target.WorkspaceID = workspace.value;
                    target.AccountId = data.AccountId;
                    target.SequenceId = data.SequenceId;
                    target.ServiceDay = data.ServiceDay;
                    target.EarliestArrival = data.EarliestArrival;
                    target.LatestArrival = data.LatestArrival;
                    target.NoServiceStart = data.NoServiceStart;
                    target.NoServiceEnd = data.NoServiceEnd;
                    target.Address = data.Address;
                    target.Demand = data.Demand;
                    target.ServiceTime = time;
                    target.AlleyService = data.AlleyService;
                    setServiceLocationTarget(target);
                    if (data.SkillSetProperties) {
                        let s = [];
                        data.SkillSetProperties.forEach((ss, i) => {
                            let ds = specialtyList.find((d) => d.value === ss);
                            s.push({ ...ds, Id: i });
                        });
                        setServiceLocationSpecialties(s);
                    } else {
                        setServiceLocationSpecialties([]);
                    }
                }
            } else {
                target.WorkspaceID = workspace.value;
                target.AccountId = null;
                target.SequenceId = null;
                target.ServiceDay = null;
                target.EarliestArrival = null;
                target.LatestArrival = null;
                target.NoServiceStart = null;
                target.NoServiceEnd = null;
                target.Demand = null;
                target.ServiceTime = null;
                target.AlleyService = null;
                setServiceLocationTarget(target);
                setServiceLocationSpecialties([]);
            }
            setEditServiceLocationOpen(true);
        }
    };

    const handleDeleteSelectedServiceLocations = (mode) => {
        if (mode === 'CANCEL') {
            setDeleteServiceLocationDialogOpen(false);
        } else if (mode === 'DELETE') {
            let data = [...selectedServiceLocationIds];
            setIsMapLoading(true);
            deleteServiceLocations(workspace.value, data, siteMode, project).then(
                (res) => {
                    setSelectedServiceLocationIds([]);
                    setDeleteServiceLocationDialogOpen(false);
                    refreshServiceLocationList();
                    if (siteMode === 'PLANNER') refreshSwapRouteList();
                    refreshServiceLocationsInMap();
                    setIsMapLoading(false);
                },
                (err) => {
                    setIsMapLoading(false);
                    showAPICallbackDialog('Error', err.response.data);
                },
            );
        }
    };

    const updateTimeWindow = (control, e) => {
        let d = { ...serviceLocationTarget };
        if (e && e.value) {
            d[control] = e.value;
        } else {
            if (selectedServiceLocationIds.length > 1) {
                d[control] = null;
            } else {
                d[control] = 0;
            }
        }
        setServiceLocationTarget(d);
    };

    const updateServiceLocations = (control, e) => {
        let s = { ...serviceLocationTarget };
        if (control === 'AlleyService') {
            s[control] = e;
        } else {
            s[control] = e.target.value;
        }
        setServiceLocationTarget(s);
    };

    const updateServiceLocationSpecialties = (e, i) => {
        let s = [...serviceLocationSpecialties];
        s.map((sl) => {
            if (sl.Id === i) {
                sl.label = e.label;
                sl.value = e.value;
            }
            return sl;
        });
        setServiceLocationSpecialties(s);
    };

    const handleEditServiceLocationClose = (mode) => {
        if (mode === 'SAVE') {
            if (serviceLocationTarget.ServiceTime !== null) {
                serviceLocationTarget.ServiceTime = hhmmssToFloat(serviceLocationTarget.ServiceTime);
            }
            if (serviceLocationTarget.AlleyService) {
                serviceLocationTarget.AlleyService = serviceLocationTarget.AlleyService.value;
            }
            let SkillSetProperties = [];
            if (serviceLocationSpecialties.length > 0) {
                serviceLocationSpecialties.forEach((ds) => {
                    if (ds.value) {
                        SkillSetProperties.push(ds.value);
                    }
                });
            }
            let data = [];
            if (selectedServiceLocationIds.length > 1 && !serviceLocationTarget.AccountId) {
                let oldData = serviceLocationData.filter((s) => selectedServiceLocationIds.includes(s.Id));
                oldData.forEach((d) => {
                    data.push({
                        WorkspaceID: d.WorkspaceID,
                        AccountId: d.AccountId,
                        SequenceId: d.SequenceId,
                        ServiceDay: d.ServiceDay,
                        EarliestArrival: serviceLocationTarget.EarliestArrival ? serviceLocationTarget.EarliestArrival : d.EarliestArrival,
                        LatestArrival: serviceLocationTarget.LatestArrival ? serviceLocationTarget.LatestArrival : d.LatestArrival,
                        NoServiceStart: serviceLocationTarget.NoServiceStart ? serviceLocationTarget.NoServiceStart : d.NoServiceStart,
                        NoServiceEnd: serviceLocationTarget.NoServiceEnd ? serviceLocationTarget.NoServiceEnd : d.NoServiceEnd,
                        Demand: serviceLocationTarget.Demand !== null ? Number(serviceLocationTarget.Demand) : d.Demand,
                        ServiceTime: serviceLocationTarget.ServiceTime !== null ? serviceLocationTarget.ServiceTime : d.ServiceTime,
                        AlleyService: serviceLocationTarget.AlleyService !== null ? serviceLocationTarget.AlleyService : d.AlleyService,
                        SkillSetProperties: SkillSetProperties,
                    });
                });
            } else if (serviceLocationTarget.AccountId) {
                data.push({
                    WorkspaceID: serviceLocationTarget.WorkspaceID,
                    AccountId: serviceLocationTarget.AccountId,
                    SequenceId: serviceLocationTarget.SequenceId,
                    ServiceDay: serviceLocationTarget.ServiceDay,
                    EarliestArrival: serviceLocationTarget.EarliestArrival,
                    LatestArrival: serviceLocationTarget.LatestArrival,
                    NoServiceStart: serviceLocationTarget.NoServiceStart,
                    NoServiceEnd: serviceLocationTarget.NoServiceEnd,
                    Demand: Number(serviceLocationTarget.Demand),
                    ServiceTime: serviceLocationTarget.ServiceTime,
                    AlleyService: serviceLocationTarget.AlleyService ? serviceLocationTarget.AlleyService : '',
                    SkillSetProperties: SkillSetProperties,
                });
            }

            updateServiceWindow(workspace.value, data).then(
                (res) => {
                    setRefreshServiceLocations(true);
                    setRefreshMap(true);
                    setEditServiceLocationOpen(false);
                    setServiceLocationTarget(null);
                },
                (err) => {
                    showAPICallbackDialog('Error', err.response.data);
                },
            );
        } else {
            setEditServiceLocationOpen(false);
            setServiceLocationTarget(null);
            setServiceLocationSpecialties([]);
        }
    };

    const addServiceLocationSpecialty = () => {
        let newServiceLocationSpecialties = [...serviceLocationSpecialties];
        const id = Math.random();
        if (newServiceLocationSpecialties.filter((s) => s.Id === id).length === 0) {
            newServiceLocationSpecialties.push({ Id: id, label: null, value: null });
        } else {
            newServiceLocationSpecialties.push({ Id: -id, label: null, value: null });
        }
        setServiceLocationSpecialties(newServiceLocationSpecialties);
    };

    const deleteServiceLocationSpecialty = (id) => {
        let newServiceLocationSpecialties = serviceLocationSpecialties.filter((x) => x.Id !== id);
        setServiceLocationSpecialties(newServiceLocationSpecialties);
    };

    useEffect(() => {
        let options = [...specialtyList];
        options.map((o) => {
            if (serviceLocationSpecialties.find((ds) => ds.value === o.value)) {
                o.isDisabled = true;
            } else {
                o.isDisabled = false;
            }
            return o;
        });
        setSpecialtyList(options);
    }, [serviceLocationSpecialties]);

    const getDailyRoutePlan = (serviceDay) => {
        let routePlan = {};
        if (routePlans && routePlans.length > 0) {
            switch (serviceDay) {
                case 1:
                    routePlan = routePlans.find((d) => d.ServDayMonday === true);
                    break;
                case 2:
                    routePlan = routePlans.find((d) => d.ServDayTuesday === true);
                    break;
                case 3:
                    routePlan = routePlans.find((d) => d.ServDayWednesday === true);
                    break;
                case 4:
                    routePlan = routePlans.find((d) => d.ServDayThursday === true);
                    break;
                case 5:
                    routePlan = routePlans.find((d) => d.ServDayFriday === true);
                    break;
                case 6:
                    routePlan = routePlans.find((d) => d.ServDaySaturday === true);
                    break;
                case 7:
                    routePlan = routePlans.find((d) => d.ServDaySunday === true);
                    break;
                default:
                    routePlan = routePlans[0];
            }
            if (routePlan) {
                return routePlan.Id;
            } else {
                return routePlans[0].Id;
            }
        }
        return 0;
    };

    const handleTripsPanelClose = () => {
        setShowTrips(false);
    };

    const handleToggleServicePattern = () => {
        setServicePatternView(!servicePatternView);
    };

    const handleManageServicePattern = () => {
        if (!showServicePattern) {
            getServicePatterns(workspace.value, project).then(
                (res) => {
                    setServicePatternData(res.data);
                },
                (err) => {
                    setServicePatternData([]);
                },
            );
        }
        setShowServicePattern(!showServicePattern);
    };

    const handleServicePatternDialogClose = () => {
        setShowServicePattern(false);
    };

    const handleExportServicePattern = () => {
        let windowReference = null;
        if (isMobile()) {
            windowReference = window.open();
            windowReference.document.write('<h1>Generating export...Please wait...</h1>');
        }
        setIsMapLoading(true);
        if (serviceDay.value === 0) {
            let data = [];
            if (routeViewTabCategorization.value.startsWith('R')) {
                routeViewTableData[routeViewTabCategorization.value].forEach((rs) => {
                    //need adjustment here
                    rs.subRows.forEach((rss) => {
                        if (rss.checked) {
                            data.push({ route: rs.Id, day: parseInt(rss.Id) });
                        }
                    });
                });
            } else {
                routeViewTableData[routeViewTabCategorization.value].forEach((rs) => {
                    //need adjustment here
                    rs.subRows.forEach((rss) => {
                        if (rss.checked) {
                            data.push({ route: rss.Id, day: parseInt(rs.Id) });
                        }
                    });
                });
            }
            exportRouteDayServicePatterns(workspace.value, project, data).then(
                (res) => {
                    if (res.status === 200) {
                        setIsMapLoading(false);
                        let blob = new Blob([res.data], { type: 'text/csv; charset=utf-8' });
                        let fileName = `ServicePattern-${workspace.label}-${formatFileTimeStamp()}.csv`;
                        if (blob.size > 0) {
                            let url = window.URL.createObjectURL(blob);
                            if (isMobile()) {
                                windowReference.document.body.innerHTML = '';
                                windowReference.location = url;
                            } else {
                                if (!window.navigator.msSaveOrOpenBlob) {
                                    let anchor = document.createElement('a');
                                    anchor.href = url;
                                    anchor.download = fileName;
                                    anchor.click();
                                } else {
                                    window.navigator.msSaveOrOpenBlob(blob, fileName);
                                }
                            }
                            setTimeout(() => window.URL.revokeObjectURL(url), 0);
                        } else {
                            if (isMobile()) {
                                windowReference.document.body.innerHTML = '';
                                windowReference.document.write('<h1>No content</h1>');
                            }
                        }
                        // const logData = {
                        //     WorkspaceId: workspace.value,
                        //     ActionId: 243,
                        //     Mode: siteMode,
                        //     Success: true,
                        //     Metadata: workspace.value + DetailStr
                        // };
                        // updateUserActivity(logData);
                    } else if (res.status === 204) {
                        setIsMapLoading(false);
                        showAPICallbackDialog('Error', 'No content under selected service day.');
                        // const logData = {
                        //     WorkspaceId: workspace.value,
                        //     ActionId: 243,
                        //     Mode: siteMode,
                        //     Success: false,
                        //     ErrorMessage: "No content under selected service day.",
                        //     Metadata: workspace.value + DetailStr
                        // };
                        // updateUserActivity(logData);
                    }
                },
                (err) => {
                    setIsMapLoading(false);

                    const enc = new TextDecoder('utf-8');
                    const arr = new Uint8Array(err.response.data);
                    const str = enc.decode(arr);
                    //const jsn = JSON.parse(str);
                    showAPICallbackDialog('Error', str);
                    // const logData = {
                    //     WorkspaceId: workspace.value,
                    //     ActionId: 243,
                    //     Mode: siteMode,
                    //     Success: false,
                    //     ErrorMessage: `${str}` || "Unknown error.",
                    //     Metadata: workspace.value + DetailStr
                    // };
                    // updateUserActivity(logData);
                },
            );
        } else {
            let routes = '';
            routes = structuredClone(routeViewTableData['R'])
                .filter((r) => r.checked === true)
                .map((r) => r.Id); //need adjustment here
            exportServicePattern(workspace.value, serviceDay.value, project, routes.join()).then(
                (res) => {
                    if (res.status === 200) {
                        setIsMapLoading(false);
                        let blob = new Blob([res.data], { type: 'text/csv; charset=utf-8' });
                        let fileName = `ServicePattern-${workspace.label}-${formatFileTimeStamp()}.csv`;
                        if (blob.size > 0) {
                            let url = window.URL.createObjectURL(blob);
                            if (isMobile()) {
                                windowReference.document.body.innerHTML = '';
                                windowReference.location = url;
                            } else {
                                if (!window.navigator.msSaveOrOpenBlob) {
                                    let anchor = document.createElement('a');
                                    anchor.href = url;
                                    anchor.download = fileName;
                                    anchor.click();
                                } else {
                                    window.navigator.msSaveOrOpenBlob(blob, fileName);
                                }
                            }
                            setTimeout(() => window.URL.revokeObjectURL(url), 0);
                        } else {
                            if (isMobile()) {
                                windowReference.document.body.innerHTML = '';
                                windowReference.document.write('<h1>No content</h1>');
                            }
                        }
                        // const logData = {
                        //     WorkspaceId: workspace.value,
                        //     ActionId: 243,
                        //     Mode: siteMode,
                        //     Success: true,
                        //     Metadata: workspace.value + DetailStr
                        // };
                        // updateUserActivity(logData);
                    } else if (res.status === 204) {
                        setIsMapLoading(false);
                        showAPICallbackDialog('Error', 'No content under selected service day.');
                        // const logData = {
                        //     WorkspaceId: workspace.value,
                        //     ActionId: 243,
                        //     Mode: siteMode,
                        //     Success: false,
                        //     ErrorMessage: "No content under selected service day.",
                        //     Metadata: workspace.value + DetailStr
                        // };
                        // updateUserActivity(logData);
                    }
                },
                (err) => {
                    setIsMapLoading(false);

                    const enc = new TextDecoder('utf-8');
                    const arr = new Uint8Array(err.response.data);
                    const str = enc.decode(arr);
                    //const jsn = JSON.parse(str);
                    showAPICallbackDialog('Error', str);
                    // const logData = {
                    //     WorkspaceId: workspace.value,
                    //     ActionId: 243,
                    //     Mode: siteMode,
                    //     Success: false,
                    //     ErrorMessage: `${str}` || "Unknown error.",
                    //     Metadata: workspace.value + DetailStr
                    // };
                    // updateUserActivity(logData);
                },
            );
        }
    };

    const updateAvailability = (id, data) => {
        let d = { ...data };
        d.Available = !d.Available;
        updateServicePattern(workspace.value, project, id, d).then(
            (r) => {
                getServicePatterns(workspace.value, project).then(
                    (res) => {
                        setServicePatternData(res.data);
                    },
                    (err) => {
                        setServicePatternData([]);
                    },
                );
            },
            (err) => {
                showAPICallbackDialog('Error', err.response.data);
            },
        );
    };

    const handleRemoveServicePattern = (data) => {
        setOpenConfirmation(true);
        setServicePatternIdToRemove(data.Id);
        let sp = data.ServicePattern;
        let pattern = { 1: 'M', 2: 'T', 3: 'W', 4: 'R', 5: 'F', 6: 'S', 7: 'U' };
        sp = sp.substring(1).replace(/[1-7]/gi, (s) => pattern[s]);
        setConfirmationMessage(`Are you sure you wish to delete the selected service pattern &lt;${sp}&gt;`);
    };

    const removeServicePattern = () => {
        if (servicePatternIdToRemove) {
            deleteServicePattern(workspace.value, project, servicePatternIdToRemove).then(
                (r) => {
                    setServicePatternIdToRemove(null);
                    getServicePatterns(workspace.value, project).then(
                        (res) => {
                            setServicePatternData(res.data);
                        },
                        (err) => {
                            setServicePatternData([]);
                        },
                    );
                },
                (err) => {
                    setServicePatternIdToRemove(null);
                    showAPICallbackDialog('Error', err.response.data);
                },
            );
            setOpenConfirmation(false);
            setConfirmationMessage(null);
        }
    };

    const handleUpdateServicePatternDialogOpen = () => {
        setUpdateServicePatternDialogOpen(true);
    };

    const handleUpdateServicePatternDialogClose = (mode) => {
        if (mode === 'SAVE') {
            let pattern = selectedServicePatternForAdd.toString().replace(/,/g, '');
            let newPattern = selectedServicePatternForAdd.length + pattern;
            let existPattern = servicePatternData.filter((s) => s.ServicePattern == newPattern);
            let hasDuplicate = existPattern.length > 0;
            if (!hasDuplicate) {
                let data = {
                    WorkspaceId: workspace.value,
                    SandboxId: project,
                    ServicePattern: newPattern,
                    Available: false,
                };
                createServicePattern(workspace.value, project, data).then(
                    (r) => {
                        getServicePatterns(workspace.value, project).then(
                            (res) => {
                                setServicePatternData(res.data);
                                setUpdateServicePatternDialogOpen(false);
                                setSelectedServicePatternForAdd([]);
                            },
                            (err) => {
                                setServicePatternData([]);
                                setUpdateServicePatternDialogOpen(false);
                                setSelectedServicePatternForAdd([]);
                            },
                        );
                    },
                    (err) => {
                        showAPICallbackDialog('Error', err.response.data);
                    },
                );
            }
        } else {
            setUpdateServicePatternDialogOpen(false);
            setSelectedServicePatternForAdd([]);
        }
    };

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const numberCreating = (args) => {
        if (!args.text || isNaN(args.text) || !Number.isInteger(Number(args.text))) {
            args.customItem = null;
            return;
        }
        if (parseInt(args.text) > 0 && parseInt(args.text) < 1000) {
            const newItem = {
                label: args.text,
                value: args.text,
            };

            let list = [...routeNumberList];
            if (list.filter((l) => l.value === args.text).length === 0) {
                list.push(newItem);
            }
            list.sort((a, b) => a.label.localeCompare(b.label, 'en', { numeric: true }));

            setRouteNumberList(list);
            updateCreateRoutesFormData('NumRoutes', args.text);
            args.customItem = newItem;
        } else {
            args.customItem = null;
            return;
        }
    };

    React.useEffect(() => {
        if (mapMode === 0) {
            if (siteMode === 'DISPATCHER') {
                if (serviceLocationLayer) {
                    serviceLocationLayer.renderer = getRouteRenderer();
                }
            } else if (siteMode === 'PLANNER') {
                if (projectServiceLocationLayer) {
                    projectServiceLocationLayer.renderer = getRouteRenderer();
                }
            }
            refreshServiceLocationsInMap();
        }
        if (servicePatternView) {
            refreshServicePatternInMap();
        }
    }, [routeViewTableData, routeViewTabCategorization]);

    const openServiceAreaBoundaryTool = () => {
        if (isServiceAreaBoundaryWidgetClicked) {
            closeServiceAreaBoundaryTool('CANCEL');
        } else {
            setIsServiceAreaBoundaryWidgetClicked(true);
        }
    };

    const closeServiceAreaBoundaryTool = (data) => {
        if (defineServiceAreaSketch) defineServiceAreaSketch.cancel();
        if (data === 'CANCEL') {
            removeServiceAreaBoundary();
        } else if (data === 'CREATE') {
            createServiceAreaBoundaryInitial();
        } else if (data === 'SHOW') {
            showServiceAreaBoundaryInitial();
        } else if (data === 'EDIT') {
            editServiceAreaBoundaryInitial();
        } else if (data === 'DELETE') {
            deleteServiceAreaBoundaryInitial();
        }
    };

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////

    const removeServiceAreaBoundary = () => {
        if (editServiceAreaGraphicLayer) editServiceAreaGraphicLayer.removeAll();
        if (serviceAreaGraphicLayer) serviceAreaGraphicLayer.removeAll();
        if (defineServiceAreaSketch) defineServiceAreaSketch.cancel();
        if (editServiceAreaSketch) editServiceAreaSketch.cancel();
        setIsServiceAreaBoundaryWidgetClicked(false);
        setIsServiceAreaBoundaryReshapeDialogOpen(false);
        setIsServiceAreaBoundaryDeleteDialogOpen(false);
    };

    const createServiceAreaBoundaryInitial = () => {
        if (defineServiceAreaSketch) defineServiceAreaSketch.create('polygon', { mode: 'click' });
    };

    const placeServiceAreaBoundaryOnMap = (data) => {
        if (editServiceAreaGraphicLayer) editServiceAreaGraphicLayer.removeAll();
        if (serviceAreaGraphicLayer) serviceAreaGraphicLayer.removeAll();
        if (defineServiceAreaSketch) defineServiceAreaSketch.cancel();
        if (editServiceAreaSketch) editServiceAreaSketch.cancel();
        if (data || serviceAreaBoundaryRef.current) {
            const v = data || serviceAreaBoundaryRef.current;
            const shape = JSON.parse(v.Shape);

            const polygon = {
                type: 'polygon',
                rings: shape.rings,
                spatialReference: shape.spatialReference,
            };

            const serviceAreaBoundaryAttributes = {
                id: v.id,
                workspace: workspace.value,
                shape: v.shape,
            };

            const serviceAreaBoundaryPopupTemplate = {
                title: 'Service Area Boundary',
                overwriteActions: true,
                actions: [
                    {
                        id: 'redrawServiceAreaBoundary',
                        title: 'Reshape',
                        className: 'esri-icon-edit',
                    },
                    {
                        id: 'deleteServiceAreaBoundary',
                        title: 'Delete',
                        className: 'esri-icon-trash',
                    },
                ],
            };

            const serviceAreaBoundaryGraphic = new Graphic({
                geometry: polygon,
                symbol: regularSymbol,
                attributes: serviceAreaBoundaryAttributes,
                popupTemplate: serviceAreaBoundaryPopupTemplate,
            });

            serviceAreaGraphicLayer.add(serviceAreaBoundaryGraphic);
        }
    };

    const deleteServiceAreaBoundaryInitial = () => {
        placeServiceAreaBoundaryOnMap();
        zoomToServiceAreaPolygon();
        setIsServiceAreaBoundaryDeleteDialogOpen(true);
    };

    const deleteServiceAreaBoundaryConfirmed = () => {
        deleteServiceAreaBoundary(workspace.value).then(
            (res) => {
                setServiceAreaBoundary(null);
                setIsServiceAreaBoundaryDeleteDialogOpen(false);
                setIsServiceAreaBoundaryWidgetClicked(false);
                if (editServiceAreaGraphicLayer) editServiceAreaGraphicLayer.removeAll();
                if (serviceAreaGraphicLayer) serviceAreaGraphicLayer.removeAll();
            },
            (err) => {
                showAPICallbackDialog('Error', err.response.data);
            },
        );
    };

    const showServiceAreaBoundaryInitial = () => {
        placeServiceAreaBoundaryOnMap();
        zoomToServiceAreaPolygon();
        setIsServiceAreaBoundaryReshapeDialogOpen(false);
    };

    const editServiceAreaBoundaryInitial = () => {
        placeServiceAreaBoundaryOnMap();
        zoomToServiceAreaPolygon();

        let target = serviceAreaGraphicLayer?.graphics?.items;

        if (target.length === 1) {
            const clone = target[0].clone();
            editServiceAreaGraphicLayer.graphics.add(clone);
            target[0].symbol = editingSymbol;
            editServiceAreaSketch.update([clone], { tool: 'reshape' });
        }
    };

    const createUpdateServiceAreaBoundaryConfirmed = (polygon, isEdit) => {
        if (isEdit) {
            let data = {
                id: serviceAreaBoundary.Id,
                workspaceId: workspaceId.current.value,
                shape: JSON.stringify(polygonForServiceAreaBoundary),
            };
            updateServiceAreaBoundary(workspaceId.current.value, data).then(
                (res) => {
                    setPolygonForServiceAreaBoundary(null);
                    setIsServiceAreaBoundaryReshapeDialogOpen(false);
                    getServiceAreaBoundary(workspaceId.current.value).then(
                        (res) => {
                            setServiceAreaBoundary(res.data);
                            placeServiceAreaBoundaryOnMap(res.data);
                        },
                        (err) => {
                            setServiceAreaBoundary(null);
                            if (editServiceAreaGraphicLayer) editServiceAreaGraphicLayer.removeAll();
                            if (serviceAreaGraphicLayer) serviceAreaGraphicLayer.removeAll();
                            showAPICallbackDialog('Error', err.response.data);
                        },
                    );
                },
                (err) => {
                    setPolygonForServiceAreaBoundary(null);
                    setServiceAreaBoundary(null);
                    if (editServiceAreaGraphicLayer) editServiceAreaGraphicLayer.removeAll();
                    if (serviceAreaGraphicLayer) serviceAreaGraphicLayer.removeAll();
                    setIsServiceAreaBoundaryReshapeDialogOpen(false);
                    showAPICallbackDialog('Error', err.response.data);
                },
            );
        } else {
            let data = {
                workspaceId: workspaceId.current.value,
                shape: JSON.stringify(polygon),
            };
            createServiceAreaBoundary(workspaceId.current.value, data).then(
                (res) => {
                    getServiceAreaBoundary(workspaceId.current.value).then(
                        (res) => {
                            setServiceAreaBoundary(res.data);
                            placeServiceAreaBoundaryOnMap(res.data);
                        },
                        (err) => {
                            setServiceAreaBoundary(null);
                            if (editServiceAreaGraphicLayer) editServiceAreaGraphicLayer.removeAll();
                            if (serviceAreaGraphicLayer) serviceAreaGraphicLayer.removeAll();
                            setIsServiceAreaBoundaryReshapeDialogOpen(false);
                            showAPICallbackDialog('Error', err.response.data);
                        },
                    );
                },
                (err) => {
                    setServiceAreaBoundary(null);
                    if (editServiceAreaGraphicLayer) editServiceAreaGraphicLayer.removeAll();
                    if (serviceAreaGraphicLayer) serviceAreaGraphicLayer.removeAll();
                    setIsServiceAreaBoundaryReshapeDialogOpen(false);
                    showAPICallbackDialog('Error', err.response.data);
                },
            );
        }
    };

    const zoomToServiceAreaPolygon = () => {
        let target = serviceAreaGraphicLayer?.graphics?.items;
        if (target.length === 1) {
            const VP = new Viewpoint({
                targetGeometry: target[0].geometry.extent,
            });
            view.goTo(VP, { duration: 500 });
        }
    };

    const handleExportSmartSuiteClose = (mode) => {
        if (mode === 'SAVE') {
            let data = {
                workspace: workspace.value,
            };
            if (siteMode === 'DISPATCHER') {
                if (mapMode === 0) {
                    data.serviceDay = serviceDay.value;
                } else if (mapMode === 1) {
                    data.JobId = selectedScenarioId;
                }
            } else if (siteMode === 'PLANNER') {
                data.project = project;
            }
            sendSmartSuite(data).then(
                (res) => {
                    setShowSendSmartSuiteDialog(false);
                    showAPICallbackDialog('Success', 'SmartSuite Sent');
                },
                (err) => {
                    setShowSendSmartSuiteDialog(false);
                    showAPICallbackDialog('Error', JSON.stringify(err.response.data));
                },
            );
        } else {
            setShowSendSmartSuiteDialog(false);
        }
    };

    const handleRefreshServiceLocationClose = (mode) => {
        setRefreshServiceLocationOpen(false);
        if (mode === 'REFRESH') {
            refreshServiceLocationList();
            refreshSwapRouteList();
            refreshServiceLocationsInMap();
            refreshRouteSummary();
            setIsMapLoading(false);
        }
    };

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const showAPICallbackDialog = (title, message) => {
        setOpenMapAlert(true);
        setMapAlertTitle(title);
        if (message && typeof message === 'object' && !Array.isArray(message) && message !== null) {
            setMapAlertMessage(`${JSON.stringify(message['Message'])}`);
        } else {
            setMapAlertMessage(`${message}`);
        }
    };

    const showNotificationCallbackDialog = (title, message) => {
        setOpenMapAlert(true);
        setMapAlertTitle(title);
        setMapAlertMessage(message);
    };

    const handleSequenceDialogClose = (mode) => {
        setSequenceDialogOpen(false);
    };

    const handleMouseUp = (e) => {
        isResizing = false;
    };
    const handleMouseDown = (e) => {
        isResizing = true;
    };

    const handleMouseMove = (e) => {
        if (isResizing) {
            const headerHeight = 125;
            const pageHeight = document.body.offsetHeight;
            const Y = e.clientY;
            const newBottomHeight = pageHeight - Y;
            const newTopHeight = pageHeight - headerHeight - newBottomHeight;
            const minTopHeight = 420;
            const minBottomHeight = 250;
            if (newTopHeight < minTopHeight) {
                setTopHeight(minTopHeight);
                setBottomHeight(pageHeight - headerHeight - minTopHeight);
            } else if (newBottomHeight < minBottomHeight) {
                setTopHeight(pageHeight - headerHeight - minBottomHeight);
                setBottomHeight(minBottomHeight);
            } else {
                setTopHeight(newTopHeight);
                setBottomHeight(newBottomHeight);
            }
        }
    };

    return initExtent ? (
        <div>
            <div
                id='instructionDiv'
                style={{ display: mapInfoMessage ? 'block' : 'none' }}
            >
                {mapInfoMessage}
            </div>
            <div
                id='viewDiv'
                style={{
                    height: bottomPaneVisibility ? topHeight + 'px' : document.body.offsetHeight - 125 + 'px',
                }}
            >
                <div
                    id='collapseBottom'
                    className='esri-widget esri-widget--button esri-interactive'
                    title='Collapse Bottom'
                    style={bottomPaneVisibility ? {} : { display: 'none' }}
                >
                    <span
                        className={bottomPaneVisibility ? 'esri-icon-down' : 'esri-icon-up'}
                        onClick={() => {
                            setBottomPaneVisibility(!bottomPaneVisibility);
                        }}
                    />
                </div>
                <div
                    id='measurementToolsButton'
                    className='esri-widget esri-widget--button esri-interactive'
                    title='Measurement tools'
                >
                    <span
                        className={measurementToolsVisibility ? 'esri-icon-collapse' : 'esri-icon-measure-area'}
                        onClick={() => {
                            if (measurementToolsVisibility) {
                                setActiveMeasurementTool('');
                                measurementWidget.clear();
                                measurementWidget.visible = true;
                            }
                            setMeasurementToolsVisibility(!measurementToolsVisibility);
                        }}
                    />
                </div>
                {
                    <div
                        id='selectionToolsButton'
                        className='esri-widget esri-widget--button esri-interactive'
                        title='Selection tools'
                    >
                        <span
                            className={selectionToolsVisibility ? 'esri-icon-collapse' : 'RS_SELECT_BY_RECTANGLE'}
                            onClick={() => {
                                setSelectionToolsVisibility(!selectionToolsVisibility);
                            }}
                        />
                    </div>
                }
                {
                    <div
                        id='clearSelectionButton'
                        className='esri-widget esri-widget--button esri-interactive'
                        title='Clear Selection'
                    >
                        <span
                            className={'RS_CLEAR_SELECTION'}
                            onClick={() => {
                                setSelectedServiceLocationIds([]);
                                setSelectedServicePattern([]);
                                setSelectedRaaSServiceLocationIds([]);
                            }}
                        />
                    </div>
                }
                <div id='zoneInstructions'>
                    {zoneMode === 'ASSIGN' && (
                        <div
                            style={{
                                position: 'absolute',
                                top: '215px',
                                right: '60px',
                                width: '300px',
                                backgroundColor: 'rgba(255, 255, 255, 1)',
                                padding: '5px',
                            }}
                        >
                            <label style={{ fontWeight: '600' }}>ASSIGNING ZONE</label>
                            <p style={{ margin: '5px 0' }}>
                                Click gray zones ({' '}
                                <svg
                                    height='18'
                                    width='18'
                                >
                                    {' '}
                                    <rect
                                        width='18'
                                        height='18'
                                        stroke='black'
                                        strokeWidth='1'
                                        fill={'rgba(0, 0, 0, .25)'}
                                    />
                                </svg>{' '}
                                ) to assign to the currently selected route. Click highlighted zones to unassign.{' '}
                            </p>
                        </div>
                    )}
                    {zoneMode === 'SEQUENCE' && (
                        <div
                            style={{
                                position: 'absolute',
                                top: '215px',
                                right: '60px',
                                width: '300px',
                                backgroundColor: 'rgba(255, 255, 255, 1)',
                                padding: '5px',
                            }}
                        >
                            <label style={{ fontWeight: '600' }}>SETTING PRIORITY AND SEQUENCE</label>
                            <p style={{ margin: '5px 0' }}>
                                Click on a button below then click on gray zones ({' '}
                                <svg
                                    height='18'
                                    width='18'
                                >
                                    {' '}
                                    <rect
                                        width='18'
                                        height='18'
                                        stroke='black'
                                        strokeWidth='1'
                                        fill={'rgba(0, 0, 0, .25)'}
                                    />
                                </svg>{' '}
                                ) to set the appropriate priority and sequence on the currently selected route.
                            </p>
                            {sequenceMode === 'SEQUENCE' ? (
                                <div>
                                    <hr />
                                    <Box sx={{ minWidth: 120 }}>
                                        <FormControl fullWidth>
                                            <InputLabel style={{ backgroundColor: 'white' }}>Sequence Value to Assign</InputLabel>
                                            <MuiSelect
                                                id='nextSequenceSelect'
                                                value={nextSequence}
                                                onChange={(e) => setNextSequence(e.target.value)}
                                                style={{ width: '240px', textAlign: 'center' }}
                                                MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
                                            >
                                                {[...Array(100).keys()].map((i) => (
                                                    <MenuItem
                                                        key={i}
                                                        value={i + 1}
                                                        style={{ display: 'block', textAlign: 'center' }}
                                                    >
                                                        {i + 1}
                                                    </MenuItem>
                                                ))}
                                            </MuiSelect>
                                            <Button
                                                onClick={() => {
                                                    setLockSequence(!lockSequenceRef.current);
                                                }}
                                                title={'Lock Sequence'}
                                                style={
                                                    lockSequence
                                                        ? {
                                                              backgroundColor: '#f50057',
                                                              color: '#FFF',
                                                              padding: '6px 0',
                                                              position: 'absolute',
                                                              right: 0,
                                                          }
                                                        : { padding: '6px 0', position: 'absolute', right: 0 }
                                                }
                                            >
                                                <i className='material-icons'>{lockSequence ? 'lock' : 'lock_open'}</i>
                                            </Button>
                                        </FormControl>
                                    </Box>
                                    <hr />
                                    <FormControlLabel
                                        labelPlacement='start'
                                        control={
                                            <Checkbox
                                                checked={autoIncrementEnabled}
                                                disabled={lockSequence}
                                                onChange={(e) => setAutoIncrementEnabled(!autoIncrementEnabledRef.current)}
                                                style={{ backgroundColor: '#fff' }}
                                            />
                                        }
                                        label={<span style={{ fontSize: '12px' }}>{'Auto Adjust Existing Sequences on Map'}</span>}
                                        style={{ marginLeft: 0, marginRight: 0, width: '190px' }}
                                    />
                                </div>
                            ) : (
                                <></>
                            )}
                        </div>
                    )}
                </div>
                <Backdrop
                    sx={{ backgroundColor: 'rgba(235, 235, 235, 0.5)', color: '#3f51b5', zIndex: 1500 }}
                    open={isMapLoading}
                    invisible={true}
                >
                    <CircularProgress color='inherit' />
                </Backdrop>
            </div>
            {measurementToolsVisibility && (
                <div id='measurementToolsDiv'>
                    <button
                        id='distance'
                        className='esri-widget--button esri-interactive esri-icon-measure-line '
                        title='Distance Measurement Tool'
                        style={
                            activeMeasurementTool == 'distance'
                                ? { backgroundColor: '#999696', marginRight: '5px', border: '0px' }
                                : { marginRight: '5px', border: '0px' }
                        }
                        onClick={() => {
                            if (activeMeasurementTool != 'distance') {
                                setActiveMeasurementTool('distance');
                                if (measurementWidget) {
                                    measurementWidget.clear();
                                    measurementWidget.visible = true;
                                    measurementWidget.activeTool = 'distance';
                                }
                            }
                        }}
                    ></button>
                    <button
                        id='area'
                        className='esri-widget--button esri-interactive esri-icon-measure-area'
                        title='Area Measurement Tool'
                        style={
                            activeMeasurementTool == 'area'
                                ? { backgroundColor: '#999696', marginRight: '5px', border: '0px' }
                                : { marginRight: '5px', border: '0px' }
                        }
                        onClick={() => {
                            if (activeMeasurementTool != 'area') {
                                setActiveMeasurementTool('area');
                                if (measurementWidget) {
                                    measurementWidget.clear();
                                    measurementWidget.visible = true;
                                    measurementWidget.activeTool = 'area';
                                }
                            }
                        }}
                    ></button>
                    <button
                        id='clear'
                        className='esri-widget--button esri-interactive esri-icon-trash'
                        title='Clear Measurements'
                        style={{ marginRight: '5px', border: '0px' }}
                        onClick={() => {
                            setActiveMeasurementTool('');
                            if (measurementWidget) {
                                measurementWidget.clear();
                                measurementWidget.visible = false;
                            }
                        }}
                    ></button>
                </div>
            )}
            <div id='selectionToolsDiv'>
                {selectionToolsVisibility && (
                    <div style={{ top: `${127 + document.getElementById('app_bar').offsetHeight}px` }}>
                        <button
                            id='distance'
                            className='esri-widget--button esri-interactive RS_SELECT_BY_RECTANGLE'
                            title='Select stops from map by drawing rectangle'
                            style={
                                activeMeasurementTool == 'distance'
                                    ? { backgroundColor: '#ccc', cursor: 'not-allowed', marginRight: '5px', border: '0px' }
                                    : { marginRight: '5px', border: '0px' }
                            }
                            onClick={() => {
                                setMapInfoMessage('Draw one rectangle to select stops');
                                setMapSelectionMode('SL_SEL_STREET');
                                pickXYSketch.create('rectangle');
                            }}
                        ></button>
                        <button
                            id='select_by_polygon'
                            className='esri-widget--button esri-interactive RS_SELECT_BY_POLYGON'
                            title='Select stops from map by drawing polygon'
                            style={
                                activeMeasurementTool == 'distance'
                                    ? { backgroundColor: '#ccc', cursor: 'not-allowed', marginRight: '5px', border: '0px' }
                                    : { marginRight: '5px', border: '0px' }
                            }
                            onClick={() => {
                                setMapInfoMessage('Draw one end at a time, double click to end polygon and to select stops');
                                setMapSelectionMode('SL_SEL_STREET');
                                pickXYSketch.create('polygon');
                            }}
                        ></button>
                        <button
                            id='select_by_street'
                            className='esri-widget--button esri-interactive RS_SELECT_BY_STREET'
                            title='Select stops from map by selecting street'
                            style={
                                activeMeasurementTool === 'distance' || servicePatternView
                                    ? { backgroundColor: '#ccc', cursor: 'not-allowed', marginRight: '5px', border: '0px' }
                                    : { marginRight: '5px', border: '0px' }
                            }
                            disabled={servicePatternView}
                            onClick={() => {
                                setSelStreetMaintenanceMode(config.SERVICE_LOCATION + '_SEL_STREET');
                                setMapInfoMessage('Click on a street to select stops for the street');
                            }}
                        ></button>
                        <button
                            id='select_by_street_side'
                            className='esri-widget--button esri-interactive RS_SELECT_BY_STREET_SIDE'
                            title='Select stops from map by selecting street and side'
                            style={
                                activeMeasurementTool === 'distance' || servicePatternView
                                    ? { backgroundColor: '#ccc', cursor: 'not-allowed', marginRight: '5px', border: '0px' }
                                    : { marginRight: '5px', border: '0px' }
                            }
                            disabled={servicePatternView}
                            onClick={() => {
                                setSelStreetMaintenanceMode(config.SERVICE_LOCATION + '_SEL_STREET_SIDE');
                                setMapInfoMessage('Click on a street and then side to select stops for the side of street');
                            }}
                        ></button>
                    </div>
                )}
            </div>
            <SidePanel
                siteMode={siteMode}
                workspace={workspace}
                mapMode={mapMode}
                zoneMode={zoneMode}
                serviceDay={serviceDay}
                project={project}
                routePlanId={routePlanId}
                unSavedChanges={unSavedChanges}
                bottomPaneVisibility={bottomPaneVisibility}
                bottomPaneGrid={bottomPaneGrid}
                zoneRouteList={zoneRouteList}
                selectedZoneRoutes={selectedZoneRoutes}
                selTravelPathRoute={selTravelPathRoute}
                showTravelPathLabelBtnTooltip={showTravelPathLabelBtnTooltip}
                showTravelPathLabel={showTravelPathLabel}
                travelPathLabelTooltip={travelPathLabelTooltip}
                showSequenceLabel={showSequenceLabel}
                selSequenceRoute={selSequenceRoute}
                selDispatchTool={selDispatchTool}
                serviceDayList={serviceDayList}
                splitFormData={splitFormData}
                splitSeqList={splitSeqList}
                dispatchFacilityList={dispatchFacilityList}
                splitLoadIndicatorVisible={splitLoadIndicatorVisible}
                splitStartTime={splitStartTime}
                defSplitFormData={defSplitFormData}
                createRoutesFormData={createRoutesFormData}
                routePlanList={routePlanList}
                createRoutesLoadIndicatorVisible={createRoutesLoadIndicatorVisible}
                scenarioList={scenarioList}
                selectedScenarioId={selectedScenarioId}
                selectedScenarioRoutes={selectedScenarioRoutes}
                scenarioRouteList={scenarioRouteList}
                showScenarioSequenceLabel={showScenarioSequenceLabel}
                showScenarioTravelPathLabel={showScenarioTravelPathLabel}
                showScenarioReport={showScenarioReport}
                showScenarioExport={showScenarioExport}
                showScenarioTravelPathLabelBtnTooltip={showScenarioTravelPathLabelBtnTooltip}
                selScenarioTravelPathRoute={selScenarioTravelPathRoute}
                selScenarioSequenceRoute={selScenarioSequenceRoute}
                routeNumberList={routeNumberList}
                facilityLayerVisibility={facilityLayerVisibility}
                RAASFacilityLayerVisibility={RAASFacilityLayerVisibility}
                streetLayerVisibility={streetLayerVisibility}
                trafficLayerVisibility={trafficLayerVisibility}
                zoneFacilityLayerVisibility={zoneFacilityLayerVisibility}
                zoneServiceLocationLayerVisibility={zoneServiceLocationLayerVisibility}
                projectFacilityLayerVisibility={projectFacilityLayerVisibility}
                servicePatternView={servicePatternView}
                setMapMode={setMapMode}
                zoneRouteSelectionChanged={zoneRouteSelectionChanged}
                handleZoomToRoute={handleZoomToRoute}
                handleZoomToSelectedRoutes={handleZoomToSelectedRoutes}
                handleZoomToScenarioRoute={handleZoomToScenarioRoute}
                handleZoomToSelectedScenarioRoutes={handleZoomToSelectedScenarioRoutes}
                selMapToolChangeHandler={selMapToolChangeHandler}
                zoomToExtent={zoomToExtent}
                setShowTravelPathLabel={setShowTravelPathLabel}
                setShowSequenceLabel={setShowSequenceLabel}
                toggleMapTravelPathLayer={toggleMapTravelPathLayer}
                toggleMapSequenceLayer={toggleMapSequenceLayer}
                numberCreating={numberCreating}
                setShowScenarioTravelPathLabel={setShowScenarioTravelPathLabel}
                selScenarioMapToolChangeHandler={selScenarioMapToolChangeHandler}
                setSelectedScenarioId={setSelectedScenarioId}
                setSelectedScenarioRoutes={setSelectedScenarioRoutes}
                setShowScenarioSequenceLabel={setShowScenarioSequenceLabel}
                updateCreateRoutesFormData={updateCreateRoutesFormData}
                setCreateRoutesLoadIndicatorVisible={setCreateRoutesLoadIndicatorVisible}
                updateSplitFormData={updateSplitFormData}
                setSplitFormData={setSplitFormData}
                setSplitLoadIndicatorVisible={setSplitLoadIndicatorVisible}
                refreshScenarioList={refreshScenarioList}
                setSelDispatchTool={setSelDispatchTool}
                setSplitStartTime={setSplitStartTime}
                refreshSplitSeqList={refreshSplitSeqList}
                setOpenMapAlert={setOpenMapAlert}
                setMapAlertTitle={setMapAlertTitle}
                setMapAlertMessage={setMapAlertMessage}
                toggleScenarioTravelPathLayer={toggleScenarioTravelPathLayer}
                toggleScenarioSequenceLayer={toggleScenarioSequenceLayer}
                setZoneFacilityLayerVisibility={setZoneFacilityLayerVisibility}
                setZoneServiceLocationLayerVisibility={setZoneServiceLocationLayerVisibility}
                setFacilityLayerVisibility={setFacilityLayerVisibility}
                setRAASFacilityLayerVisibility={setRAASFacilityLayerVisibility}
                setStreetLayerVisibility={setStreetLayerVisibility}
                setTrafficLayerVisibility={setTrafficLayerVisibility}
                setProjectFacilityLayerVisibility={setProjectFacilityLayerVisibility}
                routeViewTabCategorization={routeViewTabCategorization}
                handleRouteTabViewCategorySelection={setRouteViewTabCategorization}
                routeViewTableData={routeViewTableData} //
                handleToggleUpdate={setRouteViewTableData}
                isRouteViewTabLoading={isRouteViewTabLoading}
                alleyAssignmentMode={alleyAssignmentMode}
                blockFaceLayerVisbility={blockFaceLayerVisbility}
                setBlockFaceLayerVisbility={setBlockFaceLayerVisbility}
            />
            {
                <div
                    id='bottomDiv'
                    style={{ height: bottomPaneVisibility ? bottomHeight + 'px' : 0 }}
                >
                    {bottomPaneVisibility && (
                        <div
                            id='MapResizeBar'
                            onMouseDown={handleMouseDown}
                            title={'Resize'}
                        >
                            <DragHandleIcon />
                        </div>
                    )}
                    {!alleyAssignmentMode && (
                        <>
                            {bottomPaneVisibility && mapMode === 0 && bottomPaneGrid === 'FacilityList' && (
                                <FacilityTable
                                    workspace={workspace}
                                    mapView={view}
                                    siteMode={siteMode}
                                    project={project}
                                    pickXYSketch={pickXYSketch}
                                    pickXYGraphicsLayer={pickXYGraphicsLayer}
                                    setSelStreetMaintenanceMode={setSelStreetMaintenanceMode}
                                    refreshFacilityLayer={refreshFacilityLayer}
                                    pickedXY={pickedXY}
                                    setPickedXY={setPickedXY}
                                    pickedLinkId={pickedLinkId}
                                    setPickedLinkId={setPickedLinkId}
                                    setMapSelectionMode={setMapSelectionMode}
                                    pinFacilityDialog={pinFacilityDialog}
                                    setPinFacilityDialog={setPinFacilityDialog}
                                    mode={facilityDialogMode}
                                    setMode={setFacilityDialogMode}
                                    editFacilityDialog={editFacilityDialog}
                                    setEditFacilityDialog={setEditFacilityDialog}
                                    isFacilityFormValid={isFacilityFormValid}
                                    setIsFacilityFormValid={setIsFacilityFormValid}
                                    setMapInfoMessage={setMapInfoMessage}
                                    selectedFacilityIds={selectedFacilityIds}
                                    setSelectedFacilityIds={setSelectedFacilityIds}
                                    facilityTarget={facilityTarget}
                                    setFacilityTarget={setFacilityTarget}
                                    setOpenMapAlert={setOpenMapAlert}
                                    setMapAlertTitle={setMapAlertTitle}
                                    setMapAlertMessage={setMapAlertMessage}
                                    pinFacility={pinFacility}
                                    setPinFacility={setPinFacility}
                                    bottomHeight={bottomHeight}
                                />
                            )}
                            {mapMode === 0 && (
                                <ServiceLocationTable
                                    workspace={workspace}
                                    siteMode={siteMode}
                                    projectObject={projectObject}
                                    mapView={view}
                                    bottomPaneGrid={bottomPaneGrid}
                                    bottomPaneVisibility={bottomPaneVisibility}
                                    showExport={showExport}
                                    serviceDay={serviceDay}
                                    week={week}
                                    frequency={frequency}
                                    setShowExport={setShowExport}
                                    handleDownloadNavusoft={handleDownloadNavusoft}
                                    handlePinServiceLocations={handlePinServiceLocations}
                                    handleFlipServiceLocations={handleFlipServiceLocations}
                                    setUnpinServiceLocationDialogOpen={setUnpinServiceLocationDialogOpen}
                                    handleEditServiceLocation={handleEditServiceLocation}
                                    slFilter={slFilter}
                                    serviceLocationData={serviceLocationData}
                                    selectedServiceLocationIds={selectedServiceLocationIds}
                                    setSelectedServiceLocationIds={setSelectedServiceLocationIds}
                                    timeWindowToggle={timeWindowToggle}
                                    setTimeWindowToggle={setTimeWindowToggle}
                                    handleToggleServicePattern={handleToggleServicePattern}
                                    handleManageServicePattern={handleManageServicePattern}
                                    servicePatternView={servicePatternView}
                                    handleExportServicePattern={handleExportServicePattern}
                                    routeViewTabCategorization={routeViewTabCategorization}
                                    routeViewTableData={routeViewTableData}
                                    editSelectedServiceLocations={editSelectedServiceLocations}
                                    setSequenceDialogOpen={setSequenceDialogOpen}
                                    bottomHeight={bottomHeight}
                                    setRefreshServiceLocationOpen={setRefreshServiceLocationOpen}
                                    setDeleteServiceLocationDialogOpen={setDeleteServiceLocationDialogOpen}
                                    specialtyList={specialtyList}
                                />
                            )}
                            {bottomPaneVisibility && mapMode === 0 && bottomPaneGrid === 'RouteSummary' && (
                                <RouteSummaryTable
                                    workspace={workspace}
                                    serviceDay={serviceDay}
                                    siteMode={siteMode}
                                    routeViewTabCategorization={routeViewTabCategorization}
                                    routeViewTableData={routeViewTableData}
                                    setShowSendSmartSuiteDialog={setShowSendSmartSuiteDialog}
                                    showReport={showReport}
                                    routeSummaryData={routeSummaryData}
                                    healthScore={healthScore}
                                    showTrips={showTrips}
                                    setShowTrips={setShowTrips}
                                    setShowReport={setShowReport}
                                    setSandboxUpdateRouteStatsDialogOpen={setSandboxUpdateRouteStatsDialogOpen}
                                    setSandboxSolverDialogOpen={setSandboxSolverDialogOpen}
                                    bottomHeight={bottomHeight}
                                />
                            )}
                            {bottomPaneVisibility && mapMode === 1 && bottomPaneGrid === 'ScenarioServiceLocation' && (
                                <ScenarioServiceLocationTable
                                    mapView={view}
                                    bottomPaneGrid={bottomPaneGrid}
                                    bottomPaneVisibility={bottomPaneVisibility}
                                    showScenarioExport={showScenarioExport}
                                    setShowScenarioExport={setShowScenarioExport}
                                    scenarioServiceLocationData={scenarioServiceLocation}
                                    selectedRaaSServiceLocationIds={selectedRaaSServiceLocationIds}
                                    selectedScenarioRoutes={selectedScenarioRoutes}
                                    setSelectedRaaSServiceLocationIds={setSelectedRaaSServiceLocationIds}
                                    handleExportServicePattern={handleExportServicePattern}
                                    bottomHeight={bottomHeight}
                                    setRefreshScenarioServiceLocation={setRefreshScenarioServiceLocation}
                                />
                            )}
                            {bottomPaneVisibility && mapMode === 1 && bottomPaneGrid === 'ScenarioRouteSummary' && (
                                <ScenarioRouteSummaryTable
                                    workspace={workspace}
                                    selectedScenarioRoutes={selectedScenarioRoutes}
                                    scenarioRouteSummary={scenarioRouteSummary}
                                    bottomHeight={bottomHeight}
                                    showScenarioReport={showScenarioReport}
                                    selectedScenarioId={selectedScenarioId}
                                    setShowScenarioReport={setShowScenarioReport}
                                    setShowSendSmartSuiteDialog={setShowSendSmartSuiteDialog}
                                />
                            )}
                            {bottomPaneVisibility && mapMode === 0 && bottomPaneGrid === 'ProjectGrid' && siteMode === 'PLANNER' && (
                                <ProjectTable
                                    workspaceId={workspace.value}
                                    routingProjects={routingProjects}
                                    project={project}
                                    setProject={setProject}
                                    serviceDay={serviceDay}
                                    setRefreshProject={setRefreshProject}
                                    setRefreshScenario={setRefreshScenario}
                                    setOpenAlert={setOpenAlert}
                                    setAlertTitle={setAlertTitle}
                                    setAlertMessage={setAlertMessage}
                                    setGlobalLoading={setGlobalLoading}
                                    bottomHeight={bottomHeight}
                                />
                            )}
                            {bottomPaneVisibility && mapMode === 0 && bottomPaneGrid === 'ScenarioGrid' && siteMode === 'PLANNER' && (
                                <ScenarioTable
                                    routingProjectScenarios={routingProjectScenarios}
                                    workspace={workspace}
                                    project={project}
                                    routingProjects={routingProjects}
                                    setLoadScenario={setLoadScenario}
                                    setRefreshProject={setRefreshProject}
                                    setRefreshScenario={setRefreshScenario}
                                    setOpenAlert={setOpenAlert}
                                    setAlertTitle={setAlertTitle}
                                    setAlertMessage={setAlertMessage}
                                    setGlobalLoading={setGlobalLoading}
                                    servicePatternView={servicePatternView}
                                    refreshRouteSummary={refreshRouteSummary}
                                    refreshServiceLocationList={refreshServiceLocationList}
                                    refreshServiceLocationsInMap={refreshServiceLocationsInMap}
                                    refreshServicePatternInMap={refreshServicePatternInMap}
                                    refreshSwapRouteList={refreshSwapRouteList}
                                    bottomHeight={bottomHeight}
                                    blockFaceLayerVisbility={blockFaceLayerVisbility}
                                    refreshRouteBlockfaceLayer={refreshRouteBlockfaceLayer}
                                />
                            )}
                            {bottomPaneVisibility && mapMode === 2 && (
                                <NeighborhoodClusterTable
                                    data={neighborhoodData}
                                    routePlanId={routePlanId}
                                    zoneMode={zoneMode}
                                    setZoneMode={setZoneMode}
                                    sequenceMode={sequenceMode}
                                    zoneRouteList={zoneRouteList}
                                    setSequenceMode={setSequenceMode}
                                    selectedNeighborhoodClusters={selectedNeighborhoodClusters}
                                    handleZoomToNeighborhoodCluster={handleZoomToNeighborhoodCluster}
                                    handleEditNeighborhoodCluster={handleEditNeighborhoodCluster}
                                    handleReshapeNeighborhoodCluster={handleReshapeNeighborhoodCluster}
                                    handleDeleteNeighborhoodCluster={handleDeleteNeighborhoodCluster}
                                    handleNewNeighborhood={handleNewNeighborhood}
                                    handleDrawNeighborhoodClusters={handleDrawNeighborhoodClusters}
                                    handleSaveAssignment={handleSaveAssignment}
                                    handleSaveSequence={handleSaveSequence}
                                    bottomHeight={bottomHeight}
                                    handleDeleteSelectedNeighborhood={handleDeleteSelectedNeighborhood}
                                />
                            )}
                        </>
                    )}
                    {alleyAssignmentMode && (
                        <AlleyAssignmentTable
                            alleyAssignmentsData={alleyAssignmentsData}
                            bottomHeight={bottomHeight}
                            handleDeleteAlleyAssignment={handleDeleteAlleyAssignment}
                            handleZoomToAlleyAssignment={handleZoomToAlleyAssignment}
                            handleValidAlleyAssignments={handleValidAlleyAssignments}
                        />
                    )}
                </div>
            }
            <ReportPanel
                siteMode={siteMode}
                showReport={showReport}
                selectedReport={selectedReport}
                reportTemplates={reportTemplates}
                separateReports={separateReports}
                routeViewTabCategorization={routeViewTabCategorization}
                buttonLoading={buttonLoading}
                setShowReport={setShowReport}
                handleSelectReport={handleSelectReport}
                handleSeparateReports={handleSeparateReports}
                handleDownloadReport={handleDownloadReport}
            />
            <DispatchReportPanel
                showScenarioReport={showScenarioReport}
                selectedScenarioReport={selectedScenarioReport}
                reportTemplates={reportTemplates}
                separateScenarioReports={separateScenarioReports}
                selectedScenarioRoutes={selectedScenarioRoutes}
                buttonLoading={buttonLoading}
                setShowScenarioReport={setShowScenarioReport}
                setSelectedScenarioReport={setSelectedScenarioReport}
                setSeparateScenarioReports={setSeparateScenarioReports}
                handleDownloadScenarioReport={handleDownloadScenarioReport}
            />
            <ExportPanel
                siteMode={siteMode}
                showExport={showExport}
                selectedFormat={selectedFormat}
                exportFormats={exportFormats}
                buttonLoading={buttonLoading}
                routeViewTabCategorization={routeViewTabCategorization}
                exportOptions={exportOptions}
                selectedServiceLocationIds={selectedServiceLocationIds}
                setShowExport={setShowExport}
                handleSelectFormat={handleSelectFormat}
                handleDownloadExport={handleDownloadExport}
                handleSetExportOptions={handleSetExportOptions}
            />
            <DispatchExportPanel
                showScenarioExport={showScenarioExport}
                selectedScenarioFormat={selectedScenarioFormat}
                exportFormats={exportFormats}
                selectedScenarioRoutes={selectedScenarioRoutes}
                buttonLoading={buttonLoading}
                dispatchExportOptions={dispatchExportOptions}
                selectedRaaSServiceLocationIds={selectedRaaSServiceLocationIds}
                setShowScenarioExport={setShowScenarioExport}
                setSelectedScenarioFormat={setSelectedScenarioFormat}
                handleDownloadScenarioExport={handleDownloadScenarioExport}
                handleSetDispatchExportOptions={handleSetDispatchExportOptions}
            />
            <PinServiceLocationDialog
                pinServiceLocationDialogOpen={pinServiceLocationDialogOpen}
                selectedServiceLocationIds={selectedServiceLocationIds}
                handlePinServiceLocationsDialogClose={handlePinServiceLocationsDialogClose}
            />
            <UnpinServiceLocationDialog
                unpinServiceLocationDialogOpen={unpinServiceLocationDialogOpen}
                selectedServiceLocationIds={selectedServiceLocationIds}
                handleUnpinServiceLocationsDialogClose={handleUnpinServiceLocationsDialogClose}
            />
            <EditServiceLocationDialog
                siteMode={siteMode}
                editServiceLocationOpen={editServiceLocationOpen}
                serviceLocationTarget={serviceLocationTarget}
                selectedServiceLocationIds={selectedServiceLocationIds}
                handleEditServiceLocationClose={handleEditServiceLocationClose}
                updateTimeWindow={updateTimeWindow}
                updateServiceLocations={updateServiceLocations}
                addServiceLocationSpecialty={addServiceLocationSpecialty}
                serviceLocationSpecialties={serviceLocationSpecialties}
                deleteServiceLocationSpecialty={deleteServiceLocationSpecialty}
                setManageSpecialtyDialogOpen={setManageSpecialtyDialogOpen}
                specialtyList={specialtyList}
                updateServiceLocationSpecialties={updateServiceLocationSpecialties}
            />
            <DeleteServiceLocationDialog
                deleteServiceLocationDialogOpen={deleteServiceLocationDialogOpen}
                selectedServiceLocationIds={selectedServiceLocationIds}
                handleDeleteSelectedServiceLocations={handleDeleteSelectedServiceLocations}
            />
            <ManagementSpecialtyDialog
                workspace={workspace}
                manageSpecialtyDialogOpen={manageSpecialtyDialogOpen}
                setManageSpecialtyDialogOpen={setManageSpecialtyDialogOpen}
            />
            <RefreshServiceLocationDialog
                refreshServiceLocationOpen={refreshServiceLocationOpen}
                workspace={workspace}
                projectObject={projectObject}
                handleRefreshServiceLocationClose={handleRefreshServiceLocationClose}
                setIsMapLoading={setIsMapLoading}
                showAPICallbackDialog={showAPICallbackDialog}
                showNotificationCallbackDialog={showNotificationCallbackDialog}
            />
            <StreetMode
                selStreetMaintenanceMode={selStreetMaintenanceMode}
                setSelStreetMaintenanceMode={setSelStreetMaintenanceMode}
            />
            <DeleteTurnExceptionDialog
                openUserTurnExceptionDeleteDialog={openUserTurnExceptionDeleteDialog}
                handleUserTurnExceptionDeleteClose={handleUserTurnExceptionDeleteClose}
            />
            <DeleteAlleyAssignmentsDialog
                openAlleyAssignmentsDeleteDialog={openAlleyAssignmentsDeleteDialog}
                handleAlleyAssignmentsDeleteClose={handleAlleyAssignmentsDeleteClose}
            />
            <MapAlertDialog
                openMapAlert={openMapAlert}
                mapAlertTitle={mapAlertTitle}
                mapAlertMessage={mapAlertMessage}
                setOpenMapAlert={setOpenMapAlert}
                setMapAlertTitle={setMapAlertTitle}
                setMapAlertMessage={setMapAlertMessage}
            />
            <PrintDialog
                selPrintTitle={selPrintTitle}
                selPrintFormat={selPrintFormat}
                setSelPrintTitle={setSelPrintTitle}
                setSelPrintFormat={setSelPrintFormat}
                printButtonLoading={printButtonLoading}
                printMap={printMap}
            />
            <SelectionDetailPanel
                selectedServiceLocationIds={selectedServiceLocationIds}
                selectedServicePattern={selectedServicePattern}
                serviceLocationData={serviceLocationData}
                siteMode={siteMode}
                serviceDay={serviceDay}
                selectionDetailOpen={selectionDetailOpen}
                routeViewTabCategorization={routeViewTabCategorization}
                routeViewTableData={routeViewTableData}
                swapRouteList={swapRouteList}
                pickXYSketch={pickXYSketch}
                swapRouteSettings={swapRouteSettings}
                servicePatternView={servicePatternView}
                servicePatternData={servicePatternData}
                swapServicePatternSettings={swapServicePatternSettings}
                autoCollapse={autoCollapse}
                setAutoCollapse={setAutoCollapse}
                setSlFilter={setSlFilter}
                handleSelectionDetailClose={handleSelectionDetailClose}
                setSelectedServiceLocationIds={setSelectedServiceLocationIds}
                setMapInfoMessage={setMapInfoMessage}
                setMapSelectionMode={setMapSelectionMode}
                updateSwapRouteSettings={updateSwapRouteSettings}
                updateSwapServicePatternSettings={updateSwapServicePatternSettings}
                setSwapServicePatternSettings={setSwapServicePatternSettings}
                setSwapConfirmDialogOpen={setSwapConfirmDialogOpen}
                handleCreateSwapRoutes={handleCreateSwapRoutes}
                setSelectedServicePattern={setSelectedServicePattern}
            />
            <TripsInfoPanel
                showTrips={showTrips}
                siteMode={siteMode}
                workspace={workspace}
                serviceDay={serviceDay}
                tripsData={tripsData}
                routeViewTabCategorization={routeViewTabCategorization}
                routeViewTableData={routeViewTableData}
                handleTripsPanelClose={handleTripsPanelClose}
            />
            <NeighborhoodClusterDialog
                openNeighborhoodClusterDialog={openNeighborhoodClusterDialog}
                editingMode={editingMode}
                title={title}
                targetNeighborhoodData={targetNeighborhoodData}
                updateNeighborhoodForm={updateNeighborhoodForm}
                handleNeighborhoodClusterDialogClose={handleNeighborhoodClusterDialogClose}
            />
            <ReshapeNeighborhoodClusterDialog
                reshapeDialogOpen={reshapeDialogOpen}
                handleReshapeClose={handleReshapeClose}
            />
            <DeleteNeighborhoodClusterDialog
                openDeleteNeighborhoodClusterDialog={openDeleteNeighborhoodClusterDialog}
                targetNeighborhoodData={targetNeighborhoodData}
                selectedNeighborhoodClusters={selectedNeighborhoodClusters}
                handleDeleteClose={handleDeleteClose}
            />
            <WarningDialog
                warningDialogOpen={warningDialogOpen}
                handleStay={handleStay}
                handleLeave={handleLeave}
            />
            <SwapConfirmDialog
                swapConfirmDialogOpen={swapConfirmDialogOpen}
                selectedServiceLocationIds={selectedServiceLocationIds}
                swapRouteSettings={swapRouteSettings}
                serviceLocationData={serviceLocationData}
                selectedServicePattern={selectedServicePattern}
                swapServicePatternSettings={swapServicePatternSettings}
                servicePatternView={servicePatternView}
                handleSwapClose={handleSwapClose}
            />
            <SandboxUpdateRouteStatsDialog
                workspace={workspace}
                project={project}
                serviceDay={serviceDay}
                routePlanList={routePlanList}
                sandboxUpdateRouteStatsDialogOpen={sandboxUpdateRouteStatsDialogOpen}
                routeStatsSettings={routeStatsSettings}
                updateRotueStatsSettings={updateRotueStatsSettings}
                handleSandboxUpdateRouteStatsDialogClose={handleSandboxUpdateRouteStatsDialogClose}
            />
            <SandboxSolverDialog
                sandboxSolverDialogOpen={sandboxSolverDialogOpen}
                solverSettings={solverSettings}
                routePlanList={routePlanList}
                serviceDay={serviceDay}
                routeViewTabCategorization={routeViewTabCategorization}
                routeViewTableData={routeViewTableData}
                updateSolverSettings={updateSolverSettings}
                handleSandboxSolverDialogClose={handleSandboxSolverDialogClose}
            />
            <ManageServicePattern
                showServicePattern={showServicePattern}
                siteMode={siteMode}
                workspace={workspace}
                serviceDay={serviceDay}
                servicePatternData={servicePatternData}
                updateAvailability={updateAvailability}
                handleRemoveServicePattern={handleRemoveServicePattern}
                handleServicePatternDialogClose={handleServicePatternDialogClose}
                handleUpdateServicePatternDialogOpen={handleUpdateServicePatternDialogOpen}
            />
            <UpdateServicePatternDialog
                updateServicePatternDialogOpen={updateServicePatternDialogOpen}
                selectedServicePatternForAdd={selectedServicePatternForAdd}
                servicePatternData={servicePatternData}
                setSelectedServicePatternForAdd={setSelectedServicePatternForAdd}
                handleUpdateServicePatternDialogClose={handleUpdateServicePatternDialogClose}
            />
            <ReshapeDialogServiceAreaBoundary
                open={isServiceAreaBoundaryReshapeDialogOpen}
                yes={() => createUpdateServiceAreaBoundaryConfirmed(null, true)}
                back={() => {
                    editServiceAreaSketch.update(editServiceAreaGraphicLayer.graphics.items[0], { tool: 'reshape' });
                }}
                cancel={() => showServiceAreaBoundaryInitial()}
            />
            <DeleteDialogServiceAreaBoundary
                open={isServiceAreaBoundaryDeleteDialogOpen}
                yes={() => deleteServiceAreaBoundaryConfirmed()}
                no={() => setIsServiceAreaBoundaryDeleteDialogOpen(false)}
            />
            <SmartSuiteDialog
                showSendSmartSuiteDialog={showSendSmartSuiteDialog}
                handleExportSmartSuiteClose={handleExportSmartSuiteClose}
            />
            <SequenceDialog
                sequenceDialogOpen={sequenceDialogOpen}
                workspace={workspace}
                serviceDay={serviceDay}
                project={project}
                siteMode={siteMode}
                setIsMapLoading={setIsMapLoading}
                handleSequenceDialogClose={handleSequenceDialogClose}
                setSequenceDialogOpen={setSequenceDialogOpen}
                showAPICallbackDialog={showAPICallbackDialog}
                showNotificationCallbackDialog={showNotificationCallbackDialog}
            />
            <RODialog
                open={openConfirmation}
                maxWidth='sm'
                dialogTitle='WARNING'
                cancelText='NO'
                confirmText='YES'
                handleCancel={() => {
                    setOpenConfirmation(false);
                    setConfirmationTitle(null);
                    setConfirmationMessage(null);
                }}
                handleConfirm={removeServicePattern}
            >
                {confirmationMessage || null}
            </RODialog>
            <div id='overviewDiv' />
            <>
                <button
                    id={'zoomByDrawingPolygon'}
                    className='esri-widget--button esri-icon-zoom-in-magnifying-glass'
                    title='Zoom by drawing polygon'
                    style={{ marginRight: '5px', border: '0px' }}
                    onClick={() => {
                        setMapInfoMessage('Click and drag on map to zoom to selected location');
                        pickXYSketch.create('rectangle');
                    }}
                ></button>
                <button
                    id={'map_ZoomIn'}
                    className='esri-widget--button esri-icon-plus'
                    title='Zoom in'
                    style={{ marginRight: '5px', border: '0px' }}
                    onClick={() => {
                        if (zoomWidget) {
                            zoomWidget.zoomIn();
                        }
                    }}
                ></button>
                <button
                    id={'map_ZoomOut'}
                    className='esri-widget--button esri-icon-minus'
                    title='Zoom out'
                    style={{ marginRight: '5px', border: '0px' }}
                    onClick={() => {
                        if (zoomWidget) {
                            zoomWidget.zoomOut();
                        }
                    }}
                ></button>
                <button
                    id={'selectionDetailWidget'}
                    className='esri-widget--button esri-icon-legend'
                    title='Show selection details'
                    style={
                        selectedServiceLocationIds.length > 0 || selectedServicePattern.length > 0
                            ? { border: '0px', backgroundColor: '#EE8A22', color: 'white' }
                            : { border: '0px' }
                    }
                    disabled={mapMode === 1}
                    onClick={() => {
                        if (mapMode === 0) setSelectionDetailOpen(!selectionDetailOpen);
                    }}
                ></button>
                {showServiceAreaBoundaryWidget && (
                    <ServiceAreaBoundaryWidget
                        serviceAreaBoundary={serviceAreaBoundary}
                        openServiceAreaBoundaryTool={openServiceAreaBoundaryTool}
                        isServiceAreaBoundaryWidgetClicked={isServiceAreaBoundaryWidgetClicked}
                        closeServiceAreaBoundaryTool={closeServiceAreaBoundaryTool}
                    />
                )}
            </>
        </div>
    ) : (
        <CircularProgress />
    );
};

const MapAlertDialog = (props) => {
    return (
        <Dialog
            open={props.openMapAlert}
            maxWidth={'sm'}
            fullWidth={false}
        >
            <DialogTitle>{props.mapAlertTitle}</DialogTitle>
            <DialogContent>{props.mapAlertMessage}</DialogContent>
            <DialogActions style={{ justifyContent: 'center' }}>
                <Button
                    style={{ backgroundColor: '#ee8a1d', color: '#fff' }}
                    onClick={() => {
                        props.setOpenMapAlert(false);
                        props.setMapAlertTitle('');
                        props.setMapAlertMessage('');
                    }}
                >
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    );
};

//add color properties for route and day of week
const createRouteTabViewTableData = (data) => {
    const serviceDayRoute = {};
    const routeServiceDay = {};

    data.sort((a, b) => a.Day - b.Day) //currently is sorted by route. need additional sort by day to ensure expected distribution of colors for routes
        .map((v, i) => {
            const catchAll = {
                Svg: '<svg height="10" width="13"> <circle cx="5" cy="5" r="4" stroke="black" stroke-width="1" fill="' + '#000000' + '" /></svg>',
                SvgColor: '#000000',
                SvgStyle: 'circle',
            };
            let dayOfWeekColors = catchAll; //start off as black circle
            let routeColors = catchAll; //start off as black circle

            const serviceDayColorMap = {
                0: '#000000',
                1: '#FF0000',
                2: '#00FF00',
                3: '#4C7300',
                4: '#00FFFF',
                5: '#0000FF',
                6: '#770081',
                7: '#A06632',
            };
            if (serviceDayColorMap[v.Day]) {
                dayOfWeekColors = {
                    Svg:
                        '<svg height="10" width="13"> <circle cx="5" cy="5" r="4" stroke="black" stroke-width="1" fill="' +
                        serviceDayColorMap[v.Day] +
                        '" /></svg>',
                    SvgColor: serviceDayColorMap[v.Day],
                    SvgStyle: 'circle',
                };
            }
            //
            if (v.Route !== '0' && v.Route !== '') {
                let cc = i % 10;
                let cnt = i % 30;
                let Svg =
                    '<svg height="10" width="13"> <polygon points="0,5 5,0 10,5 5,10" stroke="black" stroke-width="1" fill="' +
                    colors[cc] +
                    '" /></svg>';
                let SvgStyle = 'diamond';
                let SvgColor = colors[cc];
                if (cnt < 10) {
                    Svg =
                        '<svg height="10" width="13"> <circle cx="5" cy="5" r="4" stroke="black" stroke-width="1" fill="' + colors[cc] + '" /></svg>';
                    SvgStyle = 'circle';
                } else if (cnt < 20) {
                    Svg =
                        '<svg height="10" width="13"> <rect width="8" height="8" stroke="black" stroke-width="1" fill="' + colors[cc] + '" /></svg>';
                    SvgStyle = 'square';
                }
                routeColors = {
                    Svg,
                    SvgColor,
                    SvgStyle,
                };
            }
            return {
                ...v,
                routeColors,
                dayOfWeekColors,
            };
        })
        //add the checkbox properties and color properties
        .forEach((v) => {
            let routeLabel = v.Route === '0' || v.Route === '' ? 'No Route' : v.Route;
            let currentSvcDayRouteCount = serviceDayRoute[v.Day]?.UnmatchedCount || 0;
            let currentRouteSvcDayCount = routeServiceDay[v.Route]?.UnmatchedCount || 0;

            const additionalProperties = {
                Svg: '',
                SvgColor: '',
                SvgStyle: '',
                checked: true,
                indeterminate: false,
            };

            serviceDayRoute[v.Day] = {
                Id: v.Day,
                Label: serviceDayMap[v.Day],
                UnmatchedCount: currentSvcDayRouteCount + (v.Unmatched || 0),
                ...additionalProperties,
                colors: v.dayOfWeekColors, //

                subRows: {
                    ...serviceDayRoute[v.Day]?.subRows,
                    [v.Route]: {
                        Id: v.Route,
                        Label: routeLabel,
                        UnmatchedCount: v.Unmatched || 0,
                        fullLabel: `${v.Day}|${v.Route}`,
                        ...additionalProperties,
                        colors: v.routeColors, //
                    },
                },
            };

            routeServiceDay[v.Route] = {
                Id: v.Route,
                Label: routeLabel,
                UnmatchedCount: currentRouteSvcDayCount + (v.Unmatched || 0),
                ...additionalProperties,
                colors: routeServiceDay[v.Route]?.colors ? routeServiceDay[v.Route].colors : v.routeColors, //always apply the first applyed colors or if there isnt a color applyed, use the routeColor property
                subRows: {
                    ...routeServiceDay[v.Route]?.subRows,
                    [v.Day]: {
                        Id: v.Day,
                        Label: serviceDayMap[v.Day],
                        UnmatchedCount: v.Unmatched || 0,
                        fullLabel: `${v.Route}|${v.Day}`,
                        ...additionalProperties,
                        colors: v.dayOfWeekColors, //
                    },
                },
            };
        });

    return {
        'R/S': chooseColorsAndConvertToRouteViewDictToTableData(routeServiceDay),
        'S/R': chooseColorsAndConvertToRouteViewDictToTableData(serviceDayRoute),
        R: chooseColorsAndConvertToRouteViewDictToTableData(routeServiceDay, true),
        S: chooseColorsAndConvertToRouteViewDictToTableData(serviceDayRoute, true),
    };
};

//set the values for the properties: Svg, SvgStyle and SvgColor are set
//removes properties that are unneeded
//convert into an array that is usable for route view tab
const chooseColorsAndConvertToRouteViewDictToTableData = (data, applyColors = false, overridingColors = null) => {
    let arr = [];
    for (let key1 in data) {
        let clone = structuredClone(data[key1]);

        let colors = overridingColors || clone.colors; //will the colors come from the parameter or the property called colors
        if (applyColors) {
            clone = {
                ...clone,
                ...colors,
            };
        }

        if (clone.subRows) {
            clone = {
                ...clone,
                subRows: chooseColorsAndConvertToRouteViewDictToTableData(clone.subRows, true, applyColors ? clone.colors : null),
            };
        }

        delete clone.colors;
        arr.push(clone);
    }
    return arr;
};

const debouncePointerMove = (func, delay) => {
    let timer;
    return function (...args) {
        clearTimeout(timer);
        timer = setTimeout(() => {
            func(...args);
        }, delay);
    };
};
