/* eslint-disable react-hooks/exhaustive-deps */
/* eslint eqeqeq: 0 */
import React, { useState, useRef, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { ExpandedDurationInput, FloatInput } from '../Manage/ManageController';
//ESRI
import { Extent } from '@arcgis/core/geometry';
//RPW
import { checkFunction } from '../../_actions/Global.actions';
//DevExpress
import 'devextreme/dist/css/dx.light.compact.css';
import DataGrid, {
    Column,
    Scrolling,
    Sorting,
    Selection,
    GroupPanel,
    Grouping,
    Pager,
    Paging,
    FilterRow,
    FilterPanel,
    HeaderFilter,
    Search,
    SearchPanel,
    ColumnChooser,
    Toolbar,
    Item,
    StateStoring,
    ColumnChooserSearch,
    ColumnChooserSelection,
} from 'devextreme-react/data-grid';
import { Button as DxButton } from 'devextreme-react';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import Select from 'react-select';
import {
    timeOfDayOptions_Start,
    timeOfDayOptions_End,
    timeOfDayOptions_Prohibited,
    defaultServiceDayList,
    renderServiceWindow,
    renderServiceProhibited,
} from '../../_helpers/common';
import SendIcon from '@mui/icons-material/Send';
import EditSequenceIcon from '@mui/icons-material/EditNoteTwoTone';
import RefreshStopIcon from '@mui/icons-material/Autorenew';

export const ServiceLocationTable = (props) => {
    const title = 'Stops';
    let clientId = JSON.parse(sessionStorage.getItem('userInfo'))['ClientName'];
    if (clientId) {
        clientId = clientId.toLowerCase();
    } else {
        clientId = window.location.hostname.includes('navusoft') ? 'navusoft' : 'non-navu';
    }

    const refServiceLocationListGrid = useRef();
    const {
        workspace,
        siteMode,
        bottomPaneGrid,
        showExport,
        setShowExport,
        handleDownloadNavusoft,
        mapView,
        handlePinServiceLocations,
        handleFlipServiceLocations,
        setUnpinServiceLocationDialogOpen,
        handleEditServiceLocation,
        serviceLocationData,
        selectedServiceLocationIds,
        routeViewTableData,
        bottomPaneVisibility,
        setSelectedServiceLocationIds,
        slFilter,
        setDeleteServiceLocationDialogOpen,
        editSelectedServiceLocations,
        bottomHeight,
        timeWindowToggle,
        setTimeWindowToggle,
        serviceDay,
        handleToggleServicePattern,
        handleManageServicePattern,
        servicePatternView,
        handleExportServicePattern,
        week,
        frequency,
        routeViewTabCategorization,
        setSequenceDialogOpen,
        setRefreshServiceLocationOpen,
        projectObject,
        specialtyList,
    } = props;

    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [serviceLocationListView, setServiceLocationListView] = useState('All'); //All | Selected | Unpinned | Pinned
    const [unpinnedIds, setUnpinnedIds] = useState([]);
    const [pinnedIds, setPinnedIds] = useState([]);

    React.useEffect(() => {
        if (bottomPaneGrid === 'ServiceLocationList') {
            PopulateServiceLocations();
        }
    }, [bottomPaneGrid]);

    React.useEffect(() => {
        PopulateServiceLocations();
    }, [serviceLocationData, routeViewTabCategorization, routeViewTableData, week, frequency]);

    React.useEffect(() => {
        if (refServiceLocationListGrid && refServiceLocationListGrid.current && refServiceLocationListGrid.current.instance) {
            refServiceLocationListGrid.current.instance.clearFilter();
        }
    }, [workspace]);

    React.useEffect(() => {
        if (refServiceLocationListGrid && refServiceLocationListGrid.current && refServiceLocationListGrid.current.instance) {
            if (slFilter) {
                refServiceLocationListGrid.current.instance.filter(['Route', '=', slFilter]);
            } else {
                refServiceLocationListGrid.current.instance.clearFilter();
            }
        }
    }, [slFilter]);

    React.useEffect(() => {
        //RO-2275
        if (serviceLocationListView === 'Selected' && selectedServiceLocationIds.length === 0) {
            setServiceLocationListView('All');
        } else if (serviceLocationListView === 'Selected') {
            refServiceLocationListGrid.current?.instance.filter(filterTableInstanceBasedOnSelectedLocationIds());
        }
        window.userSelect = false;
    }, [selectedServiceLocationIds]);

    React.useEffect(() => {
        if (filteredData && filteredData.length > 0) {
            setServiceLocationDataByView(filteredData);
        }
    }, [serviceLocationListView]);

    const PopulateServiceLocations = () => {
        if (refServiceLocationListGrid && refServiceLocationListGrid.current && refServiceLocationListGrid.current.instance) {
            refServiceLocationListGrid.current.instance.clearFilter();
            refServiceLocationListGrid.current.instance.beginCustomLoading();
        }
        let sortedData = [];
        if (serviceLocationData && serviceLocationData.length > 0) {
            sortedData = serviceLocationData.sort((a, b) => {
                let routeDiff = a.Route.localeCompare(b.Route, 'en', { numeric: true });
                if (!routeDiff) {
                    if (a.Sequence === b.Sequence) {
                        if (a.Status === 'M') {
                            return 1;
                        } else if (b.Status === 'M') {
                            return -1;
                        } else {
                            return a.Status.localeCompare(b.Status, 'en', { numeric: true });
                        }
                    } else {
                        return a.Sequence - b.Sequence;
                    }
                } else {
                    return routeDiff;
                }
            });
            if (serviceDay.value === 0) {
                if (routeViewTabCategorization.value.startsWith('R')) {
                    let routes = structuredClone(props.routeViewTableData[routeViewTabCategorization.value]); //need adjustment here
                    let result = [];
                    routes.forEach((r) => {
                        let selected = r.subRows.filter((rs) => rs.checked === true).map((r) => r.Id);
                        let id = r.Id;
                        if (id === 'No Route') id = '0';
                        let sd = [...sortedData].filter((s) => s.Route === id && selected.includes(s.ServiceDay));
                        result = [...result, ...sd];
                    });
                    sortedData = [...result];
                } else {
                    let routes = structuredClone(props.routeViewTableData[routeViewTabCategorization.value]); //need adjustment here
                    let result = [];
                    routes.forEach((r) => {
                        let selected = r.subRows.filter((rs) => rs.checked === true).map((r) => (r.Id === 'No Route' ? '0' : r.Id));
                        let sd = [...sortedData].filter((s) => s.ServiceDay === r.Id && selected.includes(s.Route));
                        result = [...result, ...sd];
                    });
                    sortedData = [...result];
                }
            } else {
                let routes = structuredClone(props.routeViewTableData['R'])
                    .filter((r) => r.checked === true)
                    .map((r) => r.Id); //need adjustment here
                if (routes.includes('No Route')) {
                    routes.push('0');
                }
                sortedData = sortedData.filter((s) => routes.includes(s.Route));
            }
        }

        if (sessionStorage.getItem('mdr') === 'true') {
            if (week) {
                switch (week) {
                    case '1':
                        sortedData = sortedData.filter((s) => s['Week'] === '1' || s['Week'] == null || s['Week'] == '');
                        break;
                    case '2':
                        sortedData = sortedData.filter((s) => s['Week'] === '2' || s['Week'] == null || s['Week'] == '');
                        break;
                    case '3':
                        sortedData = sortedData.filter(
                            (s) =>
                                s['Week'] === '3' ||
                                s['Week'] == null ||
                                s['Week'] == '' ||
                                (s['Week'] === '1' && (s['Month'] == null || s['Month'] == '')),
                        );
                        break;
                    case '4':
                        sortedData = sortedData.filter(
                            (s) =>
                                s['Week'] === '4' ||
                                s['Week'] == null ||
                                s['Week'] == '' ||
                                (s['Week'] === '2' && (s['Month'] == null || s['Month'] == '')),
                        );
                        break;
                    case '5':
                        sortedData = sortedData.filter((s) => s['Week'] === '1' || s['Week'] == null || s['Week'] === '5' || s['Week'] == '');
                        break;
                    case '6':
                        sortedData = sortedData.filter((s) => s['Week'] === '2' || s['Week'] == null || s['Week'] === '6' || s['Week'] == '');
                        break;
                    case '7':
                        sortedData = sortedData.filter(
                            (s) =>
                                s['Week'] === '3' ||
                                s['Week'] == null ||
                                s['Week'] === '7' ||
                                s['Week'] == '' ||
                                (s['Week'] === '1' && (s['Month'] == null || s['Month'] == '')),
                        );
                        break;
                    case '8':
                        sortedData = sortedData.filter(
                            (s) =>
                                s['Week'] === '4' ||
                                s['Week'] == null ||
                                s['Week'] === '8' ||
                                s['Week'] == '' ||
                                (s['Week'] === '2' && (s['Month'] == null || s['Month'] == '')),
                        );
                        break;
                    default:
                        sortedData = sortedData.filter((s) => s['Week'] === week);
                        break;
                }
            }
            if (frequency) {
                switch (frequency) {
                    case '8':
                        sortedData = sortedData.filter((s) => s['Week'] == null || s['Week'] == '');
                        break;
                    case '9':
                        sortedData = sortedData.filter((s) => s['Week'] != null && s['Week'] != '' && (s['Month'] == null || s['Month'] == ''));
                        break;
                    case '10':
                        sortedData = sortedData.filter((s) => s['Week'] >= 1 && s['Week'] <= 4 && s['Month'] == 1);
                        break;
                    case '11':
                        sortedData = sortedData.filter((s) => s['Week'] > 4);
                        break;
                    default:
                        sortedData = sortedData;
                        break;
                }
            }
        }

        setFilteredData(sortedData);
        setServiceLocationDataByView(sortedData);
        setData(sortedData);
        if (refServiceLocationListGrid && refServiceLocationListGrid.current && refServiceLocationListGrid.current.instance) {
            refServiceLocationListGrid.current.instance.endCustomLoading();
        }
    };

    const setServiceLocationDataByView = (serviceLocationRows) => {
        if (serviceLocationRows) {
            let pinnedIds = [],
                pinnedData = [],
                unpinnedIds = [],
                unpinnedData = [];
            serviceLocationRows.forEach((fd) => {
                if (fd.RefId <= 0) {
                    unpinnedIds.push(fd.Id);
                    unpinnedData.push(fd);
                }
                if (fd.Score === -1) {
                    pinnedIds.push(fd.Id);
                    pinnedData.push(fd);
                }
            });
            setUnpinnedIds(unpinnedIds);
            setPinnedIds(pinnedIds);

            switch (serviceLocationListView) {
                case 'All':
                    refServiceLocationListGrid.current?.instance.clearFilter();
                    break;
                case 'Selected':
                    refServiceLocationListGrid.current?.instance.filter(filterTableInstanceBasedOnSelectedLocationIds());
                    break;
                case 'Unpinned':
                    refServiceLocationListGrid.current?.instance.filter(['RefId', '<=', 0]);
                    break;
                case 'Pinned':
                    refServiceLocationListGrid.current?.instance.filter(['Score', '=', -1]);
                    break;
                default:
                    break;
            }
        }
    };

    const zoomToServiceLocations = () => {
        let selServiceLocationXYs = [];
        serviceLocationData.forEach((row) => {
            if (selectedServiceLocationIds.includes(row.Id)) {
                selServiceLocationXYs.push([row.X, row.Y]);
            }
        });
        if (selServiceLocationXYs.length > 0) {
            var xs = selServiceLocationXYs.map(function (loc) {
                return loc[0];
            });
            var ys = selServiceLocationXYs.map(function (loc) {
                return loc[1];
            });

            var MinX = Math.min.apply(null, xs);
            var MinY = Math.min.apply(null, ys);
            var MaxX = Math.max.apply(null, xs);
            var MaxY = Math.max.apply(null, ys);

            if (MinX === MaxX && MinY === MaxY) {
                mapView.goTo({
                    center: [MinX, MaxY],
                    zoom: 17,
                });
            } else {
                let serviceLocationExtent = new Extent({ xmin: MinX, ymin: MinY, xmax: MaxX, ymax: MaxY });
                mapView.extent = serviceLocationExtent.expand(1.5);
            }
        }
    };

    const handleZoomToServiceLocation = (data) => {
        mapView.goTo({
            center: [data.X, data.Y],
            zoom: 17,
        });
    };

    const renderServiceDay = (data) => {
        if (data && data.ServiceDay) {
            return defaultServiceDayList[data.ServiceDay].label;
        }
        return '';
    };

    const renderExistServiceDay = (data) => {
        if (data && [...Array(7).keys()].map((i) => i + 1).includes(data.ExistServiceDay)) {
            return defaultServiceDayList[data.ExistServiceDay].label;
        }
        return '';
    };

    const renderSpeciality = (data) => {
        if (data.SkillSetProperties && data.SkillSetProperties.length > 0 && specialtyList && specialtyList.length > 0) {
            let arr = [];
            data.SkillSetProperties.forEach((s) => {
                arr.push(specialtyList.find((sp) => sp.value === s)['label']);
            });
            return arr.toString();
        } else {
            return '';
        }
    };

    //RO-2275
    const filterTableInstanceBasedOnSelectedLocationIds = React.useCallback(() => {
        return selectedServiceLocationIds.reduce((acc, v, i) => {
            if (i > 0) acc.push('or');
            acc.push(['Id', '=', v]);
            return acc;
        }, []);
    }, [selectedServiceLocationIds]);

    return (
        <>
            {bottomPaneVisibility && bottomPaneGrid === 'ServiceLocationList' && (
                <DataGrid
                    id={'serviceLocationGrid'}
                    ref={refServiceLocationListGrid}
                    style={{ height: bottomHeight }}
                    dataSource={data}
                    keyExpr='Id'
                    showBorders={true}
                    showColumnLines={true}
                    showRowLines={true}
                    allowColumnResizing={true}
                    allowColumnReordering={true}
                    columnResizingMode={'widget'}
                    columnMinWidth={50}
                    minWidth={1920}
                    columnAutoWidth={false}
                    selectedRowKeys={selectedServiceLocationIds}
                    onSelectionChanged={(e) => {
                        // the implementation of the devextreme's react data grid has interesting behavior:
                        // -when the clicking on the checkall without any other selection in another tab, the onSelectionChanged will run 2 times.
                        // hence the 'window.userSelect' variable is used to avoid this. This is reset when the useEffect kicks in when there is a change to  selectedServiceLocationIds
                        // -if there is a preexisting selection those ids' are not present in the current dataset for the table,
                        // when the checkall is clicked, the onSelectChange will run once, then it will run 2 times
                        // hence the 'window.multiSelectTwo' and 'window.multiSelect' variables are used because it will result in an infinite Loop

                        // the prior implementation avoided this altogether because ANY selection made would resulted in the movement of the use to the ALL tab and since the ALL tab had everything it wouldnt be a factor.

                        if (window.multiSelect) {
                            window.multiSelect = false;
                            return;
                        }
                        if (window.multiSelectTwo) {
                            window.multiSelectTwo = false;
                            return;
                        }
                        if (!window.userSelect) {
                            if (sessionStorage.getItem('mdr') === 'true') {
                                if (e.currentDeselectedRowKeys.length === 1) {
                                    let sl = serviceLocationData.find((s) => s.Id === e.currentDeselectedRowKeys[0]);
                                    let associatedSLs = serviceLocationData.filter((s) => s.SdId === sl.SdId);
                                    let gids = [...new Set(associatedSLs.map((m) => m.Id))];
                                    let selected = e.selectedRowKeys.filter((i) => !gids.includes(i));
                                    setSelectedServiceLocationIds(selected);
                                } else if (e.currentSelectedRowKeys.length === 1) {
                                    let sl = serviceLocationData.find((s) => s.Id === e.currentSelectedRowKeys[0]);
                                    let associatedSLs = serviceLocationData.filter((s) => s.SdId === sl.SdId);
                                    let gids = [...new Set(associatedSLs.map((m) => m.Id))];
                                    let selected = [...new Set([...e.selectedRowKeys, ...gids])];
                                    setSelectedServiceLocationIds(selected);
                                } else {
                                    setSelectedServiceLocationIds(e.selectedRowKeys);
                                }
                            } else {
                                setSelectedServiceLocationIds(e.selectedRowKeys);
                            }
                            window.userSelect = true;
                            if (
                                (serviceLocationListView === 'Unpinned' || serviceLocationListView === 'Pinned') &&
                                e.selectedRowKeys.length > refServiceLocationListGrid.current?.instance.totalCount()
                            ) {
                                window.multiSelect = true;
                                window.multiSelectTwo = true;
                            }
                        }
                    }}
                    onCellPrepared={(e) => {
                        if (e.data) {
                            if (unpinnedIds.includes(e.data.Id)) {
                                e.cellElement.style.color = 'rgba(255, 0, 0, 1)';
                            } else if (pinnedIds.includes(e.data.Id)) {
                                e.cellElement.style.color = 'rgba(25, 118, 210, 1)';
                            }
                        }
                    }}
                    onCellClick={(e) => {
                        if (e.columnIndex === 1) {
                            e.event.stopImmediatePropagation();
                        }
                    }}
                >
                    <SearchPanel
                        visible={true}
                        width={240}
                        placeholder='Search...'
                    />
                    <HeaderFilter visible={true} />
                    <FilterRow
                        visible={false}
                        applyFilter={true}
                    />
                    <FilterPanel visible={false} />
                    <Scrolling rowRenderingMode='virtual'></Scrolling>
                    <Paging defaultPageSize={10000} />
                    <GroupPanel visible={false} />
                    <Grouping autoExpandAll={false} />
                    <Pager
                        visible={true}
                        displayMode={'full'}
                        showPageSizeSelector={false}
                        showInfo={false}
                        showNavigationButtons={false}
                    />
                    <ColumnChooser
                        enabled={true}
                        mode={'select'}
                    >
                        <ColumnChooserSearch enabled={true} />
                        <ColumnChooserSelection
                            allowSelectAll={true}
                            selectByClick={true}
                            recursive={true}
                        />
                    </ColumnChooser>
                    {/* {
                        !servicePatternView && */}
                    <Selection
                        mode='multiple'
                        // selectAllMode={'allPages'}
                        // showCheckBoxesMode={'onClick'}
                        width={20}
                    />
                    {/* } */}
                    <Scrolling mode='virtual' />
                    <Sorting
                        mode='multiple'
                        showSortIndexes='true'
                    />
                    {/*<Export enabled={true} allowExportSelectedData={true} />*/}
                    <StateStoring
                        enabled={true}
                        type='localStorage'
                        storageKey='stopGridLayout'
                    />
                    <Toolbar>
                        <Item location='before'>
                            <div className='dx-fieldset-header'>&nbsp;{title}&nbsp;&nbsp;</div>
                        </Item>
                        <Item location='before'>
                            <DxButton
                                text={'All (' + filteredData.length + ')'}
                                type={serviceLocationListView === 'All' ? 'success' : 'normal'}
                                onClick={() => {
                                    setServiceLocationListView('All');
                                }}
                            ></DxButton>
                        </Item>
                        <Item location='before'>
                            <DxButton
                                text={'Selected (' + selectedServiceLocationIds.length + ')'}
                                type={serviceLocationListView === 'Selected' ? 'success' : 'normal'}
                                onClick={() => {
                                    setServiceLocationListView('Selected');
                                }}
                            ></DxButton>
                        </Item>
                        <Item location='before'>
                            <DxButton
                                text={'Unpinned (' + unpinnedIds.length + ')'}
                                type={serviceLocationListView === 'Unpinned' ? 'danger' : 'normal'}
                                onClick={() => {
                                    setServiceLocationListView('Unpinned');
                                }}
                            ></DxButton>
                        </Item>
                        <Item location='before'>
                            <DxButton
                                text={'Pinned (' + pinnedIds.length + ')'}
                                type={serviceLocationListView === 'Pinned' ? 'default' : 'normal'}
                                onClick={() => {
                                    setServiceLocationListView('Pinned');
                                }}
                            ></DxButton>
                        </Item>
                        <Item location='before'>
                            <DxButton
                                hint='Zoom to selected stops'
                                disabled={
                                    selectedServiceLocationIds.length > 0 && !unpinnedIds.some((it) => selectedServiceLocationIds.includes(it))
                                        ? false
                                        : true
                                }
                                onClick={() => zoomToServiceLocations()}
                            >
                                <i className={'RS_ZOOM_TO_SELECT'}></i>
                            </DxButton>
                        </Item>

                        <Item location='before'>
                            <DxButton
                                hint='Edit selected stops'
                                disabled={selectedServiceLocationIds.length === 0}
                                visible={siteMode === 'DISPATCHER'}
                                onClick={() => editSelectedServiceLocations()}
                            >
                                <i className={'RS_EDIT'}></i>
                            </DxButton>
                        </Item>

                        <Item location='before'>
                            <DxButton
                                hint='Delete selected stops'
                                disabled={
                                    (sessionStorage.getItem('mdr') !== 'true' && selectedServiceLocationIds.length === 0) ||
                                    (sessionStorage.getItem('mdr') === 'true' && (serviceDay.value !== 0 || selectedServiceLocationIds.length === 0))
                                }
                                onClick={() => setDeleteServiceLocationDialogOpen(true)}
                            >
                                <i className={'RS_DELETE'}></i>
                            </DxButton>
                        </Item>

                        <Item location='before'>
                            {siteMode === 'DISPATCHER' && (
                                <DxButton
                                    hint='Pin selected stops'
                                    disabled={selectedServiceLocationIds.length > 0 ? false : true}
                                    onClick={() => handlePinServiceLocations()}
                                >
                                    <i className={'RS_PIN'}></i>
                                </DxButton>
                            )}
                        </Item>
                        <Item location='before'>
                            {siteMode === 'DISPATCHER' && (
                                <DxButton
                                    hint='Unpin selected stops'
                                    disabled={selectedServiceLocationIds.length > 0 ? false : true}
                                    onClick={() => setUnpinServiceLocationDialogOpen(true)}
                                >
                                    <i className={'RS_UNPIN'}></i>
                                </DxButton>
                            )}
                        </Item>
                        <Item location='before'>
                            {siteMode === 'DISPATCHER' && (
                                <DxButton
                                    hint='Flip selected stops'
                                    disabled={selectedServiceLocationIds.length > 0 ? false : true}
                                    onClick={() => handleFlipServiceLocations()}
                                >
                                    <i className={'RS_FLIP'}></i>
                                </DxButton>
                            )}
                        </Item>

                        <Item
                            location='before'
                            name='groupPanel'
                        >
                            <div style={{ width: '20px' }} />
                        </Item>

                        <Item location='before'>
                            <DxButton
                                hint='Toggle Time Window Display'
                                type={timeWindowToggle ? 'success' : 'normal'}
                                onClick={() => {
                                    setTimeWindowToggle(!timeWindowToggle);
                                }}
                            >
                                <AccessTimeIcon style={{ fontSize: 16 }} />
                            </DxButton>
                        </Item>

                        <Item
                            location='before'
                            visible={checkFunction(215)}
                        >
                            <DxButton
                                icon='RS RS_EXPORT_SERVICE_LOCATION RS_GRID_ICON'
                                hint={'Export Stops'}
                                onClick={() => {
                                    setShowExport(!showExport);
                                }}
                            ></DxButton>
                        </Item>

                        <Item
                            location='before'
                            visible={clientId === 'navusoft'}
                        >
                            <DxButton
                                hint={'Send to Navusoft'}
                                onClick={() => {
                                    handleDownloadNavusoft();
                                }}
                            >
                                <SendIcon sx={{ fontSize: '14px' }} />
                            </DxButton>
                        </Item>

                        <Item
                            location='before'
                            visible={sessionStorage.getItem('mdr') === 'true'}
                        >
                            <DxButton
                                hint={'Show Service Pattern'}
                                type={servicePatternView ? 'success' : 'normal'}
                                onClick={() => {
                                    handleToggleServicePattern();
                                }}
                            >
                                <i className='RS_TOGGLE_SERVICE_PATTERN' />
                            </DxButton>
                        </Item>

                        <Item
                            location='before'
                            visible={siteMode === 'PLANNER' && sessionStorage.getItem('mdr') === 'true'}
                        >
                            <DxButton
                                hint={'Manage Service Pattern'}
                                onClick={() => {
                                    handleManageServicePattern();
                                }}
                            >
                                <i className='RS_MANAGE_SERVICE_PATTERN' />
                            </DxButton>
                        </Item>

                        <Item
                            location='before'
                            visible={sessionStorage.getItem('mdr') === 'true' && siteMode === 'PLANNER'}
                        >
                            <DxButton
                                hint={'Export Service Pattern Changes'}
                                type={'normal'}
                                onClick={() => {
                                    handleExportServicePattern();
                                }}
                            >
                                <i className='RS_EXPORT_SERVICE_PATTERN' />
                            </DxButton>
                        </Item>

                        <Item
                            location='before'
                            visible={siteMode === 'PLANNER'}
                        >
                            <DxButton
                                hint={'Edit Sequence'}
                                onClick={() => {
                                    setSequenceDialogOpen(true);
                                }}
                            >
                                <EditSequenceIcon style={{ fontSize: 16 }} />
                            </DxButton>
                        </Item>

                        <Item
                            location='before'
                            visible={siteMode === 'PLANNER'}
                        >
                            <DxButton
                                hint={'Refresh Service Locations'}
                                disabled={projectObject && !projectObject.RefreshSLAvailable}
                                onClick={() => {
                                    setRefreshServiceLocationOpen(true);
                                }}
                            >
                                <RefreshStopIcon style={{ fontSize: 16 }} />
                            </DxButton>
                        </Item>

                        <Item location='after'>
                            <DxButton
                                icon='eyeclose'
                                hint='Clear Filters'
                                onClick={() => {
                                    refServiceLocationListGrid.current.instance.clearFilter();
                                }}
                            />
                        </Item>
                        <Item
                            location='after'
                            name='columnChooserButton'
                        />
                        <Item
                            location='after'
                            name='searchPanel'
                        />
                    </Toolbar>
                    <Column
                        minWidth={siteMode === 'DISPATCHER' ? '60' : '20'}
                        width={siteMode === 'DISPATCHER' ? '60' : '20'}
                        allowHiding={false}
                        type='buttons'
                        showInColumnChooser={false}
                        allowReordering={false}
                        cellRender={(data) => {
                            return (
                                <>
                                    {(data.row.data.X !== 0 || data.row.data.Y !== 0) && (
                                        <span
                                            title='Zoom to'
                                            icon={'RS_ZOOM'}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                handleZoomToServiceLocation(data.row.data);
                                            }}
                                        >
                                            <div style={{ display: 'inline-block' }}>
                                                <i className={'RS_ZOOM'}></i>
                                            </div>
                                        </span>
                                    )}
                                    {siteMode === 'DISPATCHER' && (
                                        <span
                                            title='Edit Stop Info'
                                            icon={'RS_EDIT'}
                                            onClick={() => handleEditServiceLocation(data.row.data)}
                                        >
                                            <div style={{ display: 'inline-block' }}>
                                                <i className={'RS_EDIT'}></i>
                                            </div>
                                        </span>
                                    )}
                                </>
                            );
                        }}
                    />
                    <Column
                        dataField='Route'
                        minWidth='100'
                        width='100'
                        calculateCellValue={(data) => (data.Route === '0' ? 'No Route' : data.Route)}
                    >
                        <HeaderFilter>
                            <Search enabled={true} />
                        </HeaderFilter>
                    </Column>
                    <Column
                        dataField='Sequence'
                        minWidth='80'
                        width='80'
                    >
                        <HeaderFilter>
                            <Search enabled={true} />
                        </HeaderFilter>
                    </Column>
                    <Column
                        dataField='ServiceDay'
                        minWidth='100'
                        width='100'
                        caption='Service Day'
                        calculateCellValue={renderServiceDay}
                    >
                        <HeaderFilter>
                            <Search enabled={true} />
                        </HeaderFilter>
                    </Column>
                    <Column
                        dataField='Address'
                        minWidth='120'
                        width='150'
                    >
                        <HeaderFilter>
                            <Search enabled={true} />
                        </HeaderFilter>
                    </Column>
                    <Column
                        dataField='Building'
                        minWidth='60'
                        width='60'
                        caption='Bldg'
                    >
                        <HeaderFilter>
                            <Search enabled={true} />
                        </HeaderFilter>
                    </Column>
                    <Column
                        dataField='Apartment'
                        minWidth='60'
                        width='60'
                        caption='Apt'
                    >
                        <HeaderFilter>
                            <Search enabled={true} />
                        </HeaderFilter>
                    </Column>
                    <Column
                        dataField='City'
                        caption='City'
                        minWidth='75'
                        width='75'
                    >
                        <HeaderFilter>
                            <Search enabled={true} />
                        </HeaderFilter>
                    </Column>
                    <Column
                        dataField='PostalCode'
                        minWidth='100'
                        width='100'
                        caption='Postal Code'
                    >
                        <HeaderFilter>
                            <Search enabled={true} />
                        </HeaderFilter>
                    </Column>
                    <Column
                        dataField='ServiceTime'
                        minWidth='100'
                        width='100'
                        caption='Service Time'
                        calculateCellValue={(data) =>
                            data.ServiceTime ? new Date(data.ServiceTime * 3600 * 1000).toISOString().slice(11, 19) : '00:00:00'
                        }
                    >
                        <HeaderFilter>
                            <Search enabled={true} />
                        </HeaderFilter>
                    </Column>
                    <Column
                        dataField='Demand'
                        minWidth='80'
                        width='80'
                        caption='Demand'
                    >
                        <HeaderFilter>
                            <Search enabled={true} />
                        </HeaderFilter>
                    </Column>
                    <Column
                        dataField='AccountId'
                        minWidth='80'
                        width='80'
                        caption='Account'
                    >
                        <HeaderFilter>
                            <Search enabled={true} />
                        </HeaderFilter>
                    </Column>
                    <Column
                        dataField='CustomerName'
                        minWidth='100'
                        width='260'
                        caption='Customer'
                    >
                        <HeaderFilter>
                            <Search enabled={true} />
                        </HeaderFilter>
                    </Column>
                    <Column
                        dataField='CompanyName'
                        minWidth='100'
                        width='200'
                        caption='Company'
                    >
                        <HeaderFilter>
                            <Search enabled={true} />
                        </HeaderFilter>
                    </Column>
                    <Column
                        dataField='Comments'
                        minWidth='100'
                        width='100'
                    >
                        <HeaderFilter>
                            <Search enabled={true} />
                        </HeaderFilter>
                    </Column>
                    <Column
                        caption='Service'
                        minWidth='125'
                        width='125'
                        alignment='center'
                        calculateCellValue={renderServiceWindow}
                        allowFiltering={true}
                    >
                        <HeaderFilter>
                            <Search enabled={true} />
                        </HeaderFilter>
                    </Column>
                    <Column
                        caption='Service Prohibited'
                        minWidth='125'
                        width='125'
                        alignment='center'
                        calculateCellValue={renderServiceProhibited}
                        allowFiltering={true}
                    >
                        <HeaderFilter>
                            <Search enabled={true} />
                        </HeaderFilter>
                    </Column>

                    <Column
                        dataField='RefId'
                        minWidth='80'
                        width='80'
                        caption='Segment ID'
                        visible={false}
                    >
                        <HeaderFilter>
                            <Search enabled={true} />
                        </HeaderFilter>
                    </Column>
                    <Column
                        dataField='Side'
                        minWidth='60'
                        width='60'
                        visible={false}
                    >
                        <HeaderFilter>
                            <Search enabled={true} />
                        </HeaderFilter>
                    </Column>
                    <Column
                        dataField='Status'
                        minWidth='75'
                        width='75'
                        visible={false}
                    >
                        <HeaderFilter>
                            <Search enabled={true} />
                        </HeaderFilter>
                    </Column>
                    <Column
                        dataField='AddressId'
                        caption='Address ID'
                        minWidth='100'
                        width='100'
                        visible={false}
                    >
                        <HeaderFilter>
                            <Search enabled={true} />
                        </HeaderFilter>
                    </Column>
                    <Column
                        dataField='BinId'
                        caption='Bin ID'
                        minWidth='80'
                        width='80'
                        visible={false}
                    >
                        <HeaderFilter>
                            <Search enabled={true} />
                        </HeaderFilter>
                    </Column>
                    <Column
                        dataField='Quantity'
                        caption='Quantity'
                        minWidth='80'
                        width='80'
                        visible={false}
                    >
                        <HeaderFilter>
                            <Search enabled={true} />
                        </HeaderFilter>
                    </Column>
                    <Column
                        dataField='BinSize'
                        caption='Bin Size'
                        minWidth='80'
                        width='80'
                        visible={false}
                    >
                        <HeaderFilter>
                            <Search enabled={true} />
                        </HeaderFilter>
                    </Column>
                    <Column
                        dataField='ExistRoute'
                        caption='Exist Route'
                        minWidth='100'
                        width='100'
                        visible={false}
                    >
                        <HeaderFilter>
                            <Search enabled={true} />
                        </HeaderFilter>
                    </Column>
                    <Column
                        dataField='ExistSequence'
                        caption='Exist Sequence'
                        minWidth='120'
                        width='120'
                        visible={false}
                    >
                        <HeaderFilter>
                            <Search enabled={true} />
                        </HeaderFilter>
                    </Column>
                    <Column
                        dataField='ExtraTime'
                        caption='Extra Time'
                        minWidth='100'
                        width='100'
                        visible={false}
                    >
                        <HeaderFilter>
                            <Search enabled={true} />
                        </HeaderFilter>
                    </Column>
                    <Column
                        dataField='FrequencyCode'
                        caption='Frequency Code'
                        minWidth='120'
                        width='120'
                        visible={false}
                    >
                        <HeaderFilter>
                            <Search enabled={true} />
                        </HeaderFilter>
                    </Column>
                    <Column
                        dataField='X'
                        caption='X'
                        minWidth='50'
                        width='50'
                        visible={false}
                    >
                        <HeaderFilter>
                            <Search enabled={true} />
                        </HeaderFilter>
                    </Column>
                    <Column
                        dataField='Y'
                        caption='Y'
                        minWidth='50'
                        width='50'
                        visible={false}
                    >
                        <HeaderFilter>
                            <Search enabled={true} />
                        </HeaderFilter>
                    </Column>
                    <Column
                        dataField='LockStop'
                        minWidth='80'
                        width='80'
                        caption='Lock Stop'
                        visible={false}
                    >
                        <HeaderFilter>
                            <Search enabled={true} />
                        </HeaderFilter>
                    </Column>
                    <Column
                        dataField='ProductCode'
                        minWidth='120'
                        width='120'
                        caption='Product Code'
                        visible={false}
                    >
                        <HeaderFilter>
                            <Search enabled={true} />
                        </HeaderFilter>
                    </Column>
                    <Column
                        dataField='Region'
                        minWidth='80'
                        width='80'
                        caption='Region'
                        visible={false}
                    >
                        <HeaderFilter>
                            <Search enabled={true} />
                        </HeaderFilter>
                    </Column>
                    <Column
                        dataField='Sector'
                        minWidth='80'
                        width='80'
                        caption='Sector'
                        visible={false}
                    >
                        <HeaderFilter>
                            <Search enabled={true} />
                        </HeaderFilter>
                    </Column>
                    <Column
                        dataField='ServiceType'
                        minWidth='120'
                        width='120'
                        caption='Service Type'
                        visible={false}
                    >
                        <HeaderFilter>
                            <Search enabled={true} />
                        </HeaderFilter>
                    </Column>
                    <Column
                        dataField='State'
                        caption='State'
                        minWidth='75'
                        width='75'
                        visible={false}
                    >
                        <HeaderFilter>
                            <Search enabled={true} />
                        </HeaderFilter>
                    </Column>
                    <Column
                        dataField='StreetName'
                        caption='Street Name'
                        minWidth='100'
                        width='100'
                        visible={false}
                    >
                        <HeaderFilter>
                            <Search enabled={true} />
                        </HeaderFilter>
                    </Column>
                    <Column
                        dataField='StreetNumber'
                        caption='Street Number'
                        minWidth='120'
                        width='120'
                        visible={false}
                    >
                        <HeaderFilter>
                            <Search enabled={true} />
                        </HeaderFilter>
                    </Column>
                    <Column
                        dataField='StreetPrefix'
                        caption='Street Prefix'
                        minWidth='120'
                        width='120'
                        visible={false}
                    >
                        <HeaderFilter>
                            <Search enabled={true} />
                        </HeaderFilter>
                    </Column>
                    <Column
                        dataField='StreetPretype'
                        caption='Street Pretype'
                        minWidth='120'
                        width='120'
                        visible={false}
                    >
                        <HeaderFilter>
                            <Search enabled={true} />
                        </HeaderFilter>
                    </Column>
                    <Column
                        dataField='StreetSuffix'
                        caption='Street Suffix'
                        minWidth='120'
                        width='120'
                        visible={false}
                    >
                        <HeaderFilter>
                            <Search enabled={true} />
                        </HeaderFilter>
                    </Column>
                    <Column
                        dataField='StreetType'
                        caption='Street Type'
                        minWidth='120'
                        width='120'
                        visible={false}
                    >
                        <HeaderFilter>
                            <Search enabled={true} />
                        </HeaderFilter>
                    </Column>
                    <Column
                        dataField='ZonePriority'
                        caption='Zone Priority'
                        minWidth='120'
                        width='120'
                        visible={false}
                    >
                        <HeaderFilter>
                            <Search enabled={true} />
                        </HeaderFilter>
                    </Column>
                    <Column
                        dataField='ZoneType'
                        caption='Zone Type'
                        minWidth='120'
                        width='120'
                        visible={false}
                    >
                        <HeaderFilter>
                            <Search enabled={true} />
                        </HeaderFilter>
                    </Column>

                    <Column
                        dataField='AlleyService'
                        caption='Alley Service'
                        minWidth='120'
                        width='120'
                        visible={false}
                    >
                        <HeaderFilter>
                            <Search enabled={true} />
                        </HeaderFilter>
                    </Column>
                    <Column
                        dataField='Comments2'
                        caption='Comments 2'
                        minWidth='120'
                        width='120'
                        visible={false}
                    >
                        <HeaderFilter>
                            <Search enabled={true} />
                        </HeaderFilter>
                    </Column>
                    <Column
                        dataField='Comments3'
                        caption='Comments 3'
                        minWidth='120'
                        width='120'
                        visible={false}
                    >
                        <HeaderFilter>
                            <Search enabled={true} />
                        </HeaderFilter>
                    </Column>
                    <Column
                        dataField='District'
                        caption='District'
                        minWidth='120'
                        width='120'
                        visible={false}
                    >
                        <HeaderFilter>
                            <Search enabled={true} />
                        </HeaderFilter>
                    </Column>
                    <Column
                        dataField='ExistServiceDay'
                        caption='Exist Service Day'
                        minWidth='120'
                        width='120'
                        visible={false}
                        calculateCellValue={renderExistServiceDay}
                    >
                        <HeaderFilter>
                            <Search enabled={true} />
                        </HeaderFilter>
                    </Column>
                    <Column
                        dataField='ExistWeek'
                        caption='Exist Week'
                        minWidth='120'
                        width='120'
                        visible={false}
                    >
                        <HeaderFilter>
                            <Search enabled={true} />
                        </HeaderFilter>
                    </Column>
                    <Column
                        dataField='ExistMonth'
                        caption='Exist Month'
                        minWidth='120'
                        width='120'
                        visible={false}
                    >
                        <HeaderFilter>
                            <Search enabled={true} />
                        </HeaderFilter>
                    </Column>
                    <Column
                        dataField='Month'
                        caption='Month'
                        minWidth='120'
                        width='120'
                        visible={false}
                    >
                        <HeaderFilter>
                            <Search enabled={true} />
                        </HeaderFilter>
                    </Column>
                    <Column
                        dataField='Week'
                        caption='Week'
                        minWidth='120'
                        width='120'
                        visible={false}
                    >
                        <HeaderFilter>
                            <Search enabled={true} />
                        </HeaderFilter>
                    </Column>
                    <Column
                        dataField='Trip'
                        caption='Trip'
                        minWidth='120'
                        width='120'
                        visible={false}
                    >
                        <HeaderFilter>
                            <Search enabled={true} />
                        </HeaderFilter>
                    </Column>
                    <Column
                        dataField='PriceCode'
                        caption='Price Code'
                        minWidth='120'
                        width='120'
                        visible={false}
                    >
                        <HeaderFilter>
                            <Search enabled={true} />
                        </HeaderFilter>
                    </Column>
                    <Column
                        dataField='UniqueId'
                        caption='Unique Id'
                        minWidth='120'
                        width='120'
                        visible={false}
                    >
                        <HeaderFilter>
                            <Search enabled={true} />
                        </HeaderFilter>
                    </Column>
                    <Column
                        dataField='ServiceDate'
                        caption='Service Date'
                        minWidth='120'
                        width='120'
                        visible={false}
                    >
                        <HeaderFilter>
                            <Search enabled={true} />
                        </HeaderFilter>
                    </Column>
                    <Column
                        dataField='SiteId'
                        caption='Site Id'
                        minWidth='120'
                        width='120'
                        visible={false}
                    >
                        <HeaderFilter>
                            <Search enabled={true} />
                        </HeaderFilter>
                    </Column>
                    <Column
                        dataField='ServiceId'
                        caption='Service Id'
                        minWidth='120'
                        width='120'
                        visible={false}
                    >
                        <HeaderFilter>
                            <Search enabled={true} />
                        </HeaderFilter>
                    </Column>
                    <Column
                        dataField='SpSerial'
                        caption='Serial'
                        minWidth='120'
                        width='120'
                        visible={false}
                    >
                        <HeaderFilter>
                            <Search enabled={true} />
                        </HeaderFilter>
                    </Column>
                    <Column
                        dataField='IitTruxfreq'
                        caption='Trux Freq'
                        minWidth='120'
                        width='120'
                        visible={false}
                    >
                        <HeaderFilter>
                            <Search enabled={true} />
                        </HeaderFilter>
                    </Column>
                    <Column
                        dataField='IttTwo'
                        caption='Two'
                        minWidth='120'
                        width='120'
                        visible={false}
                    >
                        <HeaderFilter>
                            <Search enabled={true} />
                        </HeaderFilter>
                    </Column>
                    <Column
                        dataField='IitRdchgc'
                        caption='RD Charge Code'
                        minWidth='120'
                        width='120'
                        visible={false}
                    >
                        <HeaderFilter>
                            <Search enabled={true} />
                        </HeaderFilter>
                    </Column>
                    <Column
                        dataField='IitIittype'
                        caption='Trux Type'
                        minWidth='120'
                        width='120'
                        visible={false}
                    >
                        <HeaderFilter>
                            <Search enabled={true} />
                        </HeaderFilter>
                    </Column>
                    <Column
                        dataField='IitCompacted'
                        caption='Compacted'
                        minWidth='120'
                        width='120'
                        visible={false}
                    >
                        <HeaderFilter>
                            <Search enabled={true} />
                        </HeaderFilter>
                    </Column>
                    <Column
                        dataField='IitMaxlifts'
                        caption='Max Lifts'
                        minWidth='120'
                        width='120'
                        visible={false}
                    >
                        <HeaderFilter>
                            <Search enabled={true} />
                        </HeaderFilter>
                    </Column>
                    <Column
                        dataField='SkillSetProperties'
                        caption='Specialties'
                        minWidth='120'
                        width='120'
                        visible={false}
                        calculateCellValue={renderSpeciality}
                    >
                        <HeaderFilter>
                            <Search enabled={true} />
                        </HeaderFilter>
                    </Column>
                </DataGrid>
            )}
        </>
    );
};

export const PinServiceLocationDialog = (props) => {
    const { pinServiceLocationDialogOpen, selectedServiceLocationIds, handlePinServiceLocationsDialogClose } = props;
    return (
        <Dialog
            open={pinServiceLocationDialogOpen}
            maxWidth={'sm'}
            fullWidth={true}
        >
            <DialogTitle>{'Pin ' + (selectedServiceLocationIds.length > 1 ? ' Stops' : ' Stop?')}</DialogTitle>
            <DialogContent>
                <DialogContentText id='alert-dialog-description'>
                    Are you sure you want to pin selected{' '}
                    {selectedServiceLocationIds.length > 1 ? selectedServiceLocationIds.length + ' stops' : ' stop'} to highlighted location?
                </DialogContentText>
            </DialogContent>
            <DialogActions style={{ justifyContent: 'center' }}>
                <Button
                    style={{ backgroundColor: '#ee8a1d', color: '#fff' }}
                    onClick={() => handlePinServiceLocationsDialogClose('CANCEL')}
                >
                    Cancel
                </Button>
                <Button
                    style={{ backgroundColor: '#16a8a6', color: '#fff' }}
                    onClick={() => handlePinServiceLocationsDialogClose('PIN')}
                >
                    Yes
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export const UnpinServiceLocationDialog = (props) => {
    const { unpinServiceLocationDialogOpen, selectedServiceLocationIds, handleUnpinServiceLocationsDialogClose } = props;
    return (
        <Dialog
            open={unpinServiceLocationDialogOpen}
            maxWidth={'sm'}
            fullWidth={true}
        >
            <DialogTitle>{'Unpin ' + (selectedServiceLocationIds.length > 1 ? ' Stops?' : ' Stop?')}</DialogTitle>
            <DialogContent>
                <DialogContentText id='alert-dialog-description'>
                    Are you sure you want to unpin selected{' '}
                    {selectedServiceLocationIds.length > 1 ? selectedServiceLocationIds.length + ' stops?' : ' stop?'}
                </DialogContentText>
            </DialogContent>
            <DialogActions style={{ justifyContent: 'center' }}>
                <Button
                    style={{ backgroundColor: '#ee8a1d', color: '#fff' }}
                    onClick={() => handleUnpinServiceLocationsDialogClose('CANCEL')}
                >
                    Cancel
                </Button>
                <Button
                    style={{ backgroundColor: '#16a8a6', color: '#fff' }}
                    onClick={() => handleUnpinServiceLocationsDialogClose('UNPIN')}
                >
                    Yes
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export const EditServiceLocationDialog = (props) => {
    const {
        siteMode,
        editServiceLocationOpen,
        serviceLocationTarget,
        updateServiceLocations,
        updateTimeWindow,
        handleEditServiceLocationClose,
        selectedServiceLocationIds,
        serviceLocationSpecialties,
        addServiceLocationSpecialty,
        deleteServiceLocationSpecialty,
        setManageSpecialtyDialogOpen,
        specialtyList,
        updateServiceLocationSpecialties,
    } = props;

    const [activeTab, setActiveTab] = useState(1);

    useEffect(() => {
        setActiveTab(1);
    }, [editServiceLocationOpen]);

    const AlleyServiceOptions = [
        { label: 'Yes', value: 'Y' },
        { label: 'No', value: 'N' },
    ];

    return (
        <Dialog
            open={editServiceLocationOpen}
            maxWidth={'md'}
            fullWidth={true}
        >
            <DialogTitle>Edit Stop</DialogTitle>
            <DialogContent>
                <>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs
                            onChange={(e, v) => setActiveTab(v)}
                            value={activeTab}
                        >
                            <Tab
                                label='GENERAL'
                                value={1}
                            />
                            <Tab
                                label='SPECIALTIES'
                                value={2}
                                disabled={siteMode === 'PLANNER'}
                            />
                        </Tabs>
                    </Box>
                    {activeTab === 1 && (
                        <Grid
                            container
                            spacing={'10px'}
                            style={{ paddingTop: '10px' }}
                        >
                            {serviceLocationTarget?.AccountId ? (
                                <Grid
                                    container
                                    item
                                    xs={12}
                                >
                                    <Typography style={{ fontSize: 12 }}>
                                        <b>Account ID:</b> {serviceLocationTarget?.AccountId}
                                        <br />
                                        <b>Address:</b> {serviceLocationTarget?.Address}
                                    </Typography>
                                </Grid>
                            ) : (
                                <Grid
                                    container
                                    item
                                    xs={12}
                                >
                                    <Typography style={{ fontSize: 12 }}>
                                        <b>Record Selected:</b> {selectedServiceLocationIds.length}
                                    </Typography>
                                </Grid>
                            )}

                            <Grid
                                container
                                item
                                xs={12}
                            >
                                <Grid
                                    item
                                    xs={1}
                                >
                                    <Typography
                                        variant='body1'
                                        gutterBottom
                                        style={{ paddingTop: '5px' }}
                                    >
                                        Demand:{' '}
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    xs={1}
                                    style={{ marginRight: '10px', width: '100px', maxWidth: '100px' }}
                                >
                                    <FloatInput
                                        placeholder='---'
                                        type='text'
                                        id='sl_demand'
                                        name='sl_demand'
                                        value={serviceLocationTarget?.Demand}
                                        onChange={(e) => updateServiceLocations('Demand', e)}
                                        style={{ border: '1px solid #ccc', borderRadius: '5px', paddingLeft: '5px' }}
                                    />
                                </Grid>

                                <Grid
                                    item
                                    xs={1}
                                />

                                <Grid
                                    item
                                    xs={1}
                                    style={{ marginRight: '10px', width: '100px', maxWidth: '100px' }}
                                >
                                    <Typography
                                        variant='body1'
                                        gutterBottom
                                        style={{ paddingTop: '5px', whiteSpace: 'nowrap' }}
                                    >
                                        Service Time:{' '}
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    xs={1}
                                >
                                    <ExpandedDurationInput
                                        placeholder='--:--:--'
                                        type='text'
                                        id='sl_servicetime'
                                        name='sl_servicetime'
                                        value={serviceLocationTarget?.ServiceTime}
                                        onChange={(e) => updateServiceLocations('ServiceTime', e)}
                                        style={{ border: '1px solid #ccc', borderRadius: '5px', paddingLeft: '5px' }}
                                    />
                                </Grid>

                                <Grid
                                    item
                                    xs={1}
                                />

                                {
                                    <>
                                        <Grid
                                            item
                                            xs={1}
                                            style={{ marginRight: '10px', width: '100px', maxWidth: '100px' }}
                                        >
                                            <Typography
                                                variant='body1'
                                                gutterBottom
                                                style={{ paddingTop: '5px', whiteSpace: 'nowrap' }}
                                            >
                                                Alley Service:{' '}
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={2}
                                        >
                                            <Select
                                                className='basic-single'
                                                classNamePrefix='select'
                                                isClearable={true}
                                                name='sl_alleyservice'
                                                value={AlleyServiceOptions.find((o) => o.value == serviceLocationTarget?.AlleyService)}
                                                onChange={(e) => updateServiceLocations('AlleyService', e)}
                                                options={AlleyServiceOptions}
                                                menuPortalTarget={document.body}
                                                styles={{
                                                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                                }}
                                            />
                                        </Grid>
                                    </>
                                }
                            </Grid>

                            <Grid
                                container
                                item
                                xs={10}
                            >
                                <Grid
                                    container
                                    item
                                    spacing={'10px'}
                                    style={{ paddingTop: '10px' }}
                                >
                                    <Grid
                                        container
                                        item
                                        xs={12}
                                    >
                                        <Grid
                                            item
                                            xs={3}
                                        />
                                        <Grid
                                            item
                                            xs={4}
                                            style={{ textAlign: 'center' }}
                                        >
                                            After
                                        </Grid>
                                        <Grid
                                            item
                                            xs={1}
                                        />
                                        <Grid
                                            item
                                            xs={4}
                                            style={{ textAlign: 'center' }}
                                        >
                                            Before
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        container
                                        item
                                        xs={12}
                                    >
                                        <Grid
                                            item
                                            xs={3}
                                        >
                                            <Typography
                                                variant='body1'
                                                gutterBottom
                                            >
                                                Service Window:{' '}
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={4}
                                        >
                                            <Select
                                                className='basic-single'
                                                classNamePrefix='select'
                                                isClearable={true}
                                                isSearchable={true}
                                                name='EarliestArrival'
                                                value={timeOfDayOptions_Start.find((o) => o.value == serviceLocationTarget?.EarliestArrival)}
                                                onChange={(e) => updateTimeWindow('EarliestArrival', e)}
                                                options={timeOfDayOptions_Start}
                                                menuPortalTarget={document.body}
                                                styles={{
                                                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                                }}
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={1}
                                        >
                                            <div style={{ width: '100%', textAlign: 'center' }}> - </div>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={4}
                                        >
                                            <Select
                                                className='basic-single'
                                                classNamePrefix='select'
                                                isClearable={true}
                                                isSearchable={true}
                                                name='LatestArrival'
                                                value={timeOfDayOptions_End.find((o) => o.value == serviceLocationTarget?.LatestArrival)}
                                                onChange={(e) => updateTimeWindow('LatestArrival', e)}
                                                options={timeOfDayOptions_End}
                                                menuPortalTarget={document.body}
                                                styles={{
                                                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                                }}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid
                                        container
                                        item
                                        xs={12}
                                    >
                                        <Grid
                                            item
                                            xs={3}
                                        >
                                            <Typography
                                                variant='body1'
                                                gutterBottom
                                            >
                                                Service Prohibited:{' '}
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={4}
                                        >
                                            <Select
                                                className='basic-single'
                                                classNamePrefix='select'
                                                isClearable={true}
                                                isSearchable={true}
                                                name='NoServiceStart'
                                                value={timeOfDayOptions_Prohibited.find((o) => o.value == serviceLocationTarget?.NoServiceStart)}
                                                onChange={(e) => updateTimeWindow('NoServiceStart', e)}
                                                options={timeOfDayOptions_Prohibited}
                                                menuPortalTarget={document.body}
                                                styles={{
                                                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                                }}
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={1}
                                        >
                                            <div style={{ width: '100%', textAlign: 'center' }}> - </div>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={4}
                                        >
                                            <Select
                                                className='basic-single'
                                                classNamePrefix='select'
                                                isClearable={true}
                                                isSearchable={true}
                                                name='NoServiceEnd'
                                                value={timeOfDayOptions_Prohibited.find((o) => o.value == serviceLocationTarget?.NoServiceEnd)}
                                                onChange={(e) => updateTimeWindow('NoServiceEnd', e)}
                                                options={timeOfDayOptions_Prohibited}
                                                menuPortalTarget={document.body}
                                                styles={{
                                                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                    {activeTab === 2 && (
                        <Box style={{ padding: '20px 250px' }}>
                            <Grid container>
                                <Grid
                                    container
                                    item
                                    xs={12}
                                    style={{ marginBottom: '10px', textAlign: 'center' }}
                                >
                                    <Grid
                                        item
                                        xs={11}
                                        style={{ textAlign: 'left', paddingLeft: '15px' }}
                                    >
                                        <Typography
                                            variant='body1'
                                            style={{ marginTop: '5px', color: 'rgba(0, 0, 0, 0.54)', fontSize: '14px', fontWeight: 700 }}
                                        >
                                            Specialty
                                            <IconButton onClick={() => setManageSpecialtyDialogOpen(true)}>
                                                <ManageAccountsIcon />
                                            </IconButton>
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={1}
                                        style={{ textAlign: 'center' }}
                                    >
                                        <IconButton
                                            title='New'
                                            color='primary'
                                            className={'icon_button'}
                                            style={{ padding: 0, backgroundColor: '#fff', color: '#16a8a6' }}
                                            onClick={() => addServiceLocationSpecialty()}
                                        >
                                            <i className='RS_ADD' />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                                <Grid
                                    container
                                    item
                                    xs={12}
                                >
                                    {serviceLocationSpecialties.map((ds) => {
                                        return (
                                            <>
                                                <Grid
                                                    item
                                                    xs={11}
                                                    key={ds.Id}
                                                >
                                                    <FormControl
                                                        sx={{ m: 1, minWidth: 250 }}
                                                        size='small'
                                                    >
                                                        <Select
                                                            isSearchable={true}
                                                            fullWidth
                                                            value={serviceLocationSpecialties.find((s) => s.Id === ds.Id)}
                                                            onChange={(e) => updateServiceLocationSpecialties(e, ds.Id)}
                                                            options={specialtyList}
                                                            menuPortalTarget={document.body}
                                                            styles={{
                                                                menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                                                height: '30px',
                                                                width: '250px',
                                                            }}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={1}
                                                    style={{ textAlign: 'center', alignContent: 'center' }}
                                                >
                                                    <IconButton
                                                        title='Delete'
                                                        color='primary'
                                                        className={'icon_button'}
                                                        style={{
                                                            padding: 0,
                                                            marginTop: '0',
                                                            backgroundColor: '#fff',
                                                            color: '#16a8a6',
                                                            textAlign: 'center',
                                                        }}
                                                        onClick={() => deleteServiceLocationSpecialty(ds.Id)}
                                                    >
                                                        <i className='RS_DELETE' />
                                                    </IconButton>
                                                </Grid>
                                            </>
                                        );
                                    })}
                                </Grid>
                            </Grid>
                        </Box>
                    )}
                </>
            </DialogContent>
            <DialogActions style={{ justifyContent: 'center' }}>
                <Button
                    style={{ backgroundColor: '#ee8a1d', color: '#fff' }}
                    onClick={() => handleEditServiceLocationClose('CANCEL')}
                >
                    Cancel
                </Button>
                {((serviceLocationTarget?.NoServiceStart == '0.0' && serviceLocationTarget?.NoServiceEnd == '0.0') ||
                    (serviceLocationTarget?.NoServiceStart != '0.0' && serviceLocationTarget?.NoServiceEnd != '0.0')) && (
                    <Button
                        style={{ backgroundColor: '#16a8a6', color: '#fff' }}
                        onClick={() => handleEditServiceLocationClose('SAVE')}
                    >
                        Save
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
};

export const ScenarioServiceLocationTable = (props) => {
    const title = 'Stops';

    const refScenarioServiceLocationListGrid = useRef();
    const {
        workspace,
        bottomPaneGrid,
        showScenarioExport,
        setShowScenarioExport,
        mapView,
        bottomPaneVisibility,
        bottomHeight,
        scenarioServiceLocationData,
        selectedRaaSServiceLocationIds,
        setSelectedRaaSServiceLocationIds,
        selectedScenarioRoutes,
    } = props;

    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [scenarioServiceLocationListView, setScenarioServiceLocationListView] = useState('All');

    React.useEffect(() => {
        if (bottomPaneGrid === 'ScenarioServiceLocation') {
            populateScenarioServiceLocations();
        }
    }, [bottomPaneGrid]);

    React.useEffect(() => {
        populateScenarioServiceLocations();
    }, [scenarioServiceLocationData]);

    React.useEffect(() => {
        if (refScenarioServiceLocationListGrid && refScenarioServiceLocationListGrid.current && refScenarioServiceLocationListGrid.current.instance) {
            refScenarioServiceLocationListGrid.current.instance.clearFilter();
        }
    }, [workspace]);

    React.useEffect(() => {
        if (scenarioServiceLocationListView === 'Selected' && selectedRaaSServiceLocationIds.length === 0) {
            setScenarioServiceLocationDataByView('All');
        } else if (scenarioServiceLocationListView === 'Selected') {
            refScenarioServiceLocationListGrid.current?.instance.filter(filterTableInstanceBasedOnSelectedLocationIds());
        }
        window.userSelect = false;
    }, [selectedRaaSServiceLocationIds]);

    React.useEffect(() => {
        if (filteredData && filteredData.length > 0) {
            setScenarioServiceLocationDataByView(filteredData);
        }
    }, [scenarioServiceLocationListView]);

    React.useEffect(() => {
        let stops = [...scenarioServiceLocationData];
        stops = stops.filter((d) => selectedScenarioRoutes.includes(d.Route));
        setData(stops);
    }, [selectedScenarioRoutes]);

    const populateScenarioServiceLocations = () => {
        if (refScenarioServiceLocationListGrid && refScenarioServiceLocationListGrid.current && refScenarioServiceLocationListGrid.current.instance) {
            refScenarioServiceLocationListGrid.current.instance.clearFilter();
            refScenarioServiceLocationListGrid.current.instance.beginCustomLoading();
        }
        let sortedData = [];
        if (scenarioServiceLocationData && scenarioServiceLocationData.length > 0) {
            sortedData = scenarioServiceLocationData.sort((a, b) => {
                let routeDiff = a.Route.localeCompare(b.Route, 'en', { numeric: true });
                if (!routeDiff) {
                    if (a.Sequence === b.Sequence) {
                        if (a.Status === 'M') {
                            return 1;
                        } else if (b.Status === 'M') {
                            return -1;
                        } else {
                            return a.Status.localeCompare(b.Status, 'en', { numeric: true });
                        }
                    } else {
                        return a.Sequence - b.Sequence;
                    }
                } else {
                    return routeDiff;
                }
            });
        }

        sortedData = sortedData.filter((s) => selectedScenarioRoutes.includes(s.Route));

        setFilteredData(sortedData);
        setScenarioServiceLocationDataByView(sortedData);
        setData(sortedData);
        if (refScenarioServiceLocationListGrid && refScenarioServiceLocationListGrid.current && refScenarioServiceLocationListGrid.current.instance) {
            refScenarioServiceLocationListGrid.current.instance.endCustomLoading();
        }
    };

    const setScenarioServiceLocationDataByView = (serviceLocationRows) => {
        if (serviceLocationRows) {
            switch (scenarioServiceLocationListView) {
                case 'All':
                    refScenarioServiceLocationListGrid.current?.instance.clearFilter();
                    break;
                case 'Selected':
                    refScenarioServiceLocationListGrid.current?.instance.filter(filterTableInstanceBasedOnSelectedLocationIds());
                    break;
                default:
                    break;
            }
        }
    };

    const zoomToScenarioServiceLocations = () => {
        let selScenarioServiceLocationXYs = [];
        scenarioServiceLocationData.forEach((row) => {
            if (selectedRaaSServiceLocationIds.includes(row.Id)) {
                selScenarioServiceLocationXYs.push([row.X, row.Y]);
            }
        });
        if (selScenarioServiceLocationXYs.length > 0) {
            var xs = selScenarioServiceLocationXYs.map(function (loc) {
                return loc[0];
            });
            var ys = selScenarioServiceLocationXYs.map(function (loc) {
                return loc[1];
            });

            var MinX = Math.min.apply(null, xs);
            var MinY = Math.min.apply(null, ys);
            var MaxX = Math.max.apply(null, xs);
            var MaxY = Math.max.apply(null, ys);

            if (MinX === MaxX && MinY === MaxY) {
                mapView.goTo({
                    center: [MinX, MaxY],
                    zoom: 17,
                });
            } else {
                let scenarioServiceLocationExtent = new Extent({ xmin: MinX, ymin: MinY, xmax: MaxX, ymax: MaxY });
                mapView.extent = scenarioServiceLocationExtent.expand(1.5);
            }
        }
    };

    const handleZoomToServiceLocation = (data) => {
        mapView.goTo({
            center: [data.X, data.Y],
            zoom: 17,
        });
    };

    const renderServiceDay = (data) => {
        if (data && data.ServiceDay) {
            return defaultServiceDayList[data.ServiceDay].label;
        }
        return '';
    };

    const renderExistServiceDay = (data) => {
        if (data && [...Array(7).keys()].map((i) => i + 1).includes(data.ExistServiceDay)) {
            return defaultServiceDayList[data.ExistServiceDay].label;
        }
        return '';
    };

    const filterTableInstanceBasedOnSelectedLocationIds = React.useCallback(() => {
        return selectedRaaSServiceLocationIds.reduce((acc, v, i) => {
            if (i > 0) acc.push('or');
            acc.push(['Id', '=', v]);
            return acc;
        }, []);
    }, [selectedRaaSServiceLocationIds]);

    return (
        <>
            {bottomPaneVisibility && bottomPaneGrid === 'ScenarioServiceLocation' && (
                <DataGrid
                    id={'scenarioServiceLocationGrid'}
                    ref={refScenarioServiceLocationListGrid}
                    style={{ height: bottomHeight }}
                    dataSource={data}
                    keyExpr='Id'
                    showBorders={true}
                    showColumnLines={true}
                    showRowLines={true}
                    allowColumnResizing={true}
                    allowColumnReordering={true}
                    columnResizingMode={'widget'}
                    columnMinWidth={50}
                    minWidth={1920}
                    columnAutoWidth={false}
                    selectedRowKeys={selectedRaaSServiceLocationIds}
                    onSelectionChanged={(e) => {
                        if (window.multiSelect) {
                            window.multiSelect = false;
                            return;
                        }
                        if (window.multiSelectTwo) {
                            window.multiSelectTwo = false;
                            return;
                        }
                        if (!window.userSelect) {
                            setSelectedRaaSServiceLocationIds(e.selectedRowKeys);
                            window.userSelect = true;
                        }
                    }}
                    onCellClick={(e) => {
                        if (e.columnIndex === 1) {
                            e.event.stopImmediatePropagation();
                        }
                    }}
                >
                    <SearchPanel
                        visible={true}
                        width={240}
                        placeholder='Search...'
                    />
                    <HeaderFilter visible={true} />
                    <FilterRow
                        visible={false}
                        applyFilter={true}
                    />
                    <FilterPanel visible={false} />
                    <Scrolling rowRenderingMode='virtual'></Scrolling>
                    <Paging defaultPageSize={10000} />
                    <GroupPanel visible={false} />
                    <Grouping autoExpandAll={false} />
                    <Pager
                        visible={true}
                        displayMode={'full'}
                        showPageSizeSelector={false}
                        showInfo={false}
                        showNavigationButtons={false}
                    />
                    <ColumnChooser
                        enabled={true}
                        mode={'select'}
                    >
                        <ColumnChooserSearch enabled={true} />
                        <ColumnChooserSelection
                            allowSelectAll={true}
                            selectByClick={true}
                            recursive={true}
                        />
                    </ColumnChooser>
                    <Selection
                        mode='multiple'
                        width={20}
                    />
                    <Scrolling mode='virtual' />
                    <Sorting
                        mode='multiple'
                        showSortIndexes='true'
                    />
                    <StateStoring
                        enabled={true}
                        type='localStorage'
                        storageKey='stopGridLayout'
                    />
                    <Toolbar>
                        <Item location='before'>
                            <div className='dx-fieldset-header'>&nbsp;{title}&nbsp;&nbsp;</div>
                        </Item>
                        <Item location='before'>
                            <DxButton
                                text={'All (' + filteredData.length + ')'}
                                type={scenarioServiceLocationListView === 'All' ? 'success' : 'normal'}
                                onClick={() => {
                                    setScenarioServiceLocationListView('All');
                                }}
                            ></DxButton>
                        </Item>
                        <Item location='before'>
                            <DxButton
                                text={'Selected (' + selectedRaaSServiceLocationIds.length + ')'}
                                type={scenarioServiceLocationListView === 'Selected' ? 'success' : 'normal'}
                                onClick={() => {
                                    setScenarioServiceLocationListView('Selected');
                                }}
                            ></DxButton>
                        </Item>

                        <Item location='before'>
                            <DxButton
                                hint='Zoom to selected stops'
                                onClick={() => zoomToScenarioServiceLocations()}
                            >
                                <i className={'RS_ZOOM_TO_SELECT'}></i>
                            </DxButton>
                        </Item>

                        <Item
                            location='before'
                            name='groupPanel'
                        >
                            <div style={{ width: '20px' }} />
                        </Item>

                        <Item
                            location='before'
                            visible={checkFunction(215)}
                        >
                            <DxButton
                                icon='RS RS_EXPORT_SERVICE_LOCATION RS_GRID_ICON'
                                hint={'Export Stops'}
                                disabled={!data || data.length === 0}
                                onClick={() => {
                                    setShowScenarioExport(!showScenarioExport);
                                }}
                            ></DxButton>
                        </Item>

                        <Item location='after'>
                            <DxButton
                                icon='eyeclose'
                                hint='Clear Filters'
                                onClick={() => {
                                    refScenarioServiceLocationListGrid.current.instance.clearFilter();
                                }}
                            />
                        </Item>
                        <Item
                            location='after'
                            name='columnChooserButton'
                        />
                        <Item
                            location='after'
                            name='searchPanel'
                        />
                    </Toolbar>
                    <Column
                        minWidth='20'
                        width='20'
                        allowHiding={false}
                        type='buttons'
                        showInColumnChooser={false}
                        allowReordering={false}
                        cellRender={(data) => {
                            return (
                                <>
                                    {(data.row.data.X !== 0 || data.row.data.Y !== 0) && (
                                        <span
                                            title='Zoom to'
                                            icon={'RS_ZOOM'}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                handleZoomToServiceLocation(data.row.data);
                                            }}
                                        >
                                            <div style={{ display: 'inline-block' }}>
                                                <i className={'RS_ZOOM'}></i>
                                            </div>
                                        </span>
                                    )}
                                </>
                            );
                        }}
                    />
                    <Column
                        dataField='Route'
                        minWidth='100'
                        width='100'
                        calculateCellValue={(data) => (data.Route === '0' ? 'No Route' : data.Route)}
                    >
                        <HeaderFilter>
                            <Search enabled={true} />
                        </HeaderFilter>
                    </Column>
                    <Column
                        dataField='Sequence'
                        minWidth='80'
                        width='80'
                    >
                        <HeaderFilter>
                            <Search enabled={true} />
                        </HeaderFilter>
                    </Column>
                    <Column
                        dataField='ServiceDay'
                        minWidth='100'
                        width='100'
                        caption='Service Day'
                        calculateCellValue={renderServiceDay}
                    >
                        <HeaderFilter>
                            <Search enabled={true} />
                        </HeaderFilter>
                    </Column>
                    <Column
                        dataField='ExistRoute'
                        caption='Exist Route'
                        minWidth='100'
                        width='100'
                        visible={false}
                    >
                        <HeaderFilter>
                            <Search enabled={true} />
                        </HeaderFilter>
                    </Column>
                    <Column
                        dataField='ExistSequence'
                        caption='Exist Sequence'
                        minWidth='120'
                        width='120'
                        visible={false}
                    >
                        <HeaderFilter>
                            <Search enabled={true} />
                        </HeaderFilter>
                    </Column>
                    <Column
                        dataField='Address'
                        minWidth='120'
                        width='150'
                    >
                        <HeaderFilter>
                            <Search enabled={true} />
                        </HeaderFilter>
                    </Column>
                    <Column
                        dataField='Building'
                        minWidth='60'
                        width='60'
                        caption='Bldg'
                    >
                        <HeaderFilter>
                            <Search enabled={true} />
                        </HeaderFilter>
                    </Column>
                    <Column
                        dataField='Apartment'
                        minWidth='60'
                        width='60'
                        caption='Apt'
                    >
                        <HeaderFilter>
                            <Search enabled={true} />
                        </HeaderFilter>
                    </Column>
                    <Column
                        dataField='City'
                        caption='City'
                        minWidth='75'
                        width='75'
                    >
                        <HeaderFilter>
                            <Search enabled={true} />
                        </HeaderFilter>
                    </Column>
                    <Column
                        dataField='PostalCode'
                        minWidth='100'
                        width='100'
                        caption='Postal Code'
                    >
                        <HeaderFilter>
                            <Search enabled={true} />
                        </HeaderFilter>
                    </Column>
                    <Column
                        dataField='ServiceTime'
                        minWidth='100'
                        width='100'
                        caption='Service Time'
                        calculateCellValue={(data) => (data.ServiceTime ? new Date(data.ServiceTime * 1000).toISOString().slice(11, 19) : '00:00:00')}
                    >
                        <HeaderFilter>
                            <Search enabled={true} />
                        </HeaderFilter>
                    </Column>
                    <Column
                        dataField='Demand'
                        minWidth='80'
                        width='80'
                        caption='Demand'
                    >
                        <HeaderFilter>
                            <Search enabled={true} />
                        </HeaderFilter>
                    </Column>
                    <Column
                        dataField='AccountId'
                        minWidth='80'
                        width='80'
                        caption='Account'
                    >
                        <HeaderFilter>
                            <Search enabled={true} />
                        </HeaderFilter>
                    </Column>
                    <Column
                        dataField='CustomerName'
                        minWidth='100'
                        width='260'
                        caption='Customer'
                    >
                        <HeaderFilter>
                            <Search enabled={true} />
                        </HeaderFilter>
                    </Column>
                    <Column
                        dataField='CompanyName'
                        minWidth='100'
                        width='200'
                        caption='Company'
                    >
                        <HeaderFilter>
                            <Search enabled={true} />
                        </HeaderFilter>
                    </Column>
                    <Column
                        dataField='Comments'
                        minWidth='100'
                        width='100'
                    >
                        <HeaderFilter>
                            <Search enabled={true} />
                        </HeaderFilter>
                    </Column>
                    <Column
                        caption='Service'
                        minWidth='125'
                        width='125'
                        alignment='center'
                        calculateCellValue={renderServiceWindow}
                        allowFiltering={true}
                    >
                        <HeaderFilter>
                            <Search enabled={true} />
                        </HeaderFilter>
                    </Column>
                    <Column
                        caption='Service Prohibited'
                        minWidth='125'
                        width='125'
                        alignment='center'
                        calculateCellValue={renderServiceProhibited}
                        allowFiltering={true}
                    >
                        <HeaderFilter>
                            <Search enabled={true} />
                        </HeaderFilter>
                    </Column>
                    <Column
                        dataField='FrequencyCode'
                        caption='Frequency Code'
                        minWidth='120'
                        width='120'
                        visible={false}
                    >
                        <HeaderFilter>
                            <Search enabled={true} />
                        </HeaderFilter>
                    </Column>
                    <Column
                        dataField='ProductCode'
                        minWidth='120'
                        width='120'
                        caption='Product Code'
                        visible={false}
                    >
                        <HeaderFilter>
                            <Search enabled={true} />
                        </HeaderFilter>
                    </Column>
                    <Column
                        dataField='X'
                        caption='X'
                        minWidth='50'
                        width='50'
                        visible={false}
                    >
                        <HeaderFilter>
                            <Search enabled={true} />
                        </HeaderFilter>
                    </Column>
                    <Column
                        dataField='Y'
                        caption='Y'
                        minWidth='50'
                        width='50'
                        visible={false}
                    >
                        <HeaderFilter>
                            <Search enabled={true} />
                        </HeaderFilter>
                    </Column>
                    <Column
                        dataField='ServiceType'
                        minWidth='120'
                        width='120'
                        caption='Service Type'
                        visible={false}
                    >
                        <HeaderFilter>
                            <Search enabled={true} />
                        </HeaderFilter>
                    </Column>
                    <Column
                        dataField='StreetName'
                        caption='Street Name'
                        minWidth='100'
                        width='100'
                        visible={false}
                    >
                        <HeaderFilter>
                            <Search enabled={true} />
                        </HeaderFilter>
                    </Column>
                    <Column
                        dataField='StreetNumber'
                        caption='Street Number'
                        minWidth='120'
                        width='120'
                        visible={false}
                    >
                        <HeaderFilter>
                            <Search enabled={true} />
                        </HeaderFilter>
                    </Column>
                    <Column
                        dataField='StreetPrefix'
                        caption='Street Prefix'
                        minWidth='120'
                        width='120'
                        visible={false}
                    >
                        <HeaderFilter>
                            <Search enabled={true} />
                        </HeaderFilter>
                    </Column>
                    <Column
                        dataField='StreetPretype'
                        caption='Street Pretype'
                        minWidth='120'
                        width='120'
                        visible={false}
                    >
                        <HeaderFilter>
                            <Search enabled={true} />
                        </HeaderFilter>
                    </Column>
                    <Column
                        dataField='StreetSuffix'
                        caption='Street Suffix'
                        minWidth='120'
                        width='120'
                        visible={false}
                    >
                        <HeaderFilter>
                            <Search enabled={true} />
                        </HeaderFilter>
                    </Column>
                    <Column
                        dataField='StreetType'
                        caption='Street Type'
                        minWidth='120'
                        width='120'
                        visible={false}
                    >
                        <HeaderFilter>
                            <Search enabled={true} />
                        </HeaderFilter>
                    </Column>
                    <Column
                        dataField='Week'
                        caption='Week'
                        minWidth='120'
                        width='120'
                        visible={false}
                    >
                        <HeaderFilter>
                            <Search enabled={true} />
                        </HeaderFilter>
                    </Column>
                    <Column
                        dataField='Month'
                        caption='Month'
                        minWidth='120'
                        width='120'
                        visible={false}
                    >
                        <HeaderFilter>
                            <Search enabled={true} />
                        </HeaderFilter>
                    </Column>
                </DataGrid>
            )}
        </>
    );
};

export const DeleteServiceLocationDialog = (props) => {
    const { deleteServiceLocationDialogOpen, selectedServiceLocationIds, handleDeleteSelectedServiceLocations } = props;
    return (
        <Dialog
            open={deleteServiceLocationDialogOpen}
            maxWidth={'sm'}
            fullWidth={true}
        >
            <DialogTitle>{'Delete ' + (selectedServiceLocationIds.length > 1 ? ' Stops?' : ' Stop?')}</DialogTitle>
            <DialogContent>
                <DialogContentText id='alert-dialog-description'>
                    Are you sure you want to delete {selectedServiceLocationIds.length} selected
                    {selectedServiceLocationIds.length > 1 ? ' stops?' : ' stop?'}
                </DialogContentText>
            </DialogContent>
            <DialogActions style={{ justifyContent: 'center' }}>
                <Button
                    style={{ backgroundColor: '#ee8a1d', color: '#fff' }}
                    onClick={() => handleDeleteSelectedServiceLocations('CANCEL')}
                >
                    Cancel
                </Button>
                <Button
                    style={{ backgroundColor: '#16a8a6', color: '#fff' }}
                    onClick={() => handleDeleteSelectedServiceLocations('DELETE')}
                >
                    Yes
                </Button>
            </DialogActions>
        </Dialog>
    );
};
